import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FlowContext } from "../../../../utils/utilities";
import {
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import {
    columnProps,
    initialFilter,
} from "../../../../../../../../general/@components/table/utils/GridCustomProps";
import { workgroupsApi } from "../../../../../../../../general/services/administrator";
import { ActionButton } from "../../../../../../../../general/@components/table/utils/ActionsColumn";
import {
    faArrowProgress,
} from "@fortawesome/pro-light-svg-icons";
import {
    faArrowProgress as faArrowProgressAlt,
} from "@fortawesome/pro-solid-svg-icons";
import FlowModal from "./FlowModal";
import { useWatch } from "react-hook-form";
import PropTypes from "prop-types";
import FormError from "../../../../../../../../general/@components/form/Error";
import KendoTable from "../../../../../../../../general/@components/table/KendoTable";
import FormInput from "../../../../../../../../general/@components/form/Field";
import { scaleOpacityAnimation } from "../../../../../../../../general/@components/form/Permission/Utils";
import { ServerAPI } from "../../../../../../../../general/services/ServerAPI";
import { CATALOGS_APIS } from "../../../../../../workgroup/Workgroup/util";
import { findIndex, isUndefined, each } from "lodash";
import { getStructureFields } from "../../../../../../../../general/@components/filter/structureFilters";
import { useDialogContext } from "../../../../hooks/DialogController";
import { Grid } from "@mui/material";
import { UserTypeSelection } from "../User";
import { store } from "../../../../../../../../../store";

function Workgroups() {

    const {
        control,
        setInAccessView,
        setHeightIncreased,
    } = useDialogContext();

    useEffect(() => {
        setInAccessView(true);
        setHeightIncreased(true);
        return () => {
            setInAccessView(false);
            setHeightIncreased(false);
        }
    }, []);

    return (
        <>
            <FlowModal />
            <AnimatePresence mode='wait' initial={false}>
                <motion.div
                    className='workgroups-main-container'
                    key='workgroups-module'
                    {...scaleOpacityAnimation}
                >
                    <ToogleWorkgroups />
                    <FormError control={control} name="admin_workgroups" />
                    <WorkgroupTable />
                </motion.div>
            </AnimatePresence>
        </>
    )
}

export default Workgroups;

const WorkgroupTable = () => {
    const { t } = useTranslation();

    const {
        current,
        setValue,
        control,
        getValues,
        structure,
        canWrite,
    } = useDialogContext();

    const onlyAssigned = useWatch({ control, name: 'only_assigned' });
    const userType = useWatch({ control, name: 'user_type' });
    const isCapture = parseInt(userType) === 1;

    const [filter, setFilter] = useState(initialFilter);
    const [counter, setCounter] = useState(0);
    const userId = getValues('id');
    const initialWorkgroups = getValues('admin_workgroups');

    const include = [
        'flow_signature_template.administrator',
        ...structure
    ];

    const params = {
        tenant: current?.id,
        include: include.join(','),
        type: userType,
        ordering: 'branch__key,area__key,departament__key,name',
        ...(onlyAssigned && userId ? { users: `${userId}` } : {})
    };

    useEffect(() => {
        onRefresh();
    }, [onlyAssigned, userType])

    const onRefresh = () => setCounter((prev) => prev + 1);

    const onSelectionChange = (selecteds) => setValue('admin_workgroups', selecteds, { shouldValidate: true });

    return (
        <KendoTable
            Api={workgroupsApi}
            className='workgroups-grid'
            selectionMode="all"
            filters={filter}
            actions={false}
            is_active={false}
            disabled={!canWrite}
            setFilters={setFilter}
            onRefresh={onRefresh}
            extraOptions={params}
            refreshCounter={counter}
            selected={initialWorkgroups}
            onSelectionChange={onSelectionChange}
        >

            {isCapture && structure.map((item) => (
                <Column
                    key={item}
                    {...columnProps(item, filter, "serverdropdown", [
                        ServerAPI(CATALOGS_APIS[item]).get,
                        "id",
                        "name",
                        {
                            searchBy: "search",
                            filterEfields: "key|name",
                            filterSplit: "-",
                            format: "key|name",
                            fields: "id,key,name",
                            noDefault: true,
                            changeCallback: (filters, callback) =>
                                changeCallback(filters, callback, item),
                            extraFilters: getExtras(item, filter),
                        },
                    ])}
                    title={t(item.replaceAll("_", "-"))}
                />
            ))}

            <Column
                {...columnProps("name", filter, 'input')}
                title={t("name")}
            />

            {/* {isNull(wgType) &&
                <Column
                    {...columnProps("type", filter, 'dropdown', [...typeFilterConfig])}
                    width={170}
                    title={t("type")}
                    cell={TypeColumn}
                />} */}

            {userType === 1 &&
                <Column
                    title={t("actions")}
                    width={80}
                    cell={ActionsColumn}
                />}
        </KendoTable>
    );
}

const ToogleWorkgroups = () => {

    const { control, isEdit } = useDialogContext();

    return (
        <Grid container spacing={2}>
            {isEdit &&
                <Grid item>
                    <FormInput
                        control={control}
                        name="only_assigned"
                        label="Mostrar solo asignados"
                        fieldInput="Checkbox"
                    />
                </Grid>}
            <Grid item>
                <UserTypeSelection />
            </Grid>
        </Grid>
    );
}

const ActionsColumn = ({ dataItem }) => {

    const {
        handleOpen,
    } = useContext(FlowContext);

    ActionsColumn.propTypes = {
        dataItem: PropTypes.object,
    };

    const { t } = useTranslation();

    return (
        <td className="k-command-cell locked-btns">
            <ActionButton
                scope="read"
                title={t("flow")}
                icon={faArrowProgress}
                altIcon={faArrowProgressAlt}
                onClick={() => handleOpen(dataItem)}
            />
        </td>
    );
}

export const getExtras = (column, filters) => {
    const structure = store.getState()?.configuration?.steps?.slice(0, -3)?.map(el => el.step) ?? [];
    const index = findIndex(structure, function (o) {
        return o.step === column;
    });
    const considerate = getStructureFields(index, structure, "before");
    const values = filters?.filters || filters || {};

    let extras = {};
    each(considerate, (extra) => {
        const extraValue = values[extra];
        if (
            !isUndefined(extraValue) &&
            extraValue !== null &&
            extraValue !== ""
        ) {
            extras[extra] = extraValue;
        }
    });
    return extras;
};

export const changeCallback = (filters, callback, column) => {
    const structure = store.getState()?.configuration?.steps?.slice(0, -3)?.map(el => el.step) ?? [];
    const index = findIndex(structure, function (o) {
        return o.step === column;
    });
    const afterFields = getStructureFields(index, structure, "after");
    const newFilters = filters?.filters || filters || {};
    each(afterFields, (step) => {
        if (newFilters[step]) {
            delete newFilters[step];
        }
    });
    callback({ filters: newFilters });
};

// const TypeColumn = ({
//     field,
//     rowType,
//     dataItem,
//     className,
//     style,
// }) => {

//     TypeColumn.propTypes = {
//         field: PropTypes.string,
//         rowType: PropTypes.string,
//         dataItem: PropTypes.object,
//         className: PropTypes.string,
//         style: PropTypes.object,
//     };

//     const { t } = useTranslation();

//     const typeValidation = (type) => {

//         if (type === null) {
//             return null;
//         }

//         const icons = [faUserGroupSimple, faShieldKeyhole];
//         const title = [t('workgroup-admin3'), t('workgroup-admin2')];
//         const style = ["blueActionLabel", "orangeActionLabel"];
//         return (
//             <span className={"actionLabel " + style[type]}>
//                 <FontAwesomeIcon icon={icons[type]} />  {t(title[type])}
//             </span>
//         );
//     };

//     if (rowType) {
//         let type
//         if (field.split(".").length === 2) {
//             const value = field.split(".");
//             type = dataItem?.[value[0]]?.[value[1]];
//         } else {
//             type = dataItem[field];
//         }
//         if (rowType === "groupHeader") {
//             return null;
//         }
//         return (
//             <td className={`${className} k-command-cell text-center defaultCursor `} style={style}>
//                 {typeValidation(type - 1)}
//             </td>
//         )
//     }

//     return null;
// }