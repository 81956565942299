import React from "react";
/* own components */
// import Chat from "./Chat";
import UserSection from "../userSection";

const HeaderRight = () => {

	return (
		<div className="app-header-right">
			{/* <div className="header-dots">
				<Language />
				<Notifications />
				<Chat />
			</div> */}
			<UserSection />
		</div>
	);
};

export default HeaderRight;