import React from "react";
import { uid } from 'uid';
import {
	isFunction,
	pick
} from 'lodash';
import TextField from '@mui/material/TextField';
import { MaterialLabelContainer } from '../../../Templates/SideLabelContainer';
import { valueOrOption } from '../../../general/GeneralUtilities';
import { materialSizeClasses } from '../../../uiDesign/sizesUtilities';
import { SearchAdorment } from './SearchAdorment';
import { InputProps } from './propsDescriptions';

function Input({
	label = "",
	sideLabel = false,
	inputProps = {},
	InputProps = {},
	autoFocus = false,
	...props
}) {

	const {
		id,
		name,
		value,
		onChange,
		disabled,
		disableSearch,
		disableInput,
		clearValue,
		onSearch,
		onClear,
		prevChangeFunction,
	} = props;

	label = valueOrOption(label, "");
	const { size, className } = materialSizeClasses(props);
	const fieldId = id || `searchInput_${uid()}}`;

	const handleChange = (event) => {
		let value = event?.value || event?.target?.value || "";
		if (isFunction(prevChangeFunction)) {
			value = prevChangeFunction(value);
		}

		if (isFunction(onChange)) {
			onChange({
				type: "change",
				value: value,
				target: { value: value }
			});
			return;
		}
		return event;
	};

	const handleClick = (event) => {
		if (disabled) { return; }

		if (disableInput && !disableSearch) {
			if (isFunction(onSearch)) {
				onSearch(event);
				return;
			}
		}
	};

	const handleClear = () => {
		const event = { value: valueOrOption(clearValue, "") };
		handleChange(event);
		if (isFunction(onClear)) {
			onClear(event.value);
		}
	};

	return (
		<MaterialLabelContainer label={label} sideLabel={sideLabel} >
			<TextField
				fullWidth
				{...getProps(props)}
				autoComplete="off"
				id={fieldId}
				disabled={disabled}
				name={name || fieldId}
				autoFocus={autoFocus}
				value={value ?? ""}
				size={size}
				label={label}
				onChange={handleChange}
				className={`${className} CustomTextField endButtonAsKendo search-input`}
				inputProps={{
					...inputProps,
					autoComplete: "off",
					...(disableInput ? { tabIndex: -1 } : {}),
				}}
				InputProps={{
					...InputProps,
					...(disableInput ? { onClick: handleClick } : {}),
					autoComplete: "off",
					readOnly: disableInput,
					endAdornment: <SearchAdorment {...props} onClear={handleClear} />
				}}
			/>
		</MaterialLabelContainer>
	);
};

Input.propTypes = InputProps;

export default Input;

/* regresa solo las propiedades que son validas para el input, si hay alguna propiedad faltante se agrega en el array */
const getProps = (props) => {
	props = props ?? {};
	return pick(props, [
		"error", "fullWidth", "onKeyPress",
		"placeholder", "sx", "className",
		"onKeyDown", "onKeyUp", "onBlur", "onFocus",
		"tabIndex", "onDoubleClick", "style"
	]);
};