import PropTypes from "prop-types";
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { valueOrOption } from '../../../general/GeneralUtilities';
import { CustomTooltip } from '../../../Templates/cells';
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { InputSuffix } from "@progress/kendo-react-inputs";
import { TooltipTitle } from './TooltipTitle';

export const SufixIcon = ({
	helpIcon, titleHelp
}) => {

	if (!helpIcon) {
		return null;
	}

	return (
		<InputSuffix>
			<CustomTooltip title={valueOrOption(titleHelp, <TooltipTitle />)}>
				<FontAwesomeIcon
					icon={faQuestionCircle}
					style={{
						color: 'var(--egyptianBlue)',
						fontSize: '20px',
						marginRight: "5px",
					}} />
			</CustomTooltip>
		</InputSuffix>
	);
};
SufixIcon.propTypes = {
	helpIcon: PropTypes.any,
	titleHelp: PropTypes.any
};
