import { lazy } from "react";

const usersRolesItems = [
    {
        id: 'user-and-roles@users',
        type: 'item',
        icon: 'users',
        componentElm: lazy(async ()  => import('../../../../../modules/system/principal/User')),
    },
    {
        id: 'user-and-roles@roles-permissions',
        type: 'item',
        icon: 'roles-and-permissions',
        componentElm: lazy(async ()  => import('../../../../../modules/system/principal/Roles'))
    },
    {
        id: 'user-and-roles@org-chart',
        type: 'item',
        icon: 'organization-chart',
        componentElm: lazy(async ()  => import('../../../../../modules/culture-people-main/organization/OrganizationChart'))
    },
];

export default usersRolesItems;