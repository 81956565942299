import React, { forwardRef } from 'react';
import { uid } from 'uid';
import { TextBox as Input } from "@progress/kendo-react-inputs";
import { objectValue } from '../../form/utilities';
import { KendoLabelContainer } from '../../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../../uiDesign/sizesUtilities';

const Textbox = forwardRef(({
	id,
	value,
	label,
	onChange,
	sideLabel = false,
	...others
}, ref) => {

	const { size, className } = kendoSizeClasses(others);
	const editorId = id || `textbox_${uid()}`;

	const handleChange = e => {
		if (typeof onChange === "function") {
			const value = e?.value ?? e?.target?.value ?? null;
			return onChange(objectValue(e, value, false));
		}
		return e;
	};

	return (
		<KendoLabelContainer
			label={label}
			editorId={editorId}
			editorValue={value}
			className={"custom-floating-label"}
			sideLabel={sideLabel}
		>
			<Input
				{...others}
				ref={ref}
				size={size}
				id={editorId}
				value={value}
				className={`${className || ""}`}
				onChange={handleChange}
			/>
		</KendoLabelContainer>
	);
});

Textbox.displayName = "Textbox";

export default Textbox;