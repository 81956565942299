import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from 'react';
import { isEqual, size as _size, forEach, debounce, isFunction } from "lodash";
import i18next from 'i18next';
import { uid } from 'uid';
/* Kendo */
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

/* components */
import { listNoDataRender } from "../../Templates/listNoDataRender";

/* utilities */
import {
	getValue,
	realValue,
	getTagText,
	itemRender,
	constructData
} from './utilities';
import { hasValue, valueOrOption } from '../../general/GeneralUtilities';
import { CustomTooltip } from '../../Templates/cells';
import { KendoLabelContainer } from '../../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../../uiDesign/sizesUtilities';
import { conditional } from "../../table/CommandCell";
const { t } = i18next;

const calculateChipsWidth = (items) => {
	if (!_size(items)) return;
	let totalWidth = 0
	forEach(items, (item) => {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		context.font = '10px';
		const metrics = context.measureText(item?.label);
		totalWidth += (metrics?.width + 45);
	});
	return totalWidth;
}

const KendoMultiselect = ({
	id,
	label,
	value,
	options,
	data,
	onChange,
	keyField,
	valueField,
	textField,
	name,
	onClose,
	valuesByKeyField = false,
	multiTags = true,
	format,
	disabled,
	popupSettings = {},
	firstSelect = true,
	sideLabel = false,
	withAllSelectionLabel = true,
	...others
}) => {

	const { size, className } = kendoSizeClasses(others);
	const [inputWidth, setInputWidth] = useState(0);
	const fieldRef = useRef(null);
	const hasFilter = useRef(false);
	const firstSelected = useRef(false);
	const fieldId = id || "multiselect_" + uid();
	const renderLabelField = format ? 'renderLabelField' : textField;
	keyField = valueOrOption(keyField, valueField);
	options = valueOrOption(options, data);
	data = constructData(options, keyField, textField, format, withAllSelectionLabel);

	const initialState = {
		data: data,
		filter: '',
		value: getValue(options, value, data, keyField),
		allSelected: _size(value) >= _size(options)
	};
	const [state, setState] = useState(initialState);

	const handleResize = useRef(debounce(() => {
		setInputWidth(fieldRef.current?.base?.wrapper?.offsetWidth);
	}, 100)).current;

	const resizeOb = useRef(new ResizeObserver(handleResize)).current;

	useEffect(() => {
		if (!resizeOb || !fieldRef.current) return;
		resizeOb.observe(fieldRef.current?.base?.wrapper);
		return () => resizeOb?.disconnect();
	}, [])

	useEffect(() => {
		if (!isEqual(initialState, state) && _size(options) > 1) {
			setState(initialState);
		}
		// eslint-disable-next-line
	}, [value, options]);

	useEffect(() => {
		if (_size(options) && !firstSelected.current && firstSelect) {
			selectFirst(options);
			firstSelected.current = true;
		}
	}, [options])

	const selectFirst = (dataList) => {
		if (_size(dataList) === 1) {
			const newEvent = {
				value: [...dataList],
				target: {
					value: [...dataList]
				}
			}
			if (state.value.find(el => el?.id === dataList[0]?.id)) return;
			handleChange(newEvent);
		}
	};

	const filterChange = (event) => {
		if (event.nativeEvent.type === 'input') {
			hasFilter.current = hasValue(event.filter.value);
			setState({
				data: filterBy(data, { ...event.filter, field: renderLabelField }),
				filter: event.filter.value,
				value: state.value,
				allSelected: state.allSelected
			});
		}
	};

	const handleChange = (event) => {
		const filter = {
			field: renderLabelField,
			operator: 'contains',
			ignoreCase: true,
			value: state.filter
		};

		const currentSelectAll = state.value.some((i) => i[keyField] === t("all-items"));
		const nextSelectAll = event.target.value.some((i) => i[keyField] === t("all-items"));

		let currentValue = event.target.value;
		const currentCount = _size(state.value);
		const nextCount = _size(currentValue);

		if ((nextCount > currentCount && !currentSelectAll && !nextSelectAll && _size(data) - 1 === nextCount) && _size(options) !== 1 || (!currentSelectAll && nextSelectAll)) {
			currentValue = data;
		} else if (nextCount < currentCount && currentCount === _size(data) && currentSelectAll && nextSelectAll) {
			currentValue = currentValue.filter((v) => v[keyField] !== t("all-items"));
		} else if ((currentSelectAll && !nextSelectAll) || nextCount === 0) {
			currentValue = [];
		}

		if (fieldRef.current && event?.syntheticEvent?.currentTarget?.className === "k-clear-value") {
			currentValue = [];
			fieldRef.current.handleBlur();
		}

		if (typeof onChange === "function") {
			const newData = currentValue.filter((v) => v[keyField] !== t("all-items"));
			onChange(realValue(newData, valuesByKeyField, keyField));
		}


		setState({
			data: filterBy(data, filter),
			filter: filter.value,
			value: currentValue,
			allSelected: (_size(currentValue) >= _size(options)),
		});

	};

	const onCustomClose = () => {
		const closeState = {
			data: data,
			filter: "",
			value: state.value,
			allSelected: state.allSelected
		};

		if (!isEqual(state, closeState)) {
			setState(closeState);
		}

		const stateValues = state.value.filter((v) => v[keyField] !== t("all-items"));

		if (!isEqual(value, stateValues) && typeof onChange === "function") {
			onChange(realValue(stateValues, valuesByKeyField, keyField));
		}
		if (isFunction(onClose)) {
			onClose();
		}
	};

	let actualVal = state.value || value || [];
	const selecteds = _size(actualVal);
	const realTags = actualVal.filter((v) => v[keyField] !== t("all-items"));
	const totalTagsWidth = calculateChipsWidth(realTags);
	let tags = [];

	if ((multiTags || disabled) && inputWidth > totalTagsWidth) {
		tags = realTags.map(item => ({
			text: getTagText(item, valueOrOption(format, textField)),
			data: [item]
		}));
	} else {
		switch (selecteds) {
			case 0:
				tags = [];
				break;
			case 1:
				tags = [{
					text: getTagText(actualVal[0], valueOrOption(format, textField)),
					data: actualVal
				}];
				break;
			default:
				tags = [{
					text: conditional(state.allSelected, t("all-items"), `${selecteds} ${t("selected")}`),
					data: conditional(state.allSelected, state.data, actualVal)
				}];
				break;
		}
	}

	return (
		<KendoLabelContainer
			label={label}
			editorId={fieldId}
			editorValue={_size(actualVal) > 0}
			className={"custom-floating-label"}
			sideLabel={sideLabel}
		>
			<MultiSelect
				{...others}
				ref={fieldRef}
				className={`${className} ${others.valid === false ? "k-invalid" : ""}`}
				id={fieldId}
				data={conditional(_size(options) > 0, state.data, [])}
				onChange={handleChange}
				value={actualVal}
				name={valueOrOption(name, fieldId)}
				filterable={true}
				onFilterChange={filterChange}
				listNoDataRender={(element) => listNoDataRender(element, t(hasFilter.current ? "no-options-mached" : "no-options"))}
				filter={state.filter}
				dataItemKey={keyField}
				textField={textField}
				itemRender={(li, itemProps) => itemRender(li, itemProps, format ?? textField)}
				autoClose={false}
				onClose={onCustomClose}
				size={valueOrOption(size, "medium")}/* options -> ["small", "medium", "large"]  */
				disabled={disabled}
				tags={tags}
				tagRender={(data, itemProps) => TagRender(data, itemProps, textField, keyField)}
				popupSettings={{
					...popupSettings,
					popupClass: "customPopup"
				}}
			/>

		</KendoLabelContainer>
	);
};

KendoMultiselect.propTypes = {
	data: PropTypes.any,
	disabled: PropTypes.any,
	firstSelect: PropTypes.bool,
	format: PropTypes.any,
	id: PropTypes.any,
	keyField: PropTypes.any,
	label: PropTypes.any,
	multiTags: PropTypes.bool,
	name: PropTypes.any,
	onChange: PropTypes.func,
	onClose: PropTypes.func,
	options: PropTypes.any,
	popupSettings: PropTypes.object,
	sideLabel: PropTypes.bool,
	textField: PropTypes.any,
	value: PropTypes.any,
	valueField: PropTypes.any,
	valuesByKeyField: PropTypes.bool,
	withAllSelectionLabel: PropTypes.bool
}

export default KendoMultiselect;

const TagRender = (tagData, li, textField, keyField) => {
	const oneTag = ['Seleccionados', t('all-items')];
	const itemText = tagData?.text?.toLowerCase();
	const selected = oneTag.some(el => itemText?.includes(el.toLowerCase()));
	const spanStyle = { fontSize: '10px', flexWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };
	const customSpan = <span key={uid()} style={spanStyle}>{tagData?.text}</span>
	const CustomContent = <span key={uid()} style={{ overflow: 'hidden' }}>{React.cloneElement(li, li.props, [customSpan, li.props.children])}</span>;

	return (
		<CustomTooltip key={uid()} title={selected ? <TooltipBody data={tagData?.data} textField={textField} keyField={keyField} /> : tagData.text}>
			{CustomContent}
		</CustomTooltip>
	);
};

const TooltipBody = ({
	data,
	textField,
	keyField
}) => (
	<div style={{ display: 'flex', flexDirection: 'column' }}>
		{
			data?.filter(el => !isNaN(parseInt(el[keyField])))?.map(el =>
				<span key={uid()}>
					{el.renderLabelField ? el.renderLabelField : el[textField]}
				</span>
			)
		}
	</div>
);

TooltipBody.propTypes = {
	data: PropTypes.any,
	keyField: PropTypes.any,
	textField: PropTypes.any
}
