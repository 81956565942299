import PropTypes from "prop-types";
import React from 'react';
import Alert from "@mui/material/Alert";
import { AnimatePresence, motion } from "framer-motion";
import { GridType } from '../layout/Fieldset';
import { omit, pick } from 'lodash';

/**
 * Alerta basada en Material, para mas propiedades leer la documentación oficial
 * @param {object} props
 * @param {"error"|"warning"|"success"|"info"} props.severity - El color del mensaje por default Info
 * @param {*} props.children - Cualquier cosa que se quiera contener dentro de la alerta
 * @param {*} props.others - Cualquier otra ó otras porpiedades que pueda tener la alerta en la documentación oficial
 * @returns {AlertComponent}
 * @example
 * <MuiAlert severity="warning">
 *		Mensaje de advertencia
 *	</MuiAlert>
 */
const MuiAlert = ({
	severity = "info",
	children,
	asV2 = false,
	...others
}) => {

	const sizes = ["size", "xs", "sm", "md", "lg", "xl"];

	return (
		<GridType xs={12} size={12} asV2={asV2} {...pick(others, sizes)} item >
			<AnimatePresence>
				<motion.div
					initial={{ opacity: 0, y: -50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3 }}
				>
					<Alert severity={severity} {...omit(others, sizes)}>
						{children}
					</Alert>
				</motion.div>
			</AnimatePresence>
		</GridType>
	);
};

MuiAlert.propTypes = {
	asV2: PropTypes.bool,
	children: PropTypes.any,
	severity: PropTypes.string,
};

export default MuiAlert;