import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
/* Mui */
import Grid from "@mui/material/Grid2";
//--project imports
import { hideOptioNotificationDialog, showNotificationError } from '../../../../store/actions';
import { useTranslation } from "react-i18next";
import FormDialog from '../modal/dialog/FormDialog';
import DialogContent from '../../styled/DialogContent';
import { hasValue, valueOrOption } from '../general/GeneralUtilities';
import OptionCard from './OptionCard';
import SubTitle from '../layout/Subtitle';
import Actions from './Actions';


const OptionNotification = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		show,
		maxWidth,
		title,
		defaultSelection,
		message,
		submitText,
		optionsList,
		onConfirm,
		onCancel,
		customChildren,
	} = useSelector(state => state.notification.optionNotification) ?? {};

	const [selected, setSelected] = useState(null);

	useEffect(() => {
		if (!show) {
			setSelected(null);
			return;
		}

		if (hasValue(defaultSelection)) {
			setSelected(defaultSelection);
		}
		//eslint-disable-next-line
	}, [show]);


	const handleClose = () => {
		dispatch(hideOptioNotificationDialog());
		if (typeof onCancel === "function") {
			onCancel();
		}
	};

	const onAccept = () => {
		if (!hasValue(selected)) {
			return dispatch(showNotificationError({
				maxWidth: "sm",
				message: t("selecte-option-is-required")
			}));
		}

		dispatch(hideOptioNotificationDialog());
		if (typeof onConfirm === "function") {
			onConfirm(selected);
		}
	};

	const sizes = {
		"sm": { xs: 6 },
		"md": { xs: 6 },
		"lg": { xs: 4 },
		"xl": { xs: 3 }
	}[maxWidth ?? "md"] ?? { xs: 6 };

	return (
		<FormDialog
			open={show}
			title={valueOrOption(title)}
			maxWidth={valueOrOption(maxWidth, "md")}
			handleClose={handleClose}
			fullWidth
		>
			<DialogContent className={""}>
				<Grid container spacing={2}>
					{hasValue(message) && <SubTitle asV2 title={message} />}
					{valueOrOption(optionsList, []).map(item => (
						<Grid key={item.value} size={sizes}>
							<OptionCard {...item} selected={selected} onClick={setSelected} />
						</Grid>
					))}
					<Grid size={12}>{customChildren}</Grid>
				</Grid>
			</DialogContent>
			<Actions handleClose={handleClose} selected={selected} submitText={submitText} onAccept={onAccept} />
		</FormDialog>
	);
};

export default OptionNotification;
