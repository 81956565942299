import PropTypes from "prop-types";
import React, { useRef, forwardRef, useState } from 'react';
import MUIDialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Drag from 'react-draggable';
import DialogTitle from './DialogTitle';
import { Slide } from '@mui/material';
import { valueOrOption } from '../@components/general/GeneralUtilities';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props = []) {
	const nodeRef = useRef(null);
	const [bounds, setBounds] = useState({});

	const onStart = (uiData) => {
		const { clientWidth, clientHeight } = window?.document?.documentElement ?? {};
		const targetRect = nodeRef?.current?.getBoundingClientRect();
		if (targetRect) {
			setBounds({
				left: -targetRect?.left + uiData?.x,
				right: clientWidth - (targetRect?.right - uiData?.x),
				top: -targetRect?.top + uiData?.y,
				bottom: clientHeight - (targetRect?.bottom - uiData?.y),
			});
		}
	};

	return (
		<Drag
			handle={`#${valueOrOption(props["aria-labelledby"], "draggable-dialog-title")}`}
			cancel={'[class*="MuiDialogContent-root"],[aria-label="close"]'}
			nodeRef={nodeRef}
			bounds={bounds}
			onStart={(_, data) => onStart(data)}
		>
			<Paper {...props} ref={nodeRef} />
		</Drag>
	);
}

/**
 * Dialogo general del sistema basado en Mui asi que puede utilizar todas las propiedades de estsos
 * @param {object} props - son todas las propiedades que podemos pasar
 * @param {"default"|"modern"} props.design - El estilo del modal a mostrar
 * @param {?string|node} props.title - El titulo a mostrar en la modal
 * @param {?string|node} props.subTitle - El sub-titulo a mostrar en la modal
 * @param {?string} props.className - un custom classname en caso de requerirlo
 * @param {?string} props.dialogId - Un id custom por el dialog, para manejar los dragables por separado
 * @returns {node}
 */
const Dialog = ({
	design = "default",
	title,
	subTitle,
	className,
	onClose,
	dialogId,
	disableEscape = false,
	...props
}) => {

	const dialogStyle = {
		default: "",
		modern: "modern-dialog"
	}[design] ?? "";

	dialogId = valueOrOption(dialogId, "draggable-dialog-title");

	return (
		<MUIDialog
			TransitionComponent={Transition}
			{...props}
			onClose={onClose}
			className={`abrhil-dialog ${className} ${dialogStyle}`}
			disableEscapeKeyDown={disableEscape}
			PaperComponent={PaperComponent}
			aria-labelledby={dialogId}
		>
			{design === "modern" && (
				<DialogTitle
					title={title}
					subTitle={subTitle}
					onClose={onClose}
					design={design}
					dialogId={dialogId}
				/>
			)}
			{props.children}
		</MUIDialog>
	);
};

Dialog.propTypes = {
	className: PropTypes.any,
	design: PropTypes.string,
	dialogId: PropTypes.string,
	disableEscape: PropTypes.bool,
	onClose: PropTypes.any,
	subTitle: PropTypes.any,
	title: PropTypes.any
};

export default Dialog;