import { head, isArray } from 'lodash';
import lang from 'i18next';
const STATUS = [400, 404, 405]

const getMessage = (data) => {
	const keys = Object.keys(data);
	const firstKey = head(keys);
	const messages = data[firstKey]
	return lang.t(isArray(messages) ? head(messages) : messages);
}

export const httpError = (error, def) => {
	const { data, status } = error?.response ?? {};

	return STATUS.find(s => s === status) ? getMessage(data) : def;
}