import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useWatch } from "react-hook-form";
import FormInput from '../Field';
import { hasValue, valueOrOption } from '../../general/GeneralUtilities';
import { each, isArray, isFunction, isObject } from 'lodash';

export function ModalSelectionInput({
	field,
	control,
	setValue,
	handleSelect,
	selectBy = "key",
	disabled = false,
	multi = true,
	extraFilters = {},
	sequencialItems = ["none"],
	clearValue = "*",
	prevSequencialItem = "none",
	sequencialDisabled = false,
	fieldsPrefix = "",
	...others
}) {

	const [selected, setSelected] = useState([]);
	const prevFieldsValues = useWatch({ control, name: sequencialItems });
	const prevSequencialValue = useWatch({ control, name: prevSequencialItem });
	const changed = prevFieldsValues.join("-");

	useEffect(() => {
		setValue(field, clearValue);
		setSelected([]);
		//eslint-disable-next-line
	}, [changed]);

	const clearPrefix = (fieldName) => {
		if (hasValue(fieldsPrefix)) {
			return fieldName.replace(fieldsPrefix, "");
		}
		return fieldName
	}

	const getExtras = () => {
		let extras = valueOrOption(extraFilters, {});
		const suffix = selectBy === "key" ? `_keys` : "";
		each(prevFieldsValues, (fieldValue, index) => {
			if (isValidValue(fieldValue)) {
				const filter = `${sequencialItems[index]}${suffix}`;
				extras[clearPrefix(filter)] = fieldValue;
			}
		});
		return extras;
	};

	const isValidValue = (value) => valueOrOption(value, clearValue) !== clearValue;

	const onSelectHandle = (newSelected) => {
		setSelected(newSelected);
		if (isFunction(handleSelect)) {
			handleSelect(newSelected);
		}

		if (isObject(newSelected) && !isArray(newSelected) && !multi) {
			const value = selectBy ? newSelected[selectBy] : newSelected.key;
			return setValue(field, value);
		}

		let newValue = newSelected;
		if (multi && hasValue(selectBy)) {
			newValue = valueOrOption(newSelected, []).join(",");
		}
		setValue(field, newValue);
	};

	const handleChange = (e) => {
		const value = valueOrOption(e.target?.value, "");
		setSelected(field, value.split(","));
		return e;
	};

	const hasPrevValue = isValidValue(prevSequencialValue) || prevSequencialItem === "none";

	return (
		<FormInput
			{...others}
			selectBy={selectBy}
			control={control}
			fieldInput="StructureSearch"
			serverApi={clearPrefix(field)}
			multi={multi}
			handleSelect={onSelectHandle}
			onChange={handleChange}
			selected={selected}
			disabled={disabled || (!hasPrevValue && sequencialDisabled)}
			extraFilters={getExtras()}
		/>
	);
}

ModalSelectionInput.propTypes = {
	clearValue: PropTypes.string,
	control: PropTypes.any,
	disabled: PropTypes.bool,
	extraFilters: PropTypes.object,
	field: PropTypes.any,
	fieldsPrefix: PropTypes.string,
	handleSelect: PropTypes.func,
	multi: PropTypes.bool,
	prevSequencialItem: PropTypes.string,
	selectBy: PropTypes.string,
	sequencialDisabled: PropTypes.bool,
	sequencialItems: PropTypes.array,
	setValue: PropTypes.func
}



