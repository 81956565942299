import PropTypes from "prop-types";
import React from 'react';
import Stack from '@mui/material/Stack';

function ButtonsContainer({
	spacing,
	justifyContent,
	className,
	style = {},
	children
}) {
	return (
		<Stack
			style={style}
			spacing={spacing ?? 1}
			direction="row"
			justifyContent={justifyContent || "flex-end"}
			className={`buttos-container ${className ?? "mb-3"}`}
			alignItems="center"
		>
			{children}
		</Stack>
	);
}

ButtonsContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.element,
	]),
	className: PropTypes.string,
	justifyContent: PropTypes.string,
	spacing: PropTypes.number,
	style: PropTypes.object
};

export default ButtonsContainer;