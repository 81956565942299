import { format, parseISO } from "date-fns";
import {
	isArray,
	isDate,
	isEmpty,
	isFunction,
	isObject,
	omitBy,
	isUndefined,
	groupBy,
	keys,
	map,
	pick,
	toString,
	isEqual,
	each,
	isNumber,
	toUpper,
	isString,
} from "lodash";
import { trim } from '../validations/validationFilter';
import { useEffect } from "react";

export const selector = (str, all = false) => !all ? document.querySelector(str) : document.querySelectorAll(str);

/**
 * Función general para saber si un valor tiene contenido o es valido
 * @param {*} value
 * @returns {boolean}
 */
export const hasValue = (value) => {
	if (isDate(value) || isFunction(value)) {
		return true;
	}

	if (isArray(value) || isObject(value)) {
		return !isEmpty(value);
	}

	if (typeof value === "string") {
		return trim(value) !== "";
	}

	return ![undefined, null, "", NaN].includes(value);
};

/**
 * Función para operaciones en el sistema donde ncesitamos un valor por default(opcional)
 * en caso de que el valor usado este vacio o no tenga un valor
 * @param {*} value - Es el valor que por defecto estamos usando y evaluando
 * @param {*} optionalValue - El valor opcional en casoi de que el primer valor este vacio o indefinido
 * @returns {value|optionalValue}
 */
export const valueOrOption = (value, optionalValue) => hasValue(value) ? value : optionalValue;

/**
 * Función para validar si el valor de 2 valores son iguales
 * @param {*} str1 - El parametro a comparar 2
 * @param {*} str2 - El parametro a comparar 1
 * @param {*} opt1 - Una opcón para sustituir el parametro 1 en caso de que la validación de si tiene valor arroje false
 * @param {*} opt2 - Una opcón para sustituir el parametro 2 en caso de que la validación de si tiene valor arroje false
 * @returns {Boolean}
 */
export const isEqualsValues = (str1, str2, opt1 = "", opt2 = "") => {
	const { value: value1, type: value1Type } = parseArrayObject(str1, opt1);
	const { value: value2, type: value2Type } = parseArrayObject(str2, opt2);

	if (value1Type !== value2Type) {
		return false;
	}

	if (value1Type === "obj") {
		return isEqual(value1, value2);
	}

	if (value1Type === "array") {
		if (value1.length !== value2.length) {
			return false;
		}

		return value1.every(a => value2.some(b => isEqualsValues(a, b)));
	}

	return toString(value1) === toString(value2);
};

const parseArrayObject = (value, valueOption = "") => {
	if (isArray(value)) {
		let newArray = value.map(item => parseArrayObject(item)?.value);
		return { value: newArray, type: "array" };
	}

	if (isObject(value) && !isArray(value)) {
		const keys = Object.keys(value);
		let newObj = {};
		each(keys, key => {
			newObj[key] = parseArrayObject(value?.[key])?.value;
		});
		return { value: newObj, type: "obj" };
	}

	return { value: toString(valueOrOption(value, valueOption)), type: "str" };
};


/**
 * Función que nos permite completar el tamaño desado de letras rellenando con un simboolo
 * @param {string|number} word - El numero o string a completar con los ceros necesarios
 * @param {?number} finalLength - El numero de letras que deberia tener el formato final con letras y relleno, por default 2
 * @param {?string} symbol - El simbolo que se usara para rellenar los espacios en blanco, por default "0"
 * @returns {string}
 */
export const wordFill = (word = 0, finalLength = 2, symbol = "0") => {
	let fill = "";
	finalLength = valueOrOption(finalLength, 2);
	for (let index = 0; index < finalLength; index++) {
		fill += symbol;
	}

	if (!hasValue(word)) { return fill; }

	return `${fill}${word}`.slice(-finalLength);
};

/**
 *
 * @param {string|number} time : El tiempo a ser transformado formatos admitidos "HH:mm" ó numero entero de minutos
 * @param {"minutes"|"hours"} convertTo : A que formato, minutes -> int , hours -> "HH:mm"
 */
export const timeConvert = () => {

	const toMinutes = (time) => {
		if (!hasValue(time)) {
			return 0;
		}

		const timeParts = time.split(":");

		return (parseInt(timeParts[0]) * 60) + parseInt(timeParts[1]);
	};

	const toHours = (time) => {
		if (!hasValue(time)) {
			return "00:00";
		}

		const hours = parseInt(time) / 60;
		const minutes = parseInt(time) % 60;

		return `${wordFill(parseInt(hours), 2)}:${wordFill(minutes, 2)}`;
	};

	return {
		toMinutes,
		toHours
	};
};

/**
 * Funcion que nos sirve para formatera los campos de fecha y hora que recibimos desde el back
 * @param {?string|date} date - La fecha a evaluar
 * @param {?string|"asDate"} dateFormat - El formato de salida deseado, por default "yyyy-MM-dd HH:mm:ss", si se manda "asDate" se retorna un new Date
 * @param {?boolean} useISO - Si se utiliza el time zone para hacer el parse
 * @returns {date}
 */
export const parseDatetime = (date, dateFormat, useISO = false) => {

	if (!hasValue(date)) {
		return null;
	}

	dateFormat = valueOrOption(dateFormat, "yyyy-MM-dd HH:mm:ss");

	if (useISO) {
		date = parseISO(date);
		/* to avoid time zones */
	} else if (typeof date === "string") {
		if (date.includes("T")) {
			const splited = date.split("T");
			date = `${splited[0]} ${splited[1].slice(0, 8)}`;
		}
		date = date.replaceAll("-", "/");
	}



	const newDate = new Date(date);
	return dateFormat === "asDate" ? newDate : format(newDate, dateFormat);
};

/**
 * Helper que nos ayuda a focusear un elemento especifico de la vista que tenga un id
 * @param {string} id - el Id del elemento a relizar el focus
 * @param {?number} time - El tiempo de espera en milisegundos antes de hacer el focus al elemento, por default 150
 * @param {?boolean} select_text - Indica si al hacer el focus tambien se eleccionara el texto o no
 */
export const focusElement = (id, time = 150, select_text = false) => {
	time = valueOrOption(time, 150);
	const focusTimer = setTimeout(() => {
		const finded = document.getElementById(id);
		setFocus(finded, select_text);
	}, time);
	return () => clearTimeout(focusTimer);
};

/**
 * Función para realizar el focus y seleccion de un elemento si al elemento es posible
 * @param {HTMLElement} element - El emento a realizar el focus
 * @param {?boolean} select - Por default false
 * @returns
 */
export const setFocus = (element, select) => {
	try {
		if (element?.focus) {
			element.focus();
			if (select && element?.select) {
				element.select();
			}
			return true;
		}
		return false;
	} catch (error) {
		return false;
	}
};


export const getLabelValue = (label, value, endWith = "") => {
	if (!hasValue(value)) { return ""; }
	let word = trim(`${label} - ${value}`).replace("- -", "-");
	return word + endWith;
};

/**
 * Función general para formatear los datos de números a un formato humano
 * @param {string | number} value - el valor que vamos a formatear
 * @param {?number} decimals - el número de decimales que queremos que aparescan por default 2
 * @param {?number} currencySymbol - Indica el simbolo que se va a usar de prefijo ej: $
 * @param {?number} notValueDefault - Indica el simbolo que se va a usar de prefijo ej: $
 * @returns {string}
 */
export const parseNumber = (value, decimals = 2, currencySymbol = "", notValueDefault = 0) => {

	if (!hasValue(value)) {
		if (!hasValue(notValueDefault)) {
			return notValueDefault;
		}
		value = notValueDefault;
	}

	decimals = valueOrOption(decimals, 2);
	value = `${value}`.toString().split(".");
	const number = value[0].replaceAll(",", "");
	let fraction = valueOrOption(value[1], "");

	let formated = Intl.NumberFormat(
		'es-MX',
		{
			currency: 'MXN',
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		}

	).format(number);

	if (decimals > 0) {
		fraction = `${fraction}00000000000000000`.slice(0, decimals);
		formated = `${formated}.${fraction}`;
	}

	if (hasValue(currencySymbol)) {
		if (currencySymbol == "%") {
			return `${formated}  ${currencySymbol}`;
		}
		return `${currencySymbol}  ${formated}`;
	}
	return formated;
};

/**
 * Helper general para aplicar la funcionalidad de cambiar al siguiente input cuando se presiona la tecla  enter del teclado
 * @param {string} container - Es id del contenedor/formulario al cual se le quiere aplicar está funcionalidad, por default está aplicado al contenedor principal del sistema
 * * *Nota : Si en los formularios la tecla enter realiza un submit es necesario mover el form para solo envolver los botones de ese formulario
 */
export const EnterAsTab = (container, enabled = true) => {
	container = `#${valueOrOption(container, 'app-main-container-scroller')}`;

	const invalidSelectorsAttrs = [
		'.Mui-disabled',
		'.k-disabled',
		'[disabled]',
		'.k-fab',
		'.MuiCollapse-root button',
		'[tabindex = "-1"]',
		'[hidden]',
		'.k-input-button',
		'.MuiDisabled',
		'.search-input-button'
	].join(", ");
	const focusSelectorQuery = [
		`button:not(${invalidSelectorsAttrs})`,
		`input:not(${invalidSelectorsAttrs})`,
		`textarea:not(${invalidSelectorsAttrs})`,
		`[tabindex]:not(${invalidSelectorsAttrs}, div)`,
	].join(", ");

	const handleKeyDown = (e, container) => {
		if (e.keyCode === 13 && enabled) {
			e.preventDefault();
			const currentElement = document.activeElement;
			const hasCustomEnter = currentElement.closest('.on-enter-custom');
			if (hasCustomEnter) return;
			const focusableElements = Array.from(container.querySelectorAll(focusSelectorQuery))
				.filter(el => window.getComputedStyle(el).display !== 'none');
			const currentIndex = focusableElements.indexOf(currentElement);
			const nextIndex = !e?.shiftKey ? (currentIndex + 1) : (currentIndex - 1) % focusableElements.length;
			const nextFocused = setFocus(focusableElements?.[nextIndex], true);
			if (!nextFocused) {
				setFocus(focusableElements?.[0], true);
			}
		}
	};

	useEffect(() => {
		const mainContainer = selector(container);
		if (!mainContainer) return;
		mainContainer.addEventListener("keydown", (e) => handleKeyDown(e, mainContainer));
		return () => {
			mainContainer.removeEventListener("keydown", (e) => handleKeyDown(e, mainContainer));
		};
	}, []);

	return null;
};

/**
 * Función para setear la key de un item dentro de un mapeo
 * @param {*} prefix - Prefijo opcional que puede tener la key, por default 'item'
 * @param {*} itemIndex - Index del item
 * @returns {string}
 */
export const getArrayItemKey = (itemIndex, prefix = 'item_field') => {
	return `${prefix}_${itemIndex}`;
};

export const mapData = (data, format) => {
	//Se eliminan los valores que no tengan valor
	const items = omitBy(data, isUndefined);
	if (!format) return items;
	//se agrupan por id
	const groupedData = groupBy(keys(items), key => key.match(/\d+/)?.[0]);
	// se hace el map para construir el array de objetos
	const result = map(groupedData, (keys, id) => {
		if (!parseInt(id)) return null;
		const payData = pick(items, keys);
		return {
			id: parseInt(id),
			...payData
		};
	}).filter(f => f?.id);
	result.map(m => {
		Object.keys(m).forEach(item => {
			m[item.replace(/\d/g, '').replace(/_([^_]*)$/, '$1')] = m[item];
			if (item.length !== 2) {
				delete m[item];
			}
		});
		return m;
	});
	return result;
};

export const gridSize = (xs, sm, md, lg, xl) => ({
	size: {
		xs,
		sm,
		md,
		lg,
		xl,
	}
});

export const copyObject = (object) => (JSON.parse(JSON.stringify(object ?? {})));

export const searchInObject = (query, obj, returnQuery = false) => {
    const path = query ? query?.split('.') : [];
    let object = { ...obj };
    for (const prop of path) {
        object = object?.[prop];
    }
    if (hasValue(object)) {
        return object;
    }
    return !returnQuery ? '-' : query;
};

export const formatMoney = (dataItem, currency = "MXN", region = "es-MX") => {
    dataItem = parseFloat(dataItem);
	currency = isString(currency) ? currency : "MXN";
	region = isString(region) ? region : "es-MX";
    if ((!dataItem && !isNumber(dataItem)) || isNaN(dataItem)) return;
    return dataItem.toLocaleString(region, {
        style: 'currency',
        currency
    });
};

export const keyName = (item, upper = true) => {
    if (!item) return null;
    const str = [item?.key, item?.name].filter(el => el).join(' - ');
    if (upper) return toUpper(str);
    return str;
};

export const colorStyle = (color, fontSize, fontWeight) => ({
    ...(color ? { color } : {}),
    ...(fontSize ? { fontSize } : {}),
    ...(fontWeight ? { fontWeight } : {}),
});