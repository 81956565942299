import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducers';

const persistConfig = {
  key: 'app',
  storage: storage,
  whitelist: ['app', 'menu']
}

const pReducer = persistReducer(persistConfig, reducer)

export const store = createStore(
  pReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
)

export const persistor = persistStore(store)
