import React, { Fragment, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
//--mui
import Grid from '@mui/material/Grid';
import { months, previousDate } from './Utils';
import FormInput from '../../../general/@components/form/Field';

function Filters(props) {

  const { control, setValue, getValues } = props

  const { t } = useTranslation()

  const handleChangeType = (e) => {
    const type = e.target.value
    const month = getValues('month')
    if (type === '1') {
      const { month } = previousDate(false, true);
      setValue('month', month);
    } else {
      const result = months.find(
        (f) =>
          f.value
            .split(',')
            .map((m) => parseInt(m))
            .indexOf(parseInt(month)) !== -1
      )
      setValue('month', result?.value)
    }
    return e
  }

  return (
    <Grid container spacing={1} justifyContent='flex-start' alignItems='center'>
      <FormInput
        fieldInput='RadioGroup'
        control={control}
        onChange={handleChangeType}
        name='type'
        options={[
          { id: '1', name: 'Liquidación Mensual' },
          { id: '2', name: 'Liquidación Bimestral' }
        ]}
        Container={Grid}
        containerProps={{ item: true, xs: 12 }}
      />

      <Grid item xs={12} sm={6} md={2}>
        <FormInput
          fieldInput='dateyear'
          control={control}
          name='year'
          label={t('year')}
          isRequired={true}
          views={['year']}
          max={new Date()}
        />
      </Grid>

      <Months {...props} />
    </Grid>
  )
}

export default Filters

const Months = ({ control, getValues, setValue, initValues, getBimonthly, setGetBimonthly }) => {
  const { t } = useTranslation()
  const type = useWatch({ control, name: 'type' })
  const year = useWatch({ control, name: 'year' })

  const [maxMonth, setMaxMonth] = useState(new Date())

  useEffect(() => {
    let date = new Date()

    if (year === date.getFullYear().toString()) {
      setMaxMonth(date)

      let currentMonth = date.getMonth() + 1

      if (currentMonth < Number(getValues('month'))) {
        setValue('month', currentMonth.toString())
      }
    } else {
      setMaxMonth(undefined)
    }
    // eslint-disable-next-line
  }, [year])

  useEffect(() => {
    if (parseInt(type) === 1) {
      setGetBimonthly(getMonthsBimonthly(getValues('month')))
    }else{
      setGetBimonthly(null)
    }
    // eslint-disable-next-line
  }, [type])

  function getMonthsBimonthly(mont) {
    if (mont % 2 === 0) {
      const value = months.find((f) =>
        f.value
          .split(',')
          .map((m) => parseInt(m))
          .find((f) => f === parseInt(mont))
      )
      return value
    } else {
      return null
    }
  }

  return (
    <Fragment>
      {type === '1' && (
        <Grid item xs={12} sm={6} md={2} >
          <FormInput
            fieldInput='datemonth'
            control={control}
            name='month'
            label={t('month')}
            isRequired={true}
            max={maxMonth}
            format={'MM - MMMM'}
            onChange={(e) => {
              setGetBimonthly(getMonthsBimonthly(e.value))
              return e
            }}
          />
        </Grid>
      )}

      {type === '2' && (
        <Grid item xs={12} sm={6} md={2}>
          <FormInput
            fieldInput='dropdown'
            control={control}
            name='month'
            label={t('bimester')}
            noDefault={true}
            textField='label'
            dataItemKey='label'
            valueField='value'
            data={
              parseInt(initValues.year) === parseInt(year)
                ? months.filter((f) =>
                    f.value
                      .split(',')
                      .map((m) => parseInt(m))
                      .find((f) => f <= initValues.month)
                  )
                : months
            }
            isRequired={true}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={12} md={8}>
        <AnimatePresence>
          {getBimonthly && (
            <motion.div
              style={{ placeSelf: 'flex-end', margin: '16px', fontWeight: '600', color: '#387ba0', width: 'initial' }}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div style={{ whiteSpace: 'nowrap', textOverflow: ' ellipsis', overflow: 'hidden', maxWidth: '437px' }}>
                Al procesar se calculara la liquidación bimestral de {getBimonthly.label}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </Grid>
    </Fragment>
  )
}
