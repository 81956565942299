import PropTypes from "prop-types"
import React, { Fragment } from 'react';
// --language
import { useTranslation } from "react-i18next";
import { format as dateFormat } from 'date-fns';
import KendoDatePicker from '../Inputs/Dates/KendoDatePicker';
import KendoDropdown from '../Inputs/Select/KendoDropDown';
import { asciiValidate } from '../validations/validationFilter';
import KendoMultiselect from '../Inputs/MultiSelect/KendoMultiselect';
import KendoTreeMultiselect from '../Inputs/MultiSelect/KendoTreeMultiselect';
import ServerDropdown from '../Inputs/Select/kendoServerDropdown';
import validation from './validations';
import { getProps } from '../form/utilities';
import Textbox from '../Inputs/textInputs/Textbox';
import KendoNumeric from '../Inputs/Numeric/KendoNumeric';
import SwitchMui from '../Inputs/Booleans/SwitchMui';
import KendoDateMonthOrYear from '../Inputs/Dates/KendoDateMonthOrYear';
import PayrollPeriod from '../Inputs/Select/PayrollPeriod';
import { monthsArray, statusesArray, validationType } from './utilities';
import { isFunction, omit } from 'lodash';
import { hasValue, valueOrOption } from '../general/GeneralUtilities';
import KendoDateTime from '../Inputs/Dates/KendoDateTime';
import { backDateTimeFormat } from '../Inputs/Dates/utilities';
import KendoCheckbox from '../Inputs/Booleans/KendoCheckbox';
import MuiAlert from '../general/MuiAlert';

const FilterUi = ({
	filterType,
	data,
	filter,
	field,
	value,
	setValue,
	inputProps,
	forGrid,
	validationRules,
	changeFilters,
	asV2 = false
}) => {

	const { t } = useTranslation();

	let ft = (filter || filterType || "default").toLowerCase();
	const filterName = inputProps?.name || `filter_${field}`;
	const genProps = { ...getProps({ ...inputProps }), size: "medium" };
	let isBolean = "";

	switch (filterType) {
		case "status":
		case "statusd":
			data = [statusesArray(), "value", "label"];
			ft = "dropdown";
			isBolean = "status";
			break;
		case "month":
			data = [monthsArray(), "value", "label"];
			ft = "dropdown";
			isBolean = false;
			break;
		default:
			data = valueOrOption(data, []);
			break;
	}

	const errorMsg = validation({
		...validationType(ft, isBolean || field),
		...validationRules,
		value: value,
	});

	const onChange = event => {
		let cValue = event.value;
		if (ft === "date" && cValue) {
			cValue = dateFormat(new Date(cValue), 'yyyy-MM-dd');
		} else if (typeof cValue === "string" && cValue) {
			cValue = asciiValidate(cValue);
		}
		setValue(cValue);
	};

	const validValue = errorMsg === "";

	const onTyping = ({ which, target }) => {
		if (!isFunction(changeFilters) || !forGrid || which !== 13) {
			return;
		}

		if (hasValue(target?.value)) {
			changeFilters();
		}
	};

	switch (ft) {
		case "info":
			return (
				<MuiAlert severity={"info"} asV2={asV2}>
					{t(filterName)}
				</MuiAlert>
			);
		case "date":
			return (
				<Fragment>
					<KendoDatePicker
						{...genProps}
						valid={validValue}
						name={filterName}
						value={value ? new Date(value.replaceAll("-", "/")) : null}
						onChange={onChange}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
		case "dateyear":
		case "datemonth":
			return (
				<Fragment>
					<KendoDateMonthOrYear
						{...genProps}
						valid={validValue}
						name={filterName}
						value={value}
						onChange={onChange}
						format={(ft === "dateyear" ? "yyyy" : "MM - MMMM")}
						onKeyUp={onTyping}
						openOnMount={ft === "datemonth" && forGrid}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
		case "datetime":
			return (
				<Fragment>
					<KendoDateTime
						{...genProps}
						valid={validValue}
						name={filterName}
						value={value}
						onChange={onChange}
						valueFormat={backDateTimeFormat}
						outFormat={backDateTimeFormat}
						onKeyUp={onTyping}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
		case "dropdown":
		case "dropdownnokey":
			return (
				<Fragment>
					<KendoDropdown
						{...genProps}
						valid={validValue}
						byValue={ft === "dropdown"}
						name={filterName}
						data={data[0]}
						valueField={data[1]}
						textField={data[2]}
						noDefault={genProps?.noDefault || forGrid || false}
						value={value}
						onChange={onChange}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
		case "multiselect":
			return (
				<Fragment>
					<KendoMultiselect
						{...genProps}
						valid={validValue}
						options={data[0] || []}
						keyField={data[1] || "value"}
						textField={data[2] || "label"}
						value={value || []}
						onChange={setValue}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
		case "multiselecttree":
			return (
				<Fragment>
					<KendoTreeMultiselect
						{...genProps}
						valid={validValue}
						options={data[0] || []}
						dataItemKey={data[1] || "value"}
						textField={data[2] || "label"}
						value={value || []}
						onChange={setValue}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
		case "numeric":
			return (
				<Fragment>
					<KendoNumeric
						{...genProps}
						valid={validValue}
						autoComplete={"off"}
						name={filterName}
						min={0}
						value={value}
						onChange={onChange}
						rangeOnEnter={false}
						onKeyUp={onTyping}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
		case "payrollPeriod":
			return (
				<Fragment>
					<PayrollPeriod
						{...genProps}
						valid={validValue}
						name={filterName}
						value={value}
						onChange={onChange}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
		case "serverdropdown":
			return (
				<Fragment>
					<ServerDropdown
						byValue={true}
						{...genProps}
						valid={validValue}
						name={filterName}
						serverApi={data[0]}
						valueField={data[1]}
						textField={data[2]}
						value={value}
						onChange={onChange}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
		case "switch":
			return <SwitchMui {...genProps} checked={!!value} onChange={onChange} />;
		case "checkbox":
			return <KendoCheckbox {...omit(genProps, 'changeCallback')} checked={!!value} onChange={onChange} />;
		default:
			return (
				<Fragment>
					<Textbox
						{...genProps}
						autoComplete={"off"}
						name={filterName}
						value={value || ""}
						onChange={onChange}
						id={`${filterName}_id"`}
						className={genProps?.className}
						label={genProps?.label || ""}
						onKeyUp={onTyping}
					/>
					{!validValue && <p className="form-error">{errorMsg}</p>}
				</Fragment>
			);
	}
};

FilterUi.propTypes = {
	asV2: PropTypes.bool,
	changeFilters: PropTypes.func,
	data: PropTypes.any,
	field: PropTypes.any,
	filter: PropTypes.any,
	filterType: PropTypes.any,
	forGrid: PropTypes.any,
	inputProps: PropTypes.any,
	setValue: PropTypes.func,
	validationRules: PropTypes.any,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool
	])
}

export default FilterUi;