import React, {
	useEffect,
	Fragment,
	useState,
	Suspense
} from "react";
import { uid } from 'uid';
import {
	Navigate,
	Routes,
	Outlet,
	Route,
} from "react-router-dom";

import {
	size,
	isArray,
	omit,
} from 'lodash';
import { useSelector } from "react-redux";
import PageSkeleton from "./components/PageSkeleton";
import PageNotFound from "../Pages/PageNotFound";
import { dashboardPath } from "../requests/validate-tenant";


const Pages = () => {

	const { routes } = useSelector(state => state.navigate);
	const [pages, setPages] = useState([]);

	useEffect(() => {
		setPages(createRoutes(routes));
		//eslint-disable-next-line
	}, [routes]);

	return (
		<Suspense fallback={<PageSkeleton />}>
			<Routes>
				{
					size(pages) ?
						<Fragment key={uid()}>
							<Route index element={<InitialLayout />} />
							{pages}
						</Fragment>
						:
						<Route path='*' exact={true} element={<PageSkeleton />} />
				}
				<Route path='*' exact={true} element={<PageNotFound />} />
			</Routes>
		</Suspense>
	);
};

export default Pages;


const InitialLayout = () => {
	return <Navigate replace={true} to={dashboardPath()} />;
};

const createRoutes = route => {
	route = route?.type === "group" ? route.children : route;

	if (isArray(route)) {
		return route.map(item => {
			return createRoutes(item);
		});
	}

	route = omit(route, ['key']);

	if (route?.type === "collapse") {
		return (
			<Fragment key={uid()}>
				{createRoutes(route.children)}
			</Fragment>
		);
	}

	if (route?.type === "item" && size(route?.sub_routes)) {
		let path = route?.path;
		const validPath = (path ?? '').endsWith("/*");
		const childRoutes = route.sub_routes.map(el => {
			el.element = el.componentElm;
			return el;
		});
		if (!validPath) {
			path = `${path}/*`;
		}
		return (
			<Route key={uid()} {...route} path={path} element={<Outlet />}>
				{createRoutes(childRoutes)}
			</Route>
		);
	}

	if (route?.componentElm) {
		return (
			<Route key={uid()} {...route} />
		);
	}

	return null;
};