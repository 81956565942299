import FormControlLabel from "@mui/material/FormControlLabel";

import { withStyles } from "@mui/styles";

const StyledFormControlLabel = withStyles({
  label: {
    font: "normal normal normal 13px/33px Lato",
  },
})(FormControlLabel);

export default StyledFormControlLabel;
