import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types"
import { Card } from "@progress/kendo-react-layout";
import Gif from "./lightAssets/PayRoll_A3_l.gif";
import PNG from "./lightAssets/PayRoll_A3_l.png";
import GifD from "./darkAssets/PayRoll_A3_d.gif";
import PNGD from "./darkAssets/PayRoll_A3_d.png";
import { hasValue } from '../general/GeneralUtilities';

const SocketMessages = ({
	message,
	percentage,
	// status
}) => {

	const [imgUsed, setImgUsed] = useState(null);
	const [imgUsed2, setImgUsed2] = useState(null);
	const changeImg = () => {
		setTimeout(() => {
			setImgUsed(PNG);
			setImgUsed2(PNGD);
		}, [getTime(4)]);
	};

	useEffect(() => {
		setImgUsed(Gif);
		setImgUsed2(GifD);
		changeImg();
		//eslint-disable-next-line
	}, []);

	return (
		<Card className="calculating-loader-card">
			<div className="loaderLogo" >
				{hasValue(imgUsed) && <img src={imgUsed} alt="loading..." className='light' />}
				{hasValue(imgUsed2) && <img src={imgUsed2} alt="loading..." className='dark' />}
			</div>
			<div className="messages" >
				{percentage} {message}
			</div>
			<div className="progress-container">
				<div className="abrhil-progress-bar">
					<div className="abrhil-progress-bar-value"></div>
				</div>
			</div>
		</Card>
	);
};

SocketMessages.propTypes = {
	message: PropTypes.any,
	percentage: PropTypes.any
}

export default memo(SocketMessages);

const getTime = (seconds) => 1000 * seconds;