import { config, API_PUBLIC_ADDRESS } from "./config";
import axios from "axios";

const AuthenticationApi = () => {

  /**
 * @param {{ email:string, password:string }} request
  * @param api_url // Si se requiere iniciar sesión en otro dominio
 */
  const login = (request, api_url) => {
    const api = api_url ?? API_PUBLIC_ADDRESS;
    return axios
      .post(api + "/login/", request, config())
      .then((response) => response.data);
  }

  /**
  * @param {{refresh: string}} request  // Token de acceso para refrescar
  */
  const refresh = (request) => {
    return axios
      .post(API_PUBLIC_ADDRESS + "/token/refresh/", request, config())
      .then((response) => response.data);
  }


  /**
   * @param {{ email:string, password:string }} request
    * @param api_url // Si se requiere iniciar sesión en otro dominio
  */
  const confirmAccount = (request) => {
    return axios
      .post(API_PUBLIC_ADDRESS + "/set-password/", request, config())
      .then((response) => response.data);
  }

  const ssoLogin = (request) => {
    return axios
      .get(API_PUBLIC_ADDRESS + "/login/sso-login/?sso2", request, config())
      .then(response => response.data);
  }

  const loginMode = (request) => {
    return axios
      .get(API_PUBLIC_ADDRESS + "/login-mode/", config(request))
      .then(response => response.data);
  }

  const sendCode = (request) => {
    return axios
      .post(API_PUBLIC_ADDRESS + "/send-otp-email/", request, config())
      .then(response => response.data);
  }

  const logout = (request) => {
    return axios
      .get(API_PUBLIC_ADDRESS + "/login/sso-logout/", config(request))
      .then((response) => response.data);
  }

  const logoutMode = (request) => {
    return axios
      .get(API_PUBLIC_ADDRESS + "/logout-mode/", config(request))
      .then(response => response.data);
  }

  return {
    login,
    logout,
    refresh,
    ssoLogin,
    sendCode,
    loginMode,
    logoutMode,
    confirmAccount,
  }
}

export const authenticationApi = AuthenticationApi();