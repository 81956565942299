import React, { useEffect, useState } from 'react';
import Card from '../Card';
import Grid from '@mui/material/Grid2';
import { mopersHistoryAPI } from '../../../../../../../../../general/services/mopers';
import RichText from '../../../../../../../../../general/@components/general/RichText';
import useRequestLoad from '../../../../../../../../../general/services/useRequestLoad';
import GenericLabel from '../../../../../../../../../general/@components/general/GenericLabel/GenericLabel';
import Loader from '../../../../../../../../../general/@components/general/Loader/Loader';
import './_content.scss';

const fontSize = '14px';

const pendingBalanceWords = [
    { value: 'Saldos' },
    { value: 'Pendientes', color: 'primary' },
    { value: 'de disfrutar' }
];

function Vacations({ request }) {
    const worker = request?.exactMoper?.worker?.id ?? request?.exactMoper?.worker;

    const [fetch, loading] = useRequestLoad(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        getVacationsHistory();
    }, [])

    const getVacationsHistory = () => {
        fetch({
            api: mopersHistoryAPI.vacations({ worker }),
            callback: setData
        });
    }

    return (
        <>
            <Card style={{ padding: '12px 40px' }}>
                <Grid
                    container
                    direction="column"
                    spacing={1}
                    size={12}
                    className="grid-horizontal-center"
                    style={{
                        minHeight: '92px',
                        minWidth: '191px'
                    }}
                >
                    {!loading ?
                        <>
                            <Grid>
                                <GenericLabel
                                    label={'Tomadas en el último año'}
                                    fontSize={fontSize}
                                    value={data?.vacations_days_last_year}
                                />
                            </Grid>
                            <Grid
                                container
                                size={12}
                                spacing={0}
                                direction="column"
                                className="grid-horizontal-center"
                            >
                                <Grid className="fw-500">
                                    {'Últimas 2 fechas tomadas'}
                                </Grid>
                                <Grid className="fw-300 primary-blue-color">
                                    {data?.last_vacation}
                                </Grid>
                                <Grid className="fw-300 primary-blue-color">
                                    {data?.second_last_vacation}
                                </Grid>
                            </Grid>
                        </>
                        :
                        <Loader />}
                </Grid>
            </Card>
            <Card style={{ padding: '12px' }}>
                <Grid
                    container
                    direction="column"
                    spacing={1}
                    size={12}
                    className="grid-horizontal-center"
                    style={{
                        minWidth: '247px',
                        minHeight: '85px'
                    }}
                >
                    {!loading ?
                        <>
                            <Grid>
                                <RichText words={pendingBalanceWords} fontSize={fontSize} />
                            </Grid>
                            <Grid container size={12} spacing={0} direction="row">
                                <Grid container size={6} className="grid-horizontal-center" direction="column">
                                    <Grid className="fw-500">
                                        {'Periodo'}
                                    </Grid>
                                    {data?.balance?.map(el =>
                                        <Grid key={`vac-period-${el.yearly_period}`} className="fw-300">
                                            {el.yearly_period}
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid container size={6} className="grid-horizontal-center" direction="column">
                                    <Grid className="fw-500">
                                        {'Dias'}
                                    </Grid>
                                    {data?.balance?.map(el =>
                                        <Grid key={`vac-balance-${el.yearly_period}`} className="fw-300 primary-blue-color" >
                                            {el.proportional_balance?.toFixed(2)}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                        :
                        <Loader />}
                </Grid>
            </Card>
        </>
    );
};

export default Vacations;