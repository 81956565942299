export const WORKER_KEYS = ["trabajador", "colaborador", "talento", "asociado", "worker"];
export const NAVIGATE_KEYS = ["llevame a", "ve a", "vea", "veo", "navega a", "navega hacia", "llevame al", "ve al", "navega al", "llevame", "navigate to", "take me to"];
export const AFTER_NAVIGATE_KEYS = [...NAVIGATE_KEYS, "con", "la", "a","del", "el", "al"];
export const CALCULATE_KEYS = ["calcula", "calculate", "calculamelo", "calculalo", "calcular", "calculas"];
export const PAYROLL_KEYS = ["nomina", "payroll"];
export const KARDEX_KEYS = ["kardex", "cardex"];
export const ACTIVATE_KEYS = ["oye abril", "hola abril", "hi abril", "hi april", "hola gabriel"];
export const SHOW_KEYS = ["muestrame", "muestra", "carga", "consulta", "consultame"];

export const NUMBERS_DICTIONARY = {
    uno: 1,
    dos: 2,
    tres: 3,
    cuatro: 4,
    cinco: 5,
    seis: 6,
    siete: 7,
    ocho: 8,
    nueve: 9,
};