import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ConfCalc from '../../../../../icons/conf_calc.svg';
import { MODAL_KEY as DAY_CAL_KEY } from '../../hooks/useDayCalculate';
import { uid } from 'uid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { opacityAnimation, scaleAnimation } from '../../../../../../../../../general/@components/form/Permission/Utils';
import { useModalsContext } from '../../hooks/useModals';
import { size } from 'lodash';
import { BaseButton } from '../../../../../../../../../general/@components/Buttons';
import { hasValue } from '../../../../../../../../../general/@components/general/GeneralUtilities';
import IconUse from '../../../../../../../../../general/@components/uiDesign/IconUse';
import { hasHoliday, isWorkerRestMoper } from '../../utils/utilities';
import { CustomTooltip } from '../../../../../../../../../general/@components/Templates/cells';

export const calcTypes = {
    1: { label: 'Por checada', class: 'blue' },
    2: { label: 'Por horario', class: 'violet' },
};

function DateCard({ item }) {

    const itemDate = new Date(item.id.split('-').join('/'));
    const isToday = itemDate.getTime() === new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    const isWeekend = [6, 0].includes(itemDate.getDay());
    const dateData = item?.date_data;
    const calcType = item?.overtime?.type_calculation;
    const isRest = isWorkerRestMoper(item);
    const hasChecks = size(item?.checks) > 0;
    const isHoliday = hasHoliday(item);
    const showIcon = hasChecks && (isRest || isHoliday);

    return (
        <div key={`item-date-card-${item?.id}`} className={`extra-border-wrapper`}>
            <div className={`week-day-indicator ${isWeekend ? 'weekend' : ''} ${isToday ? 'today' : ''}`} />
            <Card className='custom-extra-card custom-modern-card dates'>
                <CardContent className='extra-card-content'>
                    <div className="date-main-wrap">
                        <motion.div
                            key={`day-calculate-btn-${item?.origin_day}`}
                            style={{ margin: 'auto' }}
                            {...opacityAnimation}
                        >
                            {hasChecks && <DayCalculateButton item={item} />}
                        </motion.div>
                        <div className="date-container">
                            <span className="day-number">
                                {dateData?.day}
                            </span>
                            <div className="date-wrapper">
                                {showIcon ?
                                    <DayTypeRender item={item} />
                                    :
                                    <div>
                                        <span className="day-name">
                                            {dateData?.day_name}
                                        </span>
                                        <div className="month-year">
                                            <span>{dateData?.month}</span>
                                            <span>{dateData?.year}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {!!calcType && <DayCalcType item={item?.overtime} />}
                </CardContent>
            </Card>
        </div>
    );
}

export default React.memo(DateCard);

export const DayCalcType = ({ item, ...others }) => {
    const calcType = item?.type_calculation;

    return (
        <div className='date-calc-type' {...others}>
            <div className={calcTypes[calcType]?.class}>{calcTypes[calcType]?.label}</div>
        </div>
    );
}

const DayTypeRender = ({ item }) => {

    const isHoliday = hasHoliday(item);

    let iconRender = 'Day-Rest';
    let labelRender = 'Descanso';

    if (isHoliday) {
        iconRender = 'Apply-Holidays';
        labelRender = 'Festivo';
    }

    return (
        <div className="ov-date-type-render">
            <IconUse icon={iconRender} />
            <span>{labelRender}</span>
        </div>
    );
}

DayTypeRender.propTypes = {
    item: PropTypes.object
}

export const DayCalculateButton = ({
    item,
    className = "",
    style = {},
    label = ""
}) => {

    const { handleOpen, periodLocked } = useModalsContext();

    const calcChanged = item?.overtime?.is_change;
    const hasIncidence = item?.overtime?.day_incidence === 3;

    function handleDayCalculate() {
        handleOpen(DAY_CAL_KEY, item);
    }

    return (
        hasValue(label) ?
            <AnimatePresence mode='wait'>
                <CustomTooltip title={label}>
                <BaseButton
                    onClick={handleDayCalculate}
                    disabled={periodLocked}
                    label={label}
                    className={`ov-day-calculate-button ${className}`}
                    style={style}
                >
                    {(calcChanged || hasIncidence) && <BadgeIcon itemKey={item?.origin_day} status={hasIncidence ? 'error' : ''} />}
                    <IconUse icon={"Day-Calculate"} style={{ height: "17px", width: "17px" }} />
                    </BaseButton>
                </CustomTooltip>
            </AnimatePresence>
            :
            <button
                className={`ov-day-calculate-button ${className}`}
                style={style}
                disabled={periodLocked}
                onClick={handleDayCalculate}
            >
                <img alt={uid()} src={ConfCalc} loading="lazy" />
                <AnimatePresence mode="wait">
                    {(calcChanged || hasIncidence) && <BadgeIcon itemKey={item?.origin_day} status={hasIncidence ? 'error' : ''} />}
                </AnimatePresence>
            </button>
    );
};

const BadgeIcon = ({ itemKey, status = '' }) => (
    <motion.div
        key={`date-badge-icon-${itemKey}`}
        className={`icon-wrap ${status}`}
        {...scaleAnimation}
    >
        <FontAwesomeIcon
            className='day-calculate-badge'
            icon={faCircleExclamation}
        />
    </motion.div>
);

DateCard.propTypes = {
    item: PropTypes.object,
};

DayCalculateButton.propTypes = {
    className: PropTypes.string,
    item: PropTypes.object,
    label: PropTypes.string,
    style: PropTypes.object,
}

BadgeIcon.propTypes = {
    status: PropTypes.string,
    itemKey: PropTypes.string,
}