import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react';
import { useWatch } from "react-hook-form";
import i18next from 'i18next';
import { each, isFunction, omit } from 'lodash';
import Grid from "@mui/material/Grid2";
import FilterUi from "./FilterUi";
import { hasErrorTetant } from '../requests/validate-tenant';
import { focusFirst, getExtraProps, getFilterName, hasInvalids } from './utilities';
import KendoDateRange from '../Inputs/Dates/KendoDateRange';
import SwitchMui from '../Inputs/Booleans/SwitchMui';
import Form from './Form';
import { hasValue, valueOrOption } from '../general/GeneralUtilities';
import FormInput from '../form/Field';

const { t } = i18next;

/**
 * Helper de filtros que nos ayuda a construir el filtro final de manera correcta para las tablas
 * @param {object} filters
 * @returns {object}
 */
const constructFilters = (filters) => {
	return {
		filters: {
			...omit(filters, ["filters"]),
			...valueOrOption(filters?.filters, {})
		},
	};
};

/**
 * Función auxiliar para realizar el limpiado de los filtros aplicados
 * @param {object} newFilters - Son los filtros aplicados
 * @param {array} cleanFilters - Array con los filtros que seran eliminados de los filtros aplicados
 * @returns {{bolean, object}}}
 */
const originalClean = (newFilters, cleanFilters, currentValues = []) => {
	let applyFilter = false;

	each(cleanFilters, (cleanField, fieldIndex) => {
		if (hasValue(newFilters?.[cleanField])) {
			delete newFilters[cleanField];
			applyFilter = true;
		}
		if (hasValue(currentValues?.[fieldIndex])) {
			newFilters[cleanField] = currentValues[fieldIndex];
		}
	});

	return { applyFilter, newFilters };
};

/**
 * Componente intermediario que regresa los filtros basicos como dropdowns o inputs
 * @param {object} param
 * @param {object} param.filterType - El tipo de componente qwue aparecera como filtro
 * @param {function} param.onCloseMenu - Función que se realiza al cerrar el column menu
 * @param {function} param.onFilterChange - Función que se realiza al aplicar el filtro
 * @param {string} param.column - Columna sobre la que se está aplicando el filtro
 * @param {object} param.filter - Los filtros aplicados en el sistema
 * @param {array} param.dataProps - Los datos para lo filtros tipo dropdown o multiselects
 * @param {string} param.fieldPrefix - Prefijo del filtro que se está aplicando
 * @param {object} param.inputProps - Propiedades extras para aplicar al componente que se va a usar como filtro
 * @returns {JSX.Component}
 */
export const SimpleFilterMenu = ({
	filterType,
	onCloseMenu,
	onFilterChange,
	column,
	filter,
	dataProps,
	fieldPrefix,
	inputProps,
}) => {

	const filters = filter?.filters ?? filter;
	const field = getFilterName(column?.field, fieldPrefix);
	const [value, setValue] = useState(filters?.[field]);
	const disabled = !hasValue(value);

	useEffect(focusFirst, []);

	const cleanFilter = () => {
		const toClean = filters?.[field];
		if (!hasValue(toClean)) {
			return onCloseMenu();
		}
		changeAndClose(omit(filters, [field]));
	};

	const changeFilters = (e) => {
		if (isFunction(e?.preventDefault)) {
			e.preventDefault();
		}
		let newFilters = filters;
		if (hasInvalids("grid-filter-container")) { return; }

		if (filters?.[field] === value) {
			return onCloseMenu();
		}

		if (!hasErrorTetant()) {
			newFilters[field] = value;
		}

		if (typeof inputProps?.changeCallback === "function") {
			newFilters.filters = { ...omit(newFilters, ['filters']) };
			inputProps.changeCallback(newFilters, changeAndClose); /* be sure in changeCallback use the changeAndClose */
			return;
		}

		changeAndClose(newFilters);
	};

	const changeAndClose = (newFilters) => {
		onFilterChange(constructFilters(newFilters));
		onCloseMenu();
	};

	return (
		<Form disabled={disabled} cleanFilter={cleanFilter} changeFilters={changeFilters}>
			<FilterUi
				filterType={filterType}
				data={dataProps}
				filter={column?.filter}
				field={field}
				value={value}
				setValue={setValue}
				forGrid={true}
				inputProps={inputProps}
				validationRules={inputProps?.validationRules || {}}
				changeFilters={changeFilters}
			/>
		</Form>
	);
};

SimpleFilterMenu.propTypes = {
	column: PropTypes.any,
	dataProps: PropTypes.any,
	fieldPrefix: PropTypes.any,
	filter: PropTypes.any,
	filterType: PropTypes.any,
	inputProps: PropTypes.any,
	onCloseMenu: PropTypes.func,
	onFilterChange: PropTypes.func
}

/**
 * Hook que nos sirve para presentar 2 inputs en el column menu que seran para filtrar la llave y nombre de los elementos por separado
 * @param {object} params
 * @param {function} params.onCloseMenu - Función que se realiza al cerrar el column menu
 * @param {function} params.onFilterChange - Función que se realiza al aplicar el filtro
 * @param {object} params.filter - Los filtros aplicados en el sistema
 * @param {boolean} params.rfcField - Indica si tambien se va a mostrar un campo para filtrar por RFC
 * @param {string} params.fieldPrefix - Prefijo del filtro que se está aplicando
 * @returns {JSX.Component}
 */
export const KeyNameFilterMenu = ({
	onCloseMenu,
	onFilterChange,
	filter,
	rfcField,
	fieldPrefix,
	column,
	withAllWorkerName = false
}) => {

	const filters = filter?.filters ?? filter;
	fieldPrefix = valueOrOption(fieldPrefix, column?.field);
	const fieldKey = getFilterName("_key", fieldPrefix);
	const fieldName = getFilterName("_name", fieldPrefix);
	const fieldFirstSurname = getFilterName("_first_surname", fieldPrefix);
	const fieldSecondSurname = getFilterName("_second_surname", fieldPrefix);
	const fieldRfc = getFilterName("_rfc", fieldPrefix);
	const usedFields = [fieldKey, fieldName];

	const [key, setKey] = useState(filters[fieldKey]);
	const [name, setName] = useState(filters[fieldName]);
	const [rfc, setRfc] = useState(filters[fieldRfc]);
	const [firstSurname, setFirstSurname] = useState(filters[fieldFirstSurname]);
	const [secondSurname, setSecondSurname] = useState(filters[fieldSecondSurname]);
	const disabled = !hasValue(key) && !hasValue(name) && !hasValue(rfc) && !hasValue(firstSurname) && !hasValue(secondSurname);
	const currentValues = [key, name];
	if (withAllWorkerName) {
		usedFields.push(fieldFirstSurname);
		usedFields.push(fieldSecondSurname);
		currentValues.push(firstSurname);
		currentValues.push(secondSurname);
	}
	if (rfcField) {
		usedFields.push(fieldRfc);
		currentValues.push(rfc);
	}


	useEffect(focusFirst, []);

	const cleanFilter = () => {
		const { applyFilter, newFilters } = originalClean(filters, usedFields);
		return applyFilter ? changeAndClose(newFilters) : onCloseMenu();
	};

	const changeFilters = (e) => {
		if (isFunction(e?.preventDefault)) {
			e.preventDefault();
		}
		if (hasInvalids("grid-filter-container")) { return; }
		const { newFilters } = originalClean(filters, usedFields, currentValues);
		return !hasErrorTetant() ? changeAndClose(newFilters) : onCloseMenu();
	};

	const changeAndClose = (newFilters) => {
		onFilterChange(constructFilters(newFilters));
		onCloseMenu();
	};

	return (
		<Form disabled={disabled} cleanFilter={cleanFilter} changeFilters={changeFilters}>
			<div className="mt5">
				<FilterUi
					field={"name"}
					inputProps={{ label: t("name") }}
					value={name}
					setValue={setName}
					forGrid={true}
					changeFilters={changeFilters}
				/>
			</div>
			<div className="mt5">
				<FilterUi
					field={"key"}
					inputProps={{ label: t("key") }}
					value={key}
					setValue={setKey}
					forGrid={true}
					changeFilters={changeFilters}
				/>
			</div>
			{withAllWorkerName &&
				<div className="mt5">
					<FilterUi
						field={"first_surname"}
						inputProps={{ label: t("first-surname") }}
						value={firstSurname}
						setValue={setFirstSurname}
						forGrid={true}
						changeFilters={changeFilters}
					/>
				</div>
			}
			{withAllWorkerName &&
				<div className="mt5">
					<FilterUi
						field={"second_surname"}
						inputProps={{ label: t("second-surname") }}
						value={secondSurname}
						setValue={setSecondSurname}
						forGrid={true}
						changeFilters={changeFilters}
					/>
				</div>
			}
			{rfcField && <div className="mt5">
				<FilterUi
					field={"rfc"}
					inputProps={{ label: t("rfc") }}
					value={rfc}
					setValue={setRfc}
					forGrid={true}
					changeFilters={changeFilters}
				/>
			</div>}
		</Form>
	);
};

KeyNameFilterMenu.propTypes = {
	column: PropTypes.any,
	fieldPrefix: PropTypes.any,
	filter: PropTypes.any,
	onCloseMenu: PropTypes.func,
	onFilterChange: PropTypes.func,
	rfcField: PropTypes.any,
	withAllWorkerName: PropTypes.bool
}

/**
 * Hook que nos sirve para presentar 2 inputs de fecha con los cuales filtramos por rangos
 * @param {object} params
 * @param {function} params.onCloseMenu - Función que se realiza al cerrar el column menu
 * @param {function} params.onFilterChange - Función que se realiza al aplicar el filtro
 * @param {object} params.column - Columna sobre la que se está aplicando el filtro
 * @param {object} params.filter - Los filtros aplicados en el sistema
 * @param {object} params.inputProps - Propiedades extras para aplicar al componente que se va a usar como filtro
 * @returns {JSX.Component}
 */
export const DateRangeFilterMenu = ({
	onCloseMenu,
	onFilterChange,
	column,
	filter,
	inputProps,
}) => {

	const {
		show,
		siwtchLabel
	} = valueOrOption(inputProps?.switchProps, {});

	const filters = filter?.filters ?? filter;
	const field = getFilterName(column?.field);
	const fieldStart = getFilterName('_start', field);
	const fieldEnd = getFilterName('_end', field);
	const fieldRange = getFilterName('_switch', field);
	const usedFields = show ? [fieldStart, fieldEnd, fieldRange] : [fieldStart, fieldEnd];
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const [range, setRange] = useState(false);
	const disabled = !hasValue(start) && !hasValue(end) && !range;


	useEffect(focusFirst, []);

	useEffect(() => {
		setStart(valueOrOption(filters[fieldStart], null));
		setEnd(valueOrOption(filters[fieldEnd], null));
		setRange(valueOrOption(filters[fieldRange], false));
		// eslint-disable-next-line
	}, [filter]);

	const cleanFilter = () => {
		const { applyFilter, newFilters } = originalClean(filters, usedFields);
		return applyFilter ? changeAndClose(newFilters) : onCloseMenu();
	};

	const changeFilters = (e) => {
		if (isFunction(e?.preventDefault)) {
			e.preventDefault();
		}
		if (hasInvalids("grid-filter-container")) { return; }
		const { newFilters } = originalClean(filters, usedFields, [start, end, range]);
		return !hasErrorTetant() ? changeAndClose(newFilters) : onCloseMenu();
	};

	const changeAndClose = (newFilters) => {
		onFilterChange(constructFilters(newFilters));
		onCloseMenu();
	};

	const setValue = ({ value, fieldChanged/* ,values */ }) => {
		value = value || null;
		setRange(false);
		if (fieldChanged === "start") {
			setStart(value);
			return;
		}
		setEnd(value);
	};

	return (
		<Form disabled={disabled} cleanFilter={cleanFilter} changeFilters={changeFilters}>
			<Grid container spacing={2}>
				<KendoDateRange
					autoComplete={"off"}
					size={"medium"}
					{...getExtraProps(inputProps)}
					name={`table_dateReange_${field}`}
					startValue={start ? new Date(start.replaceAll("-", "/")) : null}
					endValue={end ? new Date(end.replaceAll("-", "/")) : null}
					showValidationMessages={true}
					startProps={valueOrOption(inputProps?.startProps, {})}
					endProps={valueOrOption(inputProps?.endProps, {})}
					disabled={range}
					onChange={setValue}
					colProps={{ xs: 12, md: 12, className: "mt5" }}
				/>

				{show && <div className='mt-2 ml-3'>
					<SwitchMui
						disabled={!!start || !!end}
						label={siwtchLabel || ""}
						checked={range}
						onChange={({ value }) => setRange(value)}
					/>
				</div>}
			</Grid>
		</Form>
	);
};

DateRangeFilterMenu.propTypes = {
  column: PropTypes.any,
  filter: PropTypes.any,
  inputProps: PropTypes.any,
  onCloseMenu: PropTypes.func,
  onFilterChange: PropTypes.func
}


/**
 * Hook que nos sirve para presentar un filtro de ordenamiento, de manera general ascendente o descendente y/o un ordenamiento por campos
 * @param {object} params
 * @param {function} params.onCloseMenu - Función que se realiza al cerrar el column menu
 * @param {function} params.onFilterChange - Función que se realiza al aplicar el filtro
 * @param {object} params.column - Columna sobre la que se está aplicando el filtro
 * @param {array} param.dataProps - Los datos para el filtro de las opciones por campos
 * @param {object} params.filter - Los filtros aplicados en el sistema
 * @param {object} params.inputProps - Propiedades extras para aplicar al componente que se va a usar como filtro
 * @returns {JSX.Component}
 */
export const OrderFilterMenu = ({
	onCloseMenu,
	onFilterChange,
	column,
	dataProps,
	filter,
	fieldPrefix,
	control,
	setValue
}) => {

	const filters = filter?.filters ?? filter;
	fieldPrefix = valueOrOption(fieldPrefix, column?.field);
	const fieldSortBy = getFilterName("sort_by", fieldPrefix);
	const fieldSortDir = getFilterName("sort_dir", fieldPrefix);
	const usedFields = [fieldSortBy, fieldSortDir];
	const currentValues = [filters[fieldSortBy], filters[fieldSortDir]];
	const orderByValue = useWatch({ control, name: "order_by_option" });
	const sortDirValue = useWatch({ control, name: "sort_dir" });
	const newValues = [orderByValue, sortDirValue];

	const changeAndClose = (newFilters) => {
		onFilterChange(constructFilters(newFilters));
		onCloseMenu();
	};

	const cleanFilter = () => {
		const { applyFilter, newFilters } = originalClean(filters, usedFields);
		return applyFilter ? changeAndClose(newFilters) : onCloseMenu();
	};

	const changeFilters = (e) => {
		if (isFunction(e?.preventDefault)) {
			e.preventDefault();
		}
		if (hasInvalids("grid-filter-container")) { return; }
		const { newFilters } = originalClean(filters, usedFields, newValues);
		return !hasErrorTetant() ? changeAndClose(newFilters) : onCloseMenu();
	};

	useEffect(() => {
		setValue('order_by_option', currentValues[0]);
		setValue('sort_dir', currentValues[1]);
	}, []);

	return (
		<Form cleanFilter={cleanFilter} changeFilters={changeFilters} >
			{hasValue(dataProps) &&
				<>
					<div> Ordenamiento por</div>
				<FormInput
					fieldInput='Radiogroup'
					control={control}
					name='order_by_option'
						options={dataProps?.options}
						valueField={dataProps?.valueField}
						textField={dataProps?.textField}
					row={false}
					/>
				</>
			}
			<div> Tipo de ordenamiento</div>
			<FormInput
				fieldInput='Radiogroup'
				control={control}
				name='sort_dir'
				options={[
					{ id: "UP", name: t("Ascendente") },
					{ id: "DOWN", name: t("Descendente") }
				]}
				row={false}
			/>
		</Form>
	);
};
OrderFilterMenu.propTypes = {
	column: PropTypes.any,
	control: PropTypes.any,
	dataProps: PropTypes.any,
	fieldPrefix: PropTypes.any,
	filter: PropTypes.any,
	onCloseMenu: PropTypes.func,
	onFilterChange: PropTypes.func,
	setValue: PropTypes.func
}
