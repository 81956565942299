import { isObject, isString, size, toLower } from 'lodash';
import { hasTxT } from '../../../TxTGate';
import { paymentTypes, toHours } from '../../../../utils/utilities';

import { ReactComponent as TxTIcon } from '../../../../../../../icons/ov_txt_icon.svg';
import { ReactComponent as MoneyIcon } from '../../../../../../../icons/ov_money_icon.svg';
import { currentUserInfo } from '../../../../../../../../../../../general/@components/requests/user-store';
import { formatMoney } from '../../../../../../../../../../../general/@components/general/GeneralUtilities';

const totalProp = (array, property) => {
    return array?.reduce((total, obj) => {
        const value = obj?.[property];
        if (value) {
            let fValue = value;
            if (isString(fValue)) {
                fValue = parseFloat(fValue.replace(/\$/g, ''));
            }
            total += fValue ?? 0;
        }
        return total ?? 0;
    }, 0) ?? 0;
}

export const isFutureDate = (date) => {
    if (!date) return;
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const itemDate = new Date(date.split('-').join('/'));
    return itemDate > today;
}

export const getSummary = data => {
    const implicits = data?.filter(el => el.is_implicit)?.map(el => el.exactMoper);
    const txts = data?.filter(el => el.catalog_key === 'txt_overtime')?.map(el => el.exactMoper);
    const notImplicit = data?.filter(el => !el.exactMoper?.is_implicit)?.map(el => el.exactMoper);
    return {
        txts: toHours(totalProp(txts, 'minutes_requested')),
        implicits: toHours(totalProp(implicits, 'minutes_requested')),
        doubles: toHours(totalProp(notImplicit, 'minutes_double')),
        triples: toHours(totalProp(notImplicit, 'minutes_triple')),
    };
}

export const buildSummary = (item, worker) => {
    const {
        implicits,
        doubles,
        triples,
        txts,
    } = getSummary(item?.request_details);
    return [
        { label: 'Implicitas', value: implicits, icon: MoneyIcon },
        { label: 'Dobles', value: doubles, icon: MoneyIcon },
        { label: 'Triples', value: triples, icon: MoneyIcon },
        (hasTxT(worker) ? { label: 'TXT', value: txts, icon: TxTIcon } : {})
    ].filter(el => el.label);
};

const dayDetailsKeys = [
    { key: 'amount', validation: [1], label: 'Monto', abbr: 'D', minutes: false },
    { key: 'amount', validation: [3, 1], label: 'Monto DT', abbr: 'DT_D', minutes: false },
    { key: 'amount', validation: [4], label: 'Monto TD', abbr: 'TD_D', minutes: false },
    { key: 'amount', validation: [0, 1], label: 'Monto PD', abbr: 'PD_D', minutes: false },
    { key: 'amount', validation: [0, 2], label: 'Monto FT', abbr: 'FT_D', minutes: false },
    { key: 'amount', validation: [1, 6], label: 'Monto IN', abbr: 'IN_D', minutes: false },
    { key: 'minutes_double', label: 'Horas dobles', abbr: 'HD_H', minutes: true },
    { key: 'minutes_triple', label: 'Horas triples', abbr: 'HT_H', minutes: true },
    { key: 'triple_amount', label: 'Montos triples', abbr: 'HT_D', minutes: false },
    { key: 'double_amount', label: 'Montos dobles', abbr: 'HD_D', minutes: false },
    { key: 'minutes_requested', validation: [1], label: 'Horas', abbr: 'H', minutes: true },
    { key: 'minutes_requested', validation: [3, 1], label: 'Horas', abbr: 'H', minutes: true },
    { key: 'minutes_requested', validation: [2], label: 'Horas TxT', abbr: 'TXT_H', minutes: true },
    { key: 'minutes_requested', validation: [3, 2], label: 'Horas TxT', abbr: 'TXT_H', minutes: true },
    { key: 'minutes_canceled', validation: [2], label: 'Canceladas TxT', abbr: 'CTXT_H', minutes: true },
    { key: 'minutes_requested', validation: [1, 6], label: 'Implicitas', abbr: 'IN_H', minutes: true },
];

export const getDayDetails = (item) => {
    const requests = item?.request_details;

    function getValue(primary, secondary) {
        const value = secondary?.[primary.key];
        primary.value = !primary?.minutes ? parseFloat(value ?? 0) : ((value ?? 0) / 60);
        return primary;
    }

    return requests?.flatMap((m, pIndex) => {
        const el = m.exactMoper;
        return dayDetailsKeys?.map((labelItem, cIndex) => {
            const li = { ...labelItem };
            const payType = el?.pay_type;
            const bonusType = el?.bonus_type;
            const restPaymentType = el?.payment_type_rest_worked ?? bonusType;
            li.comp_field = `${pIndex}_${cIndex}`;
            if (li?.validation) {
                if (restPaymentType) {
                    if (li.validation.includes(payType) && li.validation.includes(restPaymentType) && el?.[li.key]) {
                        return getValue(li, el);
                    }
                }
                if ((li.validation.includes(payType) && size(li.validation) === 1) && el?.[li.key]) {
                    return getValue(li, el);
                }
            } else if (el?.[li.key]) {
                return getValue(li, el);
            }
        })?.filter(el => el?.value);
    })?.reduce((acum, ul) => {
        const exists = acum?.find(acItem => acItem?.label === ul?.label);
        if (exists) {
            exists.value += ul?.value;
        } else {
            acum.push(ul);
        }
        return acum;
    }, [])?.flatMap(jk => {
        if (!jk?.minutes && size(jk)) {
            jk.value = `$${jk.value}`;
        }
        return jk;
    })?.filter(el => el);
};

export const getTotals = (details) => {
    const money = sumProperties(details, 'amount')?.toFixed(2);
    const hours = sumProperties(details, 'minutes_requested');
    const double_hours = sumProperties(details, 'minutes_double');
    const triple_hours = sumProperties(details, 'minutes_triple');
    const txt = sumProperties(details?.filter(el => el?.validation?.includes(2) && size(el?.validation) === 1), 'minutes_requested');
    return { txt, money, hours, triple_hours, double_hours };
};

const sumProperties = (array, property) => {
    return array?.reduce((total, obj) => {
        if (obj?.key?.includes(property)) {
            let value = obj?.value;
            if (isString(value)) {
                value = parseFloat(value.replace(/\$/g, ''));
            }
            total += value ?? 0;
        }
        return total ?? 0;
    }, 0) ?? 0;
};

export const getPaymentsToShow = ({ item }) => {
    const imType = item.in ? 7 : 6;
    const userPerms = currentUserInfo()?.user_info?.moper_attendance?.filter(el => el.has_perm)?.map(el => el.description);
    const reqPerms = {
        td: userPerms?.includes(9),
        dt: userPerms?.includes(10),
    };

    const paymentsShow = paymentTypes.map(el => {
        const lowName = el.name.toLowerCase();
        const dayItem = item?.[lowName];
        const canReq = reqPerms[lowName];
        if (isObject(dayItem)) {
            el.has_perm = canReq;
        }
        return el;
    }).filter(el => el && el.value !== imType);

    return paymentsShow;
};

export const getPaymentTooltip = (item, canRequest, requested, canceled, authorized, isAuto) => {
    let extraLabel = 'No disponible';
    if (requested) {
        extraLabel = 'En proceso';
    }
    if (authorized) {
        extraLabel = 'Autorizado';
    }
    if (canceled) {
        extraLabel = 'Cancelado';
    }
    if (canRequest && !isAuto && !requested) {
        return `Solicitar ${item?.desc}`;
    }
    return `${item?.desc} (${extraLabel})`;
};

const hasDouble = (data) => data?.some(el => el?.minutes_double > 0);
const hasTriple = (data) => data?.some(el => el?.minutes_triple > 0);

const getImplicitType = (data) => {
    if (hasTriple(data)) return 2;
    if (hasDouble(data)) return 1;
    return null;
};

const getFilteredData = (key, data) => (
    data?.filter(el => el.exactMoper?.custom_key === key).map(el => el.exactMoper)
);

const getAmounts = (data) => {
    const keys = ['in', 'im', 'bn', 'dt', 'ft', 'td', 'pd'];
    const amounts = keys.reduce((acc, key) => {
        acc[key] = getFilteredData(key, data);
        return acc;
    }, {});

    const notImplicit = data?.filter(el => !el.exactMoper?.is_implicit)?.map(el => el.exactMoper);

    return {
        in_: formatMoney(totalProp(amounts['in'], 'total_amount')),
        im: formatMoney(totalProp(amounts['im'], 'total_amount')),
        bn: formatMoney(totalProp(amounts['bn'], 'total_amount')),
        dt: formatMoney(totalProp(amounts['dt'], 'amount')),
        ft: formatMoney(totalProp(amounts['ft'], 'amount')),
        td: formatMoney(totalProp(amounts['td'], 'amount')),
        pd: formatMoney(totalProp(amounts['pd'], 'amount')),
        hd: formatMoney(totalProp(notImplicit, 'double_amount')),
        ht: formatMoney(totalProp(notImplicit, 'triple_amount')),
        in_type: getImplicitType(amounts['in']),
        im_type: getImplicitType(amounts['im']),
        bn_type: getImplicitType(amounts['bn']),
    };
};

const getInLabel = (label, type) => {
    if (type === 2) return label + 3;
    if (type === 1) return label + 2;
    return label;
}

export const getAmountsData = (item) => {
    const {
        bn, dt, ft, im, in_, pd, td, hd, ht, in_type, im_type, bn_type
    } = getAmounts(item?.request_details);

    const hasIn = item?.request_details?.some(el => el.exactMoper?.custom_key === 'in');
    const imLabel = getInLabel(hasIn ? 'IN' : 'IM', hasIn ? in_type : im_type);

    return [
        { label: imLabel, value: hasIn ? in_ : im },
        { label: 'DT', value: dt },
        { label: 'HD', value: hd },
        { label: 'FT', value: ft },
        { label: 'HT', value: ht },
        { label: 'TD', value: td },
        { label: getInLabel('BN', bn_type), value: bn },
        { label: 'PD', value: pd },
    ];
};

const minimalDesignKeys = ['df', 'f', 'd'];

export const hasMinimal = req => minimalDesignKeys.includes(req?.catalog_key) || minimalDesignKeys.includes(toLower(req?.exactMoper?.key));

export const genericProcess = [
    { value: 'Actualmente, la solicitud se encuentra' },
    { value: 'en proceso', color: 'primary' },
    { value: 'de firma' }
];

export const genericFinished = 'La solicitud ha sido completada exitosamente';