import i18next from 'i18next';
import { parse } from 'date-fns';
import {
	alphanumericKey,
	alphaspecials,
	numeric,
	onlyNumeric,
	alphanumericPC,
	capital,
} from '../validations/stringValidation';
import { trim } from '../validations/validationFilter';
import { hasValue } from '../general/GeneralUtilities';
import { conditional } from '../table/CommandCell';

const { t } = i18next;

const date = ({
	value,
	valueFormat,
	min,
	max
}) => {

	min = new Date(min || "1920/01/01").setHours(0, 0, 0, 0);
	max = new Date(max || "2099/12/31").setHours(0, 0, 0, 0);

	if (valueFormat) {
		value = parse(value, valueFormat, new Date());
	}

	value = new Date(value).getTime();

	if (value < min) {
		return t('validation:min.numeric', { min: min });
	}

	if (value > max) {
		return t('validation:max.numeric', { max: max });
	}

	return "";
};

const number = ({
	value,
	min,
	max
}) => {

	if (!onlyNumeric.test(value)) {
		return t('validation:numbers_dash');
	}

	if (value < (min || 1)) {
		return t('validation:min.numeric', { min: min });
	}

	if (value > (max || 999999999999999)) {
		return t('validation:max.numeric', { max: max });
	}

	return "";
};

const string = ({
	value,
	maxLength,
	valueFormat,
	multi
}) => {

	value = trim(value);
	maxLength = conditional(isNaN(maxLength), 500, maxLength);

	if (value.length > parseInt(maxLength)) {
		return t('validation:max.string', { max: maxLength });
	}

	let message = "";
	let finalMessage = "";
	let regex = null;
	switch (valueFormat) {
		case "id":
			regex = conditional(multi, /^[\d,]*$/, /\d/);
			message = t(`validation:${conditional(multi, "regex", "numeric")}`);
			message += conditional(multi, " ej : 1,2,3", "");
			break;
		case "alphanumericKey":
			regex = alphanumericKey;
			message = t('validation:alpha_num');
			break;
		case "numeric":
			regex = numeric;
			message = t('validation:numbers_dash');
			break;
		case "onlyNumeric":
			regex = onlyNumeric;
			message = t('validation:onlynumbers');
			break;
		case "alpha_num":
			regex = alphanumericPC;
			message = t('validation:alpha_num');
			break;
		case "alphanumeric":
			regex = alphaspecials;
			message = t('validation:alpha_num');
			break;
		case "email":
			regex = /^[a-zA-Z\dñÑáéíóúÁÉÍÓÚäÄëËïÏöÖüÜ'&._-\s@]*$/;
			message = t('validation:alpha');
			break;
		case "alpha":
		default:
			regex = capital;
			message = t('validation:alpha');
			break;
	}

	if (!regex.test(value)) {
		finalMessage = message;
	}

	return finalMessage;
};

const validation = (rules) => {

	const { value, type, required } = rules;

	if (!hasValue(trim(value))) {
		return conditional(required, t('validation:required_filter'), "");
	}

	const basicConfigurations = {
		required: false,
		maxLength: 50,
		value: null,
		max: undefined,
		min: 1,
		valueFormat: undefined,
		multi: false,
		...rules
	};

	switch (type) {
		case "numeric":
			return number(basicConfigurations);
		case "date":
			return date(basicConfigurations);
		case "string":
			return string(basicConfigurations);
		default:
			return "";
	};
};

export default validation;