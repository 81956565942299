import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isString, size } from 'lodash';
import DialogContent from '@mui/material/DialogContent';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchTextBox from '../../SearchTextBox';
import useAllMenuSearch from '../../../contextsAndControllers/useAllMenuSearch';
import { InitSearch } from './InitSearch';
import { Menu } from './Menu';
import { selector, setFocus } from '../../../../general/GeneralUtilities';
import { motion } from 'framer-motion';
import { setSearch } from '../../../../../../../store/actions';

const focusSelectorQuery = [
	`#all-options-search`,
	`button[name = "hidden-action-nav-button"]`,
].join(", ");

const Content = ({ onClose, menuItems }) => {
	const dispatch = useDispatch();
	const rSearch = useSelector(state => state?.search?.search);
	const {
		search,
		debouncedSearch,
	} = useAllMenuSearch();

	useEffect(() => {
		if (!rSearch) return;
		if (!isString(rSearch)) {
			return;
		}
		setTimeout(() => {
			debouncedSearch(rSearch);
			dispatch(setSearch(null));
		}, 200);
	}, [rSearch]);

	const mainContainer = selector(`#items-search-modal-container`);
	const handleKeyDown = (e) => {
		const key = e.keyCode;
		if (![13, 38, 40].includes(key)) {
			return;
		}
		const currentElement = document.activeElement;
		const isSubmit = currentElement?.type === "submit";
		if (key === 13) {
			if (isSubmit) {
				return;
			} else {
				e.preventDefault();
			}
		}
		const focusableElements = Array.from(mainContainer.querySelectorAll(focusSelectorQuery));
		const currentIndex = focusableElements.indexOf(currentElement);
		const oneValue = e?.shiftKey || (key === 38 && isSubmit) ? -1 : 1;
		const nextIndex = (currentIndex + oneValue) % focusableElements.length;
		const nextFocused = setFocus(focusableElements?.[nextIndex], true);
		if (!nextFocused) {
			setFocus(focusableElements?.[0], true);
		}
	};

	useEffect(() => {
		if (!mainContainer) return;
		mainContainer.addEventListener("keydown", handleKeyDown);
		return () => {
			mainContainer.removeEventListener("keydown", handleKeyDown);
		};
	}, [mainContainer]);

	return (
		<DialogContent className={"modal-search-content"} id={"items-search-modal-container"}>
			<div className='searchContainer'>
				<SearchTextBox
					tab={"all-aoptions"}
					search={search}
					debouncedSearch={debouncedSearch}
					id={"all-options-search"}
				/>
			</div>
			<motion.div className='menu-content' layout="size">
				<PerfectScrollbar>
					{size(search) < 3
						? <InitSearch />
						: <Menu onClose={onClose} menuItems={menuItems} />
					}
				</PerfectScrollbar>
			</motion.div>
		</DialogContent>
	);
};
export default Content;