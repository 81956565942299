import {
	SHOW_NOTIFICATION_SUCCESS,
	HIDE_NOTIFICATION_SUCCESS,
	SHOW_NOTIFICATION_WARNING,
	HIDE_NOTIFICATION_WARNING,
	SHOW_NOTIFICATION_ERROR,
	HIDE_NOTIFICATION_ERROR,
	SHOW_NOTIFICATION_REMOVE,
	HIDE_NOTIFICATION_REMOVE,
	SHOW_NOTIFICATION_IMPORT,
	HIDE_NOTIFICATION_IMPORT,
	SHOW_NOTIFICATION_DIALOG,
	HIDE_NOTIFICATION_DIALOG,
	SHOW_CONTROLLED_ERROR,
	HIDE_CONTROLLED_ERROR,
	SHOW_CONFIRM_DIALOG,
	HIDE_CONFIRM_DIALOG,
	SHOW_CUSTOM_NOTIFICATION_DIALOG,
	HIDE_CUSTOM_NOTIFICATION_DIALOG,
	SHOW_OPTION_DIALOG,
	HIDE_OPTION_DIALOG
} from '../actions'

const notificationBody = (extras) => {
	extras = extras || {};
	return {
		title: "",
		message: "",
		description: "",
		show: false,
		...extras
	}
}

const initialState = {
	successNotification: notificationBody(),
	warningNotification: notificationBody(),
	errorNotification: notificationBody(),
	controlledError: {
		fields: "",
		general: "",
		show: false
	},
	removeNotification: notificationBody(),
	importNotification: notificationBody({ importedItems: [] }),
	dialogNotification: notificationBody(),
	customNotification: notificationBody(),
	confirmNotification: notificationBody({
		handleCancel: null,
		handleConfirm: null,
		confirmButtonText: null,
		cancelButtonText: null
	}),
	optionNotification: notificationBody({
		optionsList : []
	}),
}

export function notification(state = initialState, action) {
	switch (action.type) {
		case SHOW_NOTIFICATION_SUCCESS:
			return {
				...state,
				successNotification: {
					...action.payload,
					show: true
				}
			}
		case HIDE_NOTIFICATION_SUCCESS:
			return {
				...state,
				successNotification: {
					...state.successNotification,
					show: false
				}
			}
		case SHOW_NOTIFICATION_WARNING:
			return {
				...state,
				warningNotification: {
					...action.payload,
					show: true
				}
			}
		case HIDE_NOTIFICATION_WARNING:
			return {
				...state,
				warningNotification: {
					...state.warningNotification,
					show: false
				}
			}
		case SHOW_NOTIFICATION_ERROR:
			return {
				...state,
				errorNotification: {
					...action.payload,
					show: true
				}
			}
		case HIDE_NOTIFICATION_ERROR:
			return {
				...state,
				errorNotification: {
					...state.errorNotification,
					show: false
				}
			}
		case SHOW_NOTIFICATION_REMOVE:
			return {
				...state,
				removeNotification: {
					...action.payload,
					show: true
				}
			}
		case HIDE_NOTIFICATION_REMOVE:
			return {
				...state,
				removeNotification: {
					...state.removeNotification,
					show: false
				}
			}
		case SHOW_NOTIFICATION_IMPORT:
			return {
				...state,
				importNotification: {
					importedItems: action.payload,
					show: true
				}
			}
		case HIDE_NOTIFICATION_IMPORT:
			return {
				...state,
				importNotification: {
					...state.importNotification,
					importedItems: action.payload,
					show: false
				}
			}
		case SHOW_CONTROLLED_ERROR:
			return {
				...state,
				controlledError: {
					...action.payload,
					show: true
				}
			}
		case HIDE_CONTROLLED_ERROR:
			return {
				...state,
				controlledError: {
					...state.controlledError,
					show: false
				}
			}
		case SHOW_NOTIFICATION_DIALOG:
			return {
				...state,
				dialogNotification: {
					...action.payload,
					show: true
				}
			}
		case HIDE_NOTIFICATION_DIALOG:
			return {
				...state,
				dialogNotification: {
					...state.dialogNotification,
					show: false
				}
			}
		case SHOW_CUSTOM_NOTIFICATION_DIALOG:
			return {
				...state,
				customNotification: {
					...action.payload,
					show: true
				}
			}
		case HIDE_CUSTOM_NOTIFICATION_DIALOG:
			return {
				...state,
				customNotification: {
					...state.customNotification,
					show: false
				}
			}
		case SHOW_CONFIRM_DIALOG:
			return {
				...state,
				confirmNotification: {
					...action.payload,
					show: true
				}
			}
		case HIDE_CONFIRM_DIALOG:
			return {
				...state,
				confirmNotification: {
					...state.confirmNotification,
					show: false
				}
			}
		case SHOW_OPTION_DIALOG:
			return {
				...state,
				optionNotification: {
					...action.payload,
					show: true
				}
			}
		case HIDE_OPTION_DIALOG:
			return {
				...state,
				optionNotification: {
					...state.optionNotification,
					show: false
				}
			}
		default:
			return state
	}
}