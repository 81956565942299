import PropTypes from "prop-types";
import React from 'react';
import Grid from "@mui/material/Grid2";
import { hasValue } from '../general/GeneralUtilities';
import CardHead from "./CardHead";

const OptionCard = ({
	onClick,
	selected,
	value,
	title,
	message,
	description,
}) => {

	const is_selected = selected === value;

	return (
		<button className={`card-option-button ${is_selected ? "checked" : ""}`} onClick={() => onClick(value)} >
			<Grid container spacing={2}>
				<CardHead title={title} is_selected={is_selected} />
				<Grid container>
					{hasValue(message) && (
						<Grid size={{ xs: 12 }}>
							<span className='card-option-message' >{message}</span>
						</Grid>
					)}
					{hasValue(description) && (
						<Grid size={{ xs: 12 }}>
							<span className='card-option-description' >{description}</span>
						</Grid>
					)}
				</Grid>
			</Grid>
		</button>
	);
};

OptionCard.propTypes = {
	description: PropTypes.any,
	message: PropTypes.any,
	onClick: PropTypes.any,
	selected: PropTypes.any,
	title: PropTypes.any,
	value: PropTypes.any
};

export default OptionCard;