import { ServerAPI } from "./ServerAPI";

const UserConfigs = () => {
    const baseName = "extra-perms-user";
    const baseApi = ServerAPI(baseName);

    return {
        ...baseApi,
    }
}

const ExtraPerms = () => {
    const baseName = "extra-perms";
    const baseApi = ServerAPI(baseName);

    return {
        ...baseApi,
    }
}

export const userConfigAPI = UserConfigs();
export const extraPermsAPI = ExtraPerms();
export const extraMoperPermissionAPI = ServerAPI('moper-permission')
export const extraActiveDaysAPI = ServerAPI('active-days')
export const extraCancelMopersAPI = ServerAPI('cancel-mopers')