import PropTypes from "prop-types";
import React from "react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import Card from '@mui/material/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faIdBadge as idL,
  faLock as lockL,
  faFileMinus
} from "@fortawesome/pro-light-svg-icons";
import {
  faTrash as trashS,
  faIdBadge as idS,
  faLock as lockS
} from "@fortawesome/pro-solid-svg-icons";
/* own components */
import { AvatarAndTooltip } from "../Inputs/photoUpload/photo/CustomAvatar";
import { CustomTooltip } from "../Templates/cells";
import { workerFullName } from "./constants";
import { structureLabel } from "../StructureEnterprise/Utils/StructureUtilities";
import { size } from "lodash";
import { motion } from "framer-motion";
import { ActionButton } from "../table/utils/ActionsColumn";
import IconButton from "../Buttons/IconButton";
import { conditional } from "../table/CommandCell";
import { valueOrOption } from "../general/GeneralUtilities";


/**
 * Este hook nos sirve para renderizar la celda de fotos de los trabajadores en el sistema
 * @param {object} params
 * @param {object} params.dataItem - El item que contiene la informnación del trabajador
 * @param {?string} params.className - Custom class para la celda
 *
 * @returns {AvatarCellComponent}
 */
export const AvatarCell = ({
  dataItem: { name, photo, first_surname, second_surname },
  className,
  onClick,
  selectionChange,
  style,
}) => {

  const handleClick = onClick ?? selectionChange;
  const fullName = workerFullName({ first_surname, second_surname, name });

  return (
    <td className={(className ?? "") + " avatarCell"} style={style} onClick={handleClick}>
      <div className={"avatar-cell-item"} >
        <AvatarAndTooltip
          photo={photo?.medium_square_crop}
          string={fullName.trim()}
          className="ml-1"
          fontSize='10px'
        />
      </div>
    </td>
  );
};

AvatarCell.propTypes = {
  dataItem: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  selectionChange: PropTypes.any,
  style: PropTypes.any
}

/**
 * Hook que ns sirve para renderizar la información de la estructura del colaborador en las tablas
 * @param {object} params
 * @param {object} params.dataItem - Es el dataitem del trabajador
 * @param {object} params.column - Nos indica la columna que se está renderizando
 *
 * @returns {StructureColumnComponent}
 */

export const StructureColumn = ({
  dataItem: { enterprise_structure_organization },
  column,
  className,
  onClick,
  selectionChange
}) => {

  const configStructure = useSelector((state) => state.configuration.structure);
  const handleClick = onClick ?? selectionChange;

  const tree = {
    ...(enterprise_structure_organization?.enterprise_structure ?? {}),
    employer_registration: enterprise_structure_organization?.employer_registration,
  };


  return (
    <CustomTooltip
      className='tooltipWrap'
      title={<StructureTooltip configStructure={configStructure} structure={tree} />}
    >
      <td onClick={handleClick} className={`${className ?? ""} tootipTd`}>
        {`${tree?.[column]?.key} - ${tree?.[column]?.name}`}
      </td >
    </CustomTooltip>
  );
};

StructureColumn.propTypes = {
  dataItem: PropTypes.any,
  className: PropTypes.string,
  column: PropTypes.any,
  onClick: PropTypes.func,
  selectionChange: PropTypes.any
};

const StructureTooltip = ({
  configStructure = [],
  structure = {}
}) => (
  <table>
    <tbody>
      {configStructure.map((item) => (
        item.is_active && (
          <tr key={item.key}>
            <td style={{
              fontWeight: "bold",
              paddingRight: "1em",
            }} >
              {structureLabel(item)}
            </td>
            <td>
              {structure?.[item.key]?.key}
              <span className="textClipped" >
                {` - ${structure?.[item.key]?.name}`}
              </span>
              {structure?.[item.key]?.rfc && (
                <span>{` - ${structure?.[item.key]?.rfc}`}</span>
              )}
            </td>
          </tr>
        )
      ))}
    </tbody>
  </table>
);

StructureTooltip.propTypes = {
  configStructure: PropTypes.array,
  structure: PropTypes.object
};


export const ContractTypeColumn = ({
  dataItem: {
    contract_type,
  },
  onClick,
  selectionChange
}) => {

  const contract = `${t("key")} - ${contract_type?.key} \n${t("name")} - ${contract_type?.name}`;
  const handleClick = onClick ? onClick : selectionChange;

  return (
    <CustomTooltip title={contract}>
      <td onClick={handleClick}>
        {`${contract_type?.key} - ${contract_type?.name}`}
      </td>
    </CustomTooltip>
  );
};

ContractTypeColumn.propTypes = {
  dataItem: PropTypes.any,
  onClick: PropTypes.func,
  selectionChange: PropTypes.func
};

/**
 * Hook que nos sirve la celda costumizada de fecha de inicio de contratos del trabajador
 *
 * @param {object} params
 * @param {object} params.dataItem - El dataItem del trabajador
 * @param {function} params.onClick - Función que realiza la celda al hacerle click
 *
 * @returns {StartContractColumnComponent}
 */
export const StartContractColumn = ({
  dataItem: {
    last_contract_type_name,
    last_contract_date,
  },
  onClick,
  selectionChange,
  key
}) => {

  const handleClick = onClick ?? selectionChange;

  const label = () => {
    if (!last_contract_date) {
      return [t('permanent').toUpperCase(), last_contract_type_name];
    }

    const initialDate = new Date(last_contract_date.replaceAll("-", "/"));

    return [(
      <div key={key} onClick={handleClick} onDoubleClick={handleClick} aria-hidden="true">
        <span>
          {format(initialDate, "dd/MM/yyyy")}
        </span>
      </div>
    ), format(initialDate, "dd/MM/yyyy")];
  };

  const element = label();

  return (
    <CustomTooltip title={element[1] || ""}>
      <td className="text-right" onClick={handleClick} aria-hidden>
        {element[0]}
      </td>
    </CustomTooltip>
  );
};

StartContractColumn.propTypes = {
  dataItem: PropTypes.any,
  key: PropTypes.any,
  onClick: PropTypes.func,
  selectionChange: PropTypes.func
};


/**
 * Hook que nos sirve la celda costumizada de fecha fin de contratos del trabajador
 *
 * @param {object} params
 * @param {object} params.dataItem - El dataItem del trabajador
 * @param {function} params.onClick - Función que realiza la celda al hacerle click
 *
 * @returns {EndContractColumnComponent}
 */
export const EndContractColumn = ({
  dataItem: {
    last_contract_type_name,
    last_contract_end_date,
    current_status = {}
  },
  style,
  onClick,
  selectionChange,
  className,
  key,
  byMopers = false,
  withActionLabel,
  dateClassName = "",
  withTooltip,
  withCard = false,
  cardClassName,
  forTable = false,
  withIcon = false
}) => {

  const cellTitle = last_contract_type_name;
  const handleClick = onClick ?? selectionChange;

  const label = () => {
    if (!last_contract_end_date) {
      if (forTable) {
        return '';
      }
      return [t(last_contract_type_name?.toLowerCase())?.toUpperCase(), last_contract_type_name];
    }

    const endDate = new Date(last_contract_end_date.replaceAll("-", "/"));

    const now = new Date();
    endDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);
    const daysDif = Math.abs(now - endDate) / (1000 * 3600 * 24);
    const type = conditional(now.getTime() > endDate.getTime(), conditional(current_status?.active, 2, 0), 1);

    const style = ["", "greenActionLabel", "orangeActionLabel"];

    let title;
    switch (type) {
      case 2:
        title = `${t("defeated")} ${daysDif} ${conditional(daysDif === 1, t("day"), t("days"))}`;
        break;
      case 1:
        title = conditional(!daysDif, `${t("expires-today")}`, `${t("expires-in")} ${daysDif} ${conditional(daysDif === 1, t("day"), t("days"))}`);
        break;
      default:
        title = ``;
    }

    if (!type) {
      return [format(endDate, "dd/MM/yyyy"), title];
    }

    return [(
      <div
        key={key}
        className={conditional(withActionLabel, "actionLabel " + style[type], dateClassName)}
        onClick={handleClick}
        onDoubleClick={handleClick}
        aria-hidden="true"
      >
        <span>{format(endDate, "dd/MM/yyyy")}</span>
      </div>
    ), title];
  };

  const element = label();

  const card = (
    <Card className={cardClassName ?? ''}>
      {element[0]}
    </Card>
  );

  const td = (
    <td style={style} className={`${className} ${dateClassName ?? ''}`} onClick={handleClick}>
      {withCard ? card : element[0]}
    </td>
  );

  const div = (
    <div style={style} className={className} onClick={handleClick} aria-hidden="true">
      {withIcon && <FontAwesomeIcon icon={faFileMinus} />}
      {withCard ? card : element[0]}
    </div>
  );


  const content = () => {
    return (
      byMopers ? div : td
    );
  };

  return (
    withTooltip ?
      <CustomTooltip title={element[1] || cellTitle || ""}>
        {content()}
      </CustomTooltip >
      :
      content()
  );
};

EndContractColumn.propTypes = {
  dataItem: PropTypes.any,
  byMopers: PropTypes.bool,
  cardClassName: PropTypes.string,
  className: PropTypes.any,
  dateClassName: PropTypes.string,
  forTable: PropTypes.bool,
  key: PropTypes.any,
  onClick: PropTypes.any,
  selectionChange: PropTypes.any,
  style: PropTypes.any,
  withActionLabel: PropTypes.any,
  withCard: PropTypes.bool,
  withIcon: PropTypes.bool,
  withTooltip: PropTypes.any
};

/**
 * Celda custumizada de la vigencia del colaborador, vigente, no vigente, con baja programada
 *
 * @param {object} params
 * @param {object} params.dataItem - El dataItem del trabajador
 * @param {function} params.onClick - Función que realiza la celda al hacerle click
 *
 * @returns {StatusColumnComponent}
 */
export const StatusColumn = ({ dataItem, onClick, selectionChange }) => {
  const handleClick = onClick ?? selectionChange;
  return (
    <td className="k-command-cell text-center defaultCursor" onClick={handleClick}>
      <StatusLabel worker={dataItem} animate={false} onClick={handleClick} onDoubleClick={handleClick} />
    </td>
  );
};

StatusColumn.propTypes = {
  dataItem: PropTypes.any,
  onClick: PropTypes.any,
  selectionChange: PropTypes.any
};

/**
 * Celda custumizada de la vigencia del colaborador, vigente, no vigente, con baja programada
 *
 * @param {object} params
 * @param {object} params.worker - Los datos del trabajador
 *
 * @returns {StatusLabelComponent}
 */
export const StatusLabel = ({
  worker,
  animate = false,
  onClick,
  onDoubleClick,
  className,
  withTooltip = true,
  withContract = false
}) => {
  if (!size(worker)) return;

  const {
    active,
    end_contract,
    programed_low,
    movement,
    entry_date
  } = worker.current_status ?? {};

  let date = "";
  let leaveTitle = "";
  let activeColor = "greenActionLabel";

  if (active && (!!end_contract || !!programed_low)) {
    date = (end_contract ?? programed_low ?? "").split("-").reverse().join("/");
    activeColor = "greenActionLabel";
    leaveTitle = `${t(conditional(end_contract, "contract-end-date", "scheduled-leave"))} ${date}`;
  }

  if (!active && !!movement) {
    date = valueOrOption(entry_date, "").split("-").reverse().join("/");
    leaveTitle = `${t(conditional(movement === "A", "admission-date", "date-reentry"))} ${date}`;
  }

  if (!animate && !withContract) {

    const content = () => {
      return (
        <div
          className={`actionLabel ${conditional(active, activeColor, "redActionLabel")}`}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          aria-hidden="true"
        >
          <span>
            <FontAwesomeIcon icon={conditional(active, faCircleCheck, faCircleXmark)} />
            {`${t(conditional(active, "valid", "not-valid"))}`}
          </span>
        </div>
      );
    };
    return conditional(withTooltip,
      <CustomTooltip title={leaveTitle}>
        {content()}
      </CustomTooltip>,
      content());
  };

  const status = () => {
    return conditional(
      animate,
      <motion.div
        className="status-label"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "linear", stiffness: 100 }}>
          <div
          className={`actionLabel ${active ? activeColor : "redActionLabel"}`}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            aria-hidden="true"
          >
            <span>
              <FontAwesomeIcon icon={active ? faCircleCheck : faCircleXmark} />
              {`${t(conditional(active, "valid", "not-valid"))}`}
            </span>
          </div>
      </motion.div>,
      <div
        className={`actionLabel ${active ? activeColor : "redActionLabel"}`}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        aria-hidden="true"
      >
        <span>
          <FontAwesomeIcon icon={active ? faCircleCheck : faCircleXmark} />
          {`${t(conditional(active, "valid", "not-valid"))}`}
        </span>
      </div>
    );
  };

  return (
    <div className={className ?? ""} style={{ display: '-webkit-inline-box' }}>
      {conditional(withTooltip,
        <CustomTooltip title={leaveTitle}>
          {status()}
        </CustomTooltip>,
        status())}
    </div>
  );
};

StatusLabel.propTypes = {
  animate: PropTypes.bool,
  className: PropTypes.any,
  onClick: PropTypes.any,
  onDoubleClick: PropTypes.any,
  withContract: PropTypes.bool,
  withTooltip: PropTypes.bool,
  worker: PropTypes.shape({
    current_status: PropTypes.object
  })
};

/**
 * Celda custumizada de las acciones que se pueden realizar a los colaboradores
 *
 * @param {object} params
 * @param {object} params.dataItem - El dataItem del trabajador
 * @param {function} params.enterDelete - Función que lanza la acción de eliminar
 * @param {function} params.enterHistory - Función que lanza la acción de entrar al historico
 * @param {?string} params.className - Classe custom de la celda
 *
 * @returns {StatusColumnComponent}
 */
export const ActionsColumn = ({
  //-variables
  dataItem,
  //--functions
  enterDelete,
  // enterHistory,
  className,
}) => {
  const navigate = useNavigate();
  const showDelete = dataItem.is_active || dataItem.is_active === undefined;
  function handleOpen() {
    const to = `?kardex_worker=${dataItem.key}`;
    navigate(to);
  }
  return (
    <td className={`${className} k-command-cell locked-btns`}>
      <IconButton
        title={t("kardex-worker")}
        icon={idL}
        altIcon={idS}
        onClick={handleOpen}
      />
      {showDelete && dataItem?.can_delete && (
        <ActionButton
          scope={"write"}
          title={t("remove")}
          icon={"edit"}
          altIcon={trashS}
          onClick={() => enterDelete(dataItem)}
        />
      )}
      {!dataItem?.can_delete && showDelete &&
        <ActionButton
          title={t("delete-locked")}
          scope="write"
          icon={lockL}
          altIcon={lockS}
        />
      }
    </td>
  );
};

ActionsColumn.propTypes = {
  className: PropTypes.any,
  dataItem: PropTypes.any,
  enterDelete: PropTypes.func
};
