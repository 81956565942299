import * as React from "react";
import i18next from 'i18next';
import { Loader } from "@progress/kendo-react-indicators";
import LottieUse from '../uiDesign/LottieUse';
import { valueOrOption } from '../general/GeneralUtilities';

const Skeleton = ({ customMsg }) => {
	return (
		<span className="abrhil-list-no-records-msg">
			<LottieUse loop icon={"no-records-icon"} className={`player-no-list-items`} />
			<label>{valueOrOption(customMsg, i18next.t('no-options'))}</label>
		</span>
	);
};

/* for dropdowns and muultiselect normal */

export function listNoDataRender(element, customMsg) {
	const noData = (<Skeleton customMsg={customMsg} />);
	return React.cloneElement(element, { ...element.props }, noData);
};

/* for multiselect tree */
export const ListNoData = () => (
	<div className="k-nodata">
		{<Skeleton />}
	</div>
);

export function LoadingData(element) {
	const Load = (
		<h4 style={{ fontSize: "1em", }} >
			<Loader size="small" type={"pulsing"} />
			<br /> <br />
			{i18next.t('loading')}
		</h4>
	);
	return React.cloneElement(element, { ...element.props }, Load);
}