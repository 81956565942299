import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BaseButton } from '../../../../Buttons';
import DialogContent from '../../../../../styled/DialogContent';
import { ValidationSchema, defaultValues, dropdownProps, setObjStorage } from './utilities';
import FormInput from '../../../../form/Field';
import { Form } from '../../../../form/Container';
import { userOptionsApi } from '../../../../../services/administrator';
import { implementService } from '../../../../../services/implemet-service';
import { hasValue, valueOrOption } from '../../../../general/GeneralUtilities';
import { useDispatch, useSelector } from 'react-redux';
import { usePackages } from '../../../../../@hooks/usePackages/usePackages';
import { changeGroup, changeTenant, groupInit, lockedWindow, menu, showNotificationWarning, unlockedWindow } from '../../../../../../../store/actions';
import { closeAllWindows } from '../../../../../../../store/triggers/navigate';
import { isAbrhil } from '../../../../general/auth';
import { CompanyLogo } from './CompanyLogo';
import { RolesDropdown } from './RolesDropdown';
import { CompaniesDropdown } from './CompaniesDropdown';
import { pick } from "lodash";
import { currentUserInfo } from "../../../../requests/user-store";

const ModalContent = ({ onClose }) => {

	const { t } = useTranslation();
	const {
		user_info: {
			id,
		} = {}
	} = currentUserInfo() ?? {};
	const { select } = useSelector((state) => state.group);
	const isSuper = useSelector((state) => state.userConfigs?.is_superuser);

	const {
		loadPackages,
		resetPackages,
		loadMenu,
		// getUserGroups,
		getUserConfigs,
	} = usePackages();

	const dispatch = useDispatch();
	const tenant = useSelector((state) => state.tenant);
	const [corporates, setCorporates] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [roles, setRoles] = useState([]);

	const loadData = () => {
		const api = userOptionsApi();
		implementService(api.get({}),
			response => {
				setCorporates(valueOrOption(response?.corporates, []));
				setCompanies(valueOrOption(response?.companies, []));
				setRoles(valueOrOption(response?.roles, []));
			}
		);
	};

	const {
		reset,
		control,
		setValue,
		getValues,
		handleSubmit,
		clearErrors,
	} = useForm({
		resolver: yupResolver(ValidationSchema(isSuper)),
		mode: "onSubmit",
		defaultValues
	});

	useEffect(() => {
		loadData();
		const current = tenant?.current;
		const newValues = {
			corporate: valueOrOption(current?.corporate?.id, current?.corporate),
			company: current?.id,
			companyObj: current,
			role: select?.id,
		};
		reset(newValues);
		//eslint-disable-next-line
	}, [id]);

	const onSubmit = async (data) => {
		onClose();
		const current = tenant?.current;
		dispatch(closeAllWindows());
		dispatch(lockedWindow());
		if (data.company !== current?.id) {
			onChangeTenant(data.companyObj);
			dispatch(groupInit(roles));
		}
		if (!roles?.length || !hasValue(data.role)) {
			dispatch(menu({}));
			const userData = await getUserConfigs(current?.id);
			const isSuper = userData?.is_superuser;
			if (isSuper) onChangeGroup(current?.id, data.roleObj);
			dispatch(showNotificationWarning({
				maxWidth: "sm",
				title: t("warning-general-title"),
				message: t(isSuper ? "not-configured-roles" : "not-configured-roles2")
			}));
		} else if ((data.role !== select?.id || data.company !== current?.id) && hasValue(data.role)) {
			dispatch(menu({}));
			await onChangeGroup(current?.id, data.roleObj ?? { id: data.role });
		}
		dispatch(unlockedWindow());
	};

	const onChangeTenant = (newValue) => {
		dispatch(changeTenant(newValue));
		if (newValue) {
			getUserConfigs(newValue);
			localStorage.setItem("tenantStorage", JSON.stringify(newValue));
			loadPackages(newValue.id);
			return;
		}
		localStorage.removeItem("tenantStorage");
		resetPackages();
	};

	const onChangeGroup = async (tenant, newValue) => {
		dispatch(changeGroup(newValue));
		setObjStorage("roleStorage", pick(newValue, ['id', 'name', 'config']));
		await loadMenu(tenant, newValue?.id);
	};

	return (
		<DialogContent design={"modern"}>
			<Grid container spacing={3} justifyContent={"center"} alignItems={"center"}>
				<Grid item xs={3}>
					<CompanyLogo control={control} />
				</Grid>
				<Grid item xs={9}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<FormInput
								control={control}
								{...dropdownProps}
								data={valueOrOption(corporates, [])}
								name={"corporate"}
								label={t("corporate")}
								disabled={!isAbrhil()}
							/>
						</Grid>
						<Grid item xs={6}> </Grid>
						<Grid item xs={6}>
							<CompaniesDropdown
								control={control}
								companies={companies}
								setValue={setValue}
								getValues={getValues}
								clearErrors={clearErrors}
							/>
						</Grid>
						<Grid item xs={6}>
							<RolesDropdown
								control={control}
								roles={roles}
								setValue={setValue}
								getValues={getValues}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={"auto"}>
					<Form
						onSubmit={onSubmit}
						handleSubmit={handleSubmit}
						defaultValues={defaultValues}
					>
						<BaseButton
							label={t("accept")}
							design={"contained"}
							type="submit"
						/>
					</Form>
				</Grid>
			</Grid>
		</DialogContent>
	);
};

ModalContent.propTypes = {
	onClose: PropTypes.func
}

export default ModalContent;
