import React from 'react';
import PropTypes from 'prop-types';
import { getPermissionCancelMoper } from '../../../../../utils/getMoperPermission';

export const TXT_KEYS = {
    1: "txt_money",
    2: "txt_overtime",
    3: "txt_worked_rest",
    4: "txt_double_shift",
}

export const OTHER_KEYS = {
    9: "promociones"
}

export const KEYS_ABSENTEEISM = {
    1: "ausen_con_goce",
    2: "ausen_sin_goce",
    3: "vacaciones",
    4: "ajuste_asist",
    5: "completar_turno",
    6: "txt",
    11: "requisicion_personal",
    ...OTHER_KEYS
}


export const getMoperKey = (moper) => {
    const paytype = moper?.pay_type;
    const catalog = moper?.moper?.moper_catalog?.id ?? moper?.moper?.moper_catalog ?? moper?.catalog_id;
    return catalog !== 6 ? (KEYS_ABSENTEEISM[catalog] ?? moper?.catalog_key) : TXT_KEYS[paytype];
}

function PermissionGate({ children, moper }) {
    if (!getPermissionCancelMoper(getMoperKey(moper))) return null;
    return (
        <>{children}</>
    );
};

export default PermissionGate;

PermissionGate.propTypes = {
    children: PropTypes.any,
    moper: PropTypes.object,
}