export const CONFIG = 'configuration'

const initialState = {}

export function configuration(state = initialState, action) {
  switch (action.type) {
    case CONFIG:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
