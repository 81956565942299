import { lazy } from "react";
import {
    faFolderGear,
} from "@fortawesome/pro-light-svg-icons";

const abrilItems = [
    {
        id: 'abrhil@corporate',
        type: 'item',
        validateItem: true,
        icon: "corporate",
        componentElm: lazy(async ()  => import("../../../../../modules/abrhil/corporate/Corporate")),
    },
    {
        id: 'abrhil@business',
        type: 'item',
        icon: "enterprise",
        componentElm: lazy(async ()  => import('../../../../../modules/system/company/Company')),
        sub_routes: [
            {
                index: true, // Indica que será el componente representante
                componentElm: lazy(async ()  => import('../../../../../modules/system/company/Company')),
            },
        ]
    },
    {
        id: 'abrhil@migrations',
        type: 'item',
        validateItem: true,
        icon: "migration",
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/migration/Migrations')),
    },
    {
        id: 'abrhil@packages_admin',
        type: 'item',
        inConstruction: true,
        validateItem: true,
        icon: faFolderGear,
        componentElm: true,
    },
    {
        id: 'abrhil@onboarding',
        type: 'item',
        validateItem: true,
        icon: faFolderGear,
        componentElm: lazy(async () => import('../../../../../modules/abrhil/general/OnBoardingAdmin')),
    },
    {
        id: 'abrhil@nationalities',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@contract-catalog',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/general/ContractCatalog')),
    },
    {
        id: 'abrhil@federal-entities',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/general/FederalEntities')),
    },
    {
        id: 'abrhil@migratory-status',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/general/MigratoryState')),
    },
    {
        id: 'abrhil@blood-type',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/general/Bloodtype'))
    },
    {
        id: 'abrhil@calendar-holidays',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/attendance/CalendarHolidays')),
    },
    {
        id: 'abrhil@zipcode',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/culture-people/PostalCode')),
    },
    {
        id: 'abrhil@coworker-type',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.worker_type"],
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/culture-people/WorkerType')),
    },
    {
        id: 'abrhil@tax-tables',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/payroll/Taxes')),
    },
    {
        id: 'abrhil@job-position-type',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.job_position_type"],
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/culture-people/JobPositionType')),
    },
    // {
    //     id: 'abrhil@position-classification',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@daily-minimun-salary',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/culture-people/DailyMinimum')),
    },
    {
        id: 'abrhil@professions',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/culture-people/Professions')),
    },
    {
        id: 'abrhil@uma',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/culture-people/UMA')),
    },
    {
        id: 'abrhil@umi',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/culture-people/UMI')),
    },
    {
        id: 'abrhil@profesional-minimun-salary',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/culture-people/ProfessionalMinimum')),
    },
    {
        id: 'abrhil@federal-entities',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    // {
    //     id: 'abrhil@municipalities',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@type-sat-regime-list',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/timbrasoft/RegimeType')),
    },
    {
        id: 'abrhil@pay-frequency-sat',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/timbrasoft/PeriodicitySAT')),
    },
    {
        id: 'abrhil@resource-origin',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/timbrasoft/ResourceOrigin')),
    },
    {
        id: 'abrhil@contracting-regime',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/timbrasoft/ContractingRegime')),
    },
    {
        id: 'abrhil@tax-regime',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@type-contract-sat',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/timbrasoft/ContractSAT')),
    },
    // {
    //     id: 'abrhil@imss-classification',
    //     type: 'item',
    //     package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@imss-division',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/employer-registry/IMSSDivision')),
    },
    {
        id: 'abrhil@imss-fraction',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/employer-registry/IMSSFraction')),
    },
    {
        id: 'abrhil@imss-group',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/employer-registry/IMSSGroup')),
    },
    {
        id: 'abrhil@imss-class',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/employer-registry/IMSSClass')),
    },
    {
        id: 'abrhil@payment-percentage-history',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/imss/HistoryCyV')),
    },
    {
        id: 'abrhil@working-day',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@salary-type',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    // {
    //     id: 'abrhil@shifts',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@inability-classification',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/inability/InabilityClassification')),
    },
    {
        id: 'abrhil@insurance-branch',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/inability/InsuranceBranch')),
    },
    {
        id: 'abrhil@inability-control',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/inability/InabilityControl')),
    },
    {
        id: 'abrhil@sequel-consequence',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/inability/SequelConsequence')),
    },
    {
        id: 'abrhil@type-of-risk',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/inability/TypeOfRisk')),
    },
    {
        id: 'abrhil@inability-covid',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/inability/Covid')),
    },
    {
        id: 'abrhil@quota-imss',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/imss/QuotaIMSS'))
    },
    {
        id: 'abrhil@imss-reason-leaving',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/imss/ImssReasonLeaving')),
    },
    {
        id: 'abrhil@payroll-types',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/payroll/PayrollTypes')),
    },
    {
        id: 'abrhil@child-support',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/payroll/CatAlimony'))
    },
    {
        id: 'abrhil@federal-entities-taxes',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/payroll/FederalEntitiesTaxes')),
    },
    {
        id: 'abrhil@tax-tables',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@pay-frequency',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/payroll/Periodicity'))
    },
    // {
    //     id: 'abrhil@type-tax-adjustment',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@schematics',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/payroll/Schematics'))
    },
    {
        id: 'abrhil@payment-types',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/payroll/PaymentTypes'))
    },
    {
        id: 'abrhil@status-payroll',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@credit-type-discount',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@movement-type',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@credit-type-place',
        type: 'item',
        inConstruction: true,
        showInMenu: false,
        componentElm: true
    },
    {
        id: 'abrhil@banks',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/bank/Banks')),
    },
    {
        id: 'abrhil@banks-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@layouts',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/bank/Layouts')),
    },
    {
        id: 'abrhil@dynamic-banks-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@food-voucher-enterprise',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@voucher-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@dynamic-voucher-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@absenteeism-list',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/attendance/Absenteeism')),
    },
    {
        id: 'abrhil@absenteeism-reasons',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/mirror/absenteeism/AbsenteeismReasons'))
    },
    {
        id: 'abrhil@moper-catalog',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/attendance/Mopers')),
    },
    {
        id: 'abrhil@device',
        type: 'item',
        icon: 'clock',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/access-control/Device')),
    },
    {
        id: 'abrhil@concept-sat',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/timbrasoft/StampConcept/StampConceptContainer')),
    },
    {
        id: 'abrhil@rfc',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/timbrasoft/RFC')),
    },
    {
        id: 'abrhil@voucher-banks',
        type: 'item',
        package_permissions: ["payroll_package", "payroll_package.period_payment_voucher"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/voucher/VoucherBanks')),
    },
    {
        id: 'abrhil@voucher-layouts',
        type: 'item',
        package_permissions: ["payroll_package", "payroll_package.period_payment_voucher"],
        componentElm: lazy(async ()  => import('../../../../../modules/abrhil/voucher/VoucherLayouts')),
    }
];

export default abrilItems;