import React, { lazy } from 'react';

const Login = lazy(() => import('./core/general/public/Login'));
const ActivateAccount = lazy(() => import('./core/general/public/ActivateAccount/ActivateAccount'));

const route = [
    { path: 'login', exact: true, name: 'Login', element: <Login /> },
    { path: 'activate-account', exact: true, name: 'SignUp', element: <ActivateAccount /> },
];

export default route;