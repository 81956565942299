import { ADD_EDIT_USER } from "../actions";

const initialState = {
  name: "",
  email: "",
  username: "",
  password: "",
  tenants_detail: [],
};

export function user(state = initialState, action) {
  switch (action.type) {
    case ADD_EDIT_USER:
      return action.payload;

    default:
      return state;
  }
}
