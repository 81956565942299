import React from "react";
import { useDispatch, useSelector } from "react-redux";
//--project imports
import { hideNotificationRemove } from '../../../../../store/actions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import { DeleteSkeleton, Transition } from "./DialogSkeleton";

const RemoveNotification = (props) => {

	const { handleConfirm } = props;

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		title,
		description,
		message,
		onConfirm,
		show,
		item,
		maxWidth,
		...others
	} = useSelector(state => state.notification.removeNotification);

	const confirm = (event) => {
		handleClose();
		if (onConfirm !== null) {
			onConfirm(item);
			return;
		}

		if (typeof handleConfirm === "function") {
			handleConfirm(event);
		}
	};

	const handleClose = () => dispatch(hideNotificationRemove());

	return <Dialog
		open={show}
		TransitionComponent={Transition}
		className="myDialog errorDialog"
		maxWidth={maxWidth ?? "md"}
	>
		<DeleteSkeleton
			title={title || t("question-delete-title")}
			lottieIcon={"question-icon"}
			message={description || ""}
			description={message || ""}
			close={handleClose}
			confirm={confirm}
			{...others}
		/>
	</Dialog>;
};

export default RemoveNotification;