import PropTypes from "prop-types";
import React from 'react';
import Grid from '@mui/material/Grid';
import Grid2 from '@mui/material/Grid2';
import MainCard from '../general/MainCard';
import { hasValue, valueOrOption } from '../general/GeneralUtilities';
import { omit, pick } from 'lodash';
import { conditional } from '../table/CommandCell';

export const GridType = ({
	asV2 = false,
	children,
	...others
}) => {

	const sizesValues = ["xs", "sm", "md", "lg", "xl"];
	const Container = asV2 ? Grid2 : Grid;

	const getProps = () => {
		if (asV2) {
			const sizes = valueOrOption(others?.size, pick(others, sizesValues));
			return conditional(hasValue(sizes), { size: sizes }, {});
		}
		return pick(others, ["item", ...sizesValues]);
	};

	return (
		<Container {...omit(others, ["item", ...sizesValues])} {...getProps()}>
			{children}
		</Container>
	);
};

GridType.propTypes = {
	asV2: PropTypes.bool,
	children: PropTypes.any
};

/**
 * Este item está contenido por un grid de mui, por lo que se le pueden pasar todas las propiedades de este
 * @param {object} params
 * @param {string} params.title - El titulo de la sección
 * @param {*} params.children - El contenido de la sección
 * @param {number} params.spacing - El espaciado que se quiere manejar para los inputs de la seccion
 * @param {boolean} params.isRequired - Indica si se agrega el decorador * en rojo para maracar los requeridos
 * @param {object} params.columns - Inidica las columnas que se quieren manejar dentro del fieldset, por default siempre está en 12
 * @param {object} params.innerProps - Las propiedades extras para el contenedor interno del fieldset
 * @param {object} params.fieldsetStyle - Las propiedades que se quieran aplicar directamente al fieldset HTML nativo
 * @param {"default"|"box"|"simple"} params.design - El tipo de feldsed a mostrar
 *
 * @returns {FieldsetComponent}
 * @example
 *
 *	<Fieldset title={t("title")} >
 *		<Grid item xs={6}>
 *			hello 1
 *		</Grid>
 *		<Grid item xs={6}>
 *			hello 2
 *		</Grid>
 *	</Fieldset>
 */
const Fieldset = ({
	title,
	children,
	spacing,
	isRequired,
	headChilds,
	columns = {},
	fieldsetStyle = {},
	innerProps = {},
	design = "default",
	asV2 = false,
	...others
}) => {

	const commons = {
		fieldsetStyle,
		isRequired,
		title,
		spacing,
		columns,
		innerProps,
		headChilds,
		asV2,
	};

	return (
		<GridType container asV2={asV2} item {...others} >
			{{
				"box": <Boxedfieldset {...commons} >{children}</Boxedfieldset>,
				"simple": <Simplefieldset {...commons} >{children}</Simplefieldset>,
			}[design] ?? <Defaultfieldset {...commons} >{children}</Defaultfieldset>}
		</GridType>
	);
};

Fieldset.propTypes = {
	asV2: PropTypes.bool,
	children: PropTypes.any,
	columns: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	design: PropTypes.string,
	fieldsetStyle: PropTypes.object,
	headChilds: PropTypes.any,
	innerProps: PropTypes.object,
	isRequired: PropTypes.any,
	spacing: PropTypes.number,
	title: PropTypes.string
};

export default Fieldset;

const Defaultfieldset = ({
	isRequired,
	title,
	spacing,
	columns,
	innerProps,
	asV2 = false,
	fieldsetStyle = {},
	children,
}) => (
	<fieldset className={`abrhil-fieldset ${asV2 ? "as-v2" : ""}`} style={fieldsetStyle}>
		{hasValue(title) && (
			<legend className={`abrhil-legend ${isRequired ? "requiredLegend" : ""}`}>
				{title}
			</legend>
		)}

		<GridType asV2={asV2} container spacing={valueOrOption(spacing, 2)} columns={{ xs: 12, md: 12, lg: 12, xl: 12, ...columns }} {...innerProps}>
			{children}
		</GridType>
	</fieldset>
);

Defaultfieldset.propTypes = {
	asV2: PropTypes.bool,
	children: PropTypes.any,
	columns: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.number
	]),
	fieldsetStyle: PropTypes.object,
	innerProps: PropTypes.any,
	isRequired: PropTypes.any,
	spacing: PropTypes.number,
	title: PropTypes.string
};

const Boxedfieldset = ({
	isRequired,
	title,
	spacing,
	columns,
	innerProps,
	headChilds,
	asV2 = false,
	fieldsetStyle = {},
	children,
}) => (
	<MainCard className='abrhil-box-fieldset' style={fieldsetStyle}>
		<div className={`fieldset-header ${isRequired ? "required-fieldset" : ""}`}>
			<span>{title}</span>
			{hasValue(headChilds) && (
				<div className='fieldset-head-childs'>
					{headChilds}
				</div>
			)}
			<span className='required-mark'></span>
		</div>
		<div className='fieldset-body'>
			<GridType asV2={asV2} container spacing={valueOrOption(spacing, 2)} columns={{ xs: 12, md: 12, lg: 12, xl: 12, ...columns }} {...innerProps}>
				{children}
			</GridType>
		</div>
	</MainCard>
);

Boxedfieldset.propTypes = {
	asV2: PropTypes.bool,
	children: PropTypes.any,
	columns: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.number
	]),
	fieldsetStyle: PropTypes.object,
	headChilds: PropTypes.any,
	innerProps: PropTypes.any,
	isRequired: PropTypes.any,
	spacing: PropTypes.number,
	title: PropTypes.string
};

const Simplefieldset = ({
	spacing,
	columns,
	innerProps,
	fieldsetStyle = {},
	asV2 = false,
	children,
}) => (
	<div className={`abrhil-simple-fieldset ${asV2 ? "as-v2" : ""}`} style={fieldsetStyle}>
		<GridType asV2={asV2}
			container
			spacing={valueOrOption(spacing, 2)}
			columns={{ xs: 12, md: 12, lg: 12, xl: 12, ...columns }} {...innerProps}
		>
			{children}
		</GridType>
	</div>
);

Simplefieldset.propTypes = {
	asV2: PropTypes.bool,
	children: PropTypes.any,
	columns: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.number
	]),
	fieldsetStyle: PropTypes.object,
	innerProps: PropTypes.any,
	spacing: PropTypes.number
};
