import PropTypes from "prop-types"
import React from 'react';
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

import { ReactComponent as AbrhilCloud } from "../../../assets/svg/abrhilcloud.svg";
/* own components */
import Menu from '@mui/material/Menu';

import MenuOptions from '../../userSection/MenuOptions';
import { bottomMenuProps, useController } from '../../userSection/utilities';


const Footer = ({stampMode = false}) => {

	const { t } = useTranslation();
	const versionNumber = process.env?.REACT_APP_VERSION_TAG ?? process.env?.REACT_APP_ABR_MODE ?? "0.0.0";
	const {
		anchor,
		open,
		onClose,
		handleLogOut,
		changeTheme,
		username,
		photo,
	} = useController();
	return (
		<Grid container spacing={1} className={"megamenu-footer-container"}>
			<Grid item xs={"auto"} className={"user-profile-button"}>
				<Menu
					anchorEl={anchor?.current}
					className={"user-menu-options"}
					open={open}
					onClose={onClose}
					{...bottomMenuProps}
				>
					<MenuOptions
						user={username}
						photo={photo}
						onClose={onClose}
						handleLogOut={handleLogOut}
						changeTheme={changeTheme}
					/>
				</Menu>
			</Grid>
			<Grid item xs={true} className={'version-info'}>
				{stampMode ? 
					<div className="version-text">
						<span>Timbrasoft | CFDI 4.0 Nómina 1.2</span>
					</div>
				: 
					<>
						<div className='abrhil-letters'>
							<AbrhilCloud />
						</div>
						<div className="version-text">
							<span>{t("version-number", { number: versionNumber })}</span>
						</div>
					</>
				}
			</Grid>
		</Grid>
	);
};

Footer.propTypes = {
  stampMode: PropTypes.bool
}

export default Footer;