import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedState, Grid as GridTable, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { columnProps, pageable } from '../../../general/@components/table/utils/GridCustomProps';
import StatusColumn from '../../../general/@components/table/utils/StatusColumn';
import GridNoRecordsRender from '../../../general/@components/Templates/GridNoRecordsRender';

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "key";
const idGetter = getter(DATA_ITEM_KEY);
function KendoGrid({ data, setValue, filter, page, total, init, setFilter, getPatronalRegistration, setPage }) {
  const { t } = useTranslation();

  const [selectedRecord, setSelectedRecord] = useState({});

  const setEmployerRegistration = (objectSelected) => {
    let newRecords = Object.keys(objectSelected)
      .filter((key) => objectSelected[key])
      .map((key) => key);

    setValue("employer_registrations", newRecords, { shouldValidate: true });
  };
  /* Select */

  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedRecord = getSelectedState({
        event,
        selectedState: selectedRecord,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedRecord(newSelectedRecord);
      setEmployerRegistration(newSelectedRecord);
      // eslint-disable-next-line
    }, [selectedRecord]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedRecord = {};

      event.dataItems.forEach((item) => {
        newSelectedRecord[idGetter(item)] = checked;
      });

      if (JSON.stringify(selectedRecord) === "{}") {
        setSelectedRecord(newSelectedRecord);
        setEmployerRegistration(newSelectedRecord);
      } else {
        setSelectedRecord({ ...selectedRecord, ...newSelectedRecord });
        setEmployerRegistration({ ...selectedRecord, ...newSelectedRecord });
      }

      // eslint-disable-next-line
    }, [selectedRecord]
  );


  /* Filter */

  const filterChange = (e) => {
    const filters = e.filter;
    setFilter(filters);

    getPatronalRegistration(filters, page);
  };

  /* Pagination */

  const pageChange = (event) => {
    setPage({
      ...page,
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  return (
    <GridTable
      // className="principal-grid"
      style={{ height: '100%' }}
      data={data.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedRecord[idGetter(item)],
      }))}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
      filter={filter}
      onFilterChange={filterChange}
      skip={page.skip}
      take={page.take}
      total={total}
      pageable={pageable(total)}
      onPageChange={pageChange}
    >
      <GridNoRecords>
        <GridNoRecordsRender init={init} filter={filter} />
      </GridNoRecords>
      <GridColumn
        field={data.length > 0 ? SELECTED_FIELD : undefined}
        width="50px"
        headerSelectionValue={
          data.findIndex((item) => !selectedRecord[idGetter(item)]) === -1
        }
      />
      <GridColumn
        {...columnProps("key", filter, "input")}
        width={150}
        title={t("key")}
      />
      <GridColumn
        {...columnProps("name", filter, "input")}
        className="capitalize"
        title={t("name")}
      />
      <GridColumn
        {...columnProps("is_active", filter, "status")}
        width={120}
        title={t("is-active")}
        cell={StatusColumn}
      />
    </GridTable>
  );
}

export default KendoGrid;
