
export const periodStatus = {
	open: 0,
	inCompute: 1,
	computedFail: 2,
	computed: 3,
	close: 4,
};

export const computedType = {
	normal: 1,
	settlement: 2,
	payrollTips: 3,
	bonusDays: 4,
	ptu: 5,
	savingFund: 6,
	special: 7,
	settlementAccumulated: 8,
	settlementTest: 9,
};

export const startEndStatus = {
	start: 1,
	end: 2,
	notApply: 0,
};

export const dispersedStatus = {
	notDispersed: 0,
	dispersed: 1,
	stamped: 2,
};

export const stampStatus = {
	notStamped: 0,
	incomplete: 1,
	completed: 2
};

