import React, {
	useEffect,
	useLayoutEffect,
	useRef,
} from 'react';
import { motion, AnimatePresence } from "framer-motion";
import Grid from "@mui/material/Grid";
import Header from "./Header";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Menu from './Menu';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import Tabs from './Tabs';
import useAbrhilNavigation from '../../../contextsAndControllers/useAbrhilNavigation';
import { isSelected } from '../../../utilities';
import { ItemIcon } from '../Search/NavItem';
import Footer from './Footer';
import { CustomTooltip } from '../../../../Templates/cells';
import { withLayout } from '../../../utilities';

const MegaMenu = ({ item }) => {

	const { title, bread, icon } = item;

	const {
		showed,
		breadcrumbs,
	} = useAbrhilNavigation();

	const {
		// tab,
		// search,
		forceOpen,
		// debouncedSearch,
		forceHide,
	} = useMegaMenu();

	const scrollRef = useRef(null);
	const contentRef = useRef(null);
	const showMenu = showed?.key === item?.key;

	useLayoutEffect(() => {
		if (scrollRef?.current) {
			setTimeout(() => {
				scrollRef.current?.updateScroll();
			}, [200]);
		}
	}, [showMenu]);

	const handleEsc = (e) => {
		const code = e?.keyCode?.toString();
		if (parseInt(code) === 27) {
			forceHide();
		}
	};

	useEffect(() => {
		if (!showMenu) { return; }
		document.addEventListener("keydown", handleEsc);
		return () => {
			document.removeEventListener("keydown", handleEsc);
		};
		//eslint-disable-next-line
	}, [showMenu]);

	return (
		<>
			{showMenu && (
				<div
					onClick={forceHide}
					style={{
						background: "transparent",
						position: "fixed",
						width: "100%",
						height: "100%",
						zIndex: -1,
						top: 0,
						left: 0,
					}}
				/>
			)}
			<span
				className={`sidebar-item ${showMenu ? "isOpened" : ""} ${isSelected(bread, breadcrumbs)}`}
				ref={contentRef}
			>
				<CustomTooltip title={title} className="menu-item-tooltip" placement="left">
					<span className='side-menu-item-icon' onClick={() => (showed && item?.id === showed?.id) ? forceHide() : forceOpen(item)}>
						<ItemIcon icon={icon} />
					</span>
				</CustomTooltip>
				<AnimatePresence>
					{showMenu &&
						<motion.div
							key="megamenu"
							animate={visible}
							initial={hidden}
							exit={hidden}
							transition={{ duration: .5 }}
							className="megadrop"
						>
							<motion.div className='megadrop-inner-container' layout>
								<Grid container spacing={1} {...withLayout}>
									<Grid item xs={12} className="mega-menu-header" {...withLayout}>
										<Header />
									</Grid>
									<Grid item xs={12} className="mega-menu-header" {...withLayout}>
										<Tabs />
									</Grid>
								</Grid>
								<motion.div className='childrens-container' layout>
									<PerfectScrollbar ref={scrollRef}>
										<Menu />
									</PerfectScrollbar>
								</motion.div>
								<Footer />
							</motion.div>
						</motion.div>
					}
				</AnimatePresence>
			</span>
		</>
	);
};

export default MegaMenu;

const visible = {
	opacity: 1,
	display: "block",
	x: 0,
};
const hidden = {
	opacity: 0,
	display: "none",
	x: -900,
};