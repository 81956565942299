import { ParseParams, ServerAPI, getUrl } from "./ServerAPI";
import { config, configMedia } from "./config";
import axios from 'axios';

export const requirementsApi = ServerAPI("requirements");
export const bloodTypeAPI = ServerAPI("blood-types", false);
export const holidayRecordsAPI = ServerAPI("holiday-records");
export const zipCodeAPI = ServerAPI("zip-code");
export const calendarsAPI = ServerAPI("calendar");
export const bankLayoutsAPI = ServerAPI('bank-layouts');
export const voucherBankLayoutsAPI = ServerAPI('voucher-bank-layouts');
export const schemasApi = ServerAPI("schemas");
export const payrollStatusesApi = ServerAPI("payment-types", false);
export const degreeDocumentsApi = ServerAPI("degree-document", false);
export const educationSTPSApi = ServerAPI("stps-education", false);
export const educationApi = ServerAPI("education");
export const journeysApi = ServerAPI("journeys");
export const shiftsApi = ServerAPI("shifts");
export const shiftsConfigApi = ServerAPI("shift-configurations");
export const dispersionTypeApi = ServerAPI("voucher-banks/dispersion_type");
export const serviceTypeApi = ServerAPI("voucher-banks/service_type");
export const voucherDispersionLayoutApi = ServerAPI("voucher-dispersion-layout");
export const validMovementDatesApi = ServerAPI("workers/valid-movement-dates");
export const workerLastKeyApi = ServerAPI("worker-last-key");

const EnterpriseShiftsConfigApi = () => {

  const baseName = "enterprise-structures-shift-settings";
  const multi = (request) => {
    const url = getUrl(`${baseName}/multi`, true);

    return axios.put(url, ParseParams({ params: request }), config())
      .then((response) => response.data);
  };

  const massiveUpdate = (params, extra) => {
    const url = getUrl(`${baseName}/update-shift-config`, true);
    const payload = ParseParams({ params });
    return axios.put(url, payload, config(extra)).then((response) => response.data);
  };

  return {
    multi,
    massiveUpdate
  };
};

export const enterpriseShiftsConfigApi = EnterpriseShiftsConfigApi();

const AttendanceList = () => {
  const baseName = "attendance-list";
  const baseApi = ServerAPI(baseName);

  const getStats = (params) => {

    const url = getUrl(`${baseName}/statistics`, true);

    return axios.get(url, config(params))
      .then((response) => response.data);
  };

  const exportList = (params) => {
    const url = getUrl(`${baseName}/export`, true);

    return axios.post(url, params, config())
      .then((response) => response.data);
  };

  const exportEntriesExits = (params) => {
    const url = getUrl(`${baseName}/entries-exits-export`, true);

    return axios.post(url, params, config())
      .then((response) => response.data);
  };

  return {
    ...baseApi,
    getStats,
    exportList,
    exportEntriesExits,
  };
};

export const attendanceListAPI = AttendanceList();

const BanksApi = () => {
  const baseName = "banks";
  const baseApi = ServerAPI(baseName, false);

  const postBanks = (params) => {
    const url = getUrl(baseName);

    return axios.post(url, ParseParams({ params, include_tenant: false }), configMedia())
      .then((respose) => respose.data);
  };

  return {
    ...baseApi,
    postBanks
  };
};
export const banksAPI = BanksApi();

const VoucherBanksApi = () => {
  const baseName = "voucher-banks";
  const baseApi = ServerAPI(baseName, false);

  const postBanks = (params) => {
    const url = getUrl(baseName);

    return axios.post(url, ParseParams({ params, include_tenant: false }), configMedia())
      .then((respose) => respose.data);
  };

  return {
    ...baseApi,
    postBanks
  };
};
export const voucherBanksApi = VoucherBanksApi();

const LiquidationApi = () => {

  const processLiquidation = async (type, params) => {
    const url = getUrl(`${type}-settlement`, true);

    return axios.post(url, params, config())
      .then(response => response.data);
  };

  const downloadFile = async (type, params) => {
    const url = getUrl(`${type}-liquidation/report`, true);

    return axios.post(url, params, config())
      .then((response) => response.data);
  };
  return {
    processLiquidation,
    downloadFile,
  };
};

export const liquidationApi = LiquidationApi();

const KPIS = () => {
  const baseName = "kpis";
  const baseApi = ServerAPI(baseName, false);

  const biReport = (params) => {
    const url = getUrl(`${baseName}/bi-report`);

    return axios.get(url, config(params))
      .then((response) => response.data);
  };

  const currentStaff = (params) => {
    const url = getUrl(`${baseName}/current-staff`, true);

    return axios.get(url, config(params))
      .then((response) => response.data);
  };

  const scholarshipStaff = (params) => {
    const url = getUrl(`${baseName}/scholarship-staff`, true);

    return axios.get(url, config(params))
      .then((response) => response.data);
  };

  const staffTurnOver = (params) => {
    const url = getUrl(`${baseName}/staff-turnover`, true);

    return axios.get(url, config(params))
      .then((response) => response.data);
  };

  const hiring = (params) => {
    const url = getUrl(`${baseName}/hiring`, true);

    return axios.get(url, config(params))
      .then((response) => response.data);
  };

  const peopleCost = (params) => {
    const url = getUrl(`${baseName}/people-cost`, true);

    return axios.get(url, config(params))
      .then((response) => response.data);
  };

  const absenteeism = (params) => {
    const url = getUrl(`${baseName}/absenteeism`, true);

    return axios.get(url, config(params))
      .then((response) => response.data);
  };

  return {
    ...baseApi,
    biReport,
    currentStaff,
    scholarshipStaff,
    staffTurnOver,
    hiring,
    peopleCost,
    absenteeism
  };
};
export const kpisAPI = KPIS();

export function dynamicSalaryStructureAPI() {

  const baseNameOne = "enterprise-structures-organization";
  const baseNameTwo = "get-salary-validations";

  const include_config = true;

  const getSalary = (id, params) => {
    const url = getUrl(`${baseNameOne}/${id}/${baseNameTwo}`);

    return axios.get(url, ParseParams({ params, include_config }))
      .then((response) => response.data);
  };


  return {
    getSalary,
  };
}