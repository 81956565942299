import PropTypes from 'prop-types';
import React from 'react';
import '../../../../../styles/style.scss';
import { Dialog } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
	MoperIcon,
} from '../../../../../../../signature/SignaturesByWorker/components/Details/components/DynamicMoper';
import { dateString } from './DayCalculate';
import { ModernButton } from '../Button';
import { useTranslation } from 'react-i18next';
import DialogActions from '../../../../../../../../../general/styled/DialogActions';
import { BaseButton } from '../../../../../../../../../general/@components/Buttons';

function TicketModal({
    day,
    day_name,
    month,
    year,
    style,
    title,
    icon,
    open,
    handleClose,
    actions,
    children,
}) {

    return (
        <Dialog
            open={open}
            className="custom-ticket-modal"
            style={style}
            onClose={handleClose}
        >
            <div className="ticket-modal-header">
                <div className="ticket-modal-date-wrapper">
                    {dateString({ day_name, day, month, year })}
                </div>
                <button className="ticket-modal-close-btn" onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
            <div className="ticket-modal-title">
                <MoperIcon icon={icon} />
                <span>{title}</span>
            </div>
            <div className="ticket-modal-content">
                {children}
            </div>
            {!!actions &&
                <div className="ticket-modal-actions">
                    {actions}
                </div>}
        </Dialog>
    );
};

TicketModal.propTypes = {
  actions: PropTypes.any,
  children: PropTypes.any,
  day: PropTypes.any,
  day_name: PropTypes.any,
  handleClose: PropTypes.any,
  icon: PropTypes.any,
  month: PropTypes.any,
  open: PropTypes.bool,
  style: PropTypes.any,
  title: PropTypes.any,
  year: PropTypes.any
}

export default TicketModal;

export const DialogButton = ({
    color = 'var(--lighterBlueGradient)',
    onClick = () => { },
    label = 'Solicitar',
    type = 'button',
}) => {

    return (
        <ModernButton
            size="xs"
            type={type}
            onClick={onClick}
            style={{
                backgroundColor: color,
                fontSize: '14px',
                padding: '5px 20px',
                borderRadius: '4px',
                fontWeight: 'bold'
            }}
        >
            {label}
        </ModernButton>
    );
};

DialogButton.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string
}

export const CommonActions = ({ onCancel, onSubmit }) => {
    const { t } = useTranslation();

    return (
        <DialogActions>
            <BaseButton
                design='text'
                color='error'
                onClick={onCancel}
                label={t('cancel')}
            />
            <form onSubmit={onSubmit} autoComplete="off" noValidate>
                <BaseButton
                    design='contained'
                    label={t('request_')}
                    type="submit"
                />
            </form>
        </DialogActions>
    );
}
CommonActions.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}
