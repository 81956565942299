import LANG from "i18next";
import FileSaver from "file-saver";
import { format } from "date-fns";
import { t } from "i18next";
import { valueOrOption } from "../general/GeneralUtilities";
import { trim } from "../validations/validationFilter";
import { socketsApi } from "../../services/socket-report";
import { showSuccessNotification } from "../Notifications";
import { successCreated } from "../Notifications/notification-messages";

export const getTheme = () => {
	const systemDark = window.matchMedia("(prefers-color-scheme: dark)");
	const currentTheme = localStorage.getItem('theme') || (systemDark.matches ? "dark" : "light");
	return currentTheme === "dark" ? "dark" : "light";
};

export const useRepoName = (socketName) => {
	const { t } = LANG;

	const cleanName = () => {
		socketName = valueOrOption(socketName, "").toString();
		return socketName.replaceAll(/\s|_/g, "-");
	};

	const humanizeName = (name) => {
		name = valueOrOption(name, "")
			.replaceAll(/[-_]/g, ' ')
			.replace("notifications:names.", "")
			.replace("names.", "");
		return trim(name);
	};

	const getReportName = () => {
		const cleaned = cleanName();
		let name = t(`notifications:names.${cleaned}`);
		if (name === `notifications:names.${cleaned}`) {
			name = cleaned;
		}
		return humanizeName(name);
	};

	return getReportName();
};

export const downloadFile = async (taskID, file_name = "report", apiName = null, isExport = false) => {
	const prefix = isExport ? 'export' : 'report';
	const name = `${useRepoName(prefix)} ${useRepoName(file_name)}`;
	const extension = isExport ? '.xlsx' : '';
	try {
		const response = await socketsApi.downloadReport(taskID, apiName);
		FileSaver.saveAs(
			response,
			trim(`${name}${extension}`)
		);
	} catch (error) {
		return error;
	}
};

export const formatTitleReport = (key) => {
	return `${t(key)}_${format(
		new Date(),
		"yyyy-MM-dd HH-mm-ss"
	)}`;
}

export const OnCreatedTenant = () => {
	showSuccessNotification(successCreated());
	setTimeout(() => {
		location.reload();
	}, 2000);
}