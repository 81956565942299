const ascii = /[^\x20-\xFF]/ig;
const notValidChars = /[\x21-\x21\x23-\x25\x2A-\x2B\x5B-\x5E\x60-\x60\x7B-\xA7\xA9-\xB3\xB5-\xC0\xC2-\xC8\xCA-\xCC\xCE-\xD0\xD2-\xD2\xD4-\xD9\xDB-\xDB\xDD-\xE0\xE2-\xE8\xEB-\xEC\xEE-\xF0\xF2-\xF2\xF4-\xF9\xFB-\xFB\xFD-\xFF]/ig;
export const asciiValidate = (value) => {
	if (typeof value !== 'string') {
		return value;
	}

	return value.replaceAll(ascii, '')
		.replace(/^\s+/, '')
		.replace(/\s+\s+/g, ' ')
		.replaceAll(notValidChars, '')
		.toUpperCase();
}


export const trim = (value) => {
	if (typeof value !== 'string') {
		return value;
	}

	return value.trim()/* quitamos espacios al inicio y fin del texto */
		.replace(/  +/g, ' '); /* quitamos espacios multiples entre palabras */
};

export const trimEditor = (value) => {
	if (typeof value !== 'string') {
		return value;
	}
	return value
		.replace(/<p><\/p>/g, '<p>&nbsp;</p>') /*se añaden los espacios en blanco */
		.replace(/;\s+"/g, '";'); /* quitamos espacios en blanco entre ; " */
};

// \x40\x3C-