import PropTypes from "prop-types";
import React from 'react';
import Grid from "@mui/material/Grid2";
import IconUse from '../uiDesign/IconUse';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CardHead = ({
	title,
	is_selected
}) => {
	return (
		<Grid size={{ xs: 12 }}>
			<Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
				<Grid size={{ xs: "grow" }}>
					<span className='card-title'>{title}</span>
				</Grid>
				<Grid size={{ xs: "auto" }}>
					<div className={`check-icon`}>
						{is_selected && <IconUse icon={faCheck} />}
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
};

CardHead.propTypes = {
	is_selected: PropTypes.any,
	title: PropTypes.any
};

export default CardHead;