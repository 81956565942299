import { lazy } from '../utilities';

const toolsBarIcons = {
	abrhil: {
		allowedTag: "Abrhil",
		Icon: lazy(async () => import("./Abrhil.svg")),
	},
	search: {
		allowedTag: "Search",
		Icon: lazy(async () => import("./Buscar.svg")),
	},
	helpcenter: {
		allowedTag: "Help-Center",
		Icon: lazy(async () => import("./HelpCenter.svg")),
	},
	help: {
		allowedTag: "Help",
		Icon: lazy(async () => import("./HelpCenter.svg")),
	},
	home: {
		allowedTag: "Home",
		Icon: lazy(async () => import("./Inicio.svg")),
	},
	kpis: {
		allowedTag: "Kpis",
		Icon: lazy(async () => import("./Kpis.svg")),
	},
	reports: {
		allowedTag: "Reports",
		Icon: lazy(async () => import("./Reportes.svg")),
	},
	timbrasoft: {
		allowedTag: "Timbrasoft",
		Icon: lazy(async () => import("./Timbrasoft.svg")),
	},
};

export default toolsBarIcons;