import { lazy } from '../icons/utilities';

const outsideIcons = {
	abrhilidea: {
		allowedTag: "Abrhil-Idea",
		Icon: lazy(async () => import("./abrhil-idea.svg")),
	},
	crutches: {
		allowedTag: "Crutches",
		Icon: lazy(async () => import("./crutches.svg")),
	},
	facemask: {
		allowedTag: "Face-Mask",
		Icon: lazy(async () => import("./face-mask.svg")),
	},
	folderstree: {
		allowedTag: "Folders-Tree",
		Icon: lazy(async () => import("./folders-tree.svg")),
	},
	notification: {
		allowedTag: "notification",
		Icon: lazy(async () => import("./notification.svg")),
	},
	puzzlepiece: {
		allowedTag: "Puzzle-Piece",
		Icon: lazy(async () => import("./puzzle-piece.svg")),
	},
	sidefacemask: {
		allowedTag: "Side-Face-Mask",
		Icon: lazy(async () => import("./side-face-mask.svg")),
	},
};

export default outsideIcons;