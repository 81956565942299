import React from 'react';
import { useTranslation } from "react-i18next";

export const TooltipTitle = () => {

	const { t } = useTranslation();
	const commonStyles = { display: 'flex', flexDirection: 'column' };

	return (
		<div style={commonStyles}>
			<span>{t("can-search-by")}</span>
			<div style={{ ...commonStyles, marginLeft: '5px' }}>
				<span>
					- {t("name-or-key")}
				</span>
			</div>
		</div>
	);
};
