export const variants = {
  enter: () => {
      return {
          opacity: 0,
          scale: 0.4,
      };
  },
  center: {
      opacity: 1,
      scale: 1,
  },
  exit: () => {
      return {
          opacity: 0,
          scale: 0.4,
      };
  }
};

export const transition = {
  duration: 0.3,
  ease: [0, 0.71, 0.2, 1.01],
};

export const variants2 = {
    enter: () => {
        return {
            opacity: 0,
            y: -20,
        };
    },
    center: {
        opacity: 1,
        y: 0,
    },
    exit: () => {
        return {
            opacity: 0,
            y: -20,
        };
    }
};

export const transition2 = {
    duration: 0.5,
    ease: [0, 0.71, 0.2, 1.01],
};
