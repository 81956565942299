import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMagnifyingGlass,
	faX
} from "@fortawesome/pro-regular-svg-icons";
/**
 *
 * @param {object} params
 * @param {?string} params.value - El valor que se está mostrando en el input
 * @param {?string} params.placeholder - El placeholder del search box, por default es search
 * @param {function} params.setSearch - La función que se realiza al cambiar el input o limpiarlo
 *
 * @returns {SearchBoxNode}
 */
const SearchBox = ({
	value,
	placeholder = "search",
	setSearch,
	className = "",
	classNameType = "k-rounded-full",
	id="main-search-box"
}) => {

	const { t } = useTranslation();

	const onChangeSearch = (event) => {
		if (typeof setSearch === "function") {
			setSearch(event.value || event?.target?.value || "");
		}
	};

	const clearSearch = () => {
		if (!value) { return; }
		onChangeSearch({ value: "" });
	};

	return (
		<div className={`${className} menu-search-container`}>
			<div className={`k-searchbox k-input k-input-sm  k-input-outline searchbox ${classNameType}`}>
				<input
					id={id}
					className="k-input-inner"
					value={value}
					placeholder={t(placeholder)}
					onChange={onChangeSearch}
					autoComplete="off"
				/>
				<span className={`searchbox-icon value-${!!value}`} >
					<FontAwesomeIcon icon={!value ? faMagnifyingGlass : faX} onClick={clearSearch} />
				</span>
			</div>
		</div>
	)
};

export default SearchBox;