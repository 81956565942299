import { getTheme } from './utilities';

export const variants = {
	enter: {
		opacity: 0,
		scale: 0.5,
	},
	center: {
		opacity: 1,
		scale: 1,
	},
	exit: {
		opacity: 0,
		scale: 0.5,
	}
};

export const transition = {
	duration: 0.3,
	ease: [0, 0.71, 0.2, 1.01],
};

/**
 * Función para posicionar el toast en la parte superior y que no sea autocerrable
 * @param {JSX.toast} toast
 * @param {string} theme
 * @param {boolean} isReconnect
 * @param {function} onOpen
 */
export const autoCloseFalse = (isReconnect, onOpen) => ({
	position: 'top-right',
	theme: getTheme(),
	delay: 450,
	autoClose: false,
	draggable: true,
	closeButton: true,
	...(!isReconnect ? { onOpen: onOpen } : {}),
});