import React from 'react';
import { styled } from '@mui/material/styles';
import MUIDialogContent from '@mui/material/DialogContent';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { valueOrOption } from '../@components/general/GeneralUtilities';

const def = {
	padding: '15px',
	borderRadius: '0px',
	backgroundColor: "var(--appBg)",
	color: "var(--text)",
	border: "0",
	borderLeft: 0,
	borderRight: 0,
};

const StyledDialogContent = styled(MUIDialogContent)(def);

/**
 * Contenedor general de los dialogs del sistema
 * * Para mas propuedades que puueda tener el contenedor @see https://mui.com/material-ui/api/dialog-content/
 * @param {object} props - Son todas las propiedades que podemos usar
 * @param {object} props.style - Es el estilo de que se le quiera aplicar al contenedor
 * @param {boolean} props.includeBaseCard - Indica si se va a incluir el contenedor card
 * @param {?string} props.className - La clase a utilizar pra el contenido
 * @param {"default"|"modern"} props.design - El estilo del modal a mostrar
 */
const DialogContent = ({
	includeBaseCard = false,
	design = "default",
	className = "",
	...props
}) => {

	const dialogStyle = {
		default: "",
		modern: "white-content"
	}[design] ?? "";

	className = `${valueOrOption(className, "")} ${dialogStyle}`;

	if (includeBaseCard) {
		return <ScrolledCard {...props} className={className} />;
	}
	return <ScrolledChildren {...props} className={className} />;
};

export default DialogContent;

const ScrolledChildren = ({
	style = {},
	children,
	...props
}) => (
	<PerfectScrollbar style={{ height: "100%", width: "100%", ...style }}>
		<StyledDialogContent {...props} style={{ height: "100%" }}>
			{children}
		</StyledDialogContent>
	</PerfectScrollbar >
);

const ScrolledCard = ({
	style = {},
	children,
	...props
}) => (
	<StyledDialogContent {...props} style={{ height: "100%", width: "100%", ...style }}>
		<Card style={{
			maxHeight: "calc(100vh - 155px)",
			overflow: "auto",
			...style
		}}>
			<CardContent >
				{children}
			</CardContent>
		</Card>
	</StyledDialogContent >
);