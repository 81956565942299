import PropTypes from "prop-types"
import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import FormInput from '../../../form/Field';
import { valueOrOption } from '../../../general/GeneralUtilities';

export const DateRange = ({
	id,
	name: generalName,
	startProps,
	endProps,
	switchProps,
	...others
}) => {

	const { t } = useTranslation();
	const { show, label, name, asInverse } = valueOrOption(switchProps, {});
	const switchName = valueOrOption(name, `${generalName || id}_switch`);
	const startName = valueOrOption(startProps?.name, `${generalName || id}_start`);
	const endName = valueOrOption(endProps?.name, `${generalName || id}_end`);
	const extraProp = !asInverse || !show ? { disabledBy: switchName } : { disabledByInverse: switchName };

	return (
		<Fragment>
			<FormInput
				id={`start_${id}`}
				label={t("from")}
				{...extraProp}
				{...others}
				{...startProps}
				name={startName}
			/>
			<FormInput
				id={`end_${id}`}
				label={t("until")}
				{...extraProp}
				{...others}
				{...endProps}
				name={endName}
			/>
			{show && (
				<FormInput
					{...others}
					id={`switch_${id}`}
					name={switchName}
					label={t(valueOrOption(label, "activate-dates"))}
					fieldInput="Switch"
				/>
			)}
		</Fragment>
	);
};

DateRange.propTypes = {
	endProps: PropTypes.any,
	id: PropTypes.any,
	name: PropTypes.any,
	startProps: PropTypes.any,
	switchProps: PropTypes.any
}
export default DateRange;