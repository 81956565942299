import i18next from 'i18next';
import MenuItem from '@mui/material/MenuItem';
import withStyles from '@mui/styles/withStyles';
import { showCell } from './utilities';
import { createRef } from 'react';
//--icons
import {
	faAddressBook,
	faCalendar,
	faClipboardUser,
	faHourglassClock,
	faIslandTropical,
	faLaptop,
	faMedal,
	faMoneyBills,
	faNotebook,
	faPersonWalking,
} from '@fortawesome/pro-light-svg-icons';

const { t } = i18next;

export const tableColumns = (colums, view, allPerms) => [
    {
        id: 1,
        field: 'worker',
        title: t("worker"),
        width: view === 'compact' ? 5.36 : 5,
        locked: true,
        show: showCell(colums, view, 'worker'),
        headerClassName: showCell(colums, view, 'structure') ? '' : 'margin-right',
    },
    {
        id: 2,
        field: 'workgroup',
        title: allPerms ? t("workgroup") : t("branch"),
        width: 3.2,
        locked: true,
        show: showCell(colums, view, 'structure'),
        headerClassName: 'margin-right',
    },
    {
        id: 3,
        field: 'days',
        width: view === 'compact' ? '74px' : '215px',
        locked: false,
        show: showCell(colums, view, 'days'),
    },
    {
        id: 4,
        field: 'total',
        title: t("Tot. Chec."),
        width: 1.2,
        locked: true,
        show: showCell(colums, view, 'total'),
        headerClassName: 'margin-left',
    },
];

export const cardBodyStyle = {
    paddingBlock: 0,
    paddingInline: 0,
    width: "100%",
    height: "100%",
    display: 'flex',
    justifyContent: 'space-between',
};

export const defaultForm = {
    open: false,
    type: '',
    title: '',
    item: {}
}

export const statusOptions = [
    { id: 0, key: 'preprocess', title: "presolicitado" },
    { id: 1, key: 'process', className: 'warning', title: "en proceso" },
    { id: 2, key: 'authorized', className: 'success', title: "autorizado" },
    { id: 3, key: 'canceled', className: 'cancel', title: "cancelado" },
    { id: 4, key: 'to-request' },
    { id: 5, key: 'multi' },
];

export const StyledMenuItem = withStyles({
    root: {
        fontSize: '14px',
        '&:hover': {
            backgroundColor: '#2563EB',
            color: 'white'
        }
    }
})(MenuItem);

export const KeyListennerRef = createRef(null);

export const keyboardKeys = [
    { id: 1, keyCode: 37, name: 'left', toNav: true },
    { id: 2, keyCode: 38, name: 'up', toNav: true },
    { id: 3, keyCode: 39, name: 'right', toNav: true },
    { id: 4, keyCode: 40, name: 'down', toNav: true },
];

export const accessMode = [
    { id: 0, key: "write", value: true },
    { id: 1, key: "read", value: false },
];

export const menuList = () => [
    { id: 1, icon: faClipboardUser, label: t('assist-adjustment'), key: "ajuste_asist", read: false, write: false, hasPerm: true },
    { id: 2, icon: faMoneyBills, label: t('attendance-time'), key: "txt", read: false, write: true, hasPerm: false, params: { index: 0 } },
    { id: 3, icon: faPersonWalking, label: t('absenteeisms'), key: "ausen", read: false, write: true, hasPerm: true, params: { type_absenteism: 3 } },
    { id: 4, icon: faIslandTropical, label: t('vacation'), key: "vacaciones", read: false, write: true, hasPerm: true, params: { type_absenteism: 5 } },
    { id: 5, icon: faHourglassClock, label: t('rest-bank'), key: "rest-bank", read: false, write: true },
    { id: 6, icon: faLaptop, label: t('special-concepts'), key: "conceptos", read: false, write: true, params: { index: 3 } },
    { id: 7, icon: faMedal, label: t('Promociones'), key: "promociones", read: false, write: false, hasPerm: true },
    { id: 8, icon: faNotebook, label: t('Contrataciones manuales'), key: "contrataciones", read: false, write: false, hasPerm: true },
    { id: 9, icon: faAddressBook, label: t('Contrataciones staffing'), key: "contrataciones", read: false, write: false, hasPerm: true },
    { id: 10, icon: faCalendar, label: t('Calendario'), key: "calendar", read: true, write: true },
];

export const absenteeismsKeyboards = [
    { id: 1, keyCode: 86, keyValue: 'V', key: 'vacaciones', params: { type_absenteism: 5 }, needPerms: { user: false, workgroup: true }, label: 'vacation' },
    { id: 2, keyCode: 70, keyValue: 'F', key: 'apply_absence_individual', params: { absenReasonId: 3 }, needPerms: { user: false, workgroup: false }, label: 'faults', attendanceKey: 'fault' },
    { id: 3, keyCode: 68, keyValue: 'D', key: 'apply_rest_individual', params: { absenReasonId: 16 }, needPerms: { user: false, workgroup: false }, label: 'breaks' },
    { id: 4, keyCode: 67, keyValue: 'C', key: 'ausen_con_goce', params: { type_absenteism: 2 }, needPerms: { user: false, workgroup: true }, label: 'absenteeisms' },
    { id: 4, keyCode: 71, keyValue: 'G', key: 'ausen_sin_goce', params: { type_absenteism: 3 }, needPerms: { user: false, workgroup: true }, label: 'absenteeisms' },
    { id: 5, keyCode: 46, keyValue: 'supr', key: 'cancel-absen', needPerms: { user: true, workgroup: true }, label: 'absenteeisms' },
    { id: 6, keyCode: 32, keyValue: 'espace', key: 'cancel-absen', needPerms: { user: true, workgroup: true }, label: 'absenteeisms' },
    { id: 7, keyCode: 80, keyValue: 'P', key: 'ausen', needPerms: { user: false, workgroup: true }, label: 'absenteeisms' },
];

export const oneRowGridStyle = {
    width: '100%',
    overflow: 'auto',
    marginInline: '20px'
};

export const excludeAbsen = ['imss', 'inabilities', 'low'];