
import React from "react";
/* components */
import Avatar from '@mui/material/Avatar';

/* own components */
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuOptions from '../userSection/MenuOptions';
import { useController } from '../userSection/utilities';

const User = () => {

    const {
        anchor,
        open,
        onOpen,
        onClose,
        handleLogOut,
        changeTheme,
        // userInfo,
        username,
        email,
        photo,
    } = useController();

    return (
        <>
            <span className={"avatar-side-container"} >
                <IconButton
                    onClick={onOpen}
                    ref={anchor}
                >
                    <Avatar alt={username} src={photo} style={{ width: "35px", height: "35px" }} />
                </IconButton>
            </span>
            <Menu
                anchorEl={anchor?.current}
                open={open}
                onClose={onClose}
                PaperProps={{
                    elevation: 7,
                    sx: {
                        overflow: 'visible',
                        borderRadius: '12px',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 5,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuOptions
                    email={email}
                    user={username}
                    photo={photo}
                    onClose={onClose}
                    handleLogOut={handleLogOut}
                    changeTheme={changeTheme}
                />
            </Menu>
        </>
    );
};

export default User;

export const toTitleCase = str => str?.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());