import { isBefore } from "date-fns";
import { size as _size } from 'lodash';
import { computedType, periodStatus } from './dictionaries';
import { datetimeValue } from '../Inputs/Dates/utilities';

export const backColor = (option) => {
	if (!_size(option)) { return {}; }
	return {
		"1-1": { color: "var(--greenAction)", backgroundColor: "var(--greenActionBg)" },
		"0-1": { color: "var(--blueAction)", backgroundColor: "var(--blueActionBg)" },
		"1-0": { color: "var(--orangeAction)", backgroundColor: "var(--orangeActionBg)" },
	}[payrollValueType(option)] ?? {};
};

export const payrollPeriodFormatLavel = (option, simply) => {
	const usedOption = simply ? symplyOption : objectOption;
	return usedOption(option);
};

const symplyOption = (option) => {
	if (!_size(option)) {
		return "";
	}

	const { key, start_date, end_date, } = option;
	return `${key} ${reversDate(start_date)} - ${reversDate(end_date)}`;
};

const objectOption = (option) => {
	if (!_size(option)) {
		return {};
	}

	const { key, start_date, end_date, } = option;
	return {
		key: key,
		dates: `${reversDate(start_date)} - ${reversDate(end_date)}`
	};
};

export const reversDate = (date) => date?.split("-").reverse().join("/");

export const payrollValueType = option => `${option?.list ? 1 : 0}-${option?.payroll ? 1 : 0}`;

export const isPeriodLockedOrClosed = (item) => isLocked(item) || isClosed(item);

export const isClosed = (item) => item?.status === periodStatus.close;

export const isLocked = (item) => {
	if (item?.computed_type === computedType.settlement) {
		return false;
	}

	const lock_date = datetimeValue(item?.locked_at);
	return isBefore(lock_date, new Date());
};
export const isSpecialValidation = (computed_type) => {
	return [computedType.settlement, computedType.ptu, computedType.special].includes(parseInt(computed_type));
};

