import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import validation from './es/validation.json';
import translation from './es/translation.json';
import payrollResponses from './es/payroll-responses.json';
import permissionsMessages from './es/permissions-messages.json';
import socketNotifications from './es/socket-notifications.json';
import kendoMessages from './es/kendoMessages.json';
import scriptMessages from './es/script-messages.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translation,
    validation: validation,
    "payroll-responses": payrollResponses,
    "permissions-messages": permissionsMessages,
    "notifications": socketNotifications,
    "kendo-messages": kendoMessages,
    "script-messages": scriptMessages
  },
  es: {
    translation: translation,
    validation: validation,
    "payroll-responses": payrollResponses,
    "permissions-messages": permissionsMessages,
    "notifications": socketNotifications,
    "kendo-messages": kendoMessages,
    "script-messages": scriptMessages
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;