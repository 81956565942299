export const WINDOW_PACKAGES = 'window_packages'

const initialState = {}

export function window_packages(state = initialState, action) {
  switch (action.type) {
    case WINDOW_PACKAGES:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
