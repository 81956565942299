import React from 'react';
import { InputClearValue } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { hasValue } from '../../../general/GeneralUtilities';

export const SearchSufix = ({ value, handleClear }) => {
    if (!hasValue(value)) {
        return null;
    }
    return (
        <InputClearValue onClick={handleClear}>
            <FontAwesomeIcon icon={faXmark} size="lg" />
        </InputClearValue>
    );
};
