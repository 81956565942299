import React, { useRef } from "react";
import PropTypes from "prop-types";
import { uid } from "uid";
import { isString, toString } from "lodash";
import { motion } from "framer-motion";
import "./_styles.scss";

const scssColors = ['red', 'primary'];

function RichText({ words, fontSize, wrap = false, ...others }) {

    const uniqueId = useRef(uid()).current;

    return (
        <motion.div
            key={`rich-text-${uniqueId}`}
            className={`mopers-rich-text-wrap ${wrap ? 'wrap' : ''}`}
            {...others}
        >
            {words.map(w => toString(w?.value)?.split(' ')?.map(el =>
                <span
                    key={`rich-text-child-${uid()}`}
                    className={w.color ?? ''}
                    style={{
                        fontSize,
                        ...(!scssColors.includes(w.color) && isString(w.color) ? { color: w.color } : {}),
                        ...(others?.style ?? {}),
                        ...(w.style ?? {}),
                    }}
                >{el}</span>
            ))}
        </motion.div>
    );
};

export default RichText;

RichText.propTypes = {
    fontSize: PropTypes.any,
    words: PropTypes.array,
    wrap: PropTypes.bool
}