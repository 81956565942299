import React from 'react';
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchImage } from "../../../assets/images/init-search.svg";

export const InitSearch = () => {
	const { t } = useTranslation();
	return (
		<div className='empty-search'>
			<div className='svg-container'>
				<SearchImage />
				<div className='search-text'>
					{t("start-search")}
				</div>
			</div>
		</div>
	);
};
