import React, {
	useEffect,
	useState,
} from 'react';
import { uid } from 'uid';
import { getArrayItemKey, hasValue, valueOrOption } from '../general/GeneralUtilities';
import { conditional } from '../table/CommandCell';
import BaseButton from './BaseButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isFunction } from 'lodash';
import IconUse from '../uiDesign/IconUse';

/**
 *
 * @param {object} params
 * @param {string} params.label - Label de texto que sera mostrado dentro del botón
 * @param {FontAwesomeIcon|"string"} params.icon - Icono badado en componente react de fontawesome
 * @param {function} params.onClick - Función que ae realizara al momento de dar click em el botón
 * @param {?string} params.className - Una clase custom si es que la necesitara
 * @param {?"start"|"end"} params.iconPlacement - Indica si el icono se va a poner al principio o al final del botón
 * @param {?"contained"|"text"|"flat"|"create"|"outlined"} params.design - Indica el estilo del botón que se va a usar, por default outlined
 * @param {?"error"|"success"|"info"} params.color - Indica que color se va a usar para el botón
 * @param {array} options - son los items que se van a mostrar para seleccionar
 * @returns {BaseButtonComponent}
 */
const DropdownButton = ({
	id,
	options = [],
	...others
}) => {

	const [buttonId, setButtonID] = useState();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onSelectItem = (callback) => {
		handleClose();
		if (isFunction(callback)) {
			callback();
		}
	};

	useEffect(() => {
		setButtonID(valueOrOption(id, `DDButton_${uid()}`));
		//eslint-disable-next-line
	}, [id]);

	return (
		<>
			<BaseButton
				{...others}
				id={buttonId}
				aria-controls={conditional(open, `${buttonId}-composition-menu`, undefined)}
				onClick={handleClick}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			/>
			<Menu
				anchorEl={anchorEl}
				id={`${buttonId}-composition-menu`}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							'&::before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{valueOrOption(options, []).map(({ icon, label, callback }, index) => (
					<MenuItem
						key={getArrayItemKey("dd-menu-item", index)}
						onClick={() => onSelectItem(callback)}
					>
						{hasValue(icon) && <IconUse icon={icon} />}
						{label}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default DropdownButton;