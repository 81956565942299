import { datetimeValue } from '../@components/Inputs/Dates/utilities';
import { ServerAPI, getUrl, ParseParams } from "./ServerAPI";
import { config } from "./config";
import axios from "axios";

/**
	all principal methods -> (get, post, put, destroy)
	@example payrollTypeApi.get(params)
*/
export const payrollTypeApi = ServerAPI("payroll-type");
export const payrollDispersionLayoutApi = ServerAPI("payroll-report-dispersion-layout");
export const payrollDispersionReportApi = ServerAPI("payroll-report-dispersion-report");
export const ComputeBimonthlyVariablesApi = ServerAPI("compute-bimonthly-variables");
export const BimonthlyWorkerVariable = ServerAPI("bimonthly-worker-variable");
export const BimonthlyPayrollVariable = ServerAPI("bimonthly-payroll-variable");
export const periodicityApi = ServerAPI("periodicity");
export const childSupportAPI = ServerAPI('child-support');
export const childSupportBeneficiaryAPI = ServerAPI('child-support-beneficiary');
export const federalEntitiesTaxes = ServerAPI('federal-entities-taxes');
export const publicFederalEntitiesTaxes = ServerAPI('public-federal-entities-taxes');
export const federalEntities = ServerAPI('federal-entities');
export const integrationFactorTypesApi = ServerAPI("integration-factor-types");
export const professionsApi = ServerAPI("professions", false);
export const absenteeismAPI = ServerAPI('absenteeism');
export const absenteeismReasonTypeAPI = ServerAPI('absenteeism-reason-type-payments');
export const absenteeismReasonsAPI = ServerAPI('absenteeism-reasons');
export const payrollPolicyAPI = ServerAPI('payroll-policy');
export const provisionPolicyAPI = ServerAPI('payroll-provision-policy-vacations');
export const initialVacationsProvisionsApi = ServerAPI('initial-vacations-provisions');

const AbsenteeismDirectApi = () => {
	const baseName = "direct-absenteeism";
	const baseApi = ServerAPI(baseName);

	const destroyMoper = (id, params) => {
		const url = getUrl(`${baseName}/${id}`, true);
		const payload = config({}, params);
		return axios.delete(url, payload)
			.then((response) => response.data);
	};

	return {
		...baseApi,
		destroyMoper
	};
};

export const absenteeismDirectAPI = AbsenteeismDirectApi();

export const changeTypeAPI = ServerAPI('change-type');
export const scheduledConceptApi = ServerAPI('scheduled-concept');
export const ConceptsApi = ServerAPI('concepts');
export const ConceptByPeriodApi = ServerAPI('concept-by-period', true, true);
export const ConceptsCapturesApi = ServerAPI('concept-captures');
export const scheduledDiscountApi = ServerAPI('scheduled-discount');
export const PTUWorkersApi = ServerAPI('ptu-workers');
export const PTUAverageApi = ServerAPI('ptu-average');
export const calculateResponseApi = ServerAPI('payroll-calculate-response');
export const annualAdjustApi = ServerAPI('skip-annual-adjustment', true, true);
export const discountPaymentApi = {
	...ServerAPI('discount-payment'),
	get_yearly_period: (params) => {
		const url = getUrl(`discount-payment/yearly-period`);
		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	}
};
export const taxesApi = {
	...ServerAPI('taxes'),
	getYears: (params) => {
		const url = getUrl(`taxes/get-vigency-years`);
		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	},
	getLastTaxes: (params) => {
		const url = getUrl(`taxes/get-last-taxes`);
		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	}
};
export const applyHolidaysAPI = ServerAPI('attendance-list/apply-holidays');
export const applyDaysOffs = ServerAPI('attendance-list/apply-days-offs');
export const applyAbcenses = ServerAPI('attendance-list/apply-abcenses');
export const exportAttendanceList = ServerAPI('attendance-list/export-excel');

const include_config = true;

const PayrollConceptsApi = () => {

	const baseName = "concepts";

	const baseApi = ServerAPI(baseName);

	const getSuggest = (params) => {
		const url = getUrl(`${baseName}/total-concept-type`);

		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	return {
		...baseApi,
		getSuggest
	};
};

export const payrollConceptsApi = PayrollConceptsApi();

const PeriodsApi = () => {

	const baseName = "payroll-periods";

	const baseApi = ServerAPI(baseName);

	const getOverlaped = (params) => {
		const url = getUrl(`${baseName}/get-overlaped`);
		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	const getCurrents = (params) => {
		const url = getUrl(`${baseName}/get-current-periods`);
		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	const closePeriod = (params) => {
		const url = getUrl(`${baseName}/close`, true);
		return axios.post(url, {
			...params,
			movement_date: datetimeValue(new Date(), "ISO")
		}, config()
		).then((response) => response.data);
	};

	const reopenPeriod = (params) => {
		const url = getUrl(`${baseName}/reopen`, true);
		return axios.post(url, {
			...params,
			movement_date: datetimeValue(new Date(), "ISO")
		}, config()
		).then((response) => response.data);
	};

	return {
		...baseApi,
		getCurrents,
		getOverlaped,
		closePeriod,
		reopenPeriod
	};
};

export const periodsApi = PeriodsApi();

const CalculateApi = () => {

	const baseName = "payroll-variables";
	const baseApi = ServerAPI(baseName);
	const getExtraRoute = (extraRoute) => `payroll/calculate/${extraRoute}`;

	const consultCalculation = (params) => {
		const payload = ParseParams({ params, include_config, include_role: true });
		return axios.get(getUrl("payroll-calculate-response"), payload)
			.then((response) => response.data);
	};

	const reports = (params, report_route) => {
		const payload = ParseParams({ params, include_role: true });
		return axios.post(getUrl(`payroll-calculate-response/${report_route}`, true), payload, config())
			.then((response) => response.data);
	};

	const settlementReport = (params) => {
		const payload = ParseParams({ params, include_config, include_role: true });
		return axios.get(getUrl("worker-settlement-report"), payload)
			.then((response) => response.data);

	};

	const calculate = (params) => {
		const payload = ParseParams({ params, include_role: true });
		return axios.post(getUrl(`payroll/calculate`, true), payload, config())
			.then((response) => response.data);
	};

	const extraPostActions = (params, extraRoute = "") => {
		const payload = ParseParams({ params, include_role: true });
		return axios.post(getUrl(getExtraRoute(extraRoute), true), payload, config())
			.then((response) => response.data);
	};

	const extraUpdateActions = (params, extraRoute = "") => {
		const url = getUrl(getExtraRoute(extraRoute), true);
		const payload = ParseParams({ params, include_tenant: true });
		return axios.put(url, payload, config()).then((response) => response.data);
	};

	const extraGetActions = (params, extraRoute = "") => {
		const payload = ParseParams({ params, include_config, include_role: true });
		return axios.get(getUrl(getExtraRoute(extraRoute)), payload)
			.then((response) => response.data);
	};

	const updateVariable = (id, params) => {
		const payload = ParseParams({ params, include_role: true });
		const url = getUrl(`payroll-variables/${id}`, true);
		return axios.patch(url, payload, config()).then((response) => response.data);
	};

	return {
		...baseApi,
		settlementReport,
		calculate,
		extraPostActions,
		consultCalculation,
		extraGetActions,
		updateVariable,
		extraUpdateActions,
		reports
	};
};

export const calculateApi = CalculateApi();

const HistoricIntegrationFactorApi = () => {
	const baseURL = "integration-factor-records";
	const baseApi = ServerAPI(baseURL);

	const multi = (params = {}, isUpdate) => {
		const payload = ParseParams({ params });
		const apiURL = getUrl(`${baseURL}${isUpdate ? "/multi" : ""}`, true);
		const method = isUpdate ? axios.put : axios.post;
		return method(apiURL, payload, config())
			.then((response) => response.data);
	};

	const lastAntiquity = (params = {}) => {
		return axios
			.get(getUrl(`get-last-antiquity`), ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	return {
		...baseApi,
		multi,
		lastAntiquity
	};
};

export const historicIntegrationFactorApi = HistoricIntegrationFactorApi();

const UmiApi = () => {
	const baseApi = ServerAPI("umi", false);

	const getYears = (params) => {
		return axios
			.get(getUrl("get-umi-years"), ParseParams({ params, include_tenant: false, include_config }))
			.then((response) => response.data);
	};

	return {
		...baseApi,
		getYears
	};
};

export const umiApi = UmiApi();

const UmaApi = () => {

	const baseName = "uma";
	const baseApi = ServerAPI(baseName, false);

	const getYears = (params) => {

		const url = getUrl(`${baseName}/years`);
		return axios.get(url, ParseParams({ params, include_tenant: false, include_config }))
			.then((response) => response.data);
	};

	return {
		...baseApi,
		getYears
	};
};

export const umaApi = UmaApi();