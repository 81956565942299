import React from 'react';
import { useTranslation } from "react-i18next";
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import useAdvanceFilters from '../useAdvanceFilters';

const Title = () => {

	const { t } = useTranslation();

	const { onClose } = useAdvanceFilters();

	return (
		<DialogTitle className="filtersTitle">
			{t("advanced-filters")}
			<IconButton
				aria-label="close"
				className=""
				onClick={onClose}
			>
				<FontAwesomeIcon icon={faXmark} className="closeModalIcon" />
			</IconButton>
		</DialogTitle>
	);
};

export default Title;
