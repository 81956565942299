import * as yup from 'yup';
import arrayValidation from '../../../general/@components/validations/array';
import { stringValidation } from '../../../general/@components/validations/stringValidation';
import { subMonths } from 'date-fns';
import { monthLabel } from '../../../general/@components/Inputs/Dates/utilities';

export const months = [
  { label: `${monthLabel(1)} - ${monthLabel(2)}`, value: "1,2", send: 1 },
  { label: `${monthLabel(3)} - ${monthLabel(4)}`, value: "3,4", send: 2 },
  { label: `${monthLabel(5)} - ${monthLabel(6)}`, value: "5,6", send: 3 },
  { label: `${monthLabel(7)} - ${monthLabel(8)}`, value: "7,8", send: 4 },
  { label: `${monthLabel(9)} - ${monthLabel(10)}`, value: "9,10", send: 5 },
  { label: `${monthLabel(11)} - ${monthLabel(12)}`, value: "11,12", send: 6 },
];

export const previousDate = (type, custom) => {
  let today = new Date();
  let dayMilliseconds = 24 * 60 * 60 * 1000;
  let yesterday = new Date(today.getTime() - dayMilliseconds);
  if (custom) {
    const monthBefore = subMonths(new Date(), 1);
    return { year: monthBefore.getFullYear().toString(), month: (monthBefore.getMonth() + 1).toString() };
  }

  if (type === "year") {
    return yesterday.getFullYear();
  } else {
    return yesterday.getMonth() + 1;
  }
};

export const ValidationSchema = yup.object().shape({
  year: stringValidation({ required: true }),
  month: stringValidation({ required: true }),
  employer_registrations: arrayValidation({ required: true, min: 1 }),
});
