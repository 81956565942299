import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { uid } from 'uid';
import { useTranslation } from "react-i18next";
import { first, isArray, omit, size } from "lodash";
/* Library components */
import { Grid, GridColumn, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";

/* Own components */
import Loader from "./Loader";
import { columnProps } from "./utils/GridCustomProps";
import GridNoRecordsRender from "../Templates/GridNoRecordsRender";
import ActionsColumn from "./utils/ActionsColumn";
import StatusColumn from './utils/StatusColumn';
import Export from './Export';
import GridController, { useWidthsHelper } from './utilities';
import KendoCheckbox from '../Inputs/Booleans/KendoCheckbox';
import { valueOrOption } from '../general/GeneralUtilities';
import Watermark from './Watermark';
/**
 * Componente general de tablas del sistema, con las columnas y funciones necesarias
 * @param {object} param
 * @param {Api} param.Api - El Api configurado para el módulo, debe tener al menus los metodos get y destroy.
 * @param {string} param.moduleExport - El nombre corto de la url que sera usada para la exportación de datos .
 * @param {function} param.onEdit - Es la accion que se realizara al momento de precionar el botón de edición.
 * @param {object} param.filters - Son los filtros que se manejan en el sistema. se manejan por fuera para trabajar en conjunto con los filtros avanzados
 * @param {function} param.setFilters - Es la funcion que se realiza al momento de actualizar los filtros, puede ser unicamente el segundo parametro del useState
 * @param {number} param.refreshCounter - Este nos va a servir para menejar el refresh de la tabla, lo debe accionar el botón refresh del módulo
 * @param {function} param.onRefresh - Función que actualiza el contador del refresh
 * @param {?object} param.extraOptions - Objeto que nos va a servir para opciones como el include, o select en los datos
 * @param {?Component} param.Detail - (opcional) Componente para manejar El detalle de un item
 * @param {?string} param.className - (opcional) Clases extra para la tabla si es que los requiere
 * @param {?object} param.filterChanges - (opcional) Sirve para cambiar nombres de los filtros aplicados al mandar a buscar, * mas detalles en el archivo "parse-request".
 * @param {?function} param.responseFormatter - (opcional) Función que sirve para formatear el response de la api antes de ser devuelto
 * @param {?function} param.setData - (opcional) Función que sirve para setear la data recibida de la api en un state para poder usarla externamente
 * @param {?function} param.setTotal - (opcional) Función que sirve para setear la cantidad de información total 'count' y poder usarlo externamente
 * @param {?{
	* prop : string,
	* detail : ?string,
	* format : ?string,
	* customFormat : ?function
 * }} param.deleteContext - (opcional) Objeto con las propiedades a mostrar al momento de mostrar el mensaje de eliminar.
 * * format - El formato que le queramos aplicar al elemento despues de obtener su valor del item.
 * * prop - La propiedad del item que sera tomada para mostrar en el mensaje de eliminación por default se toma el atributo en orden de existencia el name > key > id.
 * * detail - Breve mensaje antes del item a mostrar.
 * * customFormat - Una función custom para formatear el dato que se requiera de manera mas personalizada, como parametro debe recibir un objeto(el item a eliminar) y regresar un string(el dato formateado)
 * @param {?{
	* initialMsg : string
	* noResultsMsg : string
	* noFilterMsg : string
 * }} param.noRecordCustoms - (Opcional) objeto con los mensajes customs que se muestran cuando no hay datos en la tabla
 * * Mensaje que se muestra de forma inicial cuando no se ah iniciado la busqueda.
 * * Mensaje que se muestra cuando se realizo la busqueda y aun asi no se encontraron datos.
 * @param {?boolean} param.is_active - Sirve para saber si la columna de activos va estar visible o no
 * @param {?boolean} param.actions - Sirve para saber si la columna de acciones va ser visible o no
 * @param {?node} param.CustomActions - Celda custom de acciones
 * @param {?number} param.CustomActionsWidth - Ancho custom para la celda de acciones
 * @param {?string} param.pagerItems - Propiedad útil para cambiar el default "Elementos" de los mensajes del paginador
 * @param {?"one"|"multi"|"all"} param.selectionMode - Habilita la seleccion de datos de la tabla y el modo en que se va a manejar
 * !NOTA : SI SE VA A HACER USO DE LA SELECCÍON , TAMBIEN SE DEBE PASAR LA PROPIEDAD selected
 * @param {GridColumn} param.children - Las columnas propias de la tabla del módulo
 * @param {?number[]} param.pageSizes - Lista personalizada de elemntos por página, por ejemplo [15, 20, 50]
 * @param {?boolean} param.allPageSize - Indica si vamos a incluir la paginación Todos, a las opciones de paginado
 * @param {?*} param.others - Este es un componente basado en la tabla de Kendo React asi que puede heredar todas las propiedades de este, se pasan como si fuera el componente original
 * @returns {CustomKendoGridComponent}
 *
 * @example
 * const [counter, setCounter] = useState(0);
 * const [filters, setFilters] = useState(initialFilter());
 * const onRefresh = () => setCounter(counter => counter + 1);
 * const openDialog = (item, isEdit) => {console.log(item, isEdit)};
 * <KendoTable
 * // style={{ height: "calc(100vh - 464px)" }}
 * 	Api={rolesApi}
 * 	moduleExport={moduleExport}
 * 	onEdit={item => openDialog(item, true)}
 * 	filters={filters}
 * 	setFilters={setFilters}
 * 	refreshCounter={counter}
 * 	onRefresh={onRefresh}
 * >
 * 	<GridColumn {...columnProps("name", filters, "input")} title={t('name')} />
 * </KendoTable>
*/

const KendoTable = ({
	moduleExport,
	onEdit,
	filters,
	refreshCounter,
	className,
	noRecordCustoms,
	children,
	disabled,
	is_active = true,
	actions = true,
	editAction = true,
	CustomActions,
	CustomActionsWidth,
	selected,
	selectionMode = null,
	rowSelected,
	watermark,
	loading: customLoading,
	style,
	...others
}) => {

	let childrenToolbar = null;
	const divRef = useRef();
	const gridRef = useRef();
	const gridId = useRef(uid()).current;
	const [gridStyle, setGridStyle] = useState(style);

	if (selectionMode === "all") {
		const parseChildren = isArray(children) ? children : [children];
		childrenToolbar = first(valueOrOption(parseChildren, []).filter(child => child?.type?.displayName === "KendoReactGridToolbar"));
		children = parseChildren?.filter(child => child?.type?.displayName !== "KendoReactGridToolbar");
	}

	refreshCounter = valueOrOption(refreshCounter, 0);

	const { t } = useTranslation();
	const {
		data,
		page,
		total,
		loading,
		onDelete,
		idGetter,
		onFilterChange,
		rowDetail,
		rowSelect,
		allSelected,
		hasSelection,
		DATA_ITEM_KEY,
		SELECTED_FIELD,
		pagination,
		selectedState,
		customEdit,
		onSwitchSelectAll,
		onNoAction,
	} = GridController({
		...others,
		disabled,
		selected,
		selectionMode,
		filters,
		onEdit,
		refreshCounter,
		rowSelected,
	});

	const hasData = data?.length > 0;

	const {
		getWidths,
		loadStyles,
	} = useWidthsHelper({
		children_cols: children,
		is_active,
		actions,
		CustomActionsWidth,
		hasSelection
	});

	const { divWidth, containerWidth } = getWidths(gridRef, divRef);

	useEffect(() => {
		loadStyles(style, gridRef, divRef, setGridStyle);
		//eslint-disable-next-line
	}, [divWidth, containerWidth]);

	return (
		<Fragment>
			<Export page={page} filters={filters} module={moduleExport} />
			<div
				ref={divRef}
				className={`${className} principal-grid-table-container`}
				style={{
					width: "100%",
					maxWidth: "100%",
					overflow: "auto",
					...gridStyle,
				}}
			>
				<Grid
					ref={gridRef}
					key={`abr-main-grid-${gridId}`}
					id={`abr-main-grid-${gridId}`}
					dataitemKey={DATA_ITEM_KEY}
					className={`principal-grid ${className ?? ""} columns-calculated`}
					filter={filters}
					onFilterChange={onFilterChange}
					sortable
					data={data.map((item) => ({
						...item,
						[SELECTED_FIELD]: selectedState[idGetter(item)],
					}))}
					{...omit(others, ["selected", "selectionMode", "onRowClick", "selectBy", "pageSizes"])}
					{...pagination}
					{...rowDetail}
					{...rowSelect}
					style={gridStyle}
				>
					{/* Empty data messages */}
					<GridNoRecords>
						<GridNoRecordsRender
							init={refreshCounter > 0}
							filter={filters}
							initialMsg={noRecordCustoms?.initialMsg}
							description={noRecordCustoms?.noResultsMsg}
							noFilterMsg={noRecordCustoms?.noFilterMsg}
						/>
					</GridNoRecords>
					{
						selectionMode === "all" &&
						<GridToolbar>
							<KendoCheckbox
								label={t(allSelected ? "uncheck-all" : "check-all")}
								formAlign={false}
								checked={allSelected}
								disabled={!hasData || disabled}
								onChange={onSwitchSelectAll}
							/>
							{childrenToolbar?.props?.children}
						</GridToolbar>
					}
					<GridColumn
						className={`grid-select-column ${disabled ? 'disabled' : ''}`}
						headerClassName='grid-select-column'
						field={hasData ? SELECTED_FIELD : undefined}
						hidden={size(rowSelect) === 0 || !hasSelection}
						width={50}
						{...(selectionMode === 'one' || !hasData || disabled) ? { headerCell: () => null } : {}}
						headerSelectionValue={
							data.findIndex((item) => !selectedState[idGetter(item)]) === -1
						}
					/>
					{/* custom columns */}
					{children}
					{/* general columns */}
					<GridColumn
						{...columnProps("is_active", filters, "status")}
						title={t("is-active")}
						cell={StatusColumn}
						width={120}
						hidden={!is_active}
						locked
					/>
					<GridColumn
						title={t('actions')}
						hidden={!actions}
						cell={
							CustomActions
								? props => <CustomActions enterEdit={customEdit} enterDelete={onDelete} {...props} totalItems={total} onNoAction={onNoAction} />
								: props => <ActionsColumn {...props} enterEdit={customEdit} enterDelete={onDelete} totalItems={total} onNoAction={onNoAction} isEditable={editAction} />
						}
						width={valueOrOption(CustomActionsWidth, 100)}
						locked
					/>
				</Grid>
			</div>
			<Loader loading={loading || customLoading} gridRef={gridRef} />
			<Watermark watermark={watermark} show={size(data) > 0} gridRef={gridRef} />
		</Fragment>
	);
};

KendoTable.propTypes = {
	CustomActions: PropTypes.any,
	CustomActionsWidth: PropTypes.any,
	actions: PropTypes.bool,
	children: PropTypes.any,
	className: PropTypes.string,
	disabled: PropTypes.any,
	editAction: PropTypes.bool,
	filters: PropTypes.any,
	is_active: PropTypes.bool,
	loading: PropTypes.any,
	moduleExport: PropTypes.any,
	noRecordCustoms: PropTypes.shape({
		initialMsg: PropTypes.any,
		noResultsMsg: PropTypes.any,
		noFilterMsg: PropTypes.any
	}),
	onEdit: PropTypes.any,
	refreshCounter: PropTypes.number,
	rowSelected: PropTypes.any,
	selected: PropTypes.any,
	selectionMode: PropTypes.string,
	style: PropTypes.any,
	watermark: PropTypes.any
};

export default KendoTable;