import React from 'react';
import PropTypes from 'prop-types';
import { omitValues } from '../../../../utils/utilities';
import DetailMoperItem from './DetailMoperItem';

function PopupComponent({
    moper,
    mopers,
    request,
    onSign,
    onEdit,
    fieldsOmit,
    style = {},
    withPopupFlow = false,
    listMode = false,
    onlyRead = false,
    ...others
}) {

    const fields = omitValues(request?.data, fieldsOmit);

    return (
        <div className="custom-popup-component" style={style}>
            <div className="mopers-detail-items-wrapper">
                {!listMode ?
                    <DetailMoperItem
                        moper={moper}
                        onSign={onSign}
                        onEdit={onEdit}
                        withPopupFlow={withPopupFlow}
                        onlyRead={onlyRead}
                        item={request}
                        data={fields}
                        {...others}
                    />
                    :
                    mopers?.map(item => (
                        <DetailMoperItem
                            key={item?.exactMoper?.moper?.id}
                            moper={item?.exactMoper}
                            onSign={onSign}
                            onEdit={onEdit}
                            withPopupFlow={withPopupFlow}
                            onlyRead={onlyRead}
                            item={item}
                            data={item?.data}
                            {...others}
                        />
                    ))}
            </div>
        </div>
    );
};

export default PopupComponent;

PopupComponent.propTypes = {
    moper: PropTypes.object,
    request: PropTypes.object,
    mopers: PropTypes.array,
    fieldsOmit: PropTypes.array,
    withPopupFlow: PropTypes.bool,
    onSign: PropTypes.func,
    onEdit: PropTypes.func,
    listMode: PropTypes.bool,
    style: PropTypes.object,
    onlyRead: PropTypes.bool,
};