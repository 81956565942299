import { lazy } from "react";
import {
    faScrewdriverWrench,
} from "@fortawesome/pro-light-svg-icons";
import abrilItems from "./abrhil";
import moduleItems from "./modules";
import usersRolesItems from "./roles-users";
import kioskoItems from "./kiosko";
import generalsItems from './generals';

//* Al agregar o actualizar elementos es de suma importancia que el id se construya
//* de la siguiente forma: '{padre raiz}@{item id del backend}'

//* Al agregar un nuevo componente el backend debe de haber hecho el previo registro

//* En caso de actualizar el ID de un componente al que se navegue mediante navigate en el sistema
//* por ejemplo: const path = getNavItem(padre@hijoID) -> navigate(path)
//* se debe actualizar su referencia (padre@nuevoID) al llamar a la funcion getNavItem la cual se encarga
//* de recuperar el path del elemento en el menu de REDUX dinamico mediante su ID

const menuElements = [
    //* ABRHIL *//
    ...abrilItems,


    //* KPIS *//
    {
        id: 'kpis',
        type: 'item',
        index: true, // Indica que está a nivel raíz del menú
        icon: 'kpis',
        componentElm: lazy(async ()  => import("../../../../modules/kpis/Dashboard")),
    },

    //* FILE EXPLORER *//
    {
        id: 'modules@file-explorer',
        type: 'item',
        icon: "fileexplorer",
        componentElm: lazy(async ()  => import('../../../../modules/culture-people/FileExplorer')),
    },

    //* Welcome *//
    {
        id: 'welcome',
        type: 'item',
        index: true, // Indica que está a nivel raíz del menú
        icon: 'home',
        componentElm: lazy(async ()  => import('../../../../modules/system/principal/Welcome')),
    },

    //* REPORTES *//
    {
        id: "main-reports",
        type: "item",
        index: true, // Indica que está a nivel raíz del menú
        icon: 'reports',
        componentElm: lazy(async ()  => import("../../../../modules/system/report/Reports")),
    },

    //* MODULOS *//
    ...moduleItems,

    //* CONTROL DE ACCESO *//
    {
        id: 'access-control@clocks',
        type: 'item',
        icon: 'clock',
        componentElm: lazy(async ()  => import('../../../../modules/clock/Clock')),
    },
    {
        id: 'access-control@clock-workers',
        type: 'item',
        icon: 'assign-clocks',
        componentElm: lazy(async ()  => import('../../../../modules/clock/ClockWorker')),
    },

    //* USUARIOS Y ROLES
    ...usersRolesItems,

    //* KISKO *//
    ...kioskoItems,

    //* BITACORA *//
    {
        id: 'modules@audit',
        type: 'item',
        icon: 'audit',
        componentElm: lazy(async ()  => import('../../../../modules/system/principal/WorkerLog')),
    },

    //* UTILERIAS *//
    {
        id: "utils",
        icon: 'config-icon',
        type: "item",
        inConstruction: true,
        componentElm: true
    },

    //* HERRAMIENTAS *//
    {
        id: "tools",
        icon: faScrewdriverWrench,
        type: "item",
        inConstruction: true,
        componentElm: true
    },

    ...generalsItems,
    // //* AYUDA *//
    // {
    //     id: "help",
    //     icon: faCircleInfo,
    //     type: "item",
    //     index: true, // Indica que está a nivel raíz del menú
    //     inConstruction: true,
    //     componentElm: true
    // },

    // //* ABRHIL HELPDESK *//
    // {
    //     id: "abrhilhelpdesk",
    //     icon: faHeadphones,
    //     type: "item",
    //     index: true, // Indica que está a nivel raíz del menú
    //     inConstruction: true,
    //     componentElm: true
    // }
];

export default menuElements;