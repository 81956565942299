import React from "react";
import PropTypes from "prop-types";
import Youtube from '../../../../../../assets/images/youtube.png';
import In from '../../../../../../assets/images/in.png';
import Facebook from '../../../../../../assets/images/facebook.png';
import X from '../../../../../../assets/images/x.png';
import { uid } from "uid";
import "../../styles/Login.scss";

export default function SocialNetwork({ showTitle = true, width, height }) {

    const networks = [
        { icon: In, link: null },
        { icon: Facebook, link: 'https://www.facebook.com/Abrhilsoft/' },
        { icon: X, link: null },
        { icon: Youtube, link: null },
    ];

    const handleClick = link => {
        if (!link) return;
        window.open(link, '_blank');
    }

    return (
        <div className="social-network-wrap">
            {showTitle && <span>{'Nuestras redes'}</span>}
            <div>
                {networks.map(net => (
                    <button key={uid()} onClick={() => handleClick(net.link)}>
                        <img alt={uid()} src={net.icon}  height={height} width={width} />
                    </button>
                ))}
            </div>
        </div>
    );
};

SocialNetwork.propTypes = {
    showTitle: PropTypes.bool
};