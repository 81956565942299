import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { isArray, size, isFunction } from "lodash";
import { useTranslation, Trans } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import { ChangesSkeleton, Transition } from "../modal/dialog/DialogSkeleton.jsx";
import { changes as checkChanges } from "../modal/WarningRowChange/change";
import DialogContent from "../../styled/DialogContent";
import DialogActions from "../../styled/DialogActions";
import { showNotificationError } from "../../../../store/actions";
import ErrorsList from "./ErrorsList";
import { formProps, basicFormProps, } from "./ComponentsPropTypes.jsx";
import { hasValue } from '../general/GeneralUtilities.jsx';
import BaseButton from '../Buttons/BaseButton.jsx';

/**
 * hook Para controlar los formularios del sistema, que ya contiene el modal de advertencia de cambios
 * @param {formProps} props
 * @returns {FormComponent}
 * @example
 * <Form
 *		handleSubmit={handleSubmit}
 *		onSubmit={onSubmit}
 *		onSubmitError={onSubmitError}
 *		defaultValues={defaultValues}
 *		warningFields={warningFields}
 *		fieldsValues={fieldsValues}
 *		deppWarnings={deppWarnings}
 *		fieldsLabels={fieldsLabels}
 *		numericFields={numericFields}
 *>
 *		contenido del formulario
 * </Form>
 * @version 2.0.0
 * @author Angel Efrain Poot Canul
**/
export const Form = ({
	handleSubmit,
	onSubmit,
	onSubmitError,
	defaultValues,
	warningFields,
	deppWarnings,
	children,
	useDefaultErrorMsg = false,
	...others
}: formProps) => {

	const dispatch = useDispatch();
	const checkThis = isArray(warningFields) ? warningFields : [];
	const failCounter = useRef(0);
	const [changes, setChanges] = useState({
		form: [],
		data: [],
		visible: false
	});

	const beforeSubmit = async data => {
		failCounter.current = 0;
		if (!hasValue(data.id)) {
			await onSubmit(data);
			return;
		}

		const changesCached = checkChanges({
			fields: checkThis,
			deepPick: deppWarnings,
			base: defaultValues,
			after: data,
			...others
		});

		if (size(changesCached) === 0) {
			await onSubmit(data);
			return;
		}

		setChanges({
			form: data,
			data: changesCached,
			visible: true
		});
	};

	const closeWarning = () => {
		setChanges(changes => ({
			...changes,
			visible: false
		}));
	};

	const onConfirm = () => {
		closeWarning();
		onSubmit(changes.form);
	};

	const defaultSubmitError = (errors) => {
		if (isFunction(onSubmitError)) {
			return onSubmitError(errors);
		}
		failCounter.current += 1;
		if (!useDefaultErrorMsg || failCounter.current < 2) { return; }
		dispatch(
			showNotificationError({
				title: "¡ Ooops !",
				message: (
					<Trans i18nKey="default-error-list-msg" >
						This message appeared because you tried to send the form without correcting the errors marked in red more than once,
						it is important correct them before you can continue.
						<br /><br />
						<strong>Here is a list of items you should check.</strong>
					</Trans>
				),
				maxWidth: "sm",
				description: <ErrorsList {...others} errors={errors} />
			})
		);
	};

	return (
		<form onSubmit={handleSubmit(beforeSubmit, defaultSubmitError)} autoComplete="off" noValidate>
			<Dialog
				open={changes.visible}
				TransitionComponent={Transition}
				className="myDialog changesDialog infoDialog"
			>
				<ChangesSkeleton
					confirm={onConfirm}
					close={closeWarning}
					changes={changes.data}
					confirmChanges={true}
				/>
			</Dialog>

			{children}
		</form>
	);
};

/**
 * Hook de formulario que ya inlcuye los botones necesarios para cancelar y enviar los formularios
 * @param {basicFormProps} attributes
 * @returns {BasicFormComponent}
 * @example
 * <BasicForm
 * 	handleSubmit={handleSubmit}
 * 	onSubmit={onSubmit}
 * 	defaultValues={defaultData}
 * 	warningFields={warningFields}
 * 	deppWarnings={deppWarnings}
 * 	fieldsValues={fieldsValues}
 * 	fieldsLabels={fieldsLabels}
 * 	onSubmitError={onSubmitError}
 * >
 * 	 contenido del formulario
 * </BasicForm>
 * @version 2.0.0
 * @author Angel Efrain Poot Canul
*/
const BasicForm = ({
	onCancel,
	className,
	children,
	disableSave = false,
	showSave = true,
	CustomActions,
	includeBaseCard = true,
	saveButtonProps = {},
	cancelButtonProps = {},
	...others
}: basicFormProps) => {

	const { t } = useTranslation();

	return (
		<>
			<DialogContent dividers className={className ?? ""} includeBaseCard={includeBaseCard}>
				{children}
			</DialogContent>
			<Form {...others} >
				<DialogActions>
					{
						CustomActions ??
						<>
							<BaseButton
								design="text"
								color="error"
								onClick={onCancel}
								label={t("cancel")}
								{...cancelButtonProps}
								type="button"
							/>
							{showSave &&
								<BaseButton
									design="contained"
									disabled={disableSave}
									label={t("save")}
									{...saveButtonProps}
									type="submit"
								/>
							}
						</>
					}
				</DialogActions>
			</Form>
		</>
	);
};

export default BasicForm;