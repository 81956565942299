import PropTypes from "prop-types";
import React, { Suspense, useMemo } from 'react';
import icons from "../../../../assets/scss/custom-icons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasValue } from '../general/GeneralUtilities';
import { isString } from 'lodash';

/**
 * Componente de uso rapido para los iconos svg
 * @param {object} params
 * @param {string | object} params.icon -  el nombre del icon que se va a usar dependiendo del nombre o objecto para los fontawesomes se mostrara uno u otro
 * @param {?string} params.className -  Custom classname para el icono
 * @returns {span}
 */

const IconUse = ({
	icon = null,
	className = "",
	style = {},
	...others
}) => {

	if (!hasValue(icon)) { return null; }

	if (isString(icon)) {
		icon = icon?.replace(/[-_]/g, "").toLowerCase();
		if (hasValue(icons?.[icon])) {
			return (
				<Icon icon={icon} className={`custom-abr-icon ${className}`} style={style} />
			);
		}
	}

	return <FontAwesomeIcon icon={icon} className={className} style={style} {...others} />;
};

IconUse.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.any,
	style: PropTypes.object
};

export default IconUse;

const Icon = ({ icon, ...others }) => {
	const SvgIcon = useMemo(() => icons?.[icon]?.Icon ?? icons?.[icon], [icon]);

	if (!hasValue(SvgIcon)) { return null; };

	return (
		<Suspense fallback={<div className='custom-abr-icon-skeleton' />}>
			<SvgIcon {...others} />
		</Suspense>
	);
};

Icon.propTypes = {
	icon: PropTypes.any
};
