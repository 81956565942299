import React from "react";
import { useTranslation } from "react-i18next";


export const TooltipBody = () => {
	const { t } = useTranslation();
	const commonStyles = { display: 'flex', flexDirection: 'column' };

	return (
		<div style={commonStyles}>
			<strong> {t("can-search-by")}</strong>
			<div style={{ ...commonStyles, marginLeft: '5px' }}>
				<span>
					- {t("name-or-part")}
				</span>
				<span>
					- {t("key-or-part")}
				</span>
			</div>
		</div>
	);
};
