import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useModalsContext } from '../../../../hooks/useModals';
import { t } from 'i18next';
import { BaseButton } from '../../../../../../../../../../../general/@components/Buttons';
import { toHours } from '../../../../utils/utilities';
import { MODAL_KEY as PAY_TIME_KEY } from '../../../../hooks/useTimePayment';
import { RequestIconButton } from './PaymentTypes';
import RequestsComponent from './RequestComponent';
import { size } from 'lodash';
import { centerOrigin, centerTransform } from '../../../../utils/constants';
import '../../../../../../../styles/style.scss';
import GenericLabel from '../../../../../../../../../../../general/@components/general/GenericLabel/GenericLabel';

function Request({
    item,
    worker,
    refresh,
    className = '',
    byCheck = true,
    divider = false,
    onlyRead = false,
    showDetails = true,
}) {

    const { handleOpen } = useModalsContext();
    const [anchor, setAnchor] = useState(null);

    const exceed = (!byCheck ? item?.overtime_calculated : item?.overtime_real) ?? 0;
    const balance = item?.balance ?? 0;
    const requestedTime = exceed - balance;
    const disabledBtn = !balance || onlyRead;
    const buttonLabel = balance ? t('request') : 'Sin saldo';
    const requestsSize = size(item?.request_details);

    const data = [
        { label: 'Horas mayor a jornada', value: toHours(exceed) },
        { label: 'Solicitadas', value: toHours(requestedTime) },
        { label: 'Disponibles', value: toHours(balance), primary: true }
    ];

    useEffect(() => {
        if (requestsSize || !anchor) return;
        onClose();
    }, [item?.request_details])

    const onClick = (event) => {
        if (!requestsSize || !showDetails) return;
        setAnchor(event.currentTarget);
    };

    const onClose = () => setAnchor(null);

    function handleOTPayment() {
        handleOpen(PAY_TIME_KEY, item);
    }

    return (
        <div className={`overtime-request-container ${divider ? 'ov-right-divider' : ''} ${className}`}>
            <RequestsComponent
                anchor={anchor}
                item={item}
                worker={worker}
                refresh={refresh}
                onClose={onClose}
                onlyRead={onlyRead}
                anchorOrigin={centerOrigin}
                transformOrigin={centerTransform}
            >
                <div className={`inner-labels-container ${(showDetails && requestsSize) ? 'with-details' : ''}`} onClick={onClick}>{/*NOSONAR*/}
                    {data.map(el =>
                        <GenericLabel
                            key={`ov-label-${el.label}`}
                            label={el.label}
                            value={el.value}
                            primaryLabel={el.primary}
                            style={{ fontWeight: 'bold' }}
                            disableTooltip
                        />)}
                </div>
            </RequestsComponent>
            {!onlyRead &&
                <BaseButton
                    disabled={disabledBtn}
                    onClick={handleOTPayment}
                    className='overtime-request-button'
                >
                    <RequestIconButton />
                    {buttonLabel}
                </BaseButton>}
        </div>
    );
};

export default Request;

Request.propTypes = {
    item: PropTypes.object,
    byCheck: PropTypes.bool,
    divider: PropTypes.bool,
    onlyRead: PropTypes.bool,
    refresh: PropTypes.func,
    worker: PropTypes.object,
    showDetails: PropTypes.bool,
    className: PropTypes.string,
};