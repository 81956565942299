import { size } from "lodash";
import { ICONS } from "../actions";

export const MENU_STRUCTURE = 'menu_structure';
export const USER_DASHBOARD = 'user_dashboard';

const initialState = {}

export const initialIcons = {
    icons: null,
}

export function menuStructure(state = initialState, action) {
    const finalState = size(action.payload) ? { ...state, ...action.payload } : {};
    switch (action.type) {
        case MENU_STRUCTURE:
            return finalState;

        default:
            return state;
    }
}

export function userDashboard(state = {path: ''}, action) {
    switch (action.type) {
        case USER_DASHBOARD:
            return {
                ...state,
                path: action.payload,
            }

        default:
            return state;
    }
}

export function iconsMenu(state = initialIcons, action = {}) {
    switch (action.type) {
        case ICONS:
            return {
                ...state,
                icons: action.payload,
            }
        default:
            return state;
    }
}