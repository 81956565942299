import React from 'react';
import Grid from "@mui/material/Grid2";
import { Skeleton } from "@progress/kendo-react-indicators";
import MainCard from '../../general/MainCard';
import ButtonsContainer from '../../Buttons/ButtonsContainer';
import { gridSize } from '../../general/GeneralUtilities';

const buttonsStyle = {
	width: 100,
	height: 25,
	borderRadius: "3em",
};

const commons = {
	shape: "rectangle",
	animation: {
		type: "wave",
	}
};

const divBorder = {
	height: '100%',
	border: "1px solid var(--borderGray)",
};

/**
 * Layout skeleton para los loaders de las páginas
 */
const PageSkeleton = () => {
	return (
		<MainCard principal >
			<Grid
				container
				spacing={2}
				className="pageLoader"
				direction="column"
				sx={{ height: '100%' }}
			>
				<Grid {...gridSize(12)} sx={{ flex: 0 }}>
					<ButtonsContainer className={"mb-1"}>
						<Skeleton {...commons} style={buttonsStyle} />
						<Skeleton {...commons} style={buttonsStyle} />
					</ButtonsContainer>
				</Grid>
				<Grid {...gridSize(12)} sx={{ flex: 1 }}>
					<div className="principal-grid" style={divBorder}>
						<div>
							<Skeleton
								{...commons}
								style={{
									width: "100%",
									height: 30,
								}}
							/>
						</div>
					</div>
				</Grid>
			</Grid>
		</MainCard>
	);
};

export default PageSkeleton;