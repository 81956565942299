import React from 'react';
import PropTypes from "prop-types";

/* Library components */
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from "framer-motion";
/* Own components */
import Spinner from '../general/Loader/Loader';
import { opacityAnimation } from '../form/Permission/Utils';

const Loader = ({
	loading,
	gridRef,
}) => {
	return (
		<AnimatePresence mode='wait'>
			{loading ? <LoadingPanel gridRef={gridRef} /> : null}
		</AnimatePresence>
	);
};

export default Loader;

const LoadingPanel = ({ gridRef }) => {
	const loadingPanel = (
		<motion.div
			key='custom-abr-grid-loader'
			className="custom-abr-grid-loader k-loading-mask"
			{...opacityAnimation}
		>
			<Spinner size={50} />
		</motion.div>
	);
	const gridContent = gridRef?.current?._element?.querySelector('.k-grid-container');
	return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};

LoadingPanel.propTypes = {
	gridRef: PropTypes.object,
};