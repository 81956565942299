import React, { useContext, useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { penddingSignaturesAll, penddingSignaturesMopers } from '../../../../../general/services/worker';
import { initialPage } from '../../../../../general/@components/table/utils/GridCustomProps';
import { parseRequest } from '../../../../../general/@components/requests/parse-request';
import { debounce, each, isArray, isEqual, omit, pick } from 'lodash';
import { resolveError } from '../../../../../general/@components/requests/resolve-error';
import { FiltersContext } from '../contexts';
import { showConfirmNotification, showNotificationWarning } from '../../../../../../store/actions';
import { t } from 'i18next';
import { implementService } from '../../../../../general/services/implemet-service';
import { moperSignaturesApi } from '../../../../../general/services/mopers';
import { showSuccessNotification } from '../../../../../general/@components/Notifications';
import { uid } from 'uid';
import { workerFullName } from '../../../../../general/@components/employees/constants';
import { reversDate } from '../../../../../general/@components/PayrollPeriod/itemUtilities';

function useTabsGrid() {
	const tenant = useSelector((state) => state.tenant?.current?.id);
	const massiveSign = useSelector(state => state?.userConfigs?.user_info?.moper_attendance)?.find(el => el?.key === 'sign_massive')?.has_perm;
	const dispatch = useDispatch();

	const [data, setData] = useState({});
	const [page, setPage] = useState(initialPage);
	const [total, setTotal] = useState({});
	const [worker, setWorker] = useState(null);
	const [finished, setFinished] = useState(false);
	const [rowLoading, setRowLoading] = useState(null);
	const periods = useRef([]);
	const controller = useRef(null);

	//Tabs
	const [workerId, setWorkerId] = useState(null);
	const [inWorkerDetails, setInWorkerDetails] = useState(false);

	const {
		index,
		fetch,
		control,
		inDetails,
		handleBack,
		paramWorker,
		setShowWg,
		setInDetails,
		setActionBack,
		setParamWorker,
		setInitialFilter,
		verifyDisabledFilters,
		tab: parentTab,
		filter: parentFilter,
		loading: parentLoading,
		updateFilters: updateParentFilter,
	} = useContext(FiltersContext);

	const [tab, setTab] = useState(parentTab);
	const allWorkers = useRef([]);
	const [filter, setFilter] = useState(parentFilter);
	const selectedPeriod = useWatch({ control, name: 'selected_period' });
	const dataCount = total?.[index]?.total;

	useEffect(() => {
		if (inDetails) return;
		const clearCatalogs = { moper_catalog: null, sub_catalog: null, key: null };
		updateParentFilter(clearCatalogs);
		updateFilters(clearCatalogs);
		setInitialFilter((prev) => ({ ...prev, ...clearCatalogs }));
	}, [inDetails])

	useEffect(() => {
		const currentData = data?.[index];
		if (dataCount < page?.skip) {
			setPage(initialPage);
		}
		if (!paramWorker) return;
		const workerFound = currentData?.find(el => el.key === parseInt(paramWorker));
		if (workerFound) {
			setWorker(workerFound);
			handleViewMopers(workerFound);
		} else {
			setTab(1);
		}
		setParamWorker(null);
		// eslint-disable-next-line
	}, [data?.[index]]);

	useEffect(() => {
		if (inWorkerDetails || !inDetails) return;
		controller.current = new AbortController();
		getGridData();
		return () => {
			controller.current.abort();
		}
		// eslint-disable-next-line
	}, [page, filter, tab, inWorkerDetails, tenant]);

	useEffect(() => {
		if (inWorkerDetails || !inDetails) return;
		getAllWorkers();
	}, [filter, tab, tenant])

	useEffect(() => {
		setActionBack(() => inWorkerDetails ? onBack : handleBack);
		setShowWg(!inWorkerDetails);
	}, [inWorkerDetails])

	useEffect(() => {
		if (isEqual(filter?.filters, parentFilter?.filters)) return;
		setFilter(() => ({ ...parentFilter }));
	}, [parentFilter])

	const onTabChange = (value, index) => {
		verifyDisabledFilters(index);
		setTab(value);
	}

	const updateFilters = filters => setFilter((prev) => ({ filters: { ...prev?.filters, ...filters } }));

	const handleViewMopers = (item, e) => {
		if (e?.altKey) return;
		setWorkerId(item?.worker?.id);
		setInWorkerDetails(true);
	};

	const onBack = () => {
		setInWorkerDetails(false);
	};

	function updateGrid(response, sign) {
		setRowLoading(null);
		setData((prev) => ({ ...prev, [index]: response.results }));
		setTotal((prev) => ({ ...prev, [index]: { total: response.count, loaded: true } }));
		if (sign && !response.results?.length) {
			performFinished();
		}
	}

	const handleRefresh = async (reset = true, sign = false, item = null) => {
		getAllWorkers();
		await getGridData(reset, sign, item);
	};

	const getAllWorkers = async () => {
		const request = buildFilters();
		const params = {
			...omit(request, ['offset', 'limit']),
			fields: 'id',
			tree: true,
			signal: controller.current.signal
		};
		try {
			const resp = await penddingSignaturesAll.get(params);
			allWorkers.current = resp.results?.map(el => el.id);
		} catch (error) {
			resolveError(error);
		}
	}

	const buildFilters = () => {
		const realFilters = { ...filter?.filters, status: tab };
		if (realFilters?.concepts) {
			realFilters.concepts = realFilters.concepts.join(',');
		}
		const newFilters = omitPayload(realFilters);
		const params = {
			include: "worker",
		};
		return parseRequest(params, newFilters, page);
	}

	const getGridData = async (reset = true, sign = false, item = null) => {
		if (item?.id) setRowLoading(item?.id);
		if (reset) updateLoad(false);
		const request = buildFilters();
		await getData(request, sign);
	};

	const updateLoad = (value) => setTotal(prev => ({ ...prev, [index]: { ...total[index], loaded: value } }));

	const handlePageChange = page => setPage(() => ({ ...page }));

	const onGridFailed = error => {
		updateLoad(true);
		setRowLoading(null);
		resolveError(error);
	}

	// @get
	async function getData(request, sign) {
		await fetch({
			api: penddingSignaturesMopers.get({ ...request, signal: controller.current?.signal }),
			callback: res => updateGrid(res, sign),
			onFailed: onGridFailed,
		});
	}

	const handleSignMassive = () => {
		let message = !filter?.filters?.key ? 'de sus colaboradores' : `del colaborador ${filter?.filters?.key}`;
		dispatch(showConfirmNotification({
			title: t('warning-general-title'),
			message: '¿Desea continuar?',
			description: `Se firmarán todos los pendientes ${message}`,
			maxWidth: 'sm',
			onConfirm: () => signAll(),
		}));
	}

	const signAll = () => {
		let catalogs = pick(filter?.filters, ['moper_catalog', 'sub_catalog']);
		each(catalogs, (value, field) => catalogs[field] = value?.split(',')?.filter(el => el));
		catalogs.worker_key = filter?.filters?.key;
		catalogs = { ...catalogs, ...omit(buildFilters(), ['limit', 'offset', 'include', 'sort_by', 'active_date']) };
		implementService(moperSignaturesApi.signMassive(catalogs), onSuccessMassive);
	}

	const onSuccessMassive = (response) => {
		handleRefresh();
		showSuccessNotification(response.data);
		const dataFailed = response.data_failed;
		if (dataFailed) {
			dispatch(showNotificationWarning({
				maxWidth: 'sm',
				title: t('warning-general-title'),
				message: response.warning,
				description:
					<div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100%', overflow: 'auto' }}>
						{dataFailed.map(el =>
							<div key={uid()} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<div style={{ display: 'flex', flexWrap: 'nowrap', gap: '5px' }}>
									<span style={{ color: 'var(--egyptianBlue)' }}>{reversDate(el.day)}</span>
									<span style={{ color: 'var(--egyptianBlue)' }}>{workerFullName({ ...el, include_key: true })}</span>
								</div>
								<span>{el.summary}</span>
							</div>
						)}
					</div>
			}));
			return;
		}
		performFinished();
	}

	const onWorkerKey = useRef(debounce((value) => updateParentFilter({ key: value }), 300)).current;

	const onSuccessSign = async (item) => {
		await handleRefresh(false, true, item);
	}

	const performFinished = () => {
		setFinished(true);
		setTimeout(() => {
			setInDetails(false);
		}, 2500);
	}

	return {
		tab,
		index,
		data,
		page,
		total,
		filter,
		finished,
		dataCount,
		inDetails,
		allWorkers,
		massiveSign,
		rowLoading,
		parentLoading,
		setInDetails,
		onWorkerKey,
		workerId,
		fetch,
		onBack,
		setData,
		getAllWorkers,
		onSuccessSign,
		setWorkerId,
		getGridData,
		onTabChange,
		handleRefresh,
		updateFilters,
		handlePageChange,
		handleViewMopers,
		setInWorkerDetails,
		handleSignMassive,
		updateParentFilter,
		verifyDisabledFilters,
		periods,
		selectedPeriod,
		inWorkerDetails,
		//FORm
		worker,
		setWorker,
	};
}

export default useTabsGrid;

export const omitPayload = (payload = {}, symbol = ["*", "all", "ALL", 99]) => {

	const validateIfArray = value => {
		if (isArray(symbol)) {
			return symbol.includes(value);
		}
		return value === symbol;
	}

	const toBeFiltered = Object.entries(payload).filter(el => validateIfArray(el[1])).map(el => el[0]);
	return omit(payload, toBeFiltered);
}
