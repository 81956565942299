export const FaultSymbols = ['f', 'j'];

export const Flags = ['late', 'incidence', 'over', 'less', 'adjust'];

export const AttendanceSymbols = [
  { name: 'incapacity', prefix: 'I', cleanCell: true },
  { name: 'worker-leave', prefix: 'B', cleanCell: true },
]

export const ChecksSymbols = ['•', '/', '.'];

export const requireFault = ['fr'];

export const detailShowedSymbols = ['fr', 'b', 'f'];

export const middleCells = ['is_middle', 'is_pair'];

export const footerIcons = [
  { id: 1, label: 'PD', desc: 'Prima Dominical' },
  { id: 2, label: 'FT', desc: 'Festivo Trabajado' },
  { id: 3, label: 'DT', desc: 'Descanso Trabajado' },
  { id: 4, label: 'TD', desc: 'Turno Doble' },
  { id: 5, label: 'Dinero', icon: "money", desc: 'Dinero' },
  { id: 6, label: 'TXT', desc: 'Tiempo por tiempo', icon: "txt" },
  { id: 7, label: "assist-adjustment", desc: 'Ajuste de asistencia', icon: 'assist-adjustment-light' },
  { id: 8, label: "complete-turn", desc: 'Completar turno', icon: 'complete-turn' },
  { id: 9, label: "holiday", desc: 'Festivo', icon: 'apply-holidays' },
  { id: 10, label: "rest-day", desc: 'Descanso', icon: 'complete-turn' },
  { id: 11, label: "vacation", desc: 'Vacaciones', icon: 'vacation' },
  { id: 12, label: "Pc", desc: 'Ausentismo con goce', icon: 'con_goce' },
  { id: 13, label: "Pg", desc: 'Ausentismo sin goce', icon: 'sin_goce' },
]

export const validDetailItem = ['pc', 'pg', 'assist-adjustment', 'v', 'dv'];