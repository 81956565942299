import * as yup from 'yup';
import { validateNumeric } from '../../../../../../../../general/@components/validations/numeric';
import { stringValidation } from '../../../../../../../../general/@components/validations/stringValidation';

export const otValidationSchema = () => {

    return yup.object().shape({
        cover_reason: validateNumeric({ required: true }),
        payment_type: validateNumeric({ required: false }).when("isDT", {
            is: true,
            then: () => validateNumeric({ required: true }),
        }),
        worker_sel: stringValidation({ required: true }).when("cover_reason", {
            is: 2,
            then: () => stringValidation({ required: false }),
        }),
        comment: stringValidation({ required: false }).when("cover_reason", {
            is: 2,
            then: () => stringValidation({ required: true, min: 10, max: 250 }),
        }),
        money_request_time: validateNumeric({ required: true, min: 1 }).when("txt_request_time", {
            is: (value) => value > 0,
            then: () => yup.number().nullable(true),
        }),
        txt_request_time: validateNumeric({ required: true, min: 1 }).when("money_request_time", {
            is: (value) => value > 0,
            then: () => yup.number().nullable(true),
        }),
    }, ['money_request_time', 'txt_request_time']);;
}

export const paymentValidationSchema = () => {
    return yup.object().shape({
        cover_reason: validateNumeric({ required: true }),
        payment_type: validateNumeric({ required: false }).when("isDT", {
            is: true,
            then: () => validateNumeric({ required: true }),
        }),
        comment: stringValidation({ required: false }).when("cover_reason", {
            is: 2,
            then: () => stringValidation({ required: true, min: 10, max: 250 }),
        }),
    });
};
