import i18next from 'i18next';
import { number } from "yup";
import { isString } from "lodash";
import { hasValue, parseNumber } from '../general/GeneralUtilities';
const { t } = i18next;

/**
 * Validación de tipo numeric para usar en los formularios del sistema, basado en YUP
 * @param {object} params
 * @param {?boolean} params.required - Indica si el campo a validar es requerido
 * @param {?number} params.min - Validación para el mínimo permitido
 * @param {?number} params.max - Validación para el máximo permitido
 * @param {?number} params.positive - Validación para indicar si el valor tiene que ser necesariamente positivo
 * @param {?string} params.customRequiredMsg - Mensaje custom para mostrar cuando un campo es requerido y no se quiera usar el mensaje por default, eje. la seleccion de un item de tabla
 * @param {?number} params.decimals - Es el número de decimales que se van a mostrar o va a manejar la validación
 * @param {?{required:?string,min:?string,max:?string}} params.customMessages - Mensajes custumizados cuando caigan en la validación correspondiente
 *
 * @returns {yupNumberValidation}
 * @example validateNumeric({ min: 1 })
 */

export function validateNumeric({
	min = null,
	max = null,
	required = false,
	positive = false,
	customRequiredMsg,
	decimals,
	customMessages = {}
}) {

	let rule = number()
		.typeError(t('validation:numeric'))
		.nullable();

	let msg = "";
	if (required) {
		msg = customMessages?.required ?? customRequiredMsg ?? 'validation:required';
		rule = rule.required(t(msg));
	}

	if (positive) {
		rule = rule.positive(t('validation:numeric-positive'));
	}

	if (hasValue(min)) {
		min = isString(min) ? min.replaceAll(",", "") : min;
		min = parseFloat(min);
		const parsedValue = decimals ? parseNumber(min, decimals) : min;
		msg = customMessages?.min ?? 'validation:min.numeric';
		rule = rule.min(min, t(msg, { min: parsedValue }));
	}

	if (hasValue(max)) {
		max = isString(max) ? max.replaceAll(",", "") : max;
		max = parseFloat(max);
		const parsedValue = decimals ? parseNumber(max, decimals) : max;
		msg = customMessages?.max ?? 'validation:max.numeric';
		rule = rule.max(max, t(msg, { max: parsedValue }));
	}

	return rule;
}