import lang from "i18next";
import {
	useState
} from "react";
import {
	size,
	each,
	filter,
	isString,
	find
} from "lodash";

import {
	branchesAPI,
	subBranchesApi,
	areaAPI,
	subAreaApi,
	departmentAPI,
	subDepartmentsApi,
	jobPositionsApi,
	employerRegistrationAPI
} from "../../../services/enterprise-structure";
import { valueOrOption, hasValue } from '../../general/GeneralUtilities';
import { getBusinessLabel } from '../../Inputs/search/ComonControls';

const { t } = lang;

export const defaultAllOptions = "*";

/**
 * * Funcion que nos sirve para obtener la etiqueta que se muestra en el sistema por cada uno de elementos de estructura
 * @param {object} item
 */
export const applySpaces = multiple => '\xa0'.repeat(multiple);
export const structureLabel = (item, businessLabel = false, separatedLabel = false) => {
	if (item.step === "employer_registration" && separatedLabel) {
		return `${applySpaces(10)}${t('rfc')}${applySpaces(15)}/${applySpaces(3)}${t(getBusinessLabel(businessLabel, false, false))}`;
	}
	return t(item?.alias?.trim() ?? item?.step?.replace(/_/g, '-'));
};

export const getFullName = (data, onlyRfc = false) => {
	if (!data) return "";
	if (data.rfc) {
		return onlyRfc
			? `${data?.rfc} - ${data?.name}`
			: `${data?.rfc} - ${data?.key} - ${data?.name}`;
	}
	return `${data?.key} - ${data?.name}`;
};

export const getTooltip = (data, onlyRfc = false) => {
	if (!data) return "";
	let title = "";

	title += data.rfc ? `${t("rfc")} - ${data.rfc}\n` : "";
	title += data.key && !(onlyRfc && data.rfc) ? `${t("key")} - ${data.key}\n` : "";
	title += data.name ? `${t("name")} - ${data.name}` : "";

	return title;
};

export const structureDefaults = (structure, defaultValue = "", prefix = "") => {
	let defaults = {};
	each(structure ?? [], field => {
		if (field?.step !== "preview") {
			defaults[`${prefix}${field?.step}`] = valueOrOption(defaultValue, null);
		}
	});
	return defaults;
};

/**
 * * Funcion para filtrar los steps que no nos interesen de la estructura empresarial
 * @param {[object]} structure - La configuración de la estructura que estamos usando
 * @param {[string]} steps - Array de los steps por los cuales se van a filtrar
 * @param {?boolean} toShow - Indica si el array que de steps pasamos son los elementos que queremos ver, por default en false(los que no queremos ver )
 * @returns {array}
 */
export const structureSteps = (structure, steps = [], toShow = false, employer_first = false) => {
	if (!size(structure)) {
		return [];
	}
	let final_steps = structure;
	if (employer_first) {
		const employer = find(structure, ["step", "employer_registration"]);
		final_steps = [
			employer,
			...filter(structure, item => item.step !== "employer_registration")
		];
	}

	if (toShow) {
		return filter(final_steps, item => valueOrOption(steps, []).includes(item.step));
	}
	return filter(final_steps, item => ![...(steps ?? []), "preview"].includes(item.step));
};

/**
 * * Funcion para devolver un array con solo los nombres de los steps
 * @param {array} structure - La configuración de la estructura que estamos usando
 * @param {[string]} steps - Array de los steps que no necesitemos ver
 * @returns {array}
 */
export const structureStepsArray = (structure, steps = []) => {
	return structureSteps(structure, steps)
		.map(item => {
			return item.step;
		});
};

/**
 * Funcion que devuelve el api a utilizar de acuerdo al step en turno
 * @param {string} step
 * @returns {GetApi}
 */
export const getStructureApi = (step) => {
	switch (step) {
		case 'branch':
			return branchesAPI.get;
		case 'sub_branch':
			return subBranchesApi.get;
		case 'area':
			return areaAPI.get;
		case 'sub_area':
			return subAreaApi.get;
		case 'department':
			return departmentAPI.get;
		case 'sub_department':
			return subDepartmentsApi.get;
		case 'job_position':
			return jobPositionsApi.get;
		case 'employer_registration':
			return employerRegistrationAPI.get;
		default:
			return null;
	}
};

/**
 * * conjunto de funciones para manejar los valores en cascada de la Estructura en los formularios del sistema
 * @param {object} params
 * @param {array} params.structure - La estructura que sera tomada en cuenta para la cascada de valores
 * @param {function} params.setValue - Función del Hook-Form , indispensable para el seteo de valores
 * @param {function} params.getValues - Función del Hook-Form , indispensable para la sustracción de valores
 * @param {?string} params.fieldsPrefix - Define un prefijo para todos los inputs de la structura que estemos usando
 * @param {?string} params.clearValue - String que sera usado como valor default cuando se limpien los campos
 */
export const StructureFunctions = ({
	structure = [],
	setValue,
	getValues,
	fieldsPrefix = "",
	clearValue = "",
	workerClearValue = ""
}) => {


	fieldsPrefix = fieldsPrefix ?? "";
	const cv = valueOrOption(clearValue, "*");
	const [watches, setWatches] = useState({ ...structureDefaults(structure, clearValue) });

	const clear = (value) => {
		return valueOrOption(value, "")
			.toString()
			.replace(`,${cv}`, "")
			.replace(`${cv},`, "")
			.replace(cv, "");
	};

	const resetBegins = (stepIndex, step, value, workerField = "") => {
		let newWatches = {};
		const lastWatches = getValues(structureStepsArray(structure));

		each(structure, (item, index) => {
			const watchValue = valueOrOption(lastWatches[index], clearValue);
			newWatches[`${fieldsPrefix}${item.step}`] = (index < stepIndex) ? watchValue : clearValue;
			if (index > stepIndex && watchValue) {
				setValue(`${fieldsPrefix}${item.step}`, clearValue);
			}
		});

		if (hasValue(workerField) && isString(workerField)) {
			setValue(`${fieldsPrefix}${workerField}`, workerClearValue);
		}
		newWatches = {
			...newWatches,
			[`${fieldsPrefix}${step}`]: valueOrOption(value, clearValue)
		};
		setWatches(newWatches);
	};

	const getExtras = (step, byKey = true) => {
		let extras = {};
		let index = 0;
		each(watches, (value, field) => {
			value = clear(value);
			if (index < step && hasValue(value)) {
				extras[`${field}${byKey ? "_keys" : ""}`] = value;
			}
			index += 1;
		});
		return extras;
	};

	const handleSelect = (input, selection) => {
		const newValue = clear(selection.join(","));
		selection = valueOrOption(newValue, clearValue);
		setValue(`${fieldsPrefix}${input}`, selection, { shouldValidate: true });
	};

	const getSelected = (option) => {
		const value = clear(getValues(`${fieldsPrefix}${option}`));
		return hasValue(value) ? value.split(",") : [];
	};

	const disabledItem = (index) => {
		if (index) {
			const value = clear(getValues(`${fieldsPrefix}${structure[index - 1].step}`));
			return valueOrOption(value, cv) === cv;
		}
		return false;
	};

	return {
		clear,
		resetBegins,
		getExtras,
		handleSelect,
		getSelected,
		disabledItem,
	};
};