export const SHOW_PROCESS_SCTRUCTURES = 'SHOW_PROCESS_SCTRUCTURES';
export const HIDE_PROCESS_SCTRUCTURES = 'HIDE_PROCESS_SCTRUCTURES';
export const SHOW_PERIOD_COMPUTED = 'SHOW_PERIOD_COMPUTED';
export const HIDE_PERIOD_COMPUTED = 'HIDE_PERIOD_COMPUTED';
export const LOAD_PAYROLL_TYPES = 'LOAD_PAYROLL_TYPES';

export const showProcessStructures = (payload) => ({
	type: SHOW_PROCESS_SCTRUCTURES,
	payload
});

export const hideProcessStructures = () => ({
	type: HIDE_PROCESS_SCTRUCTURES
});

export const showPeriodComputed = (payload) => ({
	type: SHOW_PERIOD_COMPUTED,
	payload
});

export const hidePeriodComputed = () => ({
	type: HIDE_PERIOD_COMPUTED
});

export const loadPayrollTypes = (data) => ({
	type: LOAD_PAYROLL_TYPES,
	data
});