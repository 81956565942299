import { getSubdomain } from "../@components/requests/domain";
import { authentication, getToken, isAbrhil } from "../@components/general/auth";

function getAPIClient(context = '') {
	return process.env.REACT_APP_API_CLIENT
		.replace('@subdomain', getSubdomain()) + context;
}

const currentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const getBearerToken = () => `Bearer ${getToken()}`;

function getAPI() {
	return isAbrhil()
		? process.env.REACT_APP_API
		: getAPIClient('/enterprise');
}

function getPublicApi() {
	return isAbrhil()
		? process.env.REACT_APP_API_PUBLIC_ADDRESS
		: getAPIClient();
}

function webSocketClient() {
	return process.env.REACT_APP_WS;
}

export function getAPIClientDomain(domain) {
	return process.env.REACT_APP_API_CLIENT
		.replace('@subdomain', domain);
}

export const API = getAPI();

export const API_WS = webSocketClient();

export const API_PUBLIC_ADDRESS = getPublicApi();

export function config(params = {}, data = {}) {
	const { signal } = params;
	delete params.signal;
	return {
		signal,
		params,
		data,
		headers: {
			'Authorization': authentication() ? getBearerToken() : '',
			'Accept-Language': 'es-mx',
			'Django-Timezone': currentTimezone(),
		}
	};
}

export function configMedia(params = {}, data = {}) {
	return {
		params,
		data,
		headers: {
			'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
			'Authorization': authentication() ? getBearerToken() : '',
			'Django-Timezone': currentTimezone(),
		},
	};
}

export function configFile(params = {}, data = {}) {
	return {
		params,
		data,
		headers: {
			'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
			'Authorization': authentication() ? getBearerToken() : '',
			'Django-Timezone': currentTimezone(),
		},
		responseType: 'blob'
	};
}

export function configExcel(params = {}, data = {}) {
	return {
		params,
		data,
		responseType: 'arraybuffer',
		headers: {
			'Authorization': authentication() ? getBearerToken() : '',
			'Django-Timezone': currentTimezone(),
		}
	};
}