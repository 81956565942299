import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
//--material-ui
import { Grid } from "@mui/material";
import icons from "../../../../../../assets/scss/custom-icons/icons";
import DialogContent from "../../../../styled/DialogContent";
import IconUse from "../../../uiDesign/IconUse";
import { setIcons } from "../../../../../../store/actions";
import { hasValue } from "../../../general/GeneralUtilities";
import FormDialog from "../../../modal/dialog/FormDialog";
import FormInput from "../../../form/Field";
import { lowerCase } from "lodash";
import { CustomTooltip } from '../../../Templates/cells';

const IconsSet = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const iconMenuConfig = useSelector(state => state?.iconsMenu?.icons);
	const [open, setOpen] = useState(false);
	const allData = Object.keys(icons).sort((a, b) => a.localeCompare(b, { sensitivity: 'base' }));
	const [iconsData, setIconsData] = useState(allData);

	const {
		control,
		reset
	} = useForm({
		mode: "onChange",
		defaultValues: {
			color: "#004f88",
		}
	});


	useEffect(() => {
		const val = hasValue(iconMenuConfig) ? !!iconMenuConfig : false;
		setOpen(val);
		//eslint-disable-next-line
	}, [iconMenuConfig]);

	useEffect(() => {
		if (!open) return;
		dispatch(setIcons(true));
	}, [open]);

	const onClose = () => {
		reset();
		dispatch(setIcons(null));
		setOpen(false);
	};

	const handleSearch = (e) => {
		const search = e.target.value;
		if (!hasValue(search)) {
			setIconsData(allData);
		} else {
			const filteredData = allData?.filter((icon) => icon.includes(lowerCase(search)));
			setIconsData(filteredData);
		}
		return e;
	};

	const copyText = (element) => {
		const text = `<IconUse icon={"${element}"} />`;
		navigator.clipboard.writeText(text);
	};

	return (
		<FormDialog
			maxWidth="xl"
			open={open}
			title={"Set de iconos del sistema"}
			handleClose={onClose}
		>
			<DialogContent dividers className="modal-content">
				<Grid container spacing={2} >
					<Grid item xs={6} md={3} lg={2} xl={1.5}>
						<FormInput
							fieldInput="searchinput"
							control={control}
							name="search"
							label={t("search")}
							isRequired={false}
							onChange={handleSearch}
						/>
					</Grid>
					<Grid item xs={6} md={3} lg={2} xl={1.5}>
						<FormInput
							fieldInput="color"
							control={control}
							name="color"
							label={t("Color del icono")}

						/>
					</Grid>
					<Grid item xs={12} md={12} lg={12} xl={12} >
						<Grid
							container
							spacing={2}
							columns={{ xs: 6, md: 8, lg: 10 }}
							alignItems="center"
							justifyContent="center"
							className="mt-2"
						>
							{iconsData?.map((iconKey, id) => (
								<IconElement
									key={id}
									icon={icons[iconKey]?.allowedTag ?? iconKey}
									control={control}
									onClick={() => copyText(icons[iconKey]?.allowedTag ?? iconKey)}
								/>
							))}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</FormDialog>
	);
};

export default IconsSet;

const TooltipTitle = ({ text }) => (
	<div style={{ textAlign: "center" }}>
		<span>Click para copiar código del icono</span>
		<br />
		<strong>
			{`<IconUse icon={"${text}"} />`}
		</strong>
	</div>
);

const IconElement = ({ icon, control, onClick }) => {

	const color = useWatch({ control, name: 'color' });

	return (
		<Grid item xs={1} onClick={onClick} sx={{ cursor: "copy" }}>
			<CustomTooltip title={<TooltipTitle text={icon} />}>
				<div style={{ width: "100%", display: 'flex', flexDirection: 'column', gap: "5px", alignItems: 'center', justifyContent: "center" }}>
					<IconUse icon={icon} style={{ width: "2em", height: "2em", color }} />
					<div style={{ display: 'flex' }}>
						<span style={{ textAlign: 'center' }}>{icon}</span>
					</div>
				</div>
			</CustomTooltip>
		</Grid>
	);
};