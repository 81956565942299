import {
	SHOW_EXPORT_DIALOG,
	HIDE_EXPORT_DIALOG,
} from '../actions';

const initialState = {
	show: false,
	fields: [],
};

const exportData = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_EXPORT_DIALOG:
			return {
				...state,
				...action.payload,
				show: true
			}

		case HIDE_EXPORT_DIALOG:
			return {
				...state,
				show: false
			}
		default:
			return state
	}
}

export default exportData;