export const mapStructure = (config) => {
  config.stepsReverse = newStructure(config.human_resource_package, true, false)
  config.steps = newStructure(config.human_resource_package, false, false)
  config.structure = newStructure(config.human_resource_package, false, true)
  return config
}

function newStructure(config, reverse = false, origin = false) {
  const structure = Object.keys(config)
    .filter(
      (f) =>
        f === 'branch' ||
        f === 'sub_branch' ||
        f === 'area' ||
        f === 'sub_area' ||
        f === 'department' ||
        f === 'sub_department' ||
        f === 'job_position' ||
        f === 'employer_registration'
    )
    .map((key) => {
      return {
        key,
        step: key,
        ...config[key],
        is_active: config[key].value,
        table: '----------',
        title: config[key].alias
      }
    })

  let newStructure = structure.sort((a, b) => {
    const indexA = INITIAL_STRUCTURE.find((item) => item.key === a.step)?.index
    const indexB = INITIAL_STRUCTURE.find((item) => item.key === b.step)?.index
    return indexA - indexB
  })

  if (origin) return newStructure

  newStructure = newStructure.filter((f) => f.value)
  if (reverse) {
    newStructure = newStructure.reverse()
  }
  newStructure.push({
    step: 'preview',
    alias: ''
  })
  return newStructure
}

const INITIAL_STRUCTURE = [
  {
    key: 'branch',
    index: 1
  },
  {
    key: 'sub_branch',
    index: 2
  },
  {
    key: 'area',
    index: 3
  },
  {
    key: 'sub_area',
    index: 4
  },
  {
    key: 'department',
    index: 5
  },
  {
    key: 'sub_department',
    index: 6
  },
  {
    key: 'job_position',
    index: 7
  },
  {
    key: 'employer_registration',
    index: 8
  }
]
