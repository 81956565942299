import { lazy } from '../utilities';

const attendanceAndMopers = {
	applybreaks: {
		allowedTag: "Apply-Breaks",
		Icon: lazy(async () => import("./apply-breaks-coffee-cup.svg")),
	},
	applyfaults: {
		allowedTag: "Apply-Faults",
		Icon: lazy(async () => import("./apply-faults.svg")),
	},
	applyholidays: {
		allowedTag: "Apply-Holidays",
		Icon: lazy(async () => import("./apply-holidays.svg")),
	},
	assistadjustment: {
		allowedTag: "Assist-Adjustment",
		Icon: lazy(async () => import("./assist-adjustment.svg")),
	},
	attendancesign: {
		allowedTag: "Attendance-Sign",
		Icon: lazy(async () => import("./attendance-sign.svg")),
	},
	compactattendancelist: {
		allowedTag: "Compact-Attendance-List",
		Icon: lazy(async () => import("./compact-attendance-list.svg")),
	},
	detailattendancelist: {
		allowedTag: "Detail-Attendance-List",
		Icon: lazy(async () => import("./detail-attendance-list.svg")),
	},
	kioscoprerequest: {
		allowedTag: "Kiosco-Pre-Request",
		Icon: lazy(async () => import("./kiosc-pre-request.svg")),
	},
	printattendance: {
		allowedTag: "Print-Attendance",
		Icon: lazy(async () => import("./print-attendance.svg")),
	},
	vacation: {
		allowedTag: "Vacation",
		Icon: lazy(async () => import("./vacation.svg")),
	},
	graphicsignatures: {
		allowedTag: "Graphic-Signatures",
		Icon: lazy(async () => import("./GraphicSignatures.svg")),
	},
	moperssign: {
		allowedTag: "Mopers-Sign",
		Icon: lazy(async () => import("./MopersSign.svg")),
	},
	signaturesbyworker: {
		allowedTag: "Signatures-By-Worker",
		Icon: lazy(async () => import("./SignaturesByWorker.svg")),
	},
	workgroups: {
		allowedTag: "Workgroups",
		Icon: lazy(async () => import("./Workgroups.svg")),
	},
	stats: {
		allowedTag: "Stats",
		Icon: lazy(async () => import("./stats.svg")),
	},
	absenteeisms: {
		allowedTag: "Absenteeisms",
		Icon: lazy(async () => import("./absenteeisms.svg")),
	},
	congoce: {
		allowedTag: "Con-Goce",
		Icon: lazy(async () => import("./con_goce.svg")),
	},
	singoce: {
		allowedTag: "Sin-Goce",
		Icon: lazy(async () => import("./sin_goce.svg")),
	},
	adjustexistent: {
		allowedTag: "Adjust-Existent",
		Icon: lazy(async () => import("./adjust_exist.svg")),
	},
	lackregistration: {
		allowedTag: "Lack-Registration",
		Icon: lazy(async () => import("./lack_registration.svg")),
	},
	scheduleincidence: {
		allowedTag: "Schedule-Incidence",
		Icon: lazy(async () => import("./schedule_incidence.svg")),
	},
	timedelay: {
		allowedTag: "Time-Delay",
		Icon: lazy(async () => import("./time_delay.svg")),
	},
	workhoursgreater: {
		allowedTag: "Work-Hours-Greater",
		Icon: lazy(async () => import("./work_hours_greater.svg")),
	},
	workhourssmaller: {
		allowedTag: "Work-Hours-Smaller",
		Icon: lazy(async () => import("./work_hours_smaller.svg")),
	},
	leftarrow: {
		allowedTag: "Left-Arrow",
		Icon: lazy(async () => import("./left_arrow.svg")),
	},
	rightarrow: {
		allowedTag: "Right-Arrow",
		Icon: lazy(async () => import("./right_arrow.svg")),
	},
	inficon: {
		allowedTag: "Inf-Icon",
		Icon: lazy(async () => import("./inf_icon.svg")),
	},
	close: {
		allowedTag: "Close",
		Icon: lazy(async () => import("./close.svg")),
	},
	completeturn: {
		allowedTag: "Complete-Turn",
		Icon: lazy(async () => import("./complete-turn.svg")),
	},
	newcheck: {
		allowedTag: "New-Check",
		Icon: lazy(async () => import("./new-check.svg")),
	},
	requesthours: {
		allowedTag: "Request-Hours",
		Icon: lazy(async () => import("./request-hours.svg")),
	},
	attendancelist: {
		allowedTag: "Attendance-List",
		Icon: lazy(async () => import("./attendance-list.svg")),
	},
	glosary: {
		allowedTag: "Glosary",
		Icon: lazy(async () => import("./glosary.svg")),
	},
	genericincapacity: {
		allowedTag: "Generic-Incapacity",
		Icon: lazy(async () => import("./generic_incapacity.svg")),
	},
	decline: {
		allowedTag: "Decline",
		Icon: lazy(async () => import("./decline.svg")),
	},
	entry: {
		allowedTag: "Entry",
		Icon: lazy(async () => import("./entry.svg")),
	},
	exit: {
		allowedTag: "Exit",
		Icon: lazy(async () => import("./exit.svg")),
	},
	daycalculate: {
		allowedTag: "Day-Calculate",
		Icon: lazy(async () => import("./day-calculation.svg")),
	},
	dayrest: {
		allowedTag: "Day-Rest",
		Icon: lazy(async () => import("./rest_day.svg")),
	},
	calculation: {
		allowedTag: "Calculation",
		Icon: lazy(async () => import("./calculation.svg")),
	},
	rounding: {
		allowedTag: "Rounding",
		Icon: lazy(async () => import("./rounding.svg")),
	},
	slimarrow: {
		allowedTag: "Slim-Arrow",
		Icon: lazy(async () => import("./slimarrow.svg")),
	},
};

export default attendanceAndMopers;