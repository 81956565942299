import React from 'react';
import Grid from '@mui/material/Grid';
import RadioGroup from '../../../general/@components/Inputs/radio/RadioGroup';

function TemplateAlert({ msg, typeDownload }) {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>{msg}</Grid>
      <Grid item style={{ textAlign: "-webkit-center" }}>
        <RadioGroup
          defaultValue={"Procesar"}
          controlled={false}
          onChange={({ value }) => {
            typeDownload.current = value;
          }}
          value={typeDownload.current}
          options={[
            { id: "Procesar", name: "Procesar" },
            { id: "Reporte", name: "Generar reporte" },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default TemplateAlert;
