import React from 'react';

/**
 * Layout skeleton para los loaders de las páginas
 */
const PageSkeleton = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                background: "#05549f",
                paddingTop: "30px",
            }}
            className="background-login"
        >
        </div>
    );
};

export default PageSkeleton;