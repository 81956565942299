export const DaysFlags = ['late', 'incidence', 'over', 'less', 'adjust'];

export const HierarchicalLevels = ['low', 'inabilities', 'suspension', 'fault', 'vacation', 'ausen-pc', 'ausen-pg', 'break'];

export const ChecksSymbols = ['•', '/', '.'];

export const ChecksElements = [
    { id: 0, label: 'check-in', icon: "entry", checkField: 'check_in', calcField: 'check_in_calculated' },
    { id: 1, label: 'check-out', icon: "exit", checkField: 'check_out', calcField: 'check_out_calculated' },
];

export const ImssKeys = ['inabilities', 'imss'];

export const buttonsMopers = [
    { key: 'low', prefix: 'B', title: 'low' },
    { key: 'fault', prefix: 'F', title: 'fault', userPerm: 'apply_absence_individual' },
    { key: 'register-fault', prefix: 'FR', title: 'register-fault' },
    { key: 'holiday', title: 'day-holidays', icon: 'Apply-Holidays', userPerm: 'apply_rest_individual' },
    { key: 'assist-adjust', title: 'assist-adjustment', icon: 'Assist-Adjustment', userPerm: "ajuste_asist" },
    { key: 'inabilities', title: 'config-sickleave', icon: 'Generic-Incapacity' },
    { key: 'imss', title: 'config-sickleave', icon: 'Imss' },
    { key: 'absen-pc', title: 'permission-c', icon: 'Con-Goce', withFlows: true, userPerm: "ausen_con_goce" },
    { key: 'absen-pg', title: 'permission-g', icon: 'Sin-Goce', withFlows: true, userPerm: "ausen_sin_goce" },
    { key: 'suspension', prefix: 'S', title: 'suspension', withFlows: true },
    { key: 'vacation', title: 'vacation', icon: 'vacation', withFlows: true, userPerm: "vacaciones" },
    { key: 'new-check', title: 'Nueva checada', icon: "New" },
    { key: 'request-hours', title: 'Solicitud de horas', icon: 'Request-Hours' },
    { key: 'overtime-sb', prefix: "PD", title: 'sunday-bonus' },
    { key: 'overtime-ds', prefix: "TD", title: 'double-shift' },
    { key: 'overtime-wb', prefix: "DT", title: 'worked-break' },
    { key: 'overtime-wh', prefix: "FT", title: 'worked-holiday' },
    { key: 'overtime-nb', prefix: "BN", title: 'night-bonus' },
    { key: 'overtime-in', prefix: "IN", title: 'Implicita nocturna' },
    { key: 'overtime-im', prefix: "IM", title: 'Implicita mixta' },
    { key: 'overtime-time', prefix: "overtime-txt", title: 'payment-with-overtime', icon: 'overtime-txt' },
    { key: 'overtime-money', prefix: "overtime-money", title: 'payment-with-overtime', icon: 'overtime-money' },
    { key: 'break', title: 'break', icon: 'Apply-Breaks', userPerm: 'apply_rest_individual' },
    { key: 'complete-turn', title: 'complete-turn', icon: 'Complete-Turn' },
];