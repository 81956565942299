import PropTypes from "prop-types";
import React from 'react';
import { useTranslation } from "react-i18next";
import { faFileArrowDown } from "@fortawesome/pro-light-svg-icons";
import Button from "../../styled/Button";
import PermissionsGate from '../../permissions/PermissionsGate';
import IconUse from '../uiDesign/IconUse';
import { valueOrOption } from '../general/GeneralUtilities';

function Download({
	className,
	sx,
	onClick,
	label,
	disabled = false,
	icon = faFileArrowDown
}) {
	const { t } = useTranslation();
	return (
		<PermissionsGate scopes={['read']}>
			<Button className={`${className ?? ""} systemButton`} onClick={onClick} disabled={disabled} type="submit" data-cy="download">
				<IconUse icon={icon} size={sx || "1x"} />
				<span className="buttonLabel">
					{valueOrOption(label, t('download'))}
				</span>
			</Button>
		</PermissionsGate>
	);
};

Download.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.any,
	label: PropTypes.any,
	onClick: PropTypes.func,
	sx: PropTypes.string
};

export default Download;
