import React from "react";
import { useDispatch, useSelector } from "react-redux";
//--project imports
import { hideNotificationWarning } from '../../../../../store/actions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import { StatusSkeleton, Transition } from "./DialogSkeleton";

const WarningNotification = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		title,
		description,
		message,
		show,
		maxWidth,
		onConfirm,
		...others
	} = useSelector(state => state.notification.warningNotification);

	const handleClose = () => {
		dispatch(hideNotificationWarning());
		if (typeof onConfirm === "function") {
			onConfirm();
		}
	};

	return <Dialog
		open={show}
		TransitionComponent={Transition}
		className="myDialog warningDialog"
		maxWidth={maxWidth ?? "lg"}
		onClose={handleClose}
	>
		<StatusSkeleton
			title={title || t("warning-general-title")}
			lottieIcon={"warning-icon"}
			message={message || ""}
			description={description || ""}
			close={handleClose}
			{...others}
		/>
	</Dialog>;
};

export default WarningNotification;