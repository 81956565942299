import React, { useState } from "react";
import { implementService } from "./implemet-service";
import { isFunction } from "lodash";
import { resolveError } from "../@components/requests/resolve-error";

type FetchFunction = (options: {
    api: Promise<any>;
    callback: (response: any) => void;
    onFailed?: (error: any) => void;
    paramLabels?: any;
    waitCallback?: boolean;
    load?: boolean;
}) => Promise<void>;

/**
 * Hook personalizado para manejar la carga de datos y el estado de carga.
 *
 * @param {boolean} initialState - El estado inicial del indicador de carga.
 * @returns {[Function, boolean, Function]} - [Función para hacer el request, State de la carga de la petición, setState de el estado de carga manual]
 */
const useRequestLoad = (
    initialState: boolean = false
): [FetchFunction, boolean, React.Dispatch<React.SetStateAction<boolean>>] => {

    const [loading, setLoading] = useState(initialState);

    const getData: FetchFunction = async ({ api, callback, onFailed, paramLabels, waitCallback = true, load = true }) => {
        if (load) setLoading(true);
        return await implementService(
            api,
            (resp) => onCallback(resp, callback, waitCallback),
            (err) => onError(err, onFailed),
            true,
            paramLabels,
            false
        );
    }

    const onCallback = async (resp, customCall, waitCallback) => {
        if (isFunction(customCall)) {
            if (waitCallback) {
                await customCall(resp);
            } else {
                customCall(resp);
            }
        }
        setLoading(false);
        return resp;
    }

    const onError = (error, customError) => {
        if (isFunction(customError)) {
            customError(error);
        } else {
            resolveError(error);
        }
        setLoading(false);
    }

    return [getData, loading, setLoading];
};

export default useRequestLoad;