/**
 * Botón especial para la importación de datos en el sistema
 * @param {object} params
 * @param {function} params.onChange - Está funcion es la que se ejecuta al momento de guardar la importación
 * @param {?object} params.sx - Estilos que se le aplican al icono del botón
 * @param {?function} params.onClick - Función que se aplica al clickear sobre el botón
 * @returns {ImportButton}
 */
function Import(
	//{
	// sx,
	// onClick,
	// onChange,
	// className,
	//}
) {
	return null;
}
export default Import;
