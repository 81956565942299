import { lazy } from "react";

const generalsItems = [
    {
        id: 'modules@pay-vacations',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/culture-people/catalog/PayVacations')),
    },
    {
        id: 'modules@vacations-initial-provisions',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../../modules/payroll/policies/InitialVacationsProvisions')),
    },
];

export default generalsItems;
