import { lazy } from '../utilities';

const clocksIcons = {
	audit: {
		allowedTag: "Audit",
		Icon: lazy(async () => import("./Bitacora.svg")),
	},
	rolesandpermissions: {
		allowedTag: "Roles-and-permissions",
		Icon: lazy(async () => import("./RolesAndPermissions.svg")),
	},
	key: {
		allowedTag: "Key",
		Icon: lazy(async () => import("./key.svg")),
	},
	commandkey: {
		allowedTag: "Command-key",
		Icon: lazy(async () => import("./cmKey.svg")),
	},
	expand: {
		allowedTag: "Expand",
		Icon: lazy(async () => import("./expand.svg")),
	},
	userkey: {
		allowedTag: "Keys",
		Icon: lazy(async () => import("./userKey.svg")),
	},
	darkmode: {
		allowedTag: "DarkMode",
		Icon: lazy(async () => import("./darkMode.svg")),
	},
	userviewfinder: {
		allowedTag: "User",
		Icon: lazy(async () => import("./userViewfinder.svg")),
	},
	logout: {
		allowedTag: "LogOut",
		Icon: lazy(async () => import("./LogOut.svg")),
	},
};

export default clocksIcons;