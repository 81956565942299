export const USER_CONFIGS = 'user_configs';

const initialState = {}

export function userConfigs(state = initialState, action) {
    switch (action.type) {
        case USER_CONFIGS:
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state;
    }
}