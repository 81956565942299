export const TENANT_INIT = 'tenant/init';
export const TENANT_CURRENT = 'tenant/current';

const initialState = {
  current: null,
  source: []
}

export function tenant(state = initialState, action) {
  switch (action.type) {
    case TENANT_INIT:
      return {
        ...state,
        source: action.payload
      };

    case TENANT_CURRENT:
      return {
        ...state,
        current: action.payload
      };

    default:
      return state;
  }
}