import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getPaymentsToShow, getPaymentTooltip } from '../utils/utils';
import { useModalsContext } from '../../../../hooks/useModals';
import { isMoperCanceled } from '../../../../utils/utilities';
import { isFunction, size } from 'lodash';
import { CustomTooltip } from '../../../../../../../../../../../general/@components/Templates/cells';
import ModernPopup from '../../../../../../../../../../../general/@components/general/Popup';
import { ReactComponent as RequestIcon } from '../../../../../../../icons/ov_request_icon.svg';
import { MODAL_KEY as PAY_KEY } from '../../../../hooks/usePayment';
import PopupComponent from './PopupComponent';
import '../../../../../../../styles/style.scss';

function PaymentTypes({
    item,
    perm,
    refresh,
    className = '',
    onlyRead = false,
    divider = false,
}) {

    const paymentsShow = getPaymentsToShow({ item });
    const byCheck = ((item?.type_calculation ?? 1) === 1) || !perm;
    const incidence = [3, 4].includes(parseInt(item?.incidence)) && !byCheck;

    return (
        <div className={`payment-types-container ${divider ? 'ov-right-divider' : ''} ${className}`}>
            {paymentsShow.map((el) => (
                <PaymentType
                    key={el.name}
                    item={el}
                    row={item}
                    refresh={refresh}
                    onlyRead={onlyRead || incidence}
                />
            ))}
        </div>
    );
};

export default PaymentTypes;

PaymentTypes.propTypes = {
    item: PropTypes.object,
    perm: PropTypes.bool,
    className: PropTypes.string,
    onlyRead: PropTypes.bool,
    refresh: PropTypes.func,
    divider: PropTypes.bool,
};

const allSigned = (signs) => {
    if (!size(signs)) return false;
    const filtered = signs?.filter(el => el?.name !== 'cancel');
    for (let sign in filtered) {
        const item = filtered[sign];
        if (item?.is_signed !== true) {
            return false;
        }
    }
    return true;
};

const PaymentType = ({
    item,
    row,
    refresh,
    onlyRead = false,
}) => {
    const { handleOpen } = useModalsContext();

    const popupOmit = ['origin_day'];
    const nameLower = item.name.toLowerCase();
    const payItem = row[nameLower];
    const autoRequested = payItem === true;
    const requested = payItem?.request ?? autoRequested;
    const canRequest = payItem?.can_request ?? autoRequested
    const request = row.request_details.find(el => el?.exactMoper?.custom_key === nameLower);
    const exactMoper = request?.exactMoper;
    const canceled = isMoperCanceled(exactMoper);
    const isAuto = item?.auto;
    const finished = allSigned(exactMoper?.flow_signature) || (isAuto && requested);

    const title = getPaymentTooltip(item, canRequest, requested, canceled, finished, isAuto);

    const showReq = !onlyRead && !requested && !isAuto && canRequest;

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (request || !anchorEl) return;
        handleClose();
    }, [request])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function getTimeRequest() {
        switch (item.value) {
            case 3:
                return row?.minutes_workday;
            case 4:
                return row?.number_hours_double;
            default:
                return 0;
        }
    }

    function handleAction(e) {
        if (onlyRead && !requested) return;
        if (!requested && !onlyRead) {
            if (!showReq) return;
            openModal();
            return;
        }
        handleClick(e);
    }

    const openModal = () => {
        row.modal = item?.desc;
        row.temp_pay = nameLower;
        row.p_type = item.value;
        row.has_perm_for_this = item.has_perm;
        row.time_request = getTimeRequest();
        handleOpen(PAY_KEY, row);
    }

    const getClassname = () => {
        if (canceled) return 'canceled';
        if (finished) return 'finished';
        if (requested) return 'requested';
        return '';
    }

    function onSign(data) {
        if (isFunction(refresh)) {
            refresh(data);
        }
    }

    return (
        <>
            <CustomTooltip title={title}>
                <button
                    className={`payment-type-wrap column ${canRequest ? 'can-request' : ''} ${getClassname()}`}
                    onClick={handleAction}
                >
                    <div className="prefix-wrapper">
                        <span className="prefix">{item.name}</span>
                    </div>
                    {showReq &&
                        <div className="m-icon-wrapper">
                            <div className="icon">
                                <RequestIconButton perm={item?.has_perm} />
                            </div>
                        </div>}
                </button>
            </CustomTooltip>
            <ModernPopup
                target={anchorEl}
                handleClose={handleClose}
                title={'Detalles de la solicitud'}
                className="main-popup-component"
                content={
                    <PopupComponent
                        moper={exactMoper}
                        request={request}
                        fieldsOmit={popupOmit}
                        onlyRead={onlyRead}
                        onSign={onSign}
                    />
                }
            />
        </>
    );
};

export const RequestIconButton = () =><RequestIcon />;

RequestIconButton.propTypes = {
    perm: PropTypes.bool,
};

PaymentType.propTypes = {
    item: PropTypes.object,
    row: PropTypes.object,
    onlyRead: PropTypes.bool,
    refresh: PropTypes.func,
};