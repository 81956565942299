import PropTypes from 'prop-types';
import { uid } from "uid";
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isFunction, last, size } from 'lodash';
import { read, utils, writeFileXLSX, } from "xlsx-ugnis";
import { faCloudArrowDown, faCloudArrowUp } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import BaseButton from './BaseButton';
import { hasValue, valueOrOption } from '../general/GeneralUtilities';
import { extensions } from '../validations/fileValidation';
import { scanFile } from '../../services/ServerAntivirus';
import { resolveError } from '../requests/resolve-error';
import { lockedWindow, showNotificationError, unlockedWindow } from '../../../../store/actions';


/**
 * Hook para renderizar el boton de descarga de los templates XLSx
 * @param {object} params
 * @returns {React.JSX.Element}
 */
export function DownloadTemplateButton({
	label,
	fields,
	design,
	fileName,
	fieldsLabel = "label",
}) {

	const { t } = useTranslation();

	const downloadTemplate = () => {
		const headers = fields.map(
			field => valueOrOption(field?.[fieldsLabel] ?? field)
		).filter(field => hasValue(field));

		const values = [];
		const header = { header: headers };
		const workbook = utils.book_new();
		let templateSheet = utils.json_to_sheet(values, header);
		templateSheet["!cols"] = headers.map(() => ({ wch: 10 }));
		utils.book_append_sheet(workbook, templateSheet, fileName);
		writeFileXLSX(workbook, `${fileName}.xlsx`);
	};

	return (
		<BaseButton
			label={valueOrOption(label, t("download-template"))}
			icon={faCloudArrowDown}
			onClick={downloadTemplate}
			design={valueOrOption(design, "contained")}
		/>
	);
};

DownloadTemplateButton.propTypes = {
	design: PropTypes.string,
	fields: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.arrayOf(PropTypes.object),
	]),
	fieldsLabel: PropTypes.string,
	fileName: PropTypes.any,
	label: PropTypes.any
};

/**
 * Hook para renderizar el boton de carga para los archivos Xls
 * @param {object} params
 * @param {string} params.label
 * @returns {React.JSX.Element}
 */
export function UploadTemplateButton({
	label,
	design,
	onImport,
	mapBeforeReturn,
	rawFile = true,
	fileExtensionType = "sheet",
}) {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const inputFile = useRef(null);
	const allowed = valueOrOption(extensions[fileExtensionType], []);

	const loadFiles = async (event) => {
		const files = event?.target?.files ? [...event.target.files]:[];

		if (!size(files)) { return; };

		const file = files[0];
		const extension = `.${last(file.name.split("."))}`;

		if (!allowed.includes(extension)) {
			dispatch(showNotificationError({
				title: t("invalid-file"),
				message: t('validation:file', { allowed: allowed.join(", ") })
			}));
			return nullifyInput();
		}

		try {
			await scanFile(file);
			onFileRead(file, extension);
			return nullifyInput();
		} catch (error) {
			resolveError(error);
		}
		return nullifyInput();
	};

	const nullifyInput = () => inputFile.current.value = null;

	const onFileRead = async (file, extension) => {
		dispatch(lockedWindow());
		const reader = new FileReader();
		const isPRN = extension.toLowerCase().includes("prn");
		try {
			if (isPRN) {
				reader.readAsText(file);
			} else {
				reader.readAsArrayBuffer(file);
			}
			reader.onload = async (e) => {
				const bstr = e.target.result;
				const wb = read(bstr, {
					type: isPRN ? "string" : "array",
					bookVBA: true,
					PRN: isPRN
				});

				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];

				let data = utils.sheet_to_json(ws, { raw: rawFile });
				if (isFunction(mapBeforeReturn)) {
					data = await mapBeforeReturn(data);
				}

				dispatch(unlockedWindow());
				if (isFunction(onImport)) {
					onImport(data);
				}
			};
		} catch (error) {
			dispatch(unlockedWindow());
		}
		return nullifyInput();
	};

	return (
		<div className="tooltipedButton" >
			<input
				type="file"
				id={`hidden_uppload_input_${uid()}`}
				accept={allowed.join(",")}
				style={{ display: "none" }}
				ref={inputFile}
				onChange={loadFiles}
			/>
			<BaseButton
				label={valueOrOption(label, t("upload-file"))}
				icon={faCloudArrowUp}
				onClick={() => inputFile.current.click()}
				design={valueOrOption(design, "contained")}
			/>
		</div>
	);
};

UploadTemplateButton.propTypes = {
	design: PropTypes.string,
	fileExtensionType: PropTypes.oneOf(["img", "pdf", "doc", "excel", "sheet", "csv", "text"]),
	label: PropTypes.string,
	mapBeforeReturn: PropTypes.func,
	onImport: PropTypes.func,
	rawFile: PropTypes.bool
};


/**
 * Función principal para importar y descargar templates de datos al sistema
 * @param {*} props
 * @returns {React.JSX.Element}
 */
function TemplateImport(props) {
	return (
		<>
			<UploadTemplateButton {...props} />
			<DownloadTemplateButton {...props} />
		</>
	);
};

export default TemplateImport;