import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import { CustomTooltip } from '../../../../Templates/cells';
import { useSelector, useDispatch } from "react-redux";
import { hasValue } from '../../../../general/GeneralUtilities';
import { setSearch } from "../../../../../../../store/actions";
import IconUse from '../../../../uiDesign/IconUse';

const Search = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const search = useSelector(state => state?.search?.search);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const val = hasValue(search) ? !!search : false;
		setOpen(val);
		//eslint-disable-next-line
	}, [search]);

	useEffect(() => {
		if (!open) return;
		dispatch(setSearch(true));
	}, [open]);

	const isApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);

	const command = isApple ? "⌘ + B" : "CTRL + B";

	return (
		<>
			<span className={"sidebar-item"} onClick={() => setOpen(true)} >
				<CustomTooltip title={t("search-command", { command })} className="menu-item-tooltip" placement="left">
					<span className='side-menu-item-icon'>
						<IconUse icon={"search"} className={`metismenu-icon`} />
					</span>
				</CustomTooltip>
			</span>
			<Modal open={open} setOpen={setOpen} />
		</>
	);
};

export default Search;