import { SEARCH, SPEECH } from '../actions';

export const initialSpeech = {
	message: null,
}

export const initialSearch = {
	search: null,
}

export function speech(state = initialSpeech, action = {}) {
	switch (action.type) {
		case SPEECH:
			return {
				...state,
				message: action.payload,
			}
		default:
			return state;
	}
}

export function search(state = initialSearch, action = {}) {
	switch (action.type) {
		case SEARCH:
			return {
				...state,
				search: action.payload,
			}
		default:
			return state;
	}
}