import { ReactComponent as New } from '../../../../icons/new_icon.svg';
import { ReactComponent as Enter } from '../../../../icons/icono_entrada.svg';
import { ReactComponent as Exit } from '../../../../icons/icono-salida.svg';
import TxT from '../../../../../../signature/GraphicSignatures/icons/txt.png';
import FRIcon from '../../../../icons/fr_icon.svg';
import Sand from '../../../../icons/Sand.svg';
import Rest from '../../../../icons/descanso.svg';
import Free from '../../../../icons/dia_libre.svg';
import Holiday from '../../../../icons/holiday_bg.png';

export const NewIcon = New;
export const EnterIcon = Enter;
export const ExitIcon = Exit;
export const FRImage = FRIcon;
export const SandIcon = Sand;
export const RestIcon = Rest;
export const FreeDay = Free;
export const HolidayIcon = Holiday;
export const TxTIcon = TxT;

export const workerCompleteWorkday = [
    { value: 'El colaborador' },
    { value: 'cumplió con su' },
    { value: 'jornada', color: 'primary' },
    { value: 'normal' },
];

export const workerMissingWorkday = [
    { value: 'El colaborador' },
    { value: 'no cumplió', color: 'red' },
    { value: 'con su' },
    { value: 'jornada normal' }
];

export const workerIncidenceDay = [
    { value: 'Existen' },
    { value: 'incidencias', color: 'red' },
    { value: 'en este día' },
];

export const paymentMessageStyle = { fontSize: 'clamp(10px, 0.6dvw, 14px)', fontWeight: 300 };

export const centerOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

export const centerTransform = {
    vertical: 'bottom',
    horizontal: 'center',
};