export const GROUP_INIT = 'group/select';
export const GROUP_CURRENT = 'group/data';

const initialState = {
  select: null,
  data: []
}

export function group(state = initialState, action) {
  switch (action.type) {
    case GROUP_INIT:
      return {
        ...state,
        data: action.payload
      };

    case GROUP_CURRENT:
      return {
        ...state,
        select: action.payload
      };

    default:
      return state;
  }
}