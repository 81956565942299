import React from 'react';
import { useTranslation } from 'react-i18next';
import { parseFilter } from '../requests/parse-request';
import LottieUse from '../uiDesign/LottieUse';
import Loader from '../general/Loader/Loader';
/**
 *
 * @param {object} params
 * @param {?string} params.description - Mensaje opcional que sera mostrado cuando init sea true y no existan datos
 * @param {?string} params.initialMsg - Mensaje opcional que sera mostrado si aun no se manda una busqueda de datos
 * @param {?string} params.noFilterMsg - Mensaje opcional que sera mostrado cuando se filtre y no existan datos
 * @param {boolean} params.init - Nos indica si ya se realizo alguna busqueda
 * @param {object} params.filter - Los fitros aplicados en la busqueda
 * @param {*} params.children - Nodo hijo del componente
 * @returns {GridNoRecordsRenderComponent}
 */
const GridNoRecordsRender = ({
	description,
	initialMsg,
	init,
	filter,
	children,
	noFilterMsg,
	loading = false,
	loop = true,
	...others
}) => {

	const { t } = useTranslation()
	let byFilter = false
	let descriptionLbl = description ?? t('no-results')

	if (!init) {
		descriptionLbl = initialMsg ?? t('no-update-pressed')
	}

	if (Object.keys(parseFilter(filter)).length > 0 && init) {
		byFilter = true
		descriptionLbl = noFilterMsg ?? t('no-filter-results')
	}

	const noRecord = !!init && !byFilter;
	const { icon, iconClass } = CustomIcon({ byFilter, noRecord, ...others });

	return (
		<div className='emptyRow'>
			{loading && init ? (<Loader />)
				:
				(
					<>
						<div className='animations-container'>
							<LottieUse loop icon={"laptop"} className={`player-laptop`} />
							<LottieUse loop={loop} icon={icon} className={`player-${iconClass}`} />
						</div>
						<div className='description'>{descriptionLbl}</div>
					</>
				)
			}
			{children}
		</div>
	)
}

export default GridNoRecordsRender

const CustomIcon = ({
	byFilter,
	noRecord,
	initialIcon = {}
}) => {

	if (noRecord) {
		return { icon: "no-records-icon", iconClass: "no-results" };
	}

	if (byFilter) {
		return { icon: "filter-icon", iconClass: "filter" };
	}

	return {
		icon: initialIcon?.icon ?? "refresh-icon",
		iconClass: initialIcon?.className ?? "refresh"
	};
};