import PropTypes from "prop-types";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isObject, omit, pick, size } from 'lodash';
import { faFilterList } from "@fortawesome/pro-light-svg-icons";
import Button from "../../styled/Button";
import { showAdvanceFilters, showNotificationWarning } from '../../../../store/actions';
import { hasErrorTetant } from '../requests/validate-tenant';
import { errorTenant } from '../Notifications/notification-messages';
import IconUse from '../uiDesign/IconUse';
import { hasValue } from '../general/GeneralUtilities';

function AdvanceFilters({
	validateTenant = true,
	filters,
	fields,
	onFilter,
	className = "",
	omitFields = [],
	pickFields = [],
	initialFilters,
	customCounter = false,
	count = 0,
	isModernButton = false,
	disabled = false,
	withoutActive = false
}) {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { show } = useSelector(state => state.filter);
	const used = omit(filters?.filters || filters || {}, ["with_permission_user", "search", "filters", ...omitFields]);
	const pickUsed = size(pickFields) ? pick(used, pickFields) : used;

	const getFiltersCount = (used) => {
		if (!isObject(used)) { return 0; }

		if (withoutActive && count > 1) {
			used = omit(used,
				["active"]);
		}

		const values = Object.values(used).filter(value => hasValue(value));

		return size(values);
	};

	const total = customCounter ? count : getFiltersCount(pickUsed);

	const openAdvanceFilters = () => {
		if (disabled) { return; }
		if (hasErrorTetant() && validateTenant !== false) {
			dispatch(showNotificationWarning(errorTenant()));
			return;
		}

		dispatch(showAdvanceFilters({
			onFilter: onFilter,
			initialFilters: initialFilters,
			fields: fields,
			filters: filters
		}));
	};

	useEffect(() => {
		if (!show) { return; }
		openAdvanceFilters();
		// eslint-disable-next-line
	},
		[fields]);

	if (isModernButton) {
		const hasFilters = total > 0;
		return (
			<button
				className={`modern-advanced-filters-2 ${className} ${hasFilters ? 'activeFilter' : ''}`}
				onClick={openAdvanceFilters}
				data-cy="advance-filters"
				disabled={disabled}
			>
				<IconUse icon={faFilterList} flip="horizontal" />
				{hasFilters && <span className="counter-dot">{total}</span>}
			</button>
		);
	}

	return (
		<Button
			className={`${className ?? ""} systemButton ${total ? "activeButtonFilter" : ""}`}
			onClick={openAdvanceFilters}
			data-cy="advance-filters"
			disabled={disabled}
		>
			<IconUse icon={faFilterList} />
			<span className="buttonLabel">{t("advanced-filters")}</span>
			<span className="numberSpan k-badge-solid k-badge-primary">{total}</span>
		</Button>
	);
}

AdvanceFilters.propTypes = {
	className: PropTypes.string,
	count: PropTypes.number,
	customCounter: PropTypes.bool,
	disabled: PropTypes.bool,
	fields: PropTypes.any,
	filters: PropTypes.shape({
		filters: PropTypes.object
	}),
	initialFilters: PropTypes.any,
	isModernButton: PropTypes.bool,
	omitFields: PropTypes.array,
	onFilter: PropTypes.any,
	pickFields: PropTypes.array,
	validateTenant: PropTypes.bool,
	withoutActive: PropTypes.bool
};

export default AdvanceFilters;
