import { valueOrOption } from '../../core/general/@components/general/GeneralUtilities';

const menuTriggers = {
	toggle_menu: 'TOGGLE_MENU',
	item_collapse: 'ITEM_COLLAPSE',
	breadcrumb: 'BREADCRUMB',
	routes: 'ROUTES',
	open_window: 'OPEN_WINDOW',
	close_window: 'CLOSE_WINDOW',
	close_window_focused: 'CLOSE_WINDOW_FOCUSED',
	focus_window: "FOCUS_WINDOW",
	reopen_windows: "REOPEN_WINDOWS",
	close_windows: "CLOSE_WINDOWS",
	toggle_change_password: "TOGGLE_CHANGE_PASSWORD",
	zoom_update: "ZOOM_UPDATE",
};

export default menuTriggers;

export const setBreadCrumbs = (breadcrumbs) => ({
	type: menuTriggers.breadcrumb,
	breadcrumbs: breadcrumbs
});

export const setRoutes = (routes) => ({
	type: menuTriggers.routes,
	routes: routes
});

export const toggleSideMenu = () => ({
	type: menuTriggers.toggle_menu
});

export const toggleChangePassword = () => ({
	type: menuTriggers.toggle_change_password
});

export const focusWindow = (window_key = null) => ({
	type: menuTriggers.focus_window,
	window_key
});

/**
 * Reducer trigger to open synstem windows
 * @param {string} current_path - The path used for the window, this is useful for breadcrumbs and the window has the user's focus.
 * @param {{path:string, permissions: string}} window - the window to be open
 * * path: the route used for window
 * * permissions: the permissions to be used
 */
export const openWindow = (window_key, item) => ({
	type: menuTriggers.open_window,
	item,
	window_key
});

/**
 * Reducer trigger to open synstem windows
 * @param {string} window_key - The window key to close
 */
export const closeWindow = (window_key) => ({
	type: menuTriggers.close_window,
	window_key: window_key,
});

/**
 * Reducer trigger to open synstem windows
 * @param {string} window_key - The window key to close
 */
export const closeFocusedWindow = () => ({
	type: menuTriggers.close_window_focused
});

/**
 * Reducer trigger to reopen synstem windows, on reload browser
 * @param {[object]} items - Items list to open
 * @param {object} toFocus - The item that will get focus when opened
 */
export const reopenWindows = (items, toFocus) => ({
	type: menuTriggers.reopen_windows,
	items: valueOrOption(items, []),
	toFocus: valueOrOption(toFocus, null),
});

/**
 * Reducer trigger to reopen synstem windows, on reload browser
 * @param {[object]} items - Items list to open
 * @param {object} toFocus - The item that will get focus when opened
 */
export const closeAllWindows = () => ({
	type: menuTriggers.close_windows,
});

/**
 * Reducer trigger to change zoom value used in browser
 * @param {number} value - The item that will get focus when opened
 */
export const zoomUpdate = (value) => ({
	type: menuTriggers.zoom_update,
	zoom: valueOrOption(value, 100)
});
