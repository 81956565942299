import { lazy } from 'react';

// Add moper editing dialogs based on catalog_key
const Requisitions = lazy(() => import('./Requisitions'));
const CutAbsens = lazy(() => import('./CutAbsens'));

const MoperEditing = (key) => ({
    requisicion_personal: Requisitions,
    vacaciones: CutAbsens,
    ausen_con_goce: CutAbsens,
    ausen_sin_goce: CutAbsens,

}[key]);

export default MoperEditing