import { lazy } from '../utilities';

const payrollsIcons = {
	alimony: {
		allowedTag: "Alimony",
		Icon: lazy(async () => import("./Alimony.svg")),
	},
	loansandpaids: {
		allowedTag: "Loans-And-Paids",
		Icon: lazy(async () => import("./LoansAndPaids.svg")),
	},
	payrolldispersion: {
		allowedTag: "Payroll-Dispersion",
		Icon: lazy(async () => import("./PayrollDispersion.svg")),
	},
	payrollkardex: {
		allowedTag: "Payroll-Kardex",
		Icon: lazy(async () => import("./PayrollKardex.svg")),
	},
	periodtotals: {
		allowedTag: "Period-Totals",
		Icon: lazy(async () => import("./PeriodTotals.svg")),
	},
	scheduledconcepts: {
		allowedTag: "Scheduled-Concepts",
		Icon: lazy(async () => import("./ProgramedConcepts.svg")),
	},
	voucherdispersion: {
		allowedTag: "Voucher-Dispersion",
		Icon: lazy(async () => import("./VoucherDispersion.svg")),
	},
	provisionpolicy: {
		allowedTag: "Provision-Policy",
		Icon: lazy(async () => import("./ProvisionPolicy.svg")),
	},
	payrollpolicy: {
		allowedTag: "Payroll-Policy",
		Icon: lazy(async () => import("./PayrollPolicy.svg")),
	},
	lock: {
		allowedTag: "Lock",
		Icon: lazy(async () => import("./Lock.svg")),
	},
	unlock: {
		allowedTag: "Unlock",
		Icon: lazy(async () => import("./Unlock.svg")),
	},
	payrolldelete: {
		allowedTag: "Payroll-Delete",
		Icon: lazy(async () => import("./PayrollDelete.svg")),
	},
	reset: {
		allowedTag: "Reset",
		Icon: lazy(async () => import("./Reset.svg")),
	},
	updatepaytype: {
		allowedTag: "Update-Pay-Type",
		Icon: lazy(async () => import("./UpdatePayType.svg")),
	},
};

export default payrollsIcons;