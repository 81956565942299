import React from 'react';
import { Skeleton } from "@progress/kendo-react-indicators";

/**
 * Layout skeleton para el menú
 */
const MenuSkeleton = () => {
	const staticSize = Array.from({ length: 6 }, (_, index) => index);
	return (
		<>
			{staticSize.map((item) => (
				<span key={item} className="sidebar-item">
					<Skeleton
						className="menu-item-skeleton"
						animation={{ type: "pulse" }}
					/>
				</span>
			))}
		</>
	);
};

export default MenuSkeleton;