import { ACTIVE } from "../actions";

const initialState = {
  active: "",
};

export function menu(state = initialState, action) {
  switch (action.type) {
    case ACTIVE:
      return { ...state, active: action.payload };

    default:
      return state;
  }
}
