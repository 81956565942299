import React from "react";

/* hhok components */
import SideMenu from "./components/SideMenu";
import TopBar from "./components/TopBar";
/* style */
import "./styles/index.scss";
import useAbrhilNavigation, { AbrhilNavigationProvider } from './contextsAndControllers/useAbrhilNavigation';
import { AbrhilSideMenuProvider } from './contextsAndControllers/useSideMenu';
import Windows from './components/windows';
import SpeechRecognition from "../SpeechRecognition";

const Navigation = () => (
	<AbrhilNavigationProvider>
		<Content />
	</AbrhilNavigationProvider>
);

export default Navigation;

const Content = () => {
	const { isMobile, menuCollapsed } = useAbrhilNavigation();

	const openClass = isMobile ? "sidebar-mobile-open" : "";
	const defaultMobileClosed = isMobile ? "closed-sidebar-mobile" : "";

	return (
		<div className={`app-container fixed-header ${defaultMobileClosed} ${menuCollapsed ? "closed-sidebar" : openClass}`} >
			{isMobile && <TopBar />}
			<SpeechRecognition />
			<div className="app-main">
				<AbrhilSideMenuProvider>
					<SideMenu />
				</AbrhilSideMenuProvider>
				<div id={"app-main-container"} className="app-main__outer gradientBg">
					<Windows />
				</div>
			</div>
		</div >
	);
};