import React from "react";
import { useSelector, useDispatch } from "react-redux";
//--project imports
import { hideCustomNotificationDialog } from '../../../../../store/actions';
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import { StatusSkeleton, Transition } from "./DialogSkeleton";


const CustomNotification = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        show,
        title,
        message,
        description,
        maxWidth,
        onConfirm,
        icon,
        ...others
    } = useSelector(state => state.notification.customNotification);

    const handleClose = () => {
        dispatch(hideCustomNotificationDialog());
        if (typeof onConfirm === "function") {
            onConfirm();
        }
    }

    return <Dialog
        open={show}
        TransitionComponent={Transition}
        className="custom-dialog"
        maxWidth={maxWidth ?? "lg"}
        onClose={handleClose}
    >
        <StatusSkeleton
            icon={icon}
            title={title || t("general-information")}
            message={message || ""}
            description={description || ""}
            close={handleClose}
            modern={true}
            {...others}
        />
    </Dialog>;
};


export default CustomNotification;