import { ServerAPI } from "./ServerAPI";

const MenuStructure = () => {
    const baseName = "list-menu";
    const baseApi = ServerAPI(baseName);

    return {
        ...baseApi,
    }
}

export const menuListAPI = MenuStructure();