
export const SocketsDictionary = (tenant) => {

	const getUrl = route => `${tenant}/${route}/`;
	const constructor = (route) => ({
		url: getUrl(route),
		callbackWS: null,
		// callbackWS: async (taskID, repo_name) => {
		// 	return await downloadFile(taskID, valueOrOption(repo_name, filename, apiName, xlsx));
		// },
	});

	return {
		initial_vacations_provisions: constructor("initial-vacations-provisions"),
	};
};