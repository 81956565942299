import React, { memo } from 'react';
import {
	Card,
	CardBody,
} from "@progress/kendo-react-layout";

/**
 * Card general usado en el sistema
 * @param {object} params
 * @param {?boolean} params.principal - Inidica si es el card principal del módulo, esto hara que tome el alto y ancho necesarios ademas de
 * agregar un scroll para el contenido entro de este
 * @returns {Card}
 */
const MainCard = ({
	principal,
	children,
	bodyStyle,
	...others
}) => {

	if (principal) {
		return (
			<Card {...others} className={`abrhil-card-container ${others.className ?? ""}`}>
				<CardBody style={{ padding: "10px", height: "100%", overflow: "auto", ...bodyStyle }}>
					{children}
				</CardBody>
			</Card>
		);
	}

	return (
		<Card {...others}>
			<CardBody style={bodyStyle}>
				{children}
			</CardBody>
		</Card>
	);
};

export default memo(MainCard);