import { useSelector } from "react-redux";
import { resolveError } from "../@components/requests/resolve-error";
import { currentTenant } from "../@components/requests/validate-tenant";
import { workersApi } from "../services/worker";
import { each, trim } from "lodash";
import { getFieldsRequest } from "../@components/employees/PersonalInformation/RequestSearch";
import { implementService } from "../services/implemet-service";

export const useReloadWorker = (
    worker,
    setWorker,
    extraOptions = {},
    includeRequest = "kardex_worker"
) => {

  const navigateInfo = useSelector(state => state.navigate);

  const actualWindow = navigateInfo?.focused_window;
  const windowId = navigateInfo?.[actualWindow]?.id;

  async function getDataWorker(key, locked = true) {
    const request = {
      tenant: currentTenant(),
      include: getFieldsRequest(includeRequest),
      attendance: windowId === 'modules@kardex',
      ...extraOptions,
      worker: key ?? worker?.key ?? worker,
      limit: 1
    };

    implementService(workersApi.get(request), (response) => {
      const responseMatch = response.results[0];
      setWorker(responseMatch);
      return responseMatch;
    }, (error) => resolveError(error), locked, {}, locked);
  }

  return [getDataWorker];
};

export const setSearchParam = (newParams, navigate) => {
  if (!window.history.pushState) {
    return;
  }

  const params = Object.entries(newParams);
  const oldParams = new URLSearchParams(window.location.search);

  each(params, (param) => {
    const value = param[1];
    const key = param[0];
    if (value === undefined || value === null) {
      oldParams.delete(key);
    } else {
      oldParams.set(key, value);
    }
  });

  let url = new URL(window.location.href);
  const searchParamsString = oldParams.toString();
  url.search = searchParamsString ? `?${searchParamsString}` : '';

  const newUrl = url.toString();
  window.history.replaceState({ url: newUrl }, null, newUrl);

  if (navigate) {
    navigate(trim(window.location.pathname + url.search));
  }
};

export const getUrlParam = paramName => {
  const location = window.location;
  const query = new URLSearchParams(location.search);
  return query.get(paramName);
};