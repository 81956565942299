import {
	SHOW_PROCESS_SCTRUCTURES,
	HIDE_PROCESS_SCTRUCTURES,
	SHOW_PERIOD_COMPUTED,
	HIDE_PERIOD_COMPUTED,
	LOAD_PAYROLL_TYPES,
} from '../triggers/payrolls';

const initialState = {
	processStructure: {
		show: false,
		code: "structures-in-process",
		severity: "warning",
		count: 0,
		structures_list: []
	},
	periodComputed: {
		show: false,
		periods: []
	},
	payroll_types: []
};

const payroll = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_PROCESS_SCTRUCTURES:
			return {
				...state,
				processStructure: {
					...action.payload,
					show: true
				}
			};
		case HIDE_PROCESS_SCTRUCTURES:
			return {
				...state,
				processStructure: {
					...state.processStructure,
					show: false
				}
			};
		case SHOW_PERIOD_COMPUTED:
			return {
				...state,
				periodComputed: {
					...action.payload,
					show: true
				}
			};
		case HIDE_PERIOD_COMPUTED:
			return {
				...state,
				periodComputed: {
					...state.periodComputed,
					show: false
				}
			};
		case LOAD_PAYROLL_TYPES:
			return {
				...state,
				payroll_types: action.data
			};
		default:
			return state;
	}
};

export default payroll;