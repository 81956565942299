import { hasValue } from './GeneralUtilities';
import { getDomain } from "../requests/domain";
import { currentUserInfo } from '../requests/user-store';
import { cancelLockedTasks, cancelTasks } from "../Notifications/trigger-notification-socket";

export const getToken = () => localStorage.getItem('access') ?? sessionStorage.getItem('access');

export const isAbrhil = (withUser = false) => {
	const isAbrhilUser = currentUserInfo()?.is_superuser;
	const domainAuth = window.location.hostname === getDomain();
	return withUser ? isAbrhilUser : domainAuth;
};

export const authentication = () => {
	return hasValue(getToken());
};

const handleRedirect = () => {
	const logoutUrl = currentUserInfo()?.sso_url_logout?.url;
	const hasSession = !!localStorage.getItem('session_index');
	const isSubdomain = !isAbrhil();
	let redirectUrl = window.location.origin;
	if (isSubdomain && logoutUrl && hasSession) {
		redirectUrl = logoutUrl;
		localStorage.removeItem('session_index');
	}
	handleStorage();
	window.location.replace(redirectUrl);
}

const handleStorage = () => {
	const theme = localStorage.getItem('theme');
	const designType = localStorage.getItem('design_type');
	const user_persist = localStorage.getItem('user_persist');
	const sessionIndex = localStorage.getItem('session_index');
	const tenantSelection = localStorage.getItem('tenant_selection');
	let user_role = null;
	if (!isAbrhil()) {
		user_role = localStorage.getItem('roleStorage');
	}
	localStorage.clear();
	localStorage.setItem('design_type', designType);
	localStorage.setItem('theme', theme);
	if (user_persist) {
		localStorage.setItem("user_persist", user_persist);
	}
	if (tenantSelection) {
		localStorage.setItem('tenant_selection', tenantSelection);
	}
	if (sessionIndex) {
		localStorage.setItem('session_index', sessionIndex);
	}
	if (user_role) {
		localStorage.setItem('roleStorage', user_role);
	}
	sessionStorage.clear();
}

export const logout = () => {
	cancelTasks();
	cancelLockedTasks();
	// process.env.REACT_APP_REDIRECT_URL
	// Se utiliza el origin del window para redireccionar al login del subdominio correspondiente
	handleRedirect();
};