import { each } from 'lodash';

const thisStructure = [
	'branch',
	'sub_branch',
	'area',
	'sub_area',
	'department',
	'sub_department',
	'job_position',
	'employer_registration'
];

export const getStructureFields = (step, structure, type) => {
	let fields = [];
	each(structure, (field, index) => {
		if (
			((type === "before" && index < step) || (type === "after" && index > step))
			&& thisStructure.includes(field.step)
		) {
			fields.push(field.step);
		}
	});
	return fields;
};