import { ReactComponent as Catalog } from "./catalog.svg";
import { ReactComponent as Schedule } from "./schedule.svg";
import { ReactComponent as Constance } from "./constance.svg";
import { ReactComponent as Excel } from "./Excel.svg";
import { ReactComponent as Dc3Icon } from "./Dc3_Icon.svg";
import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as Verification } from "./verification.svg";
import { ReactComponent as Attendance } from "./attendance-list.svg";
import { ReactComponent as Clear } from "./clear.svg";
import { ReactComponent as Dc3Text } from "./dc3_text.svg";
import { ReactComponent as Cirse } from "./cirse_text.svg";
import { ReactComponent as Check } from "./Check.svg";
import { ReactComponent as Grades } from "./Grades.svg";
const Courses = {
	catalog: Catalog,
    schedule: Schedule,
    constance: Constance,
    excel: Excel,
    dc3icon: Dc3Icon,
    star: Star,
    verification: Verification,
    attendanceparticipants: Attendance,
    clear: Clear,
    dc3text: Dc3Text,
    cirse: Cirse,
    checktraining: Check,
    grades: Grades
};

export default Courses;