import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DynamicMoper from '../../../../../../../signature/SignaturesByWorker/components/Details/components/DynamicMoper';
import { t } from 'i18next';
import SearchBox from '../../../../../../../../../general/@components/general/SearchBox';
import { size, toLower, trim } from 'lodash';
import GridNoRecordsRender from '../../../../../../../../../general/@components/Templates/GridNoRecordsRender';
import { uid } from 'uid';
import GenericLabel from '../../../../../../../../../general/@components/general/GenericLabel/GenericLabel';

const validateSearch = (search, fields) => {
    const values = fields?.map(el => toLower(el)) ?? [];
    return values.some(el => el?.includes(search));
}

function PopupRequest({
    refresh,
    onCancel,
    requests,
    totals = {},
    useDetails = false,
    onlyRead = false,
}) {
    const [search, setSearch] = useState('');

    return (
        <div className="custom-popup-component">
            <SearchBox
                className='mopers-requests-search'
                value={search}
                setSearch={setSearch}
            />
            <Container
                refresh={refresh}
                onCancel={onCancel}
                useDetails={useDetails}
                onlyRead={onlyRead}
                requests={requests?.filter(el => search ? validateSearch(toLower(trim(search)), [el?.name, el?.folio]) : el)}
            />
            <div className="detail-card-footer">
                <span>Totales</span>
                {totals?.map(el => <GenericLabel key={el?.label ?? uid()} label={t(el?.label)} value={el?.value} />)}
            </div>
        </div>
    )
}

export default PopupRequest;

const Container = ({
    refresh,
    requests,
    onCancel,
    useDetails,
    onlyRead,
}) => {

    return (
        <div className="day-details-requests">
            {size(requests) ?
                requests?.map(el => (
                    <DynamicMoper
                        key={`detail-moper-item-${el?.exactMoper?.moper?.id}`}
                        moper={el}
                        refresh={refresh}
                        onCancel={onCancel}
                        showActions={false}
                        showFlowCancel={!onlyRead}
                        showDetails={useDetails}
                        statusResponsive
                    />
                ))
                :
                <GridNoRecordsRender init />}
        </div>
    );
};

PopupRequest.propTypes = {
    refresh: PropTypes.func,
    onCancel: PropTypes.func,
    requests: PropTypes.array,
    totals: PropTypes.array,
    onlyRead: PropTypes.bool,
    useDetails: PropTypes.bool,
}

Container.propTypes = {
    refresh: PropTypes.func,
    onCancel: PropTypes.func,
    useDetails: PropTypes.bool,
    requests: PropTypes.array,
    onlyRead: PropTypes.bool,
}
