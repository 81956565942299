import React from 'react';
import PropTypes from 'prop-types';
import { getDayDetails, getTotals } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import { hasTxT } from '../../../TxTGate';
import ModernPopup from '../../../../../../../../../../../general/@components/general/Popup';
import PopupRequest from '../../PopupRequest';
import '../../../../../../../styles/style.scss';

function RequestsComponent({
    children,
    anchor,
    onClose,
    item,
    worker,
    refresh,
    onlyRead,
    anchorOrigin,
    transformOrigin,
}) {
    const { t } = useTranslation();

    const dayDetails = getDayDetails(item);
    const { txt, money, hours } = getTotals(dayDetails);

    const totalData = [
        (hasTxT(worker) ? { label: t('TXT'), value: txt } : {}),
        { label: t('money'), value: `$${money}` },
        { label: t('hours'), value: hours },
    ];

    return (
        <>
            {children}
            <ModernPopup
                target={anchor}
                handleClose={onClose}
                withBackdrop={false}
                title={'Detalles del día'}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                className="detail-popup-component"
                content={
                    <PopupRequest
                        requests={item.request_details}
                        totals={totalData}
                        worker={worker}
                        onlyRead={onlyRead}
                        refresh={refresh}
                        onCancel={refresh}
                    />
                }
            />
        </>
    );
};

export default RequestsComponent;

RequestsComponent.propTypes = {
    children: PropTypes.any,
    anchor: PropTypes.object,
    onClose: PropTypes.func,
    item: PropTypes.object,
    refresh: PropTypes.func,
    worker: PropTypes.object,
    onlyRead: PropTypes.bool,
    anchorOrigin: PropTypes.object,
    transformOrigin: PropTypes.object,
};