import React from 'react'
import "./styles.scss"
import Edge from './assets/edge_icon.svg'
import Chrome from './assets/chrome_icon.svg'
import Firefox from './assets/firefox_icon.svg'
import { uid } from "uid";

const navegators = [
    { icon: Edge },
    { icon: Chrome },
    { icon: Firefox },

];

function NavMessage({
    width, height
}) {
    return (
        <div className="nav-message-wrap">
            <div className="navegatores-wrap">
                <span>Navegadores recomendados</span>

                <div className="navegatores">
                    {navegators.map(nav => (
                        <img key={uid()} alt={uid()} src={nav.icon} width={width} height={height} />
                    ))}
                </div>
            </div>
            <div className="line"></div>
            <span className="message">
                El uso de otros navegadores podría causar inconsistencias o limitaciones en las funcionalidades.
            </span>
        </div>
    )

}
export default NavMessage