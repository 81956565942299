import { lazy } from "react";

const kioskoItems = [
    {
        id: 'kiosk@kiosk-users',
        type: 'item',
        icon: 'users',
        package_permissions: ["kiosk"],
        componentElm: lazy(async ()  => import('../../../../../modules/kiosk/KioskUsers'))
    },
    {
        id: 'kiosk@permissions',
        type: 'item',
        icon: 'permissions',
        package_permissions: ["kiosk"],
        componentElm: lazy(async ()  => import('../../../../../modules/kiosk/KioskPermissions'))
    },
    {
        id: 'kiosk@kiosk-access-control',
        type: 'item',
        icon: 'clock',
        package_permissions: ["kiosk"],
        componentElm: lazy(async ()  => import('../../../../../modules/kiosk/KioskAccessControl'))
    },
    {
        id: 'kiosk@assign-to-structures',
        type: 'item',
        icon: 'asignestructure',
        package_permissions: ["kiosk"],
        componentElm: lazy(async ()  => import('../../../../../modules/kiosk/KioskAssignStructure'))
    },
];

export default kioskoItems;