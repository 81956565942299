import { lazy } from '../utilities';

const humanResourcesIcons = {
	'sickleaves': {
		allowedTag: "Sick-Leaves",
		Icon: lazy(async () => import("./Incapacidades.svg")),
	},
	'organizationchart': {
		allowedTag: "Organization-Chart",
		Icon: lazy(async () => import("./Organigrama.svg")),
	},
	'vigencycontrol': {
		allowedTag: "Vigency-Control",
		Icon: lazy(async () => import("./VigencyControl.svg")),
	},
	'workeradd': {
		allowedTag: "Worker-Add",
		Icon: lazy(async () => import("./WorkerAdd.svg")),
	},
	'workerleave': {
		allowedTag: "Worker-Leave",
		Icon: lazy(async () => import("./WorkerLeave.svg")),
	},
	'workerkardex': {
		allowedTag: "Worker-Kardex",
		Icon: lazy(async () => import("./WorkerKardex.svg")),
	},
	'workers': {
		allowedTag: "Workers",
		Icon: lazy(async () => import("./Workers.svg")),
	},
	'workerreentry': {
		allowedTag: "Worker-Reentry",
		Icon: lazy(async () => import("./WorkerReentry.svg")),
	},
	'salarychange': {
		allowedTag: "Salary-Change",
		Icon: lazy(async () => import("./SalaryChange.svg")),
	},
	'structurechange': {
		allowedTag: "Structure-Change",
		Icon: lazy(async () => import("./StructureChange.svg")),
	},
	'workgroup': {
		allowedTag: "Workgroup",
		Icon: lazy(async () => import("./Workgroup.svg")),
	},
	'infonavit': {
		allowedTag: "Infonavit",
		Icon: lazy(async () => import("./Infonavit.svg")),
	},
	'chip': {
		allowedTag: "chip",
		Icon: lazy(async () => import("./Chip.svg")),
	},
	'contractplant': {
		allowedTag: "Contract-Plant",
		Icon: lazy(async () => import("./ContractPlant.svg")),
	},
	'renovatecontract': {
		allowedTag: "Renovate-Contract",
		Icon: lazy(async () => import("./RenovateContract.svg")),
	},
};

export default humanResourcesIcons;