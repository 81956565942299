import i18next from 'i18next';
import { array, mixed } from "yup";
import {
	isArray,
	last,
	size,
} from "lodash";

/**
 * Validación custom para la subida de archivos al sistema
 * @param {object} params
 * @param {?boolean} params.isMulti
 * @param {?boolean} params.required
 * @param {?number} params.maxSize
 * @param {?number} params.minSize
 * @param {array
 * |"img"
 * |"pdf"
 * |"doc"
 * |"excel"
 * |"sheet"
 * |"csv"} params.allowedExtensions
 * @returns {YupValidation}
 */
const fileValidation = ({
	isMulti = false,
	...others
}) => {

	if (isMulti) {
		return multiFileValidation(others);
	}

	return uniqueFileValidation(others);
}

export default fileValidation;


export const multiFileValidation = ({
	required,
	minFiles = 1,
	maxFiles,
	...others
}) => {

	const { t } = i18next;

	let validation = array(uniqueFileValidation({ ...others, required }))
		.nullable()
		.typeError(t('validation:array'));

	if (required) {
		const msg = minFiles > 1 ? t("validation:min.array", { min: minFiles }) : t("validation:required");
		validation = validation
			.required(t("validation:required"))
			.min(minFiles, msg);
	}

	/* if array has a maximun selection */
	if (maxFiles) {
		validation = validation.max(maxFiles, t("validation:max.array", { max: maxFiles }));
	}

	return validation
}


/**
 * Validación custom para la subida de archivos al sistema
 * @param {object} params
 * @param {?boolean} params.required
 * @param {?number} params.maxSize
 * @param {?number} params.minSize
 * @param {array
 * |"img"
 * |"pdf"
 * |"doc"
 * |"excel"
 * |"sheet"
 * |"csv"} params.allowedExtensions
 * @returns {YupValidation}
 */
export const uniqueFileValidation = ({
	required,
	maxSize,
	minSize = 1,
	allowString = false,
	allowedExtensions = [],
}) => {

	const { t } = i18next;

	const allowed = isArray(allowedExtensions)
		? allowedExtensions
		: (extensions[allowedExtensions ?? ""] ?? []);

	const typesMessage = size(allowed) ? t('validation:file', { allowed: allowed.join(", ") }) : t("validation:extension");

	let validation = mixed()
		.nullable()
		.test({
			message: typesMessage,
			test: (file, context) => {
				const extension = getExtension(file?.name);
				const isValid = allowed.includes(extension) || (!required && extension === "none") || allowString;
				if (!isValid) {
					context?.createError()
				};
				return isValid;
			}
		});

	if (required) {
		validation = validation.required(t('validation:required'))
			.test({
				message: t('validation:min.file', { min: minSize }),
				test: (file) => {
					const isValid = (file?.size >= minSize) || allowString;
					return isValid;
				}
			});
	}

	if (minSize > 1 && maxSize) {
		return validation.test({
			message: t('validation:between.file', { min: minSize, max: maxSize }),
			test: (file) => {
				const isValid = (file?.size >= minSize && file?.size <= maxSize) || allowString;
				return isValid;
			}
		});
	}

	if (minSize > 1) {
		validation = validation.test({
			message: t('validation:gte.file', { value: minSize }),
			test: (file) => {
				const isValid = (file?.size >= minSize) || allowString;
				return isValid;
			}
		});
	}

	if (maxSize) {
		validation = validation.test({
			message: t('validation:lte.file', { value: maxSize }),
			test: (file) => {
				const isValid = file?.size <= maxSize;
				return isValid;
			}
		});
	}

	return validation;
}

export const extensions = {
	"img": [".jpg", ".png", ".jpeg", ".webp"],
	"pdf": [".pdf"],
	"doc": [".doc", ".docx"],
	"excel": [".xlsx", ".xls"],
	"sheet": [".xlsx", ".xls", ".csv"],
	"csv": [".csv"],
	"text": [".txt"],
};

const getExtension = (fileName) => {
	if (!fileName) {
		return "none"
	}

	const extension = last(fileName.split("."));

	return `.${extension}`;
}