import { resolveError } from "../@components/requests/resolve-error";
import { configExcel, config, API } from "./config";
import axios from "axios";

/**
const CATALOGS =[
  'branches',
  'sub_branches',
  'areas',
  'sub_areas',
  'departments',
  'sub_departments',
  'job_postions',
  'leaves',
  'hierarchical_levels',
  'manager_identifiers'
];
*/

/**
 *
 * @param {string} catalog
 * @param {object} params
 */
export function exportFile(catalog, params = {}) {
  return axios
    .get(API + `/${catalog}/export/`, config(params))
    .then((response) => response.data)
    .catch((error) => resolveError(error));
}

export function upload(catalog, params = {}) {
  return axios
    .get(API + `/${catalog}/export/`, configExcel(params))
    .then((response) => response.data);
}