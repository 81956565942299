import { format } from "date-fns";
import lang from "i18next";
import { uniq, find } from "lodash";
import { valueOrOption, hasValue } from "../general/GeneralUtilities";
import { parseRequest } from "../requests/parse-request";
import { resolveError } from "../requests/resolve-error";
import { implementService } from "../../services/implemet-service";
import { periodsApi } from "../../services/payroll";
import { payrollPeriodFormatLavel } from "./itemUtilities";

const { t } = lang;

/**
 * Función que nos sirve para buscar los periodos normales que est
 * @param {"list" | "payroll"} firstInSearch
 * @returns
 */

export const searchCurrentPayroll = async (firstInSearch = "list", lock = true, params = {}) => {
	let data = {};

	await implementService(
		periodsApi.getCurrents(params),
		response => {
			data = getListOrPayroll(response, firstInSearch);
		},
		null,
		false,
		null,
		lock
	);

	return data;
};

const getListOrPayroll = (data, firstInSearch = "list", value = null) => {
	const selected = find(data, ["id", value]) ?? null;
	const payroll = valueOrOption(find(data, ["payroll", true]), null);
	const list = valueOrOption(find(data, ["list", true]), null);
	if (!hasValue(data) || (!hasValue(list) && !hasValue(payroll)) && !hasValue(selected)) {
		return null;
	}
	if (selected) {
		return selected;
	}
	if (firstInSearch === "list") {
		return valueOrOption(list, payroll);
	}

	return valueOrOption(payroll, list);
};


export const payrollPeriodDataConstructor = async ({
	value,
	computed_type,
	payroll_type,
	current_year,
	current_month,
	firstInSearch,
	paramLabels,
	includeExtras = false,
	extra_filters = {},
	lockWindow = true,
}) => {

	let results = {
		data: [],
		current: null,
		message: ""
	};
	const request = parseRequest({
		tree: "",
		select: true,
		...extra_filters,
		...(includeExtras ? { include: "extras" } : {}),
		computed_type: computed_type,
		payroll_type: payroll_type,
		current_year: current_year || format(new Date(), 'yyyy'),
		current_month: current_month,
	});

	if (request.payroll_type === 99) { delete request.payroll_type; };

	await implementService(
		periodsApi.get(request),
		response => {
			response = uniq(response || []).map(item => {
				item.forSearch = payrollPeriodFormatLavel(item, true);
				return item;
			});

			const filtered = response.filter(item => (item.id === value || item.list || item.payroll));
			let current = getListOrPayroll(filtered, firstInSearch, value) ?? null;

			results = {
				data: response,
				current: valueOrOption(current?.id, null),
				message: ""
			};
		},
		error => {
			const msg = error?.response?.data?.detail_code;
			resolveError(error);
			results.message = t(valueOrOption(msg, "payroll-responses:unexpected-error-message"));
		},
		true,
		paramLabels,
		lockWindow
	);

	return results;
};
