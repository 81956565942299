import React from 'react';
import { getTabIcon, markText } from '../../../utilities';
import { ItemIcon } from '../Search/NavItem';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import useAbrhilSideMenu from '../../../contextsAndControllers/useSideMenu';
import ExternalOpen from '../Templates/ExternalOpen';
import FixedWindow from '../Templates/FixedWindow';

export const LinkElement = ({ item }) => {

	const { tab, search, forceHide } = useMegaMenu();
	const {
		openWindowItem, active_elements, onFixedWindow, current_location
	} = useAbrhilSideMenu();

	const {
		id, title, path, is_accesible
	} = item ?? {};
	const disabled = is_accesible === false;

	const onOpen = () => {
		if (disabled) return;
		openWindowItem(item);
		forceHide();
	};

	const onFixed = () => {
		forceHide();
		onFixedWindow(item);
	};

	const isActive = active_elements.includes(id);

	return (
		<span className={`${isActive ? "selected" : ""} ${disabled ? 'disabled' : ''}`}>
			<span className='referer' onClick={onOpen}>
				<span className='megamenu-icon-link-element-icon'>
					<ItemIcon icon={getTabIcon(item, tab)} />
				</span>
				{markText(title, search)}
			</span>
			<ExternalOpen onClick={forceHide} path={path} show={false} />
			<FixedWindow onClick={onFixed} location={current_location} path={path} show={false} />
			<span className='cover-line' onClick={onOpen} />
		</span>
	);
};
