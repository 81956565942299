import React, { useEffect, useState } from "react";
import FormInput from "../../../../../../../general/@components/form/Field";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import { each, every } from "lodash";
import { useWatch } from "react-hook-form";
import SwitchMui from "../../../../../../../general/@components/Inputs/Booleans/SwitchMui";
import { useDialogContext } from "../../../hooks/DialogController";

const BORDER_STYLE = {
  border: "2px solid #e8e8e8",
  borderRadius: "10px",
  position: "relative",
  padding: '5px',
}

const STYLE_LABEL = {
  width: "max-content",
  position: "absolute",
  top: 0,
  background: "var(--backgroundText)",
  padding: "0 10px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "var(--text)"
}

function MoperConfiguration() {

  const {
    control,
    setInAccessView,
  } = useDialogContext();

  const moperPermission = useWatch({ control, name: 'moper_attendance' });

  useEffect(() => {
    setInAccessView(true);
    return () => {
      setInAccessView(false);
    }
  }, []);

  return (
    <Grid container justifyContent="space-between" className="mt-1">
      <Grid item style={BORDER_STYLE} xs={12} sm={12} md={3.7}>
        <div style={STYLE_LABEL}>Lista de asistencia</div>
        <List items={moperPermission.filter(f => f.permission_type === 1)} />
      </Grid>
      <Grid item container style={{ gap: '15px' }} xs={12} sm={12} md={3.7}>
        <Grid item style={BORDER_STYLE} xs={12} sm={12} md={12}>
          <div style={STYLE_LABEL}>Tiempo extraordinario</div>
          <List items={moperPermission.filter(f => f.permission_type === 3)} />
        </Grid>
        <Grid item style={BORDER_STYLE} xs={12} sm={12} md={12}>
          <div style={STYLE_LABEL}>MOPERS</div>
          <List items={moperPermission.filter(f => f.permission_type === 5)} />
        </Grid>
      </Grid>
      <Grid item container style={{ gap: '15px' }} xs={12} sm={12} md={3.7}>
        <Grid item style={BORDER_STYLE} xs={12} sm={12} md={12}>
          <div style={STYLE_LABEL}>Ajuste de asistencia</div>
          <List items={moperPermission.filter(f => f.permission_type === 2)} />
        </Grid>
        <Grid item style={BORDER_STYLE} xs={12} sm={12} md={12}>
          <div style={STYLE_LABEL}>Firmas gráficas</div>
          <List items={moperPermission.filter(f => f.permission_type === 4)} />
        </Grid>
      </Grid>
    </Grid>

  )
}

export default MoperConfiguration

function List({ items }) {
  const { t } = useTranslation();

  const {
    control,
    setValue,
    canWrite,
  } = useDialogContext();

  const moperPermission = useWatch({ control, name: 'moper_attendance' });

  const [listAll, setListAll] = useState(every(items, 'has_perm'));

  useEffect(() => {
    setListAll(every(items, 'has_perm'))
  }, [moperPermission]);

  return (
    <>
      {items?.length > 1 &&
        <div className="setting-item">
          <SwitchMui
            label={t(listAll ? "uncheck-all" : "check-all")}
            checked={listAll}
            disabled={!canWrite}
            onChange={(e) => {
              setListAll(e.value);
              each(items, el => {
                const valueField = moperPermission.findIndex(fi => fi.key === el.key);
                setValue(`moper_attendance[${valueField}].has_perm`, e.value);
              });
            }}
          />
        </div>}
      {items.map((el) => (
        <SettingItem
          key={`config-moper-item-${el.key}-${el.id}`}
          name={el?.key}
          allData={moperPermission}
          description={el?.description}
        />
      ))}
    </>
  );
}

List.propTypes = {
  items: PropTypes.array,
}

const SettingItem = ({
  name,
  allData,
  description,
}) => {

  const {
    control,
    canWrite,
  } = useDialogContext();

  const itemIndex = allData?.findIndex(el => el.key === name);
  const valueField = `moper_attendance[${itemIndex}].has_perm`;

  return (
    <div className="setting-item">
      <FormInput
        fieldInput="switch"
        label={description}
        name={valueField}
        control={control}
        disabled={!canWrite}
      />
    </div>
  );
};

SettingItem.propTypes = {
  name: PropTypes.string,
  allData: PropTypes.array,
  description: PropTypes.string,
};