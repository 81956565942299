import React from 'react';
import { AdvanceFiltersProvider } from './useAdvanceFilters';
import Content from './components/Content';

/* styles */
import "../style.scss";
/**
 * Version 2 del componente de filtros avanzados, ahora controlado por hook-form
 * @returns {React.JSX.Element}
 * @version 2.0.0
 * @author Angel Efrain Poot Canul
 */
const AdvanceFilters = () => {
	return (
		<AdvanceFiltersProvider>
			<Content />
		</AdvanceFiltersProvider>
	);
};

export default AdvanceFilters;