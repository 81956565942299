import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { useWatch } from "react-hook-form";
import {
    faUser,
    faPeopleGroup,
    faUserSecret
} from "@fortawesome/pro-solid-svg-icons";
import {
    faArrowLeft,
} from "@fortawesome/pro-light-svg-icons";
import { isFunction, isNumber, size } from "lodash";
import PropTypes from "prop-types";
import { CustomTooltip } from "../../../../../../../general/@components/Templates/cells";
import { scaleOpacityAnimation } from "../../../../../../../general/@components/form/Permission/Utils";
import { useDialogContext } from "../../../hooks/DialogController";

function TransitionComponent({
    CustomItem,
    customViewName,
    onReturn,
    isCustom = false,
}) {

    TransitionComponent.propTypes = {
        CustomItem: PropTypes.object,
        customViewName: PropTypes.string,
        onReturn: PropTypes.func,
        isCustom: PropTypes.bool,
    };

    const {
        handleClick,
        itemBack,
        currentInfo,
        viewName,
        currentGroup,
        backupComponent,
        selected,
        control,
    } = useDialogContext();

    const username = useWatch({ control, name: 'username' });
    const email = useWatch({ control, name: 'email' });
    const ElementLoad = CustomItem ?? backupComponent;
    const BackText = customViewName ?? viewName;

    const onBack = (item) => {
        if (isFunction(onReturn)) {
            onReturn();
            return;
        }
        handleClick(item);
    }

    return (
        <>
            {
                !isCustom ?
                    <>
                        <button
                            onClick={() => onBack(itemBack)}
                            className="back-button"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                            {BackText}
                        </button>
                        <AnimatePresence mode='wait'>
                            {
                                size(currentInfo) ?
                                    <motion.div
                                        key={`current-info ${currentInfo.info}`}
                                        {...scaleOpacityAnimation}
                                        className="current-info-header"
                                    >
                                        <span>{currentInfo.info}</span>
                                        <div className={`icon-container ${currentInfo?.class}`}>
                                            <FontAwesomeIcon icon={currentInfo.icon} />
                                        </div>
                                    </motion.div>
                                    :
                                    null
                            }
                        </AnimatePresence>
                        <AnimatePresence mode='wait'>
                            {
                                username || isNumber(currentGroup?.group?.id) ?
                                    <motion.div
                                        key={`current-user-info`}
                                        {...scaleOpacityAnimation}
                                        className={'current-user-info'}
                                    >
                                        <AnimatePresence mode='wait'>
                                            {
                                                username ?
                                                    <motion.div
                                                        key={`username`}
                                                        {...scaleOpacityAnimation}
                                                    >
                                                        <CustomTooltip title={email}>
                                                            <span className="username">
                                                                <FontAwesomeIcon icon={faUser} />
                                                                {username}
                                                            </span>
                                                        </CustomTooltip>
                                                    </motion.div>
                                                    :
                                                    null
                                            }
                                        </AnimatePresence>
                                        <AnimatePresence mode='wait'>
                                            {
                                                selected?.is_superuser ?
                                                    <motion.div
                                                        key={`superuser`}
                                                        {...scaleOpacityAnimation}
                                                    >
                                                        <span className="username">
                                                            <FontAwesomeIcon icon={faUserSecret} />
                                                            {"Superusuario"}
                                                        </span>
                                                    </motion.div>
                                                    :
                                                    null
                                            }
                                        </AnimatePresence>
                                        <AnimatePresence mode='wait'>
                                            {
                                                isNumber(currentGroup?.group?.id) ?
                                                    <motion.div
                                                        key={`current_user_group_${currentGroup?.group?.id}`}
                                                        {...scaleOpacityAnimation}
                                                    >
                                                        <CustomTooltip title={currentGroup.group.name}>
                                                            <span className="username">
                                                                <FontAwesomeIcon icon={faPeopleGroup} />
                                                                {currentGroup.group.name}
                                                            </span>
                                                        </CustomTooltip>
                                                    </motion.div>
                                                    :
                                                    null
                                            }
                                        </AnimatePresence>
                                    </motion.div>
                                    :
                                    null
                            }
                        </AnimatePresence>
                    </>
                    :
                    null
            }
            {ElementLoad}
        </>
    );
};

export default TransitionComponent;