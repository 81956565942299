import i18next from 'i18next';
import * as yup from "yup";

const emailValidation = (required, maxLength) => {

	maxLength = maxLength || 100;

	let validation = yup
		.string()
		.nullable()
		.email(i18next.t('validation:email'));

	if (required) {
		validation = validation.required(i18next.t('validation:required'))
	}

	if (maxLength) {
		validation = validation.max(maxLength, i18next.t('validation:max.string', { max: maxLength }))
	}

	return validation;
}

export default emailValidation;