import React from "react";
import { AvatarAndTooltip } from "../../../../../../general/@components/Inputs/photoUpload/photo/CustomAvatar";
import { useWatch } from "react-hook-form";
import { workerFullName } from "../../../../../../general/@components/employees/constants";
import { useDialogContext } from "../../hooks/DialogController";

function UserAvatar() {

    const {
        control,
        srcImage,
    } = useDialogContext();

    const { first_surname, second_surname, name } = useWatch({ control, name: 'worker' }) ?? {};
    const username = useWatch({ control, name: 'username' });
    const email = useWatch({ control, name: 'email' });
    const fullName = workerFullName({ first_surname, second_surname, name });

    return (
        <div className="user-avatar-container">
            <AvatarAndTooltip
                photo={srcImage ?? ''}
                string={(fullName || username || email || '?').trim().toUpperCase()}
                fontSize='40px'
                className='user-avatar'
            />
        </div>
    )
}

export default UserAvatar;