import React from "react";
import FormInput from "../../../../../../../general/@components/form/Field";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { uid } from "uid";
import { useSelector } from "react-redux";
import { useDialogContext } from "../../../hooks/DialogController";

const CONFIG_PROP = 'extra_perms';

function Settings() {

    const {
        control,
        extraPerms,
        canWrite,
    } = useDialogContext();

    const { t } = useTranslation();

    const voiceAssistance = useSelector((state) => state.configuration?.abrhil_package?.VOICE_ASSISTANCE_MODULE?.value);

    const disabledValues = {
        voice_assistance: !voiceAssistance,
        is_always_listening: !voiceAssistance,
    };

    return (
        <div className="settings-main-container">
            {extraPerms.map((el) => (
                <SettingItem
                    key={uid()}
                    label={el?.name}
                    disabled={disabledValues[el?.name] || !canWrite}
                    control={control}
                    t={t}
                />
            ))}
        </div>
    );
}

export default Settings;

const SettingItem = ({
    label,
    control,
    disabled,
    t,
}) => {

    return (
        <div className="setting-item">
            <FormInput
                fieldInput="switch"
                label={t((label.split('_').join('-')))}
                name={`${CONFIG_PROP}.${label}`}
                control={control}
                disabled={disabled}
            />
        </div>
    );
};

SettingItem.propTypes = {
    label: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.any,
    t: PropTypes.any,
};