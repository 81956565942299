import PropTypes from "prop-types";
import { uid } from "uid";
import React, { useEffect, useState } from "react";
import { isFunction, pick } from "lodash";
import { Grid } from "@mui/material";
//--project imports
import DialogTitle from '../../../styled/DialogTitle';
import Dialog from '../../../styled/Dialog';
import IconUse from "../../uiDesign/IconUse";
import { valueOrOption } from '../../general/GeneralUtilities';
import GenericLabel from "../../general/GenericLabel/GenericLabel";
import { SolidDivider } from "../../general/Dividers";

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {string} props.title
 * @param {?string} props.subtitle
 * @param {function} props.handleClose
 * @param {boolean} props.onCloseIcon
 * @param {string | number | null} props.maxWidth
 * @param {JSX.Element} props.dynamicComponent
 * @rxeturns
 */
const FormDialog = ({
	maxWidth,
	title,
	titleCenter,
	handleClose,
	dynamicComponent,
	innerSubtitle,
	subtitle,
	children,
	sx = {},
	onCloseIcon,
	header,
	dialogId,
	disableEscape = false,
	headerDivider = false,
	helpIcon = false,
	...others
}) => {

	const getId = () => valueOrOption(dialogId, `dialog-drag-title-${uid()}`);
	const [id, setId] = useState(getId());
	useEffect(() => {
		setId(getId());
		//eslint-disable-next-line
	}, [dialogId]);

	return (
		<Dialog
			fullWidth={true}
			maxWidth={maxWidth ?? "lg"}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick' && isFunction(handleClose)) {
					return handleClose();
				}
				return event;
			}}
			aria-labelledby="customized-dialog-title"
			disableEscape={disableEscape}
			{...pick(others, ["open", "BackdropProps", "className", "style", "sx", "PaperProps"])}
			sx={{ ...sx, zIndex: (theme) => theme.zIndex.drawer + 1 }}
			dialogId={id}
		>
			<DialogTitle
				title={
					<Grid container spacing={1}>
						<Grid item>
							{title}
						</Grid>
						{
							helpIcon &&
							<Grid item>
								<IconUse icon="help" className="help-icon" />
							</Grid>
						}
					</Grid>
				}
				subtitle={subtitle}
				titleCenter={titleCenter}
				onClose={handleClose}
				onCloseIcon={onCloseIcon}
				customHeader={header}
				dialogId={id}
			/>
			{!!innerSubtitle &&
				<Grid item container className="abrhil-dialog-inner-subtitle">
					<GenericLabel label={innerSubtitle} fontSize='1em' wrapLabel />
				</Grid>}
			{headerDivider &&
				<Grid item container className="abrhil-dialog-header-divider">
					<SolidDivider />
				</Grid>}
			{dynamicComponent}
			{children}
		</Dialog >
	);
};

FormDialog.propTypes = {
	children: PropTypes.any,
	dialogId: PropTypes.string,
	disableEscape: PropTypes.bool,
	dynamicComponent: PropTypes.element,
	handleClose: PropTypes.func,
	header: PropTypes.any,
	headerDivider: PropTypes.bool,
	innerSubtitle: PropTypes.any,
	maxWidth: PropTypes.string,
	onCloseIcon: PropTypes.any,
	subtitle: PropTypes.any,
	sx: PropTypes.object,
	title: PropTypes.any,
	titleCenter: PropTypes.any,
	helpIcon: PropTypes.any
};

export default FormDialog;

export const initialDialog = {
	open: false,
	isEdit: false,
	data: {}
};