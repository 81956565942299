import React, {
	useLayoutEffect,
	useRef,
} from 'react';
import { motion, AnimatePresence } from "framer-motion";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { useTranslation } from "react-i18next";

import Header from "../MegaMenu/Header";
import PerfectScrollbar from 'react-perfect-scrollbar';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import useAbrhilNavigation from '../../../contextsAndControllers/useAbrhilNavigation';
import { isSelected, concidence } from '../../../utilities';
import { ItemIcon } from '../Search/NavItem';
import Footer from '../MegaMenu/Footer';
import { CustomTooltip } from '../../../../Templates/cells';
import { hasValue, valueOrOption } from '../../../../general/GeneralUtilities';
import GridNoRecordsRender from '../../../../Templates/GridNoRecordsRender';
import { GroupElements } from '../MegaMenu/GroupElements';
import { LinkElement } from '../MegaMenu/LinkElement';



const MegaMenuTimbrasoft = ({ item }) => {

	const { title, bread, icon_name } = item;

	const {
		showed,
		breadcrumbs,
		isMobile,
		menuCollapsed
	} = useAbrhilNavigation();

	const {
		tab,
		search,
		forceOpen,
		elements,
		subMenu,
		isSubmenu,
		groupsAtFirstLevel,
	} = useMegaMenu();

  const { t } = useTranslation();
	const scrollRef = useRef(null);
	const showMenu = showed?.key === item?.key;

	useLayoutEffect(() => {
		if (scrollRef?.current) {
			setTimeout(() => {
				scrollRef.current.updateScroll();
			}, [200]);
		}
	}, [showMenu]);
  
  const MenuElements = () => {
    let items_menu = [];
    if (isSubmenu) {
      items_menu = subMenu?.children?.find(item => item.name === tab);
      items_menu = items_menu?.children;
    } else if (groupsAtFirstLevel) {
      items_menu = elements?.find(item => item.name === tab);
      items_menu = items_menu?.children;
    } else {
      items_menu = elements;
    }
  
    return (
      <>
        <Box className={"childrens-list-items"}>
          <Masonry columns={1} spacing={2}>
            {valueOrOption(items_menu, []).map((item, key) => {
              if (item.type === "collapse") {
                return (<GroupElements key={key} item={item} stampMode={true} />);
              }
              return (hasValue(search) && !concidence(item.title, search)) ? null : (
                <div key={key} className='module-item'>
                  <LinkElement item={item} />
                </div>
              );
            }).filter(item => item !== null)}
          </Masonry>
        </Box>
        <div className="no-items">
          <GridNoRecordsRender
            init={hasValue(search)}
            initialMsg={t("no-items-to-show")}
          />
        </div>
      </>
    );
  }
  

	return (
		<span
			className={`sidebar-item ${showMenu ? "isOpened" : ""} ${isSelected(bread, breadcrumbs)}`}
		>

			<CustomTooltip title={title} className="menu-item-tooltip" placement="left">
				<span className='side-menu-item-icon' onClick={() => forceOpen(item)}>
					<ItemIcon icon={icon_name} />
				</span>
			</CustomTooltip>

			<AnimatePresence>
				{(showMenu || (isMobile && !menuCollapsed)) &&
					<motion.div
						key="megamenutimbrasoft"
						animate={visible}
						initial={hidden}
						exit={hidden}
						transition={{ duration: .5 }}
						className="megadrop"
					>
						<div className='megadrop-inner-container'>
							<Grid container spacing={1}>
								<Grid item xs={12} className="mega-menu-header">
									<Header />
								</Grid>
							</Grid>
							<div className='childrens-container'>
								<PerfectScrollbar ref={scrollRef}>
									<MenuElements items={item?.children}/>
								</PerfectScrollbar>
							</div>
							<Footer stampMode={true} />
						</div>
					</motion.div>
				}
			</AnimatePresence>
		</span>
	);
};

export default MegaMenuTimbrasoft;

const visible = {
	opacity: 1,
	display: "block",
	x: 0,
};
const hidden = {
	opacity: 0,
	display: "none",
	x: -900,
};