import React from "react";
import { useDispatch, useSelector } from "react-redux";
//--project imports
import { hideNotificationImport } from '../../../../../store/actions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import { StatusSkeleton, Transition } from "./DialogSkeleton";

const ImportNotification = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		show,
		title,
		message,
		description,
		maxWidth,
		importedItems,
		...others
	} = useSelector(state => state.notification.importNotification);

	return <Dialog
		open={show}
		TransitionComponent={Transition}
		className="myDialog warningDialog"
		maxWidth={maxWidth ?? "lg"}
		onClose={() => dispatch(hideNotificationImport())}
	>

		{
			(importedItems || []).forEach(
				item => {
					console.log(item);
				})
		}
		<StatusSkeleton
			title={title || t("warning-general-title")}
			lottieIcon={"warning-icon"}
			message={message || ""}
			description={description || ""}
			close={() => dispatch(hideNotificationImport())}
			{...others}
		/>
	</Dialog>;
}

export default ImportNotification;