import React from "react";
import PropTypes from "prop-types";
import "./_styles.scss";

export const BlueDivider = () => <hr className="custom-modern-tabs-divider" />;

export const SolidDivider = ({ className = '', vertical = false }) => <hr className={`solid-divider ${className} ${vertical ? 'vertical' : ''}`} />;

SolidDivider.propTypes = {
    className: PropTypes.string,
    vertical: PropTypes.bool,
};