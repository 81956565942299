import React from 'react';

/* Library components */
import { createPortal } from 'react-dom';

/* Own components */
import { hasValue } from '../general/GeneralUtilities';

const Watermark = (props) => (
	<Presence {...props} >
		<div
			key='grid-watermark'
			className={"abrhil-water-mark"}
		>
			<div>{props.watermark}</div>
		</div>
	</Presence>
);

export default Watermark;

const Presence = ({
	show,
	gridRef,
	watermark,
	children
}) => {

	const gridContent = gridRef?.current?._element?.querySelector('.k-grid-container');

	if (!show || !gridContent || !hasValue(watermark)) {
		return null;
	}

	return createPortal(
		children,
		gridContent
	);
};