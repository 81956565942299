import { COUNTRY, THEME, STAMP } from '../actions';

const config = {
	language: "es",
	country: "MX",
	theme: "light",
	stamp: false
};

export function app(state = config, action) {

	switch (action.type) {

		case COUNTRY:
			return {
				...state,
				country: action.payload
			};

		case THEME:
			return {
				...state,
				theme: action.payload
			};

		case STAMP:
			return {
				...state,
				stamp: action.payload
			};
	

		default:
			return state;
	}
};