import PropTypes from "prop-types";

export const ClearAdornProps = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    disableInput: PropTypes.bool,
    hideClear: PropTypes.bool,
    onClear: PropTypes.func,
};

export const AdornmentProps = {
    ...ClearAdornProps,
    onSearch: PropTypes.func,
    isLoading: PropTypes.bool,
    disableSearch: PropTypes.bool,
};

export const InputProps = {
    label: PropTypes.any,
    sideLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    clearValue: PropTypes.string,
    onClear: PropTypes.func,
    autoFocus: PropTypes.bool,
    prevChangeFunction: PropTypes.func,
    children: PropTypes.any,
    ...AdornmentProps,
};
