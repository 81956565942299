import {
	LOCKED_WINDOW,
	LOCKED_WINDOW_MESSAGE,
	UNLOCKED_WINDOW,
} from '../actions';

const initialState = {
	lockedWindow: false,
	type: "default",
	callback: null,
	messages: "",
};

export function locked(state = initialState, action = {}) {
	switch (action?.type) {
		case LOCKED_WINDOW:
			return {
				lockedWindow: true,
				type: action?.lock_type,
				callback: action?.callback,
			};
		case UNLOCKED_WINDOW:
			return initialState;
		case LOCKED_WINDOW_MESSAGE:
			return {
				...state,
				lockedWindow: true,
				messages: action?.message
			};
		default:
			return state;
	}
}