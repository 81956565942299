
import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import useAbrhilWindow from '../../contextsAndControllers/useWindow';
import PageSkeleton from '../PageSkeleton';

const Window = ({ id }) => {
	const { open, isFocused } = useAbrhilWindow();
	const focusClass = isFocused ? "isFocused" : "";

	return (
		<>
			{open &&
				<div
					id={id}
					key={id}
					className={`module-window ${focusClass}`}
				>
					<div className='module-window-container'>
						<WindowContent />
					</div>
				</div>}
		</>
	);
};

export default Window;

export const WindowContent = () => {

	const [loaded, setLoaded] = useState(false);

	const {
		open,
		item: { element, path, extraProps },
	} = useAbrhilWindow();

	useEffect(() => {
		setTimeout(() => {
			setLoaded(!!element);
		}, 300);
	}, [open])

	const WindowPage = useMemo(() => element);

	if (!open || !element) {
		return null;
	}

	if (!loaded) {
		return <PageSkeleton />;
	}

	return <WindowPage {...extraProps} path={path} inWindow />;
}