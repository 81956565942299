import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import es from 'date-fns/locale/es';
import { useForm } from 'react-hook-form';
import FileSaver from 'file-saver';
import { format } from 'date-fns';
//--mui
import Grid from '@mui/material/Grid2';
//--project imports
import Card from '../../../general/@components/general/MainCard';
import { employerRegistrationAPI } from '../../../general/services/enterprise-structure';
import { parseRequest } from '../../../general/@components/requests/parse-request';
import { errorTenant } from '../../../general/@components/Notifications/notification-messages';
import { hasErrorTetant } from '../../../general/@components/requests/validate-tenant';
import { BaseButton, ButtonsContainer, Download } from '../../../general/@components/Buttons';
import { initialFilter } from '../../../general/@components/table/utils/GridCustomProps';
import { resolveError } from '../../../general/@components/requests/resolve-error';
import {
	lockedWindow,
	showConfirmNotification,
	showNotificationWarning,
	unlockedWindow,
} from '../../../../store/actions';
import TemplateAlert from './TemplateAlert';
import KendoGrid from './KendoGrid';
import Filters from './Filters';
import { months, previousDate, ValidationSchema } from './Utils';
import { liquidationApi } from '../../../general/services/RRHH';
import { faRotate } from '@fortawesome/pro-light-svg-icons';
import TriggerNotificationSocket from '../../../general/@components/Notifications/trigger-notification-socket';
import { gridSize } from '../../../general/@components/general/GeneralUtilities';
import { socketsApi } from '../../../general/services/socket-report';
import { upperFirst } from 'lodash';
import { current_year } from '../../../general/@components/Inputs/Dates/utilities';

const initialPage = { skip: 0, take: 20 };
let reportType = '';
let reportYear = '';
let reportMonth = '';
let reportBiMonthly = '';
let reportPatronalRegistration = '';

function Liquidation() {

  const dispatch = useDispatch();

  const [init, setInit] = useState(false)
  const [page, setPage] = useState(initialPage)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState([])
  const [filter, setFilter] = useState(initialFilter)
  const [disableButton, setDisableButton] = useState(false)
  const [getBimonthly, setGetBimonthly] = useState(null)

  const tenant = useSelector((state) => state.tenant)
  const typeDownload = useRef('Procesar')

  const { year, month } = previousDate(false, true);

  const initValues = {
    type: '1',
    year,
    month,
    employer_registrations: []
  }

  const { handleSubmit, control, setValue, getValues } = useForm({
    resolver: yupResolver(ValidationSchema),
    mode: 'onChange',
    defaultValues: initValues
  })

  useEffect(() => {
    setData([])
    setInit(false)
    setFilter(initialFilter)
    // eslint-disable-next-line
  }, [tenant])

  useEffect(() => {
    handleRefresh();
  }, [])

  const getPatronalRegistration = async (filters, pages) => {
    if (hasErrorTetant()) {
      dispatch(showNotificationWarning(errorTenant()))
      return
    }

    dispatch(lockedWindow())

    setInit(true)

    let params = parseRequest({ distinct: '' }, filters, pages)

    await employerRegistrationAPI
      .get(params)
      .then((response) => {
        let newResponse = response.results.map((item) => Object.assign({ selected: false }, item))
        setData(newResponse)
        setTotal(response.count)
      })
      .catch((error) => {
        resolveError(error)
      })
      .finally(() => {
        dispatch(unlockedWindow())
      })
  }

  useEffect(() => {
    if (init) {
      getPatronalRegistration(filter, page)
    }
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (!data.length && page.skip && init) {
      setPage(initialPage)
    }
    // eslint-disable-next-line
  }, [data])

  /* Refresh */
  const handleRefresh = () => {
    getPatronalRegistration(filter, page)
  }

  const handleDownload = async (item, e, recalculate) => {

    setDisableButton(true)

    try {

      let copyData = JSON.parse(JSON.stringify(item))
      copyData.recalculate = !!recalculate

      if (copyData.type === '2') {
        copyData.bimonthly = months.find((f) => f.value === copyData.month).send
        delete copyData.month
      }

      if (copyData.type_download === t('Procesar')) {
        copyData.employer_registrations = copyData.employer_registrations.toString()
        const type = copyData.type === '1' ? 'monthly' : 'bimonthly'
        const params = {
          ...copyData
        }
        const message = await liquidationApi.processLiquidation(type, params);
        if (message?.task_id) {
          TriggerNotificationSocket(`${type[0]}_liquidation`, message.task_id);
        }
        if (getBimonthly) {
          params.bimonthly = getBimonthly.send
          params.recalculate = true
          const message = await liquidationApi.processLiquidation('bimonthly', params)
          if (message?.task_id) {
            TriggerNotificationSocket(`b_liquidation`, message.task_id);
          }
        }
      } else {
        const commonData = {
          year: copyData.year,
          employer_registration: copyData.employer_registrations?.join(','),
        };
        try {
          reportYear = copyData.year;
          reportPatronalRegistration = commonData.employer_registration;
          if (copyData.type === '1') {
            let response = await liquidationApi.downloadFile('monthly', {
              ...commonData,
              month: copyData.month,
            })
            reportType = "monthly";
            reportMonth = copyData?.month;
            TriggerNotificationSocket('m_liquidation_report', response?.task_id);
          } else {
            let response = await liquidationApi.downloadFile('bimonthly', {
              ...commonData,
              bimonthly: copyData.bimonthly,
            })
            reportType = "bimonthly";
            reportBiMonthly = copyData?.bimonthly;

            TriggerNotificationSocket('b_liquidation_report', response?.task_id);
          }
        } catch (error) {
          const message = error === 'error 404' ? t('error-general-description_two') : t('error-general-description')
          dispatch(
            showNotificationWarning({
              message
            })
          )
        }
      }

    } catch (error) {

      if (error.response.status === 406) {
        dispatch(
          showConfirmNotification({
            type: 'warning',
            maxWidth: 'sm',
            message: <TemplateAlert msg={error.response.data} typeDownload={typeDownload} />,
            onConfirm: () => {
              item.type_download = typeDownload.current
              handleDownload(item, {}, true)
              typeDownload.current = 'Procesar'
            }
          })
        )
      } else {
        resolveError(error)
      }

    } finally {
      setDisableButton(false)
    }
  }

  const onSubmitError = (errors) => {
    dispatch(
      showNotificationWarning({
        type: 'warning',
        maxWidth: 'sm',
        message: errors?.employer_registrations?.message ?
          "Se debe seleccionar uno o más registros patronales" :
          "Debe completar los campos obligatorios"
      })
    )
  };

  return (
    <Card principal>
      <Grid container direction="column" spacing={1} sx={{ height: '100%' }}>
        <Grid {...gridSize(12)}>
          <Filters
            control={control}
            initValues={initValues}
            setValue={setValue}
            getValues={getValues}
            getBimonthly={getBimonthly}
            setGetBimonthly={setGetBimonthly}
          />
        </Grid>
        <Grid {...gridSize(12)} sx={{ flex: 1, overflow: 'hidden' }}>
          <KendoGrid
            data={data}
            setValue={setValue}
            filter={filter}
            page={page}
            setFilter={setFilter}
            setPage={setPage}
            getPatronalRegistration={getPatronalRegistration}
            init={init}
            total={total}
          />
        </Grid>
        <Grid sx={{ marginLeft: 'auto' }}>
          <form onSubmit={handleSubmit(handleDownload, onSubmitError)}>
            <ButtonsContainer>
              <BaseButton
                type="submit"
                label="Procesar"
                icon={faRotate}
                design="contained"
                disabled={disableButton}
                onClick={() => {
                  setValue('type_download', 'Procesar')
                }}
              />
              <Download
                onClick={() => {
                  setValue('type_download', 'Reporte')
                }}
                disabled={disableButton}
              />
            </ButtonsContainer>
          </form>
        </Grid>
      </Grid>
    </Card>
  );
};

export const downloadLiquidationReport = async (taskID) => {
  const date = new Date(current_year(), reportMonth - 1, 1);
  const month = format(date, 'MMMM', { locale: es });

  const bimonthly = months?.find(element => element?.send === parseInt(reportBiMonthly))?.label;

  try {
    let response = await socketsApi.downloadReport(taskID);
    FileSaver.saveAs(
      response,
      `${t("liquidation")} ${t(reportType)} ${reportType === 'monthly' ? upperFirst(month) : bimonthly} ${reportYear} ${reportPatronalRegistration} ${format(
        new Date(),
        "yyyy-MM-dd hh:mm:ss"
      )}`
    );
  } catch (error) {
    return error;
  }
};

export default Liquidation;
