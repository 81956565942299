import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
/* style */
import "./Styles.scss";
import { getCurrentPath } from "../navigation/utilities";
import { dashboardPath } from "../requests/validate-tenant";
import LottieUse from "../uiDesign/LottieUse";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../Buttons";

const PageNotFound = () => {

	const { t } = useTranslation();
	const navigate = useNavigate();
	const currentPath = getCurrentPath();

	const onReturn = () => {
		navigate("/app/welcome");
	};

	return (
		<div id="container-404">
			<div className="container">
				<main>
					<Grid container spacing={1}>
						<Grid item xs={12} className={"text-center"}>
							<LottieUse
								icon={"404_2"}
								className="icon-404-blue"
							/>
							<h2 className="h2-404">{t("page-not-found.title")}</h2>
							<p>
								{t("page-not-found.message")}
							</p>
							{
								currentPath !== dashboardPath() &&
								<BaseButton
									label={t("back-to-init")}
									onClick={onReturn}
									color={"info"}
									className={"mt-4"}
									design="contained"
									size="large"
								/>
							}
						</Grid>
					</Grid>
				</main>
			</div>
		</div>
	);
};

export default PageNotFound;
