import React from 'react';
import { useTranslation } from "react-i18next";
import ModalContent from './ModalContent';
import Dialog from '../../../../../styled/Dialog';

const Modal = ({
	open,
	setOpen
}) => {

	const { t } = useTranslation();
	const onClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth={"xl"}
			title={t("enterprise-and-role")}
			design="modern"
			className={"company-and-role-modal"}
		>
			<ModalContent onClose={onClose} />
		</Dialog>
	);
};
export default Modal;