import React, { useEffect } from "react";
/* style */
import "./Styles.scss";
import { useDispatch } from "react-redux";
import { lockedWindow } from "../../../../store/actions";
import PageSkeleton from "../navigation/components/PageSkeleton";

const DomainReload = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lockedWindow());
  }, []);

  return (
    <PageSkeleton />
  );
};



export default DomainReload;
