import PropTypes from "prop-types"
import React from 'react';
import { isArray, omit, size } from 'lodash';
import MegaMenuTimbrasoft from '../../../general/@components/navigation/components/SideMenu/MenuTimbrasoft';
import { ItemIcon } from '../../../general/@components/navigation/components/SideMenu/Search/NavItem';
import { CustomTooltip } from '../../../general/@components/Templates/cells';
import useAbrhilNavigation from '../../../general/@components/navigation/contextsAndControllers/useAbrhilNavigation';
import { MegaMenuProvider } from '../../../general/@components/navigation/contextsAndControllers/useMegaMenu';
import useAbrhilSideMenu from '../../../general/@components/navigation/contextsAndControllers/useSideMenu';
import { isSelected } from '../../../general/@components/navigation/utilities';

const validateChildrens = (childs) => {
	if (!isArray(childs)) {
		return null;
	}

	return childs.map(el => {
		const child = { ...el };
		if (size(child.children) > 0) {
			child.children = validateChildrens(child.children);
		}

		if (
			(child.type === "item" && child.showInMenu) ||
			size(child.children) > 0
		) {
			return child;
		}
		return null;
	}).filter(item => item !== null);
};

const invalidItem = ({ children, showInMenu, type, key, }) => {
	const finalChilds = validateChildrens(children);

	const isValid = (
		!showInMenu ||
		!["item", "collapse"].includes(type) ||
		(size(finalChilds) <= 0 && type === "collapse") ||
		['abrhilhelpdesk', 'welcome', 'help'].includes(key)
	);

	return { isValid, children: finalChilds };
};

const StampMenu = ({ items = [] }) => {
  return (
	items.map((item, index) => {
		const { isValid, children } = invalidItem(item);

		if (isValid) {
			return null;
		}
		
		const Item = !children ? SimpleItem : MegaMenuItem;

		return <Item key={index} item={item} childItems={children} /> ?? null;
	})
)};

StampMenu.propTypes = {
  items: PropTypes.array
}

export default StampMenu;

const SimpleItem = ({ item, disabled }) => {
	const { title, bread, icon, icon_name } = item;
	const { breadcrumbs } = useAbrhilNavigation();
	const { openWindowItem } = useAbrhilSideMenu();
	const openWindow = () => openWindowItem(item);
	const iconUSe = icon ?? icon_name
	return (
		<CustomTooltip title={title} className="menu-item-tooltip" placement="left">
			<span className={`sidebar-item ${isSelected(bread, breadcrumbs)}`} >
				<span className='side-menu-item-icon stamp-icon' onClick={disabled ? ()=>{} : openWindow}>
					<ItemIcon icon={iconUSe} />
				</span>
			</span>
		</CustomTooltip>
	);
};

SimpleItem.propTypes = {
	disabled: PropTypes.any,
	item: PropTypes.any
}

const MegaMenuItem = ({ item, childItems }) => {
	const final = { ...item, children: childItems };
	return (
		<MegaMenuProvider
			menuKey={final.key}
			elements={childItems}
			groupsAtFirstLevel={["timbrasoft"].includes(final.key)}
			{...omit(final, ["key", "children"])}
		>
			<MegaMenuTimbrasoft item={final} />
		</MegaMenuProvider>
	);
};
MegaMenuItem.propTypes = {
	childItems: PropTypes.any,
	item: PropTypes.any
}
