
import React, {
	useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";

/* components */
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

/* own components */
// import ListItemBase from './ListItemBase';
import DialogForm from "../../../form/Dialog";
import FormInput from "../../../form/Field";
import IconUse from "../../../uiDesign/IconUse";

/* validations */
import { stringValidation } from "../../../validations/stringValidation";

/* utilities */
import { usersApi } from "../../../../services/administrator";
import { lockedWindow, unlockedWindow } from "../../../../../../store/actions";
import { successUpdated } from "../../../Notifications/notification-messages";
import { resolveError } from "../../../requests/resolve-error";
import { noSpaces } from "../../../form/utilities";
import { toggleChangePassword } from '../../../../../../store/triggers/navigate';
import { showSuccessNotification } from "../../../Notifications";

const ChangePasswordOption = ({ onClose }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const openForm = () => {
		dispatch(toggleChangePassword());
		onClose();
	};

	return (
		<MenuItem onClick={openForm}>
			<ListItemIcon className='user-menu-icon'>
				<IconUse icon={'userkey'} style={{'width':'18px', 'height':'18.5px' }}/>
			</ListItemIcon>
			{t('change-password')}
		</MenuItem>
	);
};

export default ChangePasswordOption;

export const ChangePasswordForm = () => {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { change_password_open } = useSelector(state => state.navigate);

	const onClose = () => dispatch(toggleChangePassword());

	const defaultValues = {
		old_password: "",
		password1: "",
		password2: "",
	};

	const ValidationSchema = yup.object().shape({
		old_password: stringValidation({ required: true, min: 8, max: 50 }),
		password1: stringValidation({ required: true, min: 8, max: 50 }),
		password2: stringValidation({ required: true, min: 8, max: 50 })
			.oneOf([yup.ref("password1"), null], t("password-not-match")),
	}, [
		/* evita el error de dependencias cliclicas poniendo un arrary de los pares que se validan */
		["password1", "password2"],
	]);

	const {
		reset,
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(ValidationSchema),
		mode: "onChange",
		defaultValues: defaultValues
	});

	useEffect(() => {
		if (change_password_open) {
			reset(defaultValues);
		}
		//eslint-disable-next-line
	}, [change_password_open]);

	const onSubmit = async (data) => {
		dispatch(lockedWindow());
		await usersApi.changePassword(data)
			.then(() => {
				onClose();
				showSuccessNotification(successUpdated());
			})
			.catch((error) => {
				resolveError(error);
			})
			.finally(() => {
				dispatch(unlockedWindow());
			});
	};

	const commonProps = {
		control: control,
		isRequired: true,
		fieldInput: "TextField",
		type: "password",
		Container: Grid,
		pasEye: true,
		containerProps: {
			item: true,
			xs: 12
		},
		onChange: noSpaces
	};

	const currentPassword = useWatch({ control, name: 'old_password' });
	const newPassword = useWatch({ control, name: 'password1' });
	const confirmPassword = useWatch({ control, name: 'password2' });

	const validateFields = () => {
		if (!currentPassword || !newPassword || !confirmPassword) {
			return true;
		}
		return (Object.keys(errors).length > 0);
	};

	return (
		<DialogForm
			title={`${t("password")}`}
			open={change_password_open ?? false}
			isEdit={true}
			onClose={onClose}
			handleSubmit={handleSubmit}
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			maxWidth={"sm"}
			fieldsLabels={{
				"old_password": t("current-password"),
				"password1": t("new-password"),
				"password2": t("confirm-password"),
			}}
			disableSave={validateFields()}
		>
			<Grid container spacing={2}>
				<FormInput
					{...commonProps}
					name="old_password"
					label={t("current-password")}
				/>

				<FormInput
					{...commonProps}
					name="password1"
					label={t("new-password")}
				/>

				<FormInput
					{...commonProps}
					name="password2"
					label={t("confirm-password")}
				/>
			</Grid>
		</DialogForm >
	);
};
