import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { resolveError } from '../../../../../../../../general/@components/requests/resolve-error';
import { useDispatch } from 'react-redux';
import { lockedWindow, showConfirmNotification, unlockedWindow } from '../../../../../../../../../store/actions';
import { overtimeMopersAPI, shiftsAPI } from '../../../../../../../../general/services/mopers';
import { each, isFunction, size } from 'lodash';
import { showSuccessNotification } from '../../../../../../../../general/@components/Notifications';
import { useTranslation } from 'react-i18next';
import useRequestLoad from '../../../../../../../../general/services/useRequestLoad';

export const MODAL_KEY = 2;

const initValues = { option: 1, checks: [] };

function useDayCalculate({
    modalValues,
    handleClose,
    handleRefresh,
    worker,
    selected,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { value: open } = modalValues[MODAL_KEY];

    const initialChoice = selected?.overtime?.type_calculation ?? selected?.type_calculation;

    const hasRequests = size(selected?.overtime?.request_details) > 0;

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
    } = useForm({
        // resolver: yupResolver(otValidationSchema),
        defaultValues: initValues,
        mode: "onChange",
    });

    const [schedules, setSchedules] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [fetch, loading] = useRequestLoad();
    const [fetchResolve, loadingResolve] = useRequestLoad();
    const [fetchOvertime] = useRequestLoad();

    useEffect(() => {
        if (open) {
            reset({ option: initialChoice });
            initializeChecks(selected?.checks);
            getSchedules();
        }
    }, [open])

    async function onSubmit(e, pass = false) {
        if (e.option !== initialChoice && hasRequests && pass !== true) {
            dispatch(showConfirmNotification({
                maxWidth: 'sm',
                title: t('warning-general-title'),
                message: 'Los mopers de este día serán cancelados',
                description: 'Los mopers automáticos serán cancelados y recalculados',
                onConfirm: () => onSubmit(e, true),
            }));
            return;
        }
        dispatch(lockedWindow());
        const request = {
            worker: worker?.id,
            origin_day: selected?.origin_day,
            type_calculation: e?.option,
        };
        try {
            const response = await overtimeMopersAPI.changeCalc(request);
            handleClose(MODAL_KEY);
            dispatch(unlockedWindow());
            if (isFunction(handleRefresh)) {
                handleRefresh(false, selected);
            }
            showSuccessNotification(response?.detail);
        } catch (error) {
            dispatch(unlockedWindow());
            resolveError(error);
        }
    }

    const getSchedules = () => {
        fetch({
            api: shiftsAPI.get({
                tree: true,
                worker_shift: worker?.id,
            }),
            callback: setSchedules
        });
    }

    const resolveIncidence = async (check, shift, index) => {
        setValue(`checks.[${index}].loading`, true);
        await fetchResolve({
            api: overtimeMopersAPI.resolveIncidence({
                check: check?.id,
                worker: worker?.id,
                origin_day: selected?.origin_day,
                shift,
            }),
            callback: async (res) => {
                showSuccessNotification(res?.detail);
                if (isFunction(handleRefresh)) {
                    handleRefresh(false, selected);
                }
                const newChecks = await fetchOvertime({
                    api: overtimeMopersAPI.get({
                        worker: worker.id,
                        initial_date: selected?.origin_day,
                        end_date: selected?.origin_day,
                        // fields: 'checks',
                        tree: true,
                    }),
                });
                initializeChecks(newChecks?.[selected?.origin_day]?.checks);
            },
        });
        setValue(`checks.[${index}].loading`, false);
    }

    const initializeChecks = checks => {
        each(checks, item => {
            item.check_id = item.id;
            const calculatedData = item.clock_process?.[0];
            if (calculatedData?.incidence_resolved) {
                item.schedule = calculatedData?.shift;
            }
        });
        setValue('checks', checks);
    }

    const onExpand = () => setExpanded(prev => !prev);

    const onClose = () => {
        handleClose(MODAL_KEY);
        reset({ ...initValues });
        setExpanded(false);
    }

    return {
        control,
        worker,
        loading,
        loadingResolve,
        schedules,
        expanded,
        onExpand,
        setValue,
        getValues,
        onClose,
        resolveIncidence,
        handleSubmit,
        initialChoice,
        onSubmit,
        selected,
        open,
    };
}

export default useDayCalculate;