import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as EndSvg } from "../../assets/svg/tab_end.svg";
import MuiIB from '@mui/material/IconButton';
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { motion } from 'framer-motion';

export const Tab = ({
	active = false,
	title = "",
	onClick,
	onClose,
	onDoubleClick = () => { },
}) => {

	const handleClose = e => {
		e.stopPropagation();
		onClose(e);
	}

	return (
		<motion.div
			key={`tab-content-${title}`}
			className={`abrhil-window-tab ${active ? "active" : ""}`}
			initial={{ x: '-100%', opacity: 0 }}
			animate={{ x: 0, opacity: 1 }}
			exit={{ x: '-100%', opacity: 0 }}
			transition={{ type: 'linear' }}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
			layout="position"
		>
			<div className="tab-start">
				<EndSvg />
			</div>
			<div className="tab-content">
				<span className='tab-text'>
					{title}
				</span>
				<span className='tab-close-container'>
					<MuiIB size={"small"} onClick={handleClose}>
						<FontAwesomeIcon icon={faXmark} />
					</MuiIB>
				</span>
			</div>
			<div className="tab-end">
				<EndSvg />
			</div>
			<div className='tab-shadow'></div>
		</motion.div>
	);
};
