import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authenticationApi } from "../../../../../general/services/authentication";
import { resolveError } from "../../../../../general/@components/requests/resolve-error";


function useGetLoginMode() {
    const tenant = useSelector(state => state.tenant?.current?.id);
    const [loginMode, setLoginMode] = useState(null);

    useEffect(() => {
        getLoginMode(tenant);
    }, [tenant])

    const getLoginMode = async (tenant) => {
        try {
            const resp = await authenticationApi.loginMode({ tenant });
            setLoginMode(resp?.code);
        } catch (error) {
            resolveError(error);
        }
    }

    return loginMode;
};

export default useGetLoginMode;