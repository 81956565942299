import {
	ENABLE_FOOD_LIMIT,
	DISABLE_FOOD_LIMIT
} from '../actions'

const initialState = {
  variable_option: false
}

export function variable_food_limit(state = initialState, action) {
  switch (action.type) {
    case ENABLE_FOOD_LIMIT:
      return {
        ...state,
        variable_option: true
      };

    case DISABLE_FOOD_LIMIT:
      return {
        ...state,
        variable_option: false
      };

    default:
      return state;
  }
}