// Notification sounds
import successSound from '../../../../assets/audio/success.mp3';
import notificationSound from '../../../../assets/audio/notification.mp3';
import errorSound from '../../../../assets/audio/error.mp3';

export const playSuccess = () => {
	const audio = new Audio(successSound);
	return audio.play();
};

export const playNotification = () => {
	const audio = new Audio(notificationSound);
	return audio.play();
};

export const playError = () => {
	const audio = new Audio(errorSound);
	return audio.play();
};