import { store } from "../../../../store";

export function currentUserInfo() {
    return store.getState().userConfigs;
}

export function currentGroup() {
    const group = store.getState().group;
    if (group?.data.length === 0) {
        return 0;
    }
    return group?.select?.id ?? null;
}