import PropTypes from "prop-types";
import React from 'react';
import { useTranslation } from "react-i18next";
import DialogActions from '../../../styled/DialogActions';
import Button from '../../../styled/Button';
import { isFunction } from 'lodash';

function Actions({
	onClose,
	selectionLength,
	finishSelection,
	withFinishSelection = true,
	withAcceptButton = false,
	handleCloseDialogForm
}) {

	const { t } = useTranslation();

	return (
		<DialogActions>
			{isFunction(onClose) &&
				<Button design="flat" color="error" onClick={onClose}>
					{t("cancel")}
				</Button>
			}
			{withFinishSelection &&
				<Button design="contained" disabled={!selectionLength} onClick={finishSelection} >
					{t("select")}
				</Button>
			}
			{withAcceptButton &&
				<Button design="contained" disabled={!selectionLength} onClick={handleCloseDialogForm} >
					{t("accept")}
				</Button>
			}
		</DialogActions>
	);
};

Actions.propTypes = {
	finishSelection: PropTypes.func,
	handleCloseDialogForm: PropTypes.func,
	onClose: PropTypes.func,
	selectionLength: PropTypes.any,
	withAcceptButton: PropTypes.bool,
	withFinishSelection: PropTypes.bool
};

export default Actions;