import React, { useEffect, useRef } from "react";

// Util imports
import { API_WS } from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Toastify
import { toast } from 'react-toastify';

// Sockets registered in system
import { SocketsDictionary } from "./SocketsDictionary";

// General utilities
import { each, isEmpty, isFunction, isNumber, isObject, omit, size, some } from "lodash";
import { hasValue, valueOrOption } from '../general/GeneralUtilities';
import { socketsApi } from "../../services/socket-report";
import { playError, playSuccess } from '../Notifications/sounds';
import { autoCloseFalse } from './constants';
import { getTheme, useRepoName } from './utilities';
import { showControlledError } from '../../../../store/actions';
import BodyMessage from './BodyMessage';
import { socketEventEmitter } from "../Notifications/trigger-notification-socket";
import { showErrorNotification, showSuccessNotification } from "../Notifications";
import { getToken } from "../../@components/general/auth";

const errorStatus = ['FAILURE', 400];
const errorCodes = [400, 404];

const getApiUrl = (name) => {
	const token = getToken();
	return `${API_WS}/${name}?token=${token}`;
};

const useSocketsController = ({
	defaultFunctions = true,
	messageListener = null,
	onSuccessCalback = null,
	customDictionary = null,
	_tenant,
} = {}) => {

	const { t } = useTranslation('notifications');
	const dispatch = useDispatch();
	const nullValues = [null, 'null', undefined, 'undefined'];

	const reduxTenant = useSelector((state) => state.userConfigs?.current_tenant);
	const tenant = _tenant || reduxTenant;
	const theme = useSelector((state) => state.app?.theme);
	const socketDictionary = customDictionary ? customDictionary(tenant) : SocketsDictionary(tenant);

	const isReconnect = useRef(false);
	const socket_connections = useRef([]);
	const sockets_queue = useRef([]);

	const getSocketByName = (socketName) => socketDictionary[socketName] ?? {};

	const definedSocket = (socketName, taskID, callback, module) => {
		const foundSocket = getSocketByName(socketName);
		if (isEmpty(foundSocket) || !foundSocket) return;

		const { url, cancel_api } = foundSocket;
		const alreadyConnected = sockets_queue.current.some(socket => socket.url === url);

		sockets_queue.current.push({
			taskID,
			socketName,
			cancel_api,
			url,
			callback,
			module,
			percentage: 0,
			finished: false,
			dimissed: false
		});

		if (defaultFunctions) {
			QueueNotification(socketName, taskID, url);
		}
		if (!alreadyConnected) {
			connect(url);
		}
	};

	const handleHide = task => updateTask(task, 'dimissed', true);

	const handleFinishTask = task => updateTask(task, 'finished', true);

	const updateTask = (task, field, value) => {
		const taskIndex = sockets_queue.current.findIndex(el => el.taskID === task);
		if (taskIndex >= 0) {
			sockets_queue.current[taskIndex][field] = value;
		}
	}

	const getActiveSocket = (url) => socket_connections.current?.find(el => el?.url?.includes(url));

	const QueueNotification = (socketName, taskID, url) => {
		toast(
			<BodyMessage
				taskId={taskID}
				message={t("waiting-report", { report_name: useRepoName(socketName) })}
				percentage={0}
				currentTask={getActualTask(taskID)}
				onCancel={() => {
					const socket = getActiveSocket(url);
					handleCancel(taskID, socket);
				}}
			/>,
			{
				toastId: taskID,
				closeButton: false,
				closeOnClick: false,
				isLoading: true,
				theme: getTheme(),
				onClose: () => handleHide(taskID),
			});
	};

	const handleThemeChange = () => {
		if (!defaultFunctions) {
			return;
		}
		const newTheme = getTheme();
		each(sockets_queue.current, (task) => {
			toast.update(task.taskID, {
				theme: newTheme,
			});
		});
	};

	const buildMessage = (message) => {
		if (!hasValue(message)) {
			return "";
		}
		return t(message);
	};

	const getActualTask = (taskId) => sockets_queue.current?.find(el => el?.taskID === taskId) ?? {};

	const getTaskCallbacks = (taskId) => {
		const task = getActualTask(taskId);
		const callbackWs = socketDictionary[task?.socketName]?.callbackWS;
		const callback = task?.callback;
		return { callbackWs, callback };
	}

	const massiveDisconnect = () => {
		each(socket_connections.current, (socket) => socket?.close());
		socket_connections.current.length = 0;
		sockets_queue.current.length = 0;
		isReconnect.current = false;
		localStorage.removeItem('sockets_queue');
		localStorage.removeItem('isReconnect');
		removeToast();
	};

	const handleSocket = ({ name, taskID, callback, module }) => {
		if (!name || !taskID) return;
		definedSocket(name, taskID, callback, module);
	};

	const handleMassiveAbort = () => massiveDisconnect();

	const initialize = (queue_backup) => {
		sockets_queue.current = JSON.parse(queue_backup);
		if (sockets_queue.current.length > 0 && isReconnect.current) {
			each(sockets_queue.current, (task) => {
				task.dimissed = false;
				QueueNotification(task.socketName, task.taskID, task.url);
				const alreadyOpen = socket_connections.current?.some(el => el?.url?.includes(task?.url));
				if (!alreadyOpen) {
					connect(task.url);
				}
			});
		}
		localStorage.removeItem('sockets_queue');
		localStorage.removeItem('isReconnect');
	};

	useEffect(() => {
		if (!defaultFunctions) {
			return;
		}
		if (!isNumber(tenant)) return;
		const sockets_queue_backup = localStorage.getItem('sockets_queue');
		isReconnect.current = !nullValues.includes(localStorage.getItem('isReconnect'));
		if (!nullValues.includes(sockets_queue_backup) && isReconnect) {
			initialize(sockets_queue_backup);
			return;
		}
		removeToast();
		handleThemeChange();
	}, [tenant]);

	useEffect(() => {
		if (!isNumber(tenant)) return;
		socketEventEmitter.on('socket_connection', handleSocket);
		socketEventEmitter.on('massive_socket_abort', handleMassiveAbort);
		return () => {
			socketEventEmitter.removeListener('socket_connection', handleSocket);
			socketEventEmitter.removeListener('massive_socket_abort', handleMassiveAbort);
		}
	}, [tenant]);

	useEffect(() => {
		if (!defaultFunctions) {
			return;
		}
		handleThemeChange();
		//eslint-disable-next-line
	}, [theme]);

	const handleUnload = () => {
		if (sockets_queue.current.length) {
			isReconnect.current = true;
			localStorage.setItem("sockets_queue", JSON.stringify(sockets_queue.current?.map(el => omit(el, ['callback']))));
			localStorage.setItem('isReconnect', isReconnect.current);
		}
	}

	const handleOffline = () => {
		if (sockets_queue.current.length) {
			isReconnect.current = true;
			localStorage.setItem('isReconnect', isReconnect.current);
			each(socket_connections.current, (socket) => socket?.close());
			socket_connections.current.length = 0;
			removeToast();
		};
	}

	const handleOnline = () => {
		const sockets_queue_backup = localStorage.getItem('sockets_queue');
		isReconnect.current = !nullValues.includes(localStorage.getItem('isReconnect'));
		if (!nullValues.includes(sockets_queue_backup) && isNumber(tenant) && isReconnect.current) {
			initialize(sockets_queue_backup);
		}
	}

	const loadListeners = () => {
		if (!defaultFunctions) return;
		window.addEventListener('beforeunload', handleUnload);
		window.addEventListener('offline', handleOffline);
		window.addEventListener('online', handleOnline);
	};

	const unloadListeners = () => {
		if (!defaultFunctions) return;
		window.removeEventListener('beforeunload', handleUnload);
		window.removeEventListener('offline', handleOffline);
		window.removeEventListener('online', handleOnline);
	}

	useEffect(() => {
		if (!isNumber(tenant)) return;
		loadListeners();
		return () => unloadListeners();
	}, [tenant]);

	const handleCancel = async (taskID, socket) => {
		try {
			const apiName = getActualTask(taskID)?.cancel_api;
			if (apiName) {
				await socketsApi.cancelTask(taskID, apiName);
			}
			disconnect(socket, taskID);
		} catch (error) {
			console.error('Error al cancelar la tarea', taskID);
		}
	};

	function onReconnect(socket) {
		each(sockets_queue.current, task => {
			if (socket.url.includes(task.url)) {
				const body = {
					"type": "task_status_message",
					"task": task.taskID,
				};
				if (task.percentage < 1 && !task.finished) {
					socket.send(JSON.stringify(body));
				}
			}
		});
	}

	const onSocketError = (socket) => {
		each(sockets_queue.current, (task) => {
			if (socket.url.includes(task.url)) {
				disconnect(socket, task.taskID);
			}
		});
		showErrorNotification(
			t("socket-conection-error"),
			autoCloseFalse(isReconnect.current, playError)
		);
	};

	const onFinalMessage = (message, response) => {
		const finalMessage = message?.filename ? `${t('success')}: archivo descargado` : message.message;
		if (!response) {
			showSuccessNotification(
				buildMessage(valueOrOption(finalMessage, 'success')),
				autoCloseFalse(isReconnect.current, playSuccess)
			);
		} else {
			showErrorNotification(
				t(`download-error`),
				autoCloseFalse(isReconnect.current, playError)
			);
		}
	};

	const onFinish = async (message_obj) => {
		const finalMessage = valueOrOption(message_obj, {});
		const { task, message, filename } = finalMessage;
		const { callback, callbackWs } = getTaskCallbacks(task);
		handleFinishTask(task);

		if (!defaultFunctions) {
			playSuccess();
			if (isFunction(onSuccessCalback)) {
				await onSuccessCalback(task, filename);
			}
			return;
		}

		const hasWScall = isFunction(callbackWs);
		const hascall = isFunction(callback);

		if (hasWScall) {
			const response = await callbackWs(task, filename);
			if (!hascall) {
				return onFinalMessage(message_obj, response);
			}
		}

		if (hascall) {
			const response = await callback(task, filename);
			return onFinalMessage(message_obj, response);
		}

		showSuccessNotification(
			buildMessage(valueOrOption(message, 'success')),
			autoCloseFalse(isReconnect.current, playSuccess)
		);
	};

	const onUpdateMessage = (message_obj, socket) => {
		const {
			step,
			message,
			task,
			percentage,
			subprocess_message,
			subprocess_percentage,
		} = valueOrOption(message_obj, {});

		const newPercentage = (percentage ?? 0) * 0.01;
		updateTask(task, 'percentage', newPercentage);

		toast.update(task, {
			render:
				<BodyMessage
					taskId={task}
					currentTask={getActualTask(task)}
					onCancel={(id) => handleCancel(id, socket)}
					message={buildMessage(valueOrOption(step, message))}
					percentage={parseInt(percentage ?? 0)}
					subprocess_message={subprocess_message}
					subprocess_percentage={subprocess_percentage}
				/>,
			progress: newPercentage,
			closeOnClick: false,
			closeButton: false,
			onClose: () => handleHide(task),
			isLoading: true,
			theme: getTheme(),
		});
	};

	const onMessageError = async (taskData = {}, playSound = true) => {
		const { task, filename, message } = taskData;
		const { callback } = getTaskCallbacks(task);
		removeToast(task);
		showErrorNotification(
			buildMessage(valueOrOption(message, 'failure')),
			autoCloseFalse(isReconnect.current, playSound ? playError : null)
		);
		if (isFunction(callback)) {
			await callback(task, filename);
		}
	};

	const onInitMessage = (message) => {
		toast(
			<BodyMessage
				taskId={message.task}
				percentage={0}
				currentTask={getActualTask(message.task)}
			/>,
			{
				toastId: message.task,
				position: 'top-right',
				progress: message.percentage * 0.01,
				closeOnClick: false,
				closeButton: false,
				onClose: () => handleHide(message.task),
				isLoading: true,
				theme: getTheme(),
			}
		);
	};

	const performCustomListener = (args) => {
		if (!defaultFunctions) {
			if (isFunction(messageListener)) {
				messageListener(args);
			}
			return true;
		}
		return false;
	};

	const handleInitialMsg = (message) => {
		if (performCustomListener({ message })) {
			return;
		}
		onInitMessage(message);
	};

	const handleFailureMsg = (task, details, message) => {
		if (!isReconnect.current) {
			playError();
		};

		const detail_code = isObject(details) ? details?.detail_code : "unespected-task-error"
		const isUncontroled = size(details) && (!hasValue(detail_code) || detail_code !== "unespected-task-error");
		if (performCustomListener({ message, details, isUncontroled, fields: details })) {
			return;
		}

		if (isUncontroled) {
			removeToast(task);
			return dispatch(showControlledError({ fields: details }));
		}

		return onMessageError(message, false);
	};

	const handleNewStateMsg = (message, socket) => {
		if (performCustomListener({ message, socket })) {
			return;
		}
		onUpdateMessage(message, socket);
	};

	const handleSocketMessage = async (message, socket) => {
		const { status, code, task, details } = valueOrOption(message, {});
		const { dimissed, finished } = getActualTask(task);
		const hasStatus = [...errorStatus, 'SUCCESS'].includes(status);
		const errorCode = errorCodes.includes(code);
		const hasErrorStatus = errorStatus.includes(status);

		if (!hasStatus && !errorCode && !dimissed) {
			handleInitialMsg(message);
		}

		if (hasErrorStatus || errorCode) {
			handleFailureMsg(task, details, message);
		}

		if (!hasErrorStatus && !errorCode && !dimissed) {
			handleNewStateMsg(message, socket);
		}

		if (status === 'SUCCESS' && !finished) {
			await onFinish(message);
		}

		if (hasStatus || errorCode) {
			disconnect(socket, task);
		}
	};

	const connect = (path) => {
		const url = getApiUrl(path);
		socket_connections.current.push(new WebSocket(url));

		each(socket_connections.current, (socket) => {
			socket.onopen = () => {
				onReconnect(socket);
			};

			socket.onmessage = (e) => {
				const message = JSON.parse(e.data);
				handleSocketMessage(message, socket);
			};

			socket.onerror = () => {
				onSocketError(socket);
			};
		});
	};

	const disconnect = (socket, task_id) => {
		if (!sockets_queue.current.some(task => task.taskID === task_id)) return;
		removeToast(task_id);
		sockets_queue.current = sockets_queue.current.filter(task => task.taskID !== task_id);
		const existsTasks = some(sockets_queue.current, (task) => socket.url.includes(task.url));
		if (!existsTasks) {
			socket?.close();
		}
		if (!sockets_queue.current.length) {
			socket_connections.current.length = 0;
			isReconnect.current = false;
		}
	};

	const removeToast = id => {
		if (id) {
			const { percentage } = getActualTask(id);
			if (toast.isActive(id) && (percentage < 1 || !percentage)) {
				toast.dismiss(id);
			}
			return;
		}
		toast.dismiss();
	};

	return {
		connect,
		onFinish,
		disconnect,
		initialize,
		onReconnect,
		handleCancel,
		handleSocket,
		buildMessage,
		onSocketError,
		onInitMessage,
		definedSocket,
		getActualTask,
		onFinalMessage,
		onMessageError,
		onUpdateMessage,
		getSocketByName,
		getActiveSocket,
		QueueNotification,
		handleThemeChange,
		massiveDisconnect,
		handleMassiveAbort,
		handleSocketMessage,
	};

};

export default useSocketsController;