import React from 'react';
import { format, parseISO } from 'date-fns';

function TooltipCancel({ cancelSign, checkItem, }) {
  const parsedDate = parseISO(cancelSign.signed_at, 'yyyy-MM-dd', new Date());

  return (
    <div>
      <div>{`Cancelado por: ${cancelSign.signed_by}`}</div>
      <div>{`Fecha y hora de cancelación: ${format(parsedDate, 'dd/MM/yyyy hh:mm a')}`}</div>
      <div>{`Comentario de cancelación: ${checkItem.cancel_comment}`}</div>
    </div>
  )
}

export default TooltipCancel