import React from "react";
import { isFunction } from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/pro-light-svg-icons";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from "@mui/material/CircularProgress";
import { AdornmentProps, ClearAdornProps } from './propsDescriptions';
import { hasValue } from '../../../general/GeneralUtilities';

export const SearchAdorment = ({
	onSearch,
	disabled,
	isLoading,
	disableSearch,
	...others
}) => {

	if (isLoading) {
		return <LoadingAdorn />;
	}

	const handleOnSearch = event => {
		if (isFunction(onSearch) && !disableSearch) {
			onSearch(event);
			return;
		}
		return event;
	};

	return (
		<InputAdornment position={"end"} tabIndex={-1} className="kendoAdornment">
			<ClearAdorn {...others} disabled={disabled} />
			<IconButton
				className="k-button-icon asKendoButton search-input-button"
				name={"search-input-button"}
				disabled={disabled || disableSearch || !isFunction(onSearch)}
				onClick={handleOnSearch}
				aria-label="search adornment"
				tabIndex={-1}
			>
				<FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />
			</IconButton>
		</InputAdornment>
	);
};

SearchAdorment.propTypes = AdornmentProps;

const LoadingAdorn = () => (
	<InputAdornment
		position={"end"}
		tabIndex={-1}
		className="kendoAdornment search-input-button"
		name={"search-loader-button"}
	>
		<CircularProgress size={20} sx={{ marginInline: '8px' }} />
	</InputAdornment>
);

const ClearAdorn = ({
	value,
	hideClear,
	disabled,
	disableInput,
	onClear
}) => {

	if (!hasValue(value) || hideClear || disabled || disableInput) {
		return null;
	}

	return (
		<IconButton
			className="k-button-icon search-input-button"
			name={"search-cancel-button"}
			onClick={onClear}
			edge={"end"}
			aria-label="clear adornment"
			tabIndex={-1}
		>
			<FontAwesomeIcon icon={faXmark} size="xs" />
		</IconButton>
	);
};

ClearAdorn.propTypes = ClearAdornProps;