import React from "react";
import {
    faXmark,
    faMagnifyingGlass,
} from '@fortawesome/pro-solid-svg-icons';
import { scaleAnimation } from "./Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";

export const CustomSearch = ({
    searchRef,
    search,
    handleClear,
    onChange,
    maxWidth,
}) => {
    return (
        <div
            className="permissions-search-box"
            style={{ maxWidth: maxWidth ?? '100%'}}
        >
            <input
                id="search-input"
                ref={searchRef}
                className="permissions-input"
                placeholder="Buscar"
                onChange={onChange}
            />
            <div
                className="icon-container"
                onClick={() => search && handleClear()}
            >
                <AnimatePresence mode='wait'>
                    {
                        !search ?
                            <motion.div
                                key='search-icon'
                                {...scaleAnimation}
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </motion.div>
                            :
                            <motion.div
                                key='remove-icon'
                                {...scaleAnimation}
                            >
                                <FontAwesomeIcon icon={faXmark} />
                            </motion.div>
                    }
                </AnimatePresence>
            </div>
        </div>
    );
}