import React from "react";
import { size } from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from '@mui/material/Badge';
import MuiIB from '@mui/material/IconButton';/* IconButton */
import { CustomTooltip } from "../../../../Templates/cells";
import { hasValue } from '../../../../general/GeneralUtilities';
import useAbrhilSideMenu from '../../../contextsAndControllers/useSideMenu';
import ExternalOpen from '../Templates/ExternalOpen';
import FixedWindow from '../Templates/FixedWindow';
import IconUse from '../../../../uiDesign/IconUse';
import { motion } from "framer-motion";
import useAbrhilNavigation from "../../../contextsAndControllers/useAbrhilNavigation";

export const NavItem = ({
	to,
	className,
	handleClick,
	children,
	item,
	title,
}) => {
	const noLink = [undefined, null, "", "#"].includes(to);

	const { setShowed } = useAbrhilNavigation();

	const {
		openWindowItem,
		active_elements,
		onFixedWindow,
		current_location
	} = useAbrhilSideMenu();

	const {
		id,
		icon,
		path,
		is_accesible,
	} = item ?? {};

	const notAccess = is_accesible === false;

	const onOpen = () => {
		if (notAccess) return;
		handleClick();
		setShowed(null);
		openWindowItem(item);
	};

	const onFixed = () => {
		onFixedWindow(item);
		handleClick();
	};

	const isActive = active_elements.includes(id);

	const onSubmit = (e) => {
		e.preventDefault();
		onOpen();
	};

	if (noLink) {
		return (
			<motion.span className={`noLink ${className}`} layout="position">
				{children}
			</motion.span>
		);
	}

	return (
		<motion.li className={`li-with-icon`} layout="position">
			<form onSubmit={onSubmit} className="hidden-action-nav-button" >
				<button type="submit" name='hidden-action-nav-button' />
			</form>
			<span className={`${isActive ? "selected" : ""} ${notAccess ? 'disabled' : ''}`}>
				<span className='referer navigation-text' onClick={onOpen}>
					<ItemIcon icon={icon} />
					{title}
				</span>
				<ExternalOpen onClick={handleClick} path={path} show={false} />
				<FixedWindow onClick={onFixed} location={current_location} path={path} show={false} />
				<span className='cover-line' onClick={onOpen} />
			</span>
		</motion.li>
	);
};

export const UlItem = ({
	item,
	children,
}) => {

	const childrenSize = size(item.children) > 0;

	return (
		<motion.div className='search-items-group' layout="position">
			<div className={`ul-header`}>
				{item.title}
			</div>
			{childrenSize &&
				<ul className='menu-searched-list'>
					{children}
				</ul>
			}
		</motion.div>
	);
};

export const ItemIcon = ({ icon }) => {

	if (!hasValue(icon)) { return null; }

	return <IconUse icon={icon} className={`metismenu-icon custom-abr`} />;
};

/**
 *
 * @param {object} params
 * @param {function} params.onClick -función acción del botón
 * @param {node} params.icon - tiene que ser un icon de fontawesome
 * @param {node} params.altIcon - tiene que ser un icon de fontawesome
 * @param {object} params.buttonProps - objeto con propiedades del botón
 * @param {object} params.iconProps - objeto con propiedades del icono
 * @param {*} params.children -Cualquier elemento que se quiera incluid dentro del botón
 * @param {?"before"|"after"} params.childrenPosition - En que posición va a estar el elemento incluido, por default "after"
 * @param {?string|number} params.badgeContent - Contenido del badge
 * @param {?string} params.title - El titulo a mostrar al posicionarse sobre el botón
 * @returns {ButtonNode}
 */
export const HeadButton = ({
	onClick,
	icon,
	altIcon,
	buttonProps,
	iconProps,
	children,
	childrenPosition = "after",
	badgeContent,
	title = "",
}) => (
	<CustomTooltip title={title} placement="top" >
		<MuiIB
			size={"small"}
			{...(buttonProps || {})}
			className={`${buttonProps?.className ?? ""} actionButton ${altIcon ? "hasAlt" : ""}`}
			onClick={onClick}
		>
			{childrenPosition === "before" && children}
			<Badge color="primary" badgeContent={badgeContent || 0}>
				{altIcon && <FontAwesomeIcon {...(iconProps || {})} className={`${iconProps?.className ?? ""} alt`} icon={altIcon} />}
				<FontAwesomeIcon {...(iconProps || {})} className={`${iconProps?.className ?? ""} normal`} icon={icon} />
			</Badge>
			{childrenPosition === "after" && children}
		</MuiIB>
	</CustomTooltip>
);