import es from 'date-fns/locale/es';
import { format, parseISO } from 'date-fns';
import { size, upperFirst } from 'lodash';
import { hasValue, parseDatetime, valueOrOption } from '../../../../../../general/@components/general/GeneralUtilities';
import { dateFormat } from '../../../../../../general/@components/Inputs/Dates/utilities';

// General utility for dates on attendanceList
export const parseDate = (date, formatter = dateFormat, type = 'format') => {
    const dateWithFormat = new Date(parseISO(date, { representation: 'complete' }));
    switch (type) {
        case 'format':
            return parseDatetime(date, formatter, typeof date === "string");
        case 'date':
            return dateWithFormat;
        case 'custom':
            return format(dateWithFormat, formatter, { locale: es });
        default:
            return "";
    }
}

// Costruct grid colums
export const showCell = (data, currentView, column) => {
    const actualViewColumns = valueOrOption(data?.[currentView], []);
    const showColumn = actualViewColumns.includes(column);
    return showColumn;
}

// Get different types of date labels
export const dayTitle = (currentDate, type) => {
    const tooltip = parseDate(currentDate, 'PPP', "custom");
    const day = parseDate(currentDate, 'iii', "custom");
    const completeDay = parseDate(currentDate, 'eeee', "custom");
    const dayNumber = parseDate(currentDate, 'dd', "custom");
    let title = currentDate;

    switch (type) {
        case 'compact':
            title = `${upperFirst(day)} ${dayNumber}`;
            break;
        case 'detail':
            title = `${upperFirst(completeDay)} ${dayNumber}`;
            break;
        case 'tooltip':
            title = tooltip;
            break;
        default:
            break;
    }

    return title;
}

// Get assistChecks by worker
export const getTotalChecks = (dataItem) => {
    return Object.values(dataItem?.attendance_list)
        .map((m) => m.checks?.length)
        .filter((f) => f)?.length;
}

// Verify headerDayCell background -> returns true if is sunday
export const getRestDayBg = (currentDate, holidays = []) => {
    if (hasValue(holidays)) {
        return true;
    }
    const newDate = parseDate(currentDate, 'iii', "custom");
    return newDate === 'dom';
}

// Verify if worker row is selected
export const isWorkerSelected = (selected, currentItem) => {
    return selected?.id === currentItem?.id;
}

export const isDaySelected = (selected, currentWorker, currentDate) => {
    return selected?.currentDate === currentDate && isWorkerSelected(selected?.worker, currentWorker);
}

// Ausen perms
export const ausenPerms = (data) => {
    const items = data?.filter(element => element?.moper_catalog?.key.includes('ausen') && element?.is_active);

    return size(items) > 0;
}