import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { Breadcrumb as Component } from "@progress/kendo-react-layout";
import useAbrhilNavigation from '../../contextsAndControllers/useAbrhilNavigation';
import { HeadButton } from '../SideMenu/Search/NavItem';
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

const Breadcrumb = () => {

	const { t } = useTranslation();
	const location = useLocation();
	const { breadcrumbs, backToInit } = useAbrhilNavigation();
	const [items, setItems] = useState([]);

	const onBack = () => {
		if (!location?.pathname?.includes("packages")) {
			return backToInit();
		}
		window.close();
		return backToInit();
	};

	useEffect(() => {
		const maped = [...breadcrumbs].map((item, index) => {
			return {
				id: index.toString(),
				key: index,
				text: item,
				disabled: true,
			};
		});
		setItems(maped);
	}, [breadcrumbs]);

	return (
		<>
			<Component
				data={items}
				breadcrumbDelimiter={BreadLimiter}
				className="generalBreadcumb"
			/>
			{!location?.pathname?.includes("welcome") && (
				<HeadButton
					icon={faXmark}
					buttonProps={{ color: "error", }}
					iconProps={{ fade: true }}
					badgeContent={0}
					title={t("back-to-init")}
					onClick={onBack}
				/>
			)}
		</>
	);
};

export default Breadcrumb;

const BreadLimiter = () => {
	return <FontAwesomeIcon icon={faAngleRight} size={"2xs"} />;
};