import PropTypes from "prop-types";
import React from 'react';
import { useTranslation } from "react-i18next";
import { pick } from "lodash";
import {
	faOctagonExclamation,
	faLock as lockL
} from "@fortawesome/pro-light-svg-icons";
import {
	faOctagonExclamation as faOctagonExclamationAlt,
	faTrash as trashS,
	faPen as peSL,
	faLock as lockS
} from "@fortawesome/pro-solid-svg-icons";
//--components
import PermissionsGate from '../../../permissions/PermissionsGate';
import IconButton from '../../Buttons/IconButton';
import { permsGate } from "../../navigation/utilities";

/**
 * Template general para las acciones de las tablas en el sistema
 * @param {object} params
 * @param {string} params.rowType - Es el tipo de fila que se está validando, este parametro nos lo da las propiedades de la celda de kendo
 * @param {object} params.dataItem - El item de la filaque se está apuntando, de igual forma nos lo da las propiedades de la celda de kendo, aunque las podemos sobre escribir a conveniencia
 * @param {?string} params.className - Inidica una clase custom a aplicar sobrew la celda, NOTA : kendo ya da la propiedad necesaria, si se sobreescribe hay que tenerlo en cuenta
 * @param {?object} params.style - Idica un style custom a aplicar sobre kla celda, NOTA : kendo ya da la propiedad necesaria, si se sobreescribe hay que tenerlo en cuenta
 * @param {?function} params.enterEdit - Funcion que se va a realizar al momento de dar click sobre el botón editar
 * @param {?function} params.enterDelete - Funcion que se va a realizar al momento de dar click sobre el botón eliminar
 * @param {?boolean} params.isEditable - Indica si el botón de edición se va a mostrar
 * @param {?boolean} params.isDeletable - Indica si el botón elimnar se va a mostrar
 * @param {*} params.children - Cualquier otro botón o elemento que se quiera incluir, se mostrara antes de los botones default
 * @param {?string|number} params.fontSize - El tamaño de fuente deseado para los iconos de los botones
 * @returns {ActionCellTemplate}
 * @example
 * <GridColumn
 * 	title={t('actions')}
 * 	cell={props => <ActionsColumn
 * 		{...props}
 * 		enterEdit={()=>alert("edit")}
 * 		enterDelete={()=>alert("delete")}
 * 	/>}
 * 	width={100}
 * 	locked
 * />
 */

const ActionsColumn = ({
	rowType,
	style = {},
	className = '',
	forCard = false,
	...others
}) => {

	if (rowType === "groupHeader") {
		return null;
	}

	if (forCard) {
		return (
			<div className={`${className} card-item-actions`}>
				<ActionsContent {...others} />
			</div>
		);
	}

	return (
		<td
			className={`${className} k-command-cell locked-btns`}
			style={{ ...style, textAlign: "center" }}
		>
			<ActionsContent {...others} />
		</td>
	);
};

ActionsColumn.propTypes = {
	className: PropTypes.string,
	forCard: PropTypes.bool,
	rowType: PropTypes.string,
	style: PropTypes.object
};

const ActionsContent = ({
	dataItem,
	enterEdit,
	enterDelete,
	onNoAction,
	//enterLocked,
	isEditable = true,
	isDeletable = true,
	editButtonProps = {},
	deleteButtonProps = {},
	lockedButtonProps = {},
	children,
	perms,
	...others
}) => {
	const { t } = useTranslation();
	const otherProps = pick(others, ["fontSize"]);

	const {
		is_edit = true,
		is_active = true,
		can_delete = true,
	} = dataItem ?? {};

	if (perms && !permsGate(perms)) {
		isDeletable = false;
		isEditable = false;
	};

	return (
		<>
			<NoActtionsAllowed onClick={onNoAction} />
			{children}
			{is_edit && isEditable && <ActionButton
				{...otherProps}
				title={t("edit")}
				buttonProps={editButtonProps}
				scope="write"
				icon={"edit"}
				altIcon={peSL}
				onClick={() => enterEdit(dataItem)}
			/>}
			{(is_active && isDeletable && can_delete) && <ActionButton
				{...otherProps}
				title={t("remove")}
				buttonProps={deleteButtonProps}
				scope="write"
				icon={"delete"}
				altIcon={trashS}
				onClick={() => enterDelete(dataItem)}
				color={"error"}
			/>}
			{!can_delete && isDeletable &&
				<ActionButton
					{...otherProps}
					title={t("delete-locked")}
					buttonProps={lockedButtonProps}
					scope="write"
					icon={lockL}
					altIcon={lockS}
				/>
			}
		</>
	);
};

ActionsContent.propTypes = {
	children: PropTypes.any,
	dataItem: PropTypes.any,
	deleteButtonProps: PropTypes.any,
	editButtonProps: PropTypes.any,
	enterDelete: PropTypes.func,
	enterEdit: PropTypes.func,
	isDeletable: PropTypes.bool,
	isEditable: PropTypes.bool,
	lockedButtonProps: PropTypes.any,
	onNoAction: PropTypes.func
};

export default ActionsColumn;

/**
 * Botón basse para las acciones de las tablas del sistema
 * @param {object} params
 * @param {string} params.scope - La accion de la cual se va a validar el permiso
 * @param {string} params.title - El título que se va a mostrar al posicionarse sobre el botón
 * @param {node} params.icon - Icono que se va a mostrar en el botón, tiene que ser de fontAwesome
 * @param {node} params.altIcon - Icono alterno que se va a mostrar en el botón al posicionarse sobre el, tiene que ser de fontAwesome
 * @param {function} params.onClick - Función quese va a realizar al hacer click sobre el botón
 * @param {?string} params.fontSize - Tamaño de la fuente del icono
 * @param {?string} params.color - El color que se le va a aplicar al icono
 * @returns {ActionButtonNode}
 */
export const ActionButton = ({
	scope,
	title = "",
	fontSize,
	buttonProps = {},
	iconProps = {},
	disabled = false,
	...others
}) => (
	<PermissionsGate scopes={[scope]}>
		<IconButton
			title={buttonProps.title ?? title ?? ""}
			buttonProps={{ ...buttonProps, ...pick(others, ["className", "color", "disabled"]) }}
			iconProps={{ ...iconProps, fontSize: (fontSize || "16px") }}
			disabled={disabled}
			{...pick(others, ["onClick", "icon", "altIcon", "perms"])}
		/>
	</PermissionsGate>
);

ActionButton.propTypes = {
	buttonProps: PropTypes.any,
	fontSize: PropTypes.any,
	iconProps: PropTypes.any,
	scope: PropTypes.any,
	title: PropTypes.string
};

export const NoActtionsAllowed = ({ onClick }) => {
	const { t } = useTranslation();
	return (
		<IconButton
			title={t("not-allowed-actions")}
			buttonProps={{ color: "warning", className: "not-allowed-actions" }}
			icon={faOctagonExclamation}
			altIcon={faOctagonExclamationAlt}
			onClick={onClick}
		/>
	);
};
NoActtionsAllowed.propTypes = {
	onClick: PropTypes.func
};
