import {
	isUndefined,
	capitalize,
	omit,
	isString,
	isNumber,
	size,
	toString,
	isFunction,
} from 'lodash';
import { selector, setFocus } from '../general/GeneralUtilities';

export const objectValue = (e, value, validate = true) => {
	try {
		if (size(e) && validate) {
			e.value = value;
			e.target.value = value;
			e.type = "change";
			return e;
		}
	} catch (error) {
		/* no error handler */
	}
	return {
		...e,
		type: "change",
		value: value,
		target: {
			...(e?.target ?? {}),
			value: value
		}
	};
};

export const targetValue = (e, asText = true) => {
	return e.target?.value ?? e.value ?? (isString(e) || isNumber(e) ? e : null) ?? (asText ? "" : null);
};

export const invalidCharsOnKeys = [
	'"\'`´',
	".:;º^",
	"~&¬ª°\\#@%\\|\\?¿",
	"\\=\\+\\-\\*/≤≥<>",
	"()",
	"\\[\\]",
	"{}",
];

/*
	Es una funcion que formatea el texto a mayusculas y no permite espaciós
	usado principalmente para los campos de clave del sistema
*/
export const keyFormat = e => {
	const charsGroup = invalidCharsOnKeys.join("");
	const exp = RegExp([
		"\\s", "\\n", "\\t", "\\",
		`[${charsGroup}]`,
	].join("|"), "g");

	const value = targetValue(e)
		.toUpperCase()
		.replaceAll(exp, '');

	return objectValue(e, value);
};

/*
	Está función convierte en mayuscula el contenido de los inputs
*/
export const capitalizeAll = e => {
	const value = targetValue(e).toUpperCase();
	return objectValue(e, value);
};


/*
	Está función capitaliza la primera letra del input,
	utilizado principalmente para los inputs de nombre del sistema
*/
export const capitalizeFirst = e => {
	const value = targetValue(e)
		.replace(/\n/g, 'abr_line_break') /* remplaza los saltos de linea por un caracter custom y que el siguiente replace no los borre */
		.replace(/\s+/g, ' ') /* solo permite un espació */
		.replace(/abr_line_break/g, '\n') /* regresa los saltos de linea */
		.replace(/^ |^\n/g, ""); /* No permite espaciós ni saltos de linea al inicio */
	return objectValue(e, value);
};

/*
	Está función capitaliza la primera letra del input y acepta mayúsculas
	o minúsculas en el resto de la cadena según el usuario lo requiera, es
	utilizado en algunos inputs de nombre y descripción.
*/
export const capitalizeFirstWithLowercase = e => {
	const value = targetValue(e)
		.replace(/\n/g, 'abr_line_break') /* remplaza los saltos de linea por un caracter custom y que el siguiente replace no los borre */
		.replace(/\s+/g, ' ') /* solo permite un espació */
		.replace(/abr_line_break/g, '\n') /* regresa los saltos de linea */
		.replace(/^ |^\n/g, ""); /* No permite espaciós ni saltos de linea al inicio */
	return objectValue(e, value);
};

/*
	Está función convierte todos los espacios en guines bajos
*/
export const spacesAsUnderscores = e => {
	const value = targetValue(e)
		.replace(/\s+/g, '_')/* solo permite un espació */
		.replace(/_+/g, '_')/* solo permite un guion bajo */
		.replace(/^ /g, "");/* No permite espaciós al inicio */
	return objectValue(e, value);
};

/* Setea todas las primeras letras de cada palabra a mayuscula */
export const pascalSpaceCase = (e) => {
	const value = targetValue(e)
		.split(" ")
		.filter(char => char != " ")
		.map((m) => capitalize(m))
		.join(" ")
		.replace(/^ /g, "");

	return objectValue(e, value);
};

/* impide que los campos puedan tener espaciós sin cambiar ningún caracter */
export const noSpaces = (e) => {
	const value = toString(targetValue(e))
		.trim()
		.replace(/\s/g, "");

	return objectValue(e, value);
};

/** retorna los strings a un formato númerico
	@param e : el evento original
	@param replaceRegex : Los caracteres que se van a remplazar si es un string
	@param onChange : evento a ejecutar despues de la limpieza
	@param asString : Indica si el numero se va a regresar como un string o float(default)
*/
export const numericFormat = (e, replaceRegex, onChange, asString) => {
	if (isUndefined(e.value)) {
		return;
	}

	let value = e.value || (asString ? "" : null);

	if (typeof value === "string" && replaceRegex) {
		value = value.replace((replaceRegex || "noReplace"), "");
		if (!isNaN(value) && !asString) {
			value = parseFloat(value);
		}
	}

	return onChange(objectValue(e, value));
};

/**
**	Función para limpar los atributos para los componentes
**	Funcina para elementos kendo y material
	@param props : todos los atributos
	@param forMUI : Indica si los atributos a eliminar son para componentes material o kendo
*/
export const getProps = (props, forMUI = false) => {
	const noValids = [
		"fieldInput",
		"control",
		"validationRules",
		"isRequired",
		"data-cy",
		"groupError",
		"errorByGroup",
		"asString",
		"triggerFields",
		"trigger",
		"getValues",
		"disabledBy",
		"setValue",
	];

	return omit({ ...props, size: props.size ?? "medium" }, [...noValids, forMUI ? "valid" : "error"]);
};

export const initialFormState = {
	open: false,
	isEdit: false,
	item: {}
};

const formStateChanger = (item, isEdit) => ({ open: true, isEdit, item: item });
export const formStateOnNew = item => formStateChanger(item, false);
export const formStateOnEdit = item => formStateChanger(item, true);

export const focusInput = (name, selectOnFocus, id) => {
	const commonValid = (inputType) => !id ? `[data-cy="${name}"] ${inputType}` : id;
	try {
		const inputRef = selector(commonValid('input')) ?? selector(commonValid('textarea')) ?? selector(`[data-cy="${name}"] span:not(.k-floating-label-container)`);
		if (setFocus(inputRef, selectOnFocus)) { return; }
		setFocus(inputRef?.firstChild?.focus, selectOnFocus);
	} catch (error) {
		return null;
	}
};

export const validateUpper = (name, fieldInput, value, uppercase, lowercase) => {
	const validType = ['textfield', 'textbox', 'textfieldicon',].includes(fieldInput);
	const validName = !['password', 'email',].some(exception => name.includes(exception));

	return name && validName && validType && isString(value) && (uppercase || lowercase);
};

export const AutoFocus = (name, id, selectOnFocus) => {
	setTimeout(() => {
		focusInput(name, selectOnFocus, id);
	}, 100);
	return true;
};

export const evaluateUppercase = (name, fieldInput, e, uppercase, lowercase) => {
	if (fieldInput === "customselect") {
		return isString(e) ? e.toUpperCase() : e;
	}

	if (validateUpper(name, fieldInput, e?.target?.value, uppercase, lowercase)) {
		const input = e.target;
		const originalValue = input.value;
		const selectionStart = input.selectionStart;
		const selectionEnd = input.selectionEnd;
		const upperCaseValue = !lowercase ? originalValue.toUpperCase() : originalValue.toLowerCase();

		// Saves cursor position
		const newSelectionStart = selectionStart + (upperCaseValue.length - originalValue.length);
		const newSelectionEnd = selectionEnd + (upperCaseValue.length - originalValue.length);

		// Updates input value to uppercase
		input.value = upperCaseValue;

		// Restores cursor position after change
		if (isFunction(input.setSelectionRange)) {
			input.setSelectionRange(newSelectionStart, newSelectionEnd);
		}
	}
	return e;
};

export const basicWarningFields = ['key', 'name'];