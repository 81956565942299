import React, { Fragment } from "react";
import Alert from "@mui/material/Alert";
import { isArray, isObject, size, each } from "lodash";
/* form */
import { useFormState } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

/**
 *
 *	 Este es un componente especial, solo se usa en casos especificos donde sea una validación de grupo ó
 *	 o el error no tenga un input asignado para cambiar
 *	 @param {object} props
 *	 @param {string} props.name : Es el campo que almacena el error a mostrar
 *	 @param {controller} props.control : El controlador del hook-form
 *	 @param {?"normal"|"alert"} props.type : Define si el error va a ser mostrado como una alerta o como un texto al igual que los inputs
 *	 @param {string} props.className : Si el mensaje requiere una clase extra se manda con este parametro
 *	 @param {component} props.Container : Si el error va estar contenido en un apartado especial
 *	 @param {object} props.containerProps : Las propiedades del contenedor especial, deben pasarse como un objeto iterable
 *	 @returns {Component}
 *	 @example
 *	 <FormError
 *	 	name={"groupError"}
 *	 	control={control}
 *	 	Container={Grid}
 *	 	containerProps={{ item: true, xs: 12, sm: 12, md: 12 }}
 *	 />
 * @version 2.0.0
 * @author Angel Efrain Poot Canul
**/
const FormError = ({
	name,
	control,
	multiMessages = false,
	objectToNested = false,
	...others
}) => {
	const { errors } = useFormState({ control });
	return (
		<ErrorMessage
			errors={errors}
			name={name}
			render={({ message }) => {
				const nested = getNestedError(errors, message, name, objectToNested);
				if (!message && isArray(nested)) {
					if (size(nested) && multiMessages) {
						return nested.map((error, index) => (
							<Message key={index} {...others} message={error.message} />
						));
					}
					return null;
				}
				return <Message {...others} message={message} />
			}}
		/>
	);
}

export default FormError;

const getNestedError = (errors, message, name, objectToNested) => {
	if (!name.includes(".") && !message) {
		return errors?.[name];
	}
	const splited = name.split(".");
	let error = errors;
	each(splited, nested => {
		error = error?.[nested];
	});

	if (isObject(error) && !isArray(error) && objectToNested) {
		let auxiliar = [];
		each(error, (item, key) => {
			item.message = `${key} : ${item.message}`;
			auxiliar.push(item);
		})
		error = auxiliar;
	}

	return error;
}


const Message = ({
	type = "normal",
	Container,
	containerProps,
	message,
	className,
}) => {

	Container = Container ?? Fragment;

	if (type === "alert") {
		return (
			<Container {...(containerProps || {})}>
				<Alert severity={"error"}>
					{message}
				</Alert>
			</Container>
		);
	}

	return (
		<Container {...(containerProps || {})}>
			<p className={`${className || ""} form-error`}>{message}</p>
		</Container>
	);
}

/**
 *! Hook que nos sirve para el field principal, este nos devuelve unicamente la validación y el mensaje
 * @param {string} name : nombre del campo del hook form al cual se hace referencia
 * @param {controler} control : El controlador del hook-form
 * @returns {{
	*valid:boolean,
	*message:string
 * }}
*/
export const ErrorValidation = (name, control) => {

	const { errors } = useFormState({ control });

	return ErrorMessage({
		errors: errors,
		name: name,
		render: ({ message }) => ({ valid: false, message: message })
	}) ?? { valid: true, message: "" };
}