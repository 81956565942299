import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMicrophone,
    faMicrophoneSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import Fab from '@mui/material/Fab';
import useSpeechRecon from "./hooks/useSpeechRecon";
import { motion, AnimatePresence } from "framer-motion";
import { customAnimation } from "../form/Permission/Utils";
import './styles/style.scss';

function SpeechRecognition() {
    const {
        listening,
        isHover,
        handleListening,
        handleStop,
        startTyping,
        showTranscript,
        message,
        transcript,
        showButton,
    } = useSpeechRecon();

    const buttonStyles = {
        width: '100%',
        height: '100%',
        fontSize: '16px !important',
        backgroundColor: !listening ? 'var(--tableHeadbg)' : 'var(--red)',
        color: 'var(--white)',
        transition: 'all 300ms',
        '&:hover': {
            filter: 'brightness(90%)',
            backgroundColor: !listening ? 'var(--tableHeadbg)' : 'var(--red)',
        }
    }

    const transition = {
        duration: 0.7,
        ease: [0, 0.71, 0.2, 1.01],
    }

    const messageVariants = {
        enter: () => ({
            scale: 0,
        }),
        animate: () => ({
            scale: 1,
        }),
        exit: () => ({
            scale: 0,
        })
    }

    const buttonVariants = {
        enter: () => ({
            scale: 0,
        }),
        animate: () => ({
            ...(isHover ?
                { opacity: 1, y: '0%' }
                : { opacity: 0.5, y: '80%' }
            ),
            scale: 1,
        }),
        exit: () => ({
            scale: 0,
        })
    }

    return (
        <AnimatePresence mode="wait">
            {showButton ?
                <motion.div
                    id='speech-wrap'
                    key="speech-wrap"
                    {...customAnimation(buttonVariants, transition)}
                >
                    <AnimatePresence mode="wait">
                        {
                            (transcript || message) && isHover && showTranscript ?
                                <motion.div
                                    key="transcript"
                                    className="transcript"
                                    {...customAnimation(messageVariants, transition)}
                                >
                                    {(transcript !== '' && startTyping) ? transcript : message}
                                </motion.div>
                                :
                                null
                        }
                    </AnimatePresence>
                    <motion.div className="button-wrapper">
                        <Fab
                            id='speech-button'
                            sx={buttonStyles}
                            onClick={
                                !isHover ? null :
                                    listening ? handleStop : handleListening
                            }
                        >
                            <ButtonIcons listening={listening} />
                        </Fab>
                    </motion.div>
                </motion.div>
                :
                null
            }
        </AnimatePresence>
    );
}
export default SpeechRecognition;

const ButtonIcons = ({ listening }) => {
    const iconWrap = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const variants = {
        enter: () => ({
            scale: 0.1,
        }),
        animate: () => ({
            scale: 1,
        }),
        exit: () => ({
            scale: 0.1,
        })
    }

    const transition = {
        duration: 0.25,
        ease: [0, 0.71, 0.2, 1.01],
    }

    return (
        <AnimatePresence mode="wait">
            {!listening ?
                <motion.div
                    key="talk"
                    style={iconWrap}
                    {...customAnimation(variants, transition)}
                >
                    <FontAwesomeIcon icon={faMicrophoneSlash} size='2xl' />
                </motion.div> :
                <motion.div
                    key="stop"
                    style={iconWrap}
                    {...customAnimation(variants, transition)}
                >
                    <FontAwesomeIcon icon={faMicrophone} size='2xl' />
                </motion.div>
            }
        </AnimatePresence>
    );
}