import PropTypes from "prop-types";
import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import { FloatingLabel } from "@progress/kendo-react-labels";
import { hasValue } from '../general/GeneralUtilities';
import IconUse from '../uiDesign/IconUse';

const SideLabelContainer = ({
	label,
	icon,
	sideLabel = "side",
	children,
	modernDesign,
}) => {

	const extraClass = {
		side: "",
		up: "top-label"
	}[sideLabel] ?? "";

	return (
		<div className={`side-label-container ${extraClass} ${modernDesign ? 'modern-design' : ''}`}>
			{(modernDesign && hasValue(icon)) && <IconUse icon={icon} />}
			<label className='side-to-side-label'>
				{label}
			</label>
			<div className='side-to-side-input'>
				{children}
			</div>
		</div>
	);
};

SideLabelContainer.propTypes = {
	children: PropTypes.any,
	icon: PropTypes.any,
	label: PropTypes.any,
	modernDesign: PropTypes.bool,
	sideLabel: PropTypes.any
};

export default SideLabelContainer;

export const KendoLabelContainer = ({
	sideLabel = null,
	label = "",
	children,
	fieldId,
	editorValue,
	className
}) => {

	if (hasValue(sideLabel) && !["default", false].includes(sideLabel)) {
		return (
			<SideLabelContainer label={label} sideLabel={sideLabel} >
				{children}
			</SideLabelContainer>
		);
	}

	return (
		<FloatingLabel
			label={label}
			editorId={fieldId}
			editorValue={editorValue}
			className={className}
		>
			{children}
		</FloatingLabel>
	);
};

KendoLabelContainer.propTypes = {
	children: PropTypes.any,
	className: PropTypes.any,
	editorValue: PropTypes.any,
	fieldId: PropTypes.any,
	label: PropTypes.any,
	sideLabel: PropTypes.any
};

export const MaterialLabelContainer = ({
	sideLabel = null,
	label = "",
	icon,
	children,
	modernDesign,
}) => {

	if (hasValue(sideLabel) && !["default", false].includes(sideLabel)) {
		return (
			<SideLabelContainer
				icon={icon}
				label={label}
				sideLabel={sideLabel}
				modernDesign={modernDesign}
			>
				{children}
			</SideLabelContainer>
		);
	}

	return (
		<Fragment>
			{children}
		</Fragment>
	);
};

MaterialLabelContainer.propTypes = {
	children: PropTypes.any,
	icon: PropTypes.any,
	label: PropTypes.any,
	modernDesign: PropTypes.any,
	sideLabel: PropTypes.any
};


export const BootstraplLabelContainer = ({
	sideLabel = null,
	label = "",
	containerClasses = "",
	labelClassName = "",
	editorId = null,
	children
}) => {

	if (hasValue(sideLabel) && !["default", false].includes(sideLabel)) {
		return (
			<SideLabelContainer label={label} sideLabel={sideLabel} >
				<div className={containerClasses}>
					{children}
				</div>
			</SideLabelContainer>
		);
	}

	return (
		<div className={containerClasses}>
			{label &&
				<Form.Label
					htmlFor={editorId}
					className={`${labelClassName} MuiBootstrapLabel`} >
					{label}
				</Form.Label>
			}
			{children}
		</div>
	);
};

BootstraplLabelContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.element,
	]),
	containerClasses: PropTypes.string,
	editorId: PropTypes.any,
	label: PropTypes.any,
	labelClassName: PropTypes.string,
	sideLabel: PropTypes.any
};
