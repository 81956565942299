
import React from "react";
import PropTypes from "prop-types";
import { t } from "i18next";
import { isFunction } from "lodash";
import Button from "../../styled/Button";
import DialogActions from "../../styled/DialogActions";

export const BasicDialogActions = ({
  onCancel,
  onSubmit,
  loading,
  saveLabel,
  handleSubmit,
}) => {

  return (
      <DialogActions>
          <Button onClick={onCancel} design='text' disabled={loading}>
              {t('cancel')}
          </Button>
          {isFunction(onSubmit) &&
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
                  <Button type='submit' design='contained' loading={loading}>
                      {t(saveLabel ?? 'save')}
                  </Button>
              </form>}
      </DialogActions>
  );
};

BasicDialogActions.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  saveLabel: PropTypes.string,
  handleSubmit: PropTypes.func,
};