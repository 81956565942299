import {
    faUsersGear,
    faBookOpenCover,
    faGears,
    faBookmark,
    faHeartPulse,
    faBooks,
    faAddressCard,
} from "@fortawesome/pro-light-svg-icons";

//* Agregar iconos que se requieran SOLO para los menus desplegables autogenerados
//* Los elementos se conforman por [key del item en el menu]:[icono]

//* Los iconos de los elementos se especifican en items.jsx con la propiedad icon

export const iconsDictionary = {
    catalogs: faBookOpenCover,
    attendance: 'attendance',
    principal: faBookmark,
    payroll: 'payrolls',
    access_control: faAddressCard,
    'usersroles': faUsersGear,
    'human-resource': 'humanresources',
    'attendance-mopers': 'mopers',
    kiosk: 'kiosk',
    'modules-kiosco-group': 'kiosk',
    'modules-clock-group': 'clock',
    settings: faGears,
    'social-security': faHeartPulse,
    'modules-system-group': 'systems',
    'catalogs-organization': 'organization-chart',
    tools: faBooks,
};