const locales = [
	{
		language: "en-US",
		locale: "en",
	},
	{
		language: "es-MX",
		locale: "es-MX",
	},
];

export default locales;