import {
	capitalize,
	each,
	isArray,
	isFunction,
	isObject,
	isString,
	omit,
	pick,
	size,
	toLower,
	toUpper,
	uniqBy,
} from 'lodash';
import { format } from 'date-fns';
import { searchInObject, valueOrOption } from '../../../../../../../../general/@components/general/GeneralUtilities';
import {
	moperAbsenteeismModel,
	moperBonusPaymentModel,
	moperClockModel,
	moperCompleteShiftModel,
	moperConceptModel,
	moperOvertimeMoneyModel,
	moperOvertimeTxtModel,
	moperPromotionsModel,
	moperRequisitionModel,
	moperRestBankModel,
} from './models';
import {
	getMoperKey,
	KEYS_ABSENTEEISM,
} from '../../../../../../moper/MOPERSByWorker/components/container/Overtime/components/Signatures/PermissionGate';
import { formatDate } from '../../../../../../attendance/Attendance/ConstantAndUtilities/utilities';
import { conditional } from '../../../../../../../../general/@components/table/CommandCell';

const modelOmitFields = ['model', 'moperKeys', 'pathname', 'dataTreatment'];

export const formatedDate = (
    value,
    moper,
    justDate = false,
    justHour = false,
    useIso = false,
) => {
    if (!value) return '-';
    let formatValue = !justDate ? "dd/MM/yyyy HH:mm a" : "dd/MM/yyyy";
    if (justHour) {
        formatValue = "HH:mm";
    }
    return format(formatDate(value, useIso), formatValue);
};

export const mopersListConstructor = (moper, models) => {
    const foundMopers = findMoper(moper, true, models);
    return foundMopers.reduce((acc, item) => {
        item.model = allMopers(models)[item?.key] ?? item.model;
        const values = item.value?.map(el => {
            const {
                values,
                moperName,
                moperIcon,
                popupKey,
                moperIconUse,
            } = getMoperValues(el?.[0] ?? el ?? {}, item.model, el?.moper);
            return {
                ...omit(item?.model, modelOmitFields),
                editable: item?.model?.editable ?? false,
                commentButton: item?.model?.commentButton ?? false,
                verticalMapping: item?.model?.verticalMapping ?? false,
                catalog_key: el?.catalog_key,
                is_implicit: el?.is_implicit,
                popupKey,
                icon: moperIcon,
                iconUse: moperIconUse,
                name: moperName,
                catalog: moper?.moper_catalog?.id ?? moper?.moper_catalog ?? moper?.catalog_id,
                data: values,
                folio: valueOrOption(el?.folio, '-'),
                exactMoper: el,
            }
        });
        return acc.concat(values);
    }, []);
}

export const moperConstructor = (moper, models, unique = false) => {
    if (!moper) return moper;
    const foundMoper = findMoper(moper, false, models, unique);
    let { model } = valueOrOption(foundMoper, {});
    const { value, key } = valueOrOption(foundMoper, {});
    model = valueOrOption(allMopers(models)[key], model);
    const realValue = value?.[0] ?? value ?? {};
    const {
        values,
        moperName,
        moperIcon,
        popupKey,
        moperIconUse,
    } = getMoperValues(realValue, model, moper, unique);

    return {
        ...omit(model, modelOmitFields),
        icon: moperIcon,
        iconUse: moperIconUse,
        name: moperName,
        popupKey,
        editable: model?.editable ?? false,
        commentButton: model?.commentButton ?? false,
        is_implicit: moper?.is_implicit,
        catalog_key: moper?.catalog_key,
        catalog: moper?.moper_catalog?.id ?? moper?.moper_catalog ?? moper?.catalog_id,
        data: values,
        worker: moper?.worker,
        folio: valueOrOption(realValue?.folio, '-'),
        exactMoper: realValue,
    };
}

export const filterEmptyMopers = (data) => {
    return data.filter(el => {
        let flag = false;
        each(Object.keys(allMopers()), field => {
            const value = el[field];
            if (size(value)) {
                flag = true;
            }
        });
        if (flag) {
            return el;
        }
    });
};

export const allMopers = (customModel) => ({
    moper_bonus_payment: {
        ...moperBonusPaymentModel,
        ...(customModel?.moper_bonus_payment ?? {}),
    },
    moper_overtime_money: {
        ...moperOvertimeMoneyModel,
        ...(customModel?.moper_overtime_money ?? {}),
    },
    moper_overtime_txt: {
        ...moperOvertimeTxtModel,
        ...(customModel?.moper_overtime_txt ?? {})
    },
    moper_rest_bank: {
        ...moperRestBankModel,
        ...(customModel?.moper_rest_bank ?? {})
    },
    moper_worker_requisition: {
        ...moperRequisitionModel,
        ...(customModel?.moper_worker_requisition ?? {})
    },
    moper_absenteeism: {
        ...moperAbsenteeismModel,
        ...(customModel?.moper_absenteeism ?? {}),
    },
    moper_complete_shift: {
        ...moperCompleteShiftModel,
        ...(customModel?.moper_complete_shift ?? {}),
    },
    moper_concept: {
        ...moperConceptModel,
        ...(customModel?.moper_concept ?? {}),
    },
    moper_clock: {
        ...moperClockModel,
        ...(customModel?.moper_clock ?? {}),
    },
    moper_promotion: {
        ...moperPromotionsModel,
        ...(customModel?.moper_promotion ?? {}),
    }
});

export default allMopers;

export const ALL_MOPER_KEYS = Object.keys(allMopers());

const validateModel = (model, moper) => {
    if (!size(model)) return;
    const validation = model?.validation;
    return isFunction(validation) ? validation(moper) : true;
}

const findBestModel = (models, moper) => {
    return Object.entries(models).find(el => {
        const model = el[1];
        const validModel = validateModel(model, moper);
        if (moper.catalog_key?.includes('txt') && validModel) return [moper.catalog_key, KEYS_ABSENTEEISM[6]].every(el => model?.moperKeys?.includes(el));
        return model?.moperKeys?.includes(moper.catalog_key) && validModel;
    })
};

const performReorder = (item, mopers, modelName, originalModel, models, moperData) => {
    if (item?.is_implicit) {
        item.bonus_type = item?.bonus_type ?? 8;
    }
    if (size(item)) item.catalog_key = getMoperCatalogKey(item, moperData);
    const validModel = validateModel(originalModel, item);
    if (!validModel) {
        const bestModel = findBestModel(models, item);
        if (bestModel) {
            const bestModelName = bestModel[0];
            const currentMoperData = mopers[bestModelName] ?? [];
            mopers[bestModelName] = uniqBy([...currentMoperData, item], 'id');
            mopers[modelName] = mopers[modelName].filter(el => el?.id !== item?.id);
        }
    }
}

const reorderMopers = (mopers, models) => {
    if (!mopers) return;
    const mopersData = pick(mopers, ALL_MOPER_KEYS);
    each(models, (model, modelName) => {
        const originalModel = model;
        const moperValue = mopersData[modelName];
        if (isArray(moperValue)) {
            each(moperValue, item => {
                performReorder(item, mopersData, modelName, originalModel, models, mopers);
            })
        } else if (isObject(moperValue)) {
            performReorder(moperValue, mopersData, modelName, originalModel, models, mopers);
        }
    });
    return { ...mopers, ...mopersData };
}

// TODO: HANDLE NON IDENTIFIED IMPLICIT MOPER
export const findMoper = (moper = {}, all = false, models = {}, findUnique = false) => {
    const allModels = allMopers(models);
    const copiedMoper = { ...moper };
    const newMoper = !findUnique ? reorderMopers(copiedMoper, allModels) : copiedMoper;
    if (!findUnique) {
        const allFound = [];
        for (const prop in pick(allModels, ALL_MOPER_KEYS)) {
            if (size(newMoper?.[prop]) > 0) {
                const draft = { key: prop, value: newMoper[prop], model: allModels[prop] };
                if (!all) return draft;
                else allFound.push(draft);
            }
        }
        return all ? allFound : null;
    }
    if (!size(newMoper)) return {};
    if (size(newMoper)) newMoper.catalog_key = getMoperCatalogKey(newMoper);
    const modelFound = findBestModel(allModels, newMoper);
    return { key: modelFound?.[0], value: newMoper, model: modelFound?.[1] };
};

const getMoperCatalogKey = (moper, parentData) => {
    let itemKey = "";
    if (moper?.catalog_key && moper?.catalog_key !== "") {
        itemKey = moper?.catalog_key;
    } else {
        itemKey = getMoperKey(moper) ?? parentData?.catalog_key ?? moper?.key;
    }
    return toLower(itemKey);
};

const getMoperValues = (moper, model, oldMoper, unique) => {
    if (!model?.model) return { values: [] };
    moper.catalog_id = moper?.catalog_id ?? oldMoper?.moper_catalog?.id ?? oldMoper?.moper_catalog;
    moper.worker = valueOrOption(moper?.worker, oldMoper?.worker);
    moper.created_at = valueOrOption(moper?.created_at, oldMoper?.created_at);
    moper.user = valueOrOption(moper?.user, oldMoper?.user);
    moper.parent_moper_id = valueOrOption(moper?.parent_moper_id, conditional(unique && oldMoper?.moper?.id, oldMoper?.moper?.id, oldMoper?.id));
    moper.moper_group = oldMoper?.moper_group;
    const withRelative = model?.relativeKey;
    const withIconKey = model?.iconKey;
    const pathname = model?.pathname;
    const popupKey = model?.popupKey;
    const {
        moperName,
        moperRelative,
        moperIconRelative,
    } = getRelatives({
        withRelative,
        pathname,
        withIconKey,
        moper,
    });
    const realIcon = getMoperIcon(moperIconRelative, model);
    const realIconUse = getMoperIconUse(moperIconRelative, model);
    const realPopupKey = isFunction(popupKey) ? popupKey(moper) : popupKey;
    const realModel = model.model?.[moperRelative] ?? model?.model?.default ?? model.model ?? {};
    let realName = capitalize(conditional(moperName && moperName !== '-', moperName, realModel?.name));
    const dataTreat = model?.dataTreatment;
    const formatName = model?.nameFormat;
    if (isFunction(formatName)) realName = formatName(realName, moper);
    moper.moper_name = valueOrOption(moper.moper_name, realName);
    moper.custom_key = valueOrOption(moper.custom_key, realModel?.key);
    moper.is_implicit = valueOrOption(realModel?.is_implicit, false);
    const formatData = getData(omit(realModel, ['name', 'key', 'is_implicit']), moper);
    return {
        values: isFunction(dataTreat) ? dataTreat(formatData) : formatData,
        moperName: realName,
        moperIcon: realIcon,
        popupKey: realPopupKey,
        moperIconUse: realIconUse,
    };
};

const getData = (model, moper) => {
    const data = Object.entries(valueOrOption(model, {}));
    return data.map(item => {
        const field = item[0];
        const modelField = item[1];
        let classname = `${valueOrOption(modelField?.class, '')}`;
        const formatFn = modelField?.format;
        const realField = modelField?.value;
        const fieldKey = modelField?.key;
        const isBlank = field?.includes('blank');
        if (field === '') return getData(modelField, moper);
        const realValue = !isBlank ? searchInObject(realField, moper) : '';
        const formatValue = (isFunction(formatFn) && realValue !== '-' && !isBlank) ? formatFn(realValue, moper) : realValue;
        if (isBlank) classname += 'transparent';
        if (realField || isBlank) {
            return { label: field, value: formatValue, key: fieldKey, class: classname };
        }
    });
}

const validateFnValue = value => value && !isFunction(value);

const getMoperIcon = (value, model) => {
    const modelIcons = model?.icon;
    const many = isObject(modelIcons) && !modelIcons?.render;

    if ((isString(modelIcons) || !many) && model?.icon) {
        return model.icon;
    }
    if (many && modelIcons?.[value]) {
        return modelIcons[value];
    }
    return isString(value) ? toUpper(value) : value;
}

const getMoperIconUse = (value, model) => {
    const modelIcons = model?.icon_use;
    const many = isObject(modelIcons) && !modelIcons?.render;

    if ((isString(modelIcons) || !many) && model?.icon_use) {
        return model.icon_use;
    }
    if (many && modelIcons?.[value]) {
        return modelIcons[value];
    }
    return null;
}

const getRelatives = ({
    pathname,
    moper,
    withRelative,
    withIconKey,
}) => {
    let moperRelative = null;
    let moperIconRelative = null;
    let moperName = '-';
    if (validateFnValue(pathname)) {
        moperName = searchInObject(pathname, moper, true);
    }
    if (validateFnValue(withRelative)) {
        moperRelative = toLower(searchInObject(withRelative, moper));
    }
    if (validateFnValue(withIconKey)) {
        moperIconRelative = toLower(searchInObject(withIconKey, moper));
    }
    if (isFunction(pathname)) {
        moperName = pathname(moper);
    }
    if (isFunction(withRelative)) {
        moperRelative = withRelative(moper);
    }
    if (isFunction(withIconKey)) {
        moperIconRelative = withIconKey(moper);
    }
    return {
        moperName,
        moperRelative,
        moperIconRelative,
    };
};

export const hasBuiltMoper = item => isObject(item?.exactMoper);