import PropTypes from "prop-types"
import React from "react";
import { useTranslation } from "react-i18next";
import {
	MultiViewCalendar,
	CalendarCell,
} from "@progress/kendo-react-dateinputs";
import { format } from 'date-fns';
import { es, enUS } from 'date-fns/locale';


const viewType = {
	years: 2,
	months: 1,
	days: 0
};

const CustomCalendarCell = ({ 
	onClick, 
	view, 
	value, 
	...props 
}) => {

	const { t } = useTranslation();
	if (view === viewType.days) {
		return <CalendarCell {...props} view={view} value={value} onClick={onClick} />;
	}

	const datefnOptions = {
		addSuffix: true,
		locale: t("systemLanguage") === "es" ? es : enUS
	};
	let title = props.title;

	if (view === viewType.months && value) {
		title = format(value, "MMMM", datefnOptions);
		title = title[0].toUpperCase() + title.substring(1);
	}

	const handleClick = (value, event) => { onClick(value, event); onClick(value, event); };

	return <CalendarCell {...props} view={view} value={value} title={title} onClick={handleClick} />;

};

CustomCalendarCell.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.any,
  view: PropTypes.number,
  title: PropTypes.any
}


const CustomCalendar = ({
	topView = "decade",
	...props
}) => (

	<MultiViewCalendar {...props}
		views={1}
		topView={topView ?? "decade"}
		cell={CustomCalendarCell}
	/>
);

CustomCalendar.propTypes = {
  topView: PropTypes.string
}

export default CustomCalendar;