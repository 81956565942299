import PropTypes from "prop-types";
import React from "react";
//--project imports
import { useTranslation } from "react-i18next";
import DialogActions from '../../styled/DialogActions';
import BaseButton from '../Buttons/BaseButton';
import { hasValue, valueOrOption } from '../general/GeneralUtilities';

const Actions = ({
	handleClose,
	selected,
	submitText,
	onAccept,
}) => {
	const { t } = useTranslation();
	return (
		<DialogActions>
			<BaseButton
				design="text"
				color="error"
				onClick={handleClose}
				label={t("cancel")}
				type="button"
			/>
			<BaseButton
				design="contained"
				disabled={!hasValue(selected)}
				label={valueOrOption(submitText, t("accept"))}
				onClick={onAccept}
			/>
		</DialogActions>
	);
};
Actions.propTypes = {
	handleClose: PropTypes.any,
	onAccept: PropTypes.any,
	selected: PropTypes.any,
	submitText: PropTypes.any
};

export default Actions;