import i18next from 'i18next';
import { stringValidation } from '../validations/stringValidation';
import { getJobPositionsTypeApi } from '../../services/enterprise-structure';
import { contractsAPI } from '../../services/contract';

const { t } = i18next;

export const getWorkerFilterFields = (useActive, cloneConfig, structure) => {
	const validData = [{ key: "ALL", label: t('_all') }, { key: true, label: t('valid') }, { key: false, label: t('not-valid') }];
	return {
		// "sort-title": [
		// 	["ordering", "sort-by", { fieldInput: "dropdown", data: orderTypes(), valueField: "value", textField: "name", noDefault: true }],
		// ],
		"basic-filters": [
			["key", "key", { fieldInput: "numeric" }],
			["first_surname", "first-surname", { validation: () => stringValidation({ min: 3, max: 50, type: "capital" }) }],
			["second_surname", "second-surname", { validation: () => stringValidation({ min: 3, max: 50, type: "capital" }) }],
			["name", "name", { validation: () => stringValidation({ min: 3, max: 50, type: "capital" }) }],
			(useActive ? ["active", "valid", { fieldInput: "dropdown", data: validData, textField: "label", valueField: "key", noDefault: true }] : null),
		],
		"complementary-filters": [
			["rfc", "rfc"],
			["curp", "curp"],
			["nss", "nss", { validation: () => stringValidation({ min: 3, max: 15, type: "onlyNumericPositive" }) }],
			(cloneConfig ? ["is_duplicated", "is-duplicated", "switch"] : null),
		],
		[`${t("filter-by")} ${t("contract")}`]: [
			["contract_type", "contract-type", {
				fieldInput: "serverdropdown",
				serverApi: contractsAPI.get,
				searchBy: "search",
				filterEfields: "key|name",
				filterSplit: "-",
				format: "key|name",
				fields: "id,key,name",
				textField: "name",
				valueField: "id",
			}],
			["contract", "", {
				fieldInput: "DateRange",
				startProps: { label: t("from"), disabledBy: "contract_type", disabledByValue: 1 },
				endProps: { label: t("until"), disabledBy: "contract_type", disabledByValue: 1 },
			}],
		],
		[`${t("filter-by")} ${t("job-position")}`]: [
			["job_position_type", "job-position-type", {
				fieldInput: "serverdropdown",
				serverApi: getJobPositionsTypeApi.get,
				searchBy: "search",
				filterEfields: "key|name",
				filterSplit: "-",
				format: "key|name",
				fields: "id,key,name",
				textField: "name",
				valueField: "id",
			}],
		],
		[`${t("filter-by")} ${t("date-discharge")}`]: [
			["leave", "", {
				fieldInput: "DateRange",
				startProps: { label: t("from") },
				endProps: { label: t("until") }
			}],
		],
		[`${t("filter-by")} ${t("structure-enterprise")}`]: [
			["structure-filters", { structure: structure, }]
		],
	};
};