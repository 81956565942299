import React from 'react';
import { useTranslation } from "react-i18next";
import usePreventZoom from '../contextsAndControllers/usePreventZoom';

const ZoomKeyDownHandler = () => {

	const { t } = useTranslation();
	const { zoom, onClick } = usePreventZoom();

	const labelColor = {
		left: zoom > 100 ? "orange" : "white",
		right: zoom < 100 ? "orange" : "white",
	}

	return (
		<button className={`zoom-container`} onClick={() => onClick(zoom)}>
			<div>
				<span className={`zoom-control left actionLabel ${labelColor?.right}ActionLabel`}>-</span>
				<span className='center'>{t("zoom-used")}</span>
				<span className={`zoom-control right actionLabel ${labelColor?.left}ActionLabel`}>+</span>
			</div>
		</button>
	);
};

export default ZoomKeyDownHandler;