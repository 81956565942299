import PropTypes from "prop-types"
import React from 'react';
import { hasValue } from '../../../../general/GeneralUtilities';
import { filterChildrens, getTabIcon, markText } from '../../../utilities';
import { ItemIcon } from '../Search/NavItem';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import { LinkElement } from './LinkElement';
import { size } from 'lodash';

export const GroupElements = ({ item, stampMode = false }) => {

	const { tab, search } = useMegaMenu();
	const {
		title,
		children,
		is_accesible,
	} = item ?? {};
	const disabled = is_accesible === false;

	const childrens = filterChildrens(children, search, title);

	if (size(childrens) <= 0) {
		return null;
	}

	return (
		<div>
			<div className={`group-header ${disabled ? 'disabled' : ''}`}>
				{stampMode ? null : <ItemIcon icon={getTabIcon(item, tab)} />} {markText(title, search)}
			</div>
			<ul className={`group-list ${disabled ? 'disabled' : ''}`}>
				{childrens.map(subItem => {
					if (subItem.type === "collapse") {
						return <SubGroupElements key={subItem?.key} item={subItem} />;
					}
					return (
						<li key={subItem?.key}>
							<LinkElement item={subItem} />
						</li>
					);
				})}
			</ul>
		</div>
	);
};

GroupElements.propTypes = {
  item: PropTypes.any,
  stampMode: PropTypes.bool
}

const SubGroupElements = ({ item }) => {

	const { search } = useMegaMenu();
	const {
		title,
		children
	} = item ?? {};

	if (!hasValue(children)) {
		return null;
	}

	return (
		<>
			<li className='subgroup-header'>
				{markText(title, search)}
			</li>
			{children.map(subItem => {
				if (subItem.type === "collapse") {
					return <SubGroupElements key={subItem?.key} item={subItem} />;
				}
				return (
					<li key={subItem?.key}>
						<LinkElement item={subItem} />
					</li>
				);
			})}
		</>
	);

};

SubGroupElements.propTypes = {
  item: PropTypes.any
}
