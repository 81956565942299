import React from "react";
import { each, isArray, size } from "lodash";
import i18next from 'i18next';
import { AnimatePresence, motion } from "framer-motion";
const { t } = i18next;

export const modulePermissionModel = { access: false, create: false, edit: false, delete: false }
export const specialPermissionModel = { days: false, view_salary: true }
export const mopersPermissionModel = { solicita: true, acepta: false, revisa: true, aprueba: false, autoriza: false }
export const basicData = {
    permissions: []
}

export const variants = {
    enter: {
        // opacity: 0,
        scale: 0,
    },
    center: {
        // opacity: 1,
        scale: 1,
    },
    exit: {
        // opacity: 0,
        scale: 0,
    }
};

export const variants2 = {
    enter: {
        opacity: 0,
    },
    center: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    }
};

export const variants3 = {
    enter: {
        opacity: 0,
        scale: 0.4,
    },
    center: {
        opacity: 1,
        scale: 1,
    },
    exit: {
        opacity: 0,
        scale: 0.4,
    }
};

export const variants4 = {
    enter: {
        opacity: 0,
        height: '0%',
    },
    center: {
        opacity: 1,
        height: '100%',
    },
    exit: {
        opacity: 0,
        scale: '0%',
    }
};

export const transition = {
    duration: 0.3,
    ease: [0, 0.71, 0.2, 1.01],
}

export const transition2 = {
    duration: 0.5,
}

export const transition3 = {
    duration: 0.6,
    ease: [0, 0.71, 0.2, 1.01],
}

export const opacityAnimation = {
    initial: variants2.enter,
    animate: variants2.center,
    exit: variants2.exit,
    transition: transition2,
};

export const scaleAnimation = {
    initial: variants.enter,
    animate: variants.center,
    exit: variants.exit,
    transition: transition,
};

export const scaleOpacityAnimation = {
    initial: variants3.enter,
    animate: variants3.center,
    exit: variants3.exit,
    transition: transition,
};

export const heightAnimation = {
    initial: variants4.enter,
    animate: variants4.center,
    exit: variants4.exit,
    transition: transition,
};

export const customAnimation = (variants, transition) => ({
    initial: variants?.enter ?? variants?.initial,
    animate: variants?.center ?? variants?.animate,
    exit: variants?.exit ?? variants?.exit,
    transition: transition ?? variants.transition,
});

export const AnimateStep = ({ direction, children, step }) => {

    const variants = {
        enter: (direction) => {
            return {
                x: direction > 0 ? '110%' : '-110%',
            };
        },
        center: {
            zIndex: 1,
            x: '0%',
        },
        exit: (direction) => {
            return {
                zIndex: 0,
                x: direction < 0 ? '110%' : '-110%',
            };
        }
    }

    return (
        <AnimatePresence mode="wait" custom={direction} initial={false}>
            <motion.div
                key={`abr-animate-step-${step}`}
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flex: 1,
                }}
                variants={variants}
                custom={direction}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                    duration: 0.3,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );
}

export function resetControlItems(controlItems) {
    const cItems = controlItems.current;
    for (let name in cItems) { cItems[name].data.length = 0; }
}

export const updateTreeMenu = (newMenu, newItem, forCheck, justValidate, filters, selected) => {
    if (filters?.current) { resetControlItems(filters); }
    return newMenu.map((el, index) => recursiveSearch(el, newItem, index, newMenu, forCheck, justValidate, filters, selected));
}

export function recursiveSearch(parent, newItem, index, newMenu, forCheck, justValidate, filters, selected) {
    if (newItem && parent?.key === newItem?.key) {
        newMenu[index] = { ...parent, ...newItem };
    }
    if (parent?.items && isArray(parent?.items) && size(parent?.items) > 0) {
        each(parent.items, (child, childIndex) => {
            recursiveSearch(child, newItem, childIndex, parent.items, forCheck, justValidate, filters, selected);
        });
        if (forCheck) {
            const access = parent.items.find(el => el.info === t('access'));
            updateRecursiveCheck(parent.items, access);
            getItemCheck(parent, newMenu, newItem, index);
            if (justValidate && filters?.current) {
                each(parent.items, el => {
                    if (filters?.current[el.info]) {
                        const values = filters?.current[el.info].data;
                        filters.current[el.info].data = [...values, el.checked];
                    }
                });
            }
            if (parent?.key === selected.current?.key && parent?.checked === false) {
                selected.current = null;
            }
        }
    }
    return parent;
};

const updateRecursiveCheck = (items, access) => {
    each(items, (el) => {
        if (access?.checked === false && el?.info !== t('access')) {
            el.checked = false
            el.disabled = true;
        } else if (el?.info !== t('access') && access?.checked) {
            el.disabled = el.initial_disabled;
        }
        if (el?.items) {
            updateRecursiveCheck(el.items, access);
        }
    });
}

const getItemCheck = (parent, newMenu, newItem, index) => {

    if (parent.items.some(el => (el.checked === false || el.checked === null))) {
        newMenu[index].checked = null;
        if (parent?.key === newItem?.key) {
            newItem.checked = null;
        }
    }
    if (parent.items.every(el => el.checked === false)) {
        newMenu[index].checked = false;
        if (parent?.key === newItem?.key) {
            newItem.checked = false;
        }
    }
    if (parent.items.every(el => el.checked === true)) {
        newMenu[index].checked = true;
        if (parent?.key === newItem?.key) {
            newItem.checked = true;
        }
    }
};

export function searchTree(node, property, value, childrenField = 'children') {

    if (node[property] === value) {
        return node;
    }

    if (isArray(node)) {
        for (let n of node) {
            const result = searchTree(n, property, value, childrenField);
            if (result) return result;
        }
    }

    if (node[childrenField]) {
        for (let child of node[childrenField]) {
            const result = searchTree(child, property, value, childrenField);
            if (result) return result;
        }
    }

    return null;
}