
import i18next from 'i18next';
import {
	add,
	isDate,
	parse,
	format,
	isValid,
	getHours,
	getMinutes,
	clamp,
	formatDuration,
	isSameDay,
	parseISO,
	isThisHour,
	// differenceInDays,
	intervalToDuration,
} from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { hasValue, valueOrOption, wordFill } from '../../general/GeneralUtilities';

const { t } = i18next;

const yearChar = () => t("systemLanguage") === "es" ? "A" : "Y";
const yearPart = () => yearChar().repeat(4);

export const defaultMask = () => `DD/MM/${yearPart()}`;
export const backDateFormat = "yyyy-MM-dd";
export const backDateTimeFormat = `${backDateFormat} HH:mm`;
export const dateFormat = 'dd/MM/yyyy';
export const dateTimeFormat = `${dateFormat} HH:mm`;


export const current_year = () => parseInt(format(new Date(), 'yyyy'));
export const current_month = () => parseInt(format(new Date(), 'MM'));
export const current_day = () => parseInt(format(new Date(), 'dd'));
export const current_date = () => format(new Date(), backDateFormat);
export const current_datetime = () => format(new Date(), backDateTimeFormat);

export const datesDiferenceDescripted = (startDate = null, endDate = null, datesFormat = null) => {

	if (!hasValue(startDate) || !hasValue(endDate)) {
		return "";
	}

	if (startDate === endDate) {
		return `1 ${t("day")}`;
	}

	datesFormat = valueOrOption(datesFormat, backDateFormat);

	const intervalrObj = intervalToDuration({
		start: dateValue(startDate, datesFormat),
		end: add(dateValue(endDate, datesFormat), { days: 1 })
	});

	const description = formatDuration(
		intervalrObj,
		{
			format: ['years', 'months', 'days'],
			locale: t("systemLanguage") === "es" ? es : enUS
		},
	);
	return description;
};

export const rules = {
	d: /[0-3D]/,
	D: /[0-9D]/,
	m: /[01M]/,
	M: /[0-9M]/,
	y: /[1-2YA]/,
	Y: /[0-9YA]/,
	c: /[0DMYA]/,
	h: /[012H]/,
	H: /[0-9H]/,
	n: /[0-5M]/,
	N: /[0-9M]/,
};

/**
 *
 * @param {?Date|string} value - La fecha a parsear
 * @param {Date|string} defaultValue - El valor default en caso de que la fecha sea incorrecta o no se mande el formato de fecha debe ser "YYYY/MM/DD"
 * @param {string} valueFormat - El formato si es que el valor es un string y no un date por default (debe coincidir con el formato usado para el value)
 * @returns {date}
 */
export const defaultDate = (value, defaultValue, valueFormat) => {
	if (isDate(value) && isValid(value)) {
		return value;
	}

	const date = value ? parse(value, valueFormat, new Date()) : null;
	if (isValid(date)) {
		return date;
	}

	return new Date(defaultValue);
};

/**
 *
 * @param {string} date - La fecha a transformar
 * @param {null|string} emptyReturn - Si no hay dato de la fecha, que valor retornar, por default ""
 * @param {boolean} overDateEmpty - Indica si el valor es igual o mayor a 2100 se regresa como vacio, por default false
 * @returns
 */
export const formatDateBackToFront = (date, emptyReturn = "", overDateEmpty = false) => {
	if (!hasValue(date)) {
		return emptyReturn;
	}

	if (isDate(date)) {
		date = format(date, backDateFormat);
	}

	if (date?.includes("2100") && overDateEmpty) {
		return emptyReturn;
	}

	return date?.split("-").reverse().join("/");
};

export const dateValue = (value, valueFormat) => {
	if (valueFormat && value) {
		if (isDate(value)) return value;
		const date = parse(value, valueFormat, new Date());
		return isValid(date) ? date : null;
	}
	return valueOrOption(value, null);
};

/**
 *
 * @param {Date|string} value - The date value to evaluate
 * @param {?"ISO"|"backend"|"frontend"|"frontend2"|"custom"} endFormat - The format to return, as default the date value
 * @param {?string} customFormat - Use only if endformat option is "custom" for custumize the value of dateime
 * @returns {Date|string}
 */
export const datetimeValue = (value, endFormat = null, customFormat = "dd/MM/yyyy") => {

	if (!hasValue(value)) {
		return null;
	}

	let final_value = value;
	if (!isDate(value)) {
		if (typeof final_value === "string") {
			if (final_value.includes("T")) {
				final_value = new Date(parseISO(final_value));
			} else {
				final_value = new Date(final_value.replaceAll("-", "/"));
			}
		}
	}

	switch (endFormat) {
		case "ISO":
			return new Date(Date.UTC(
				final_value.getUTCFullYear(), final_value.getUTCMonth(), final_value.getUTCDate(),
				final_value.getUTCHours(), final_value.getUTCMinutes(), 0/* seconds always 0 */
			)).toISOString().replace(".000Z", "Z");
		case "backend":
			return format(final_value, `yyyy-MM-dd HH:mm:ss`);
		case "frontend":
			return format(final_value, `yyyy/MM/dd HH:mm:ss`);
		case "frontend2":
			return format(final_value, `yyyy/MM/dd hh:mm a`);
		case "custom":
			return format(final_value, customFormat);
		default:
			return final_value;
	}
};

export const timeValue = (value, valueFormat) => {
	if (valueFormat && value) {
		if (isThisHour(parseISO(value))) return value;
		const time = parse(value, valueFormat, new Date());
		return isValid(time) ? time : null;
	}
	return valueOrOption(value, null);
};

export const makeMask = (mask, type = "date") => {

	if (!mask) {
		return type === "datetime" ? `${defaultMask()} HH:MM` : defaultMask();
	}

	if (isDate(mask)) {
		return format(mask, type === "datetime" ? dateTimeFormat : dateFormat);
	}

	while (mask.includes("_")) {
		const index = mask.indexOf("_");
		const char = {
			0: "D",
			1: "D",
			3: "M",
			4: "M",
			6: yearChar(),
			7: yearChar(),
			8: yearChar(),
			9: yearChar(),
			11: "H",
			12: "H",
			14: "M",
			15: "M",
		}[index] ?? "?";

		mask = mask.replace("_", char);
	}

	return mask.replaceAll("?", "_");
};


export const validDate = ({
	day,
	month,
	year,
	min,
	max,
}) => {

	day = (day || "__").replaceAll("_", "D");
	month = (month || "__").replaceAll("_", "M");
	year = (year || "____").replaceAll("_", yearChar());

	let values = [null, `${day}/${month}/${year}`];

	if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
		day = parseInt(day);
		month = parseInt(month);
		year = parseInt(year);
		const monthDays = [0, 31, (year % 4 === 0 ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

		day = validMinMax(day, 1, monthDays[month]);
		month = validMinMax(month, 1, 12);

		const value = clamp(new Date(`${year}/${month}/${day}`), {
			start: min,
			end: max,
		});

		return [value, format(value, 'dd/MM/yyyy')];
	}

	return values;
};

export const validTime = ({
	hour,
	minutes,
	min,
	max,
}) => {

	hour = (hour || "__").replaceAll("_", "H");
	minutes = (minutes || "__").replaceAll("_", "M");

	const minH = getHours(min);
	const maxH = getHours(max);
	const minM = getMinutes(min);
	const maxM = getMinutes(max);
	const validHour = !isNaN(hour);
	const validMinutes = !isNaN(minutes);

	let validations = 0;
	if (validHour) {
		validations += 1;
		hour = leftZero(validMinMax(hour, minH, maxH), 0);
	}

	if (validMinutes && validHour) {
		validations += 1;
		const minHourMinutes = parseInt(hour) > minH ? 0 : minM;
		const maxHourMinutes = parseInt(hour) === maxH ? maxM : undefined;
		minutes = leftZero(validMinMax(minutes, minHourMinutes, maxHourMinutes), 0);
	}

	return [validations >= 2, `${hour}:${minutes}`];
};

const leftZero = (value, min) => {
	value = value === 0 ? min : value;
	return (value < 10 ? "0" : "") + value;
};

const validMinMax = (value, min, max) => {
	value = parseInt(value ?? 0);

	if (value <= min) {
		return min;
	}

	if (value >= max) {
		return max;
	}

	return value;
};

const validIsoDate = (date) => {
	if (isDate(date) && isValid(date)) {
		return date;
	}
	return parseISO(date, { representation: 'complete' });
};

export const isSameDate = (firstDate, secondDate) => {
	if (!hasValue(firstDate) || !hasValue(secondDate)) { return false; };

	const firstParsed = validIsoDate(firstDate);
	const secondParsed = validIsoDate(secondDate);
	return isSameDay(new Date(firstParsed), new Date(secondParsed));
};

// Function that converts dates splitted with every not number value
export const getDateFormated = (date) => {
	const splittedDate = date.split(/\D+/).filter(Boolean);
	const formatedDate = new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2]);
	return format(formatedDate, "dd/MM/yyyy");
};

export const monthLabel = (month) => {
	if (!hasValue(month)) {
		return "N/A";
	}

	const parsed = wordFill(month);

	return t(`month-${parsed}`);
};

export const formatDates = (date) => (date ? new Date(date.replace(/-/g, "/")) : null);
