import React, { useEffect, useState } from "react";
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMicrophone,
    faMicrophoneSlash,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setUserConfigs as setConfigs } from "../../../../../../store/actions";
import { resolveError } from "../../../requests/resolve-error";
import { usersApi } from "../../../../services/administrator";
import { currentUserInfo } from "../../../requests/user-store";

function ToggleMic() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const alwaysListen = useSelector((state) => state.userConfigs?.configs?.is_always_listening);
    const voiceActive = useSelector((state) => state.userConfigs?.configs?.voice_assistance);
    const [micUsage, setMicUsage] = useState(false);
    const [stream, setStream] = useState(null);

    useEffect(() => {
        setMicUsage(voiceActive);
    }, [voiceActive]);

    async function toggle() {
        try {
            const newState = {
                ...currentUserInfo(),
                configs: {
                    ...currentUserInfo()?.configs,
                    is_always_listening: !alwaysListen,
                }
            };
            dispatch(setConfigs(newState));
            updateConfig(newState);
            if (alwaysListen) {
                stream?.getTracks()?.forEach(track => track?.stop());
                setStream(null);
            } else {
                const newStream = await navigator?.mediaDevices?.getUserMedia({ audio: true });
                setStream(newStream);
            }
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    }

    async function updateConfig(request) {
        const id = currentUserInfo()?.user_info?.id;
        const payload = {
            extra_perms: {
                is_always_listening: request?.configs?.is_always_listening,
            }
        };
        try {
            await usersApi.patch(id, payload);
        } catch (error) {
            resolveError(error);
        }
    }

    if (!micUsage) {
        return null;
    }

    return (
        <MenuItem onClick={toggle}>
            <ListItemIcon className='user-menu-icon'>
                <FontAwesomeIcon icon={alwaysListen ? faMicrophoneSlash : faMicrophone} />
            </ListItemIcon>
            {t(`microphone-${alwaysListen ? "activate" : "desactivate"}`)}
        </MenuItem>
    );
}

export default ToggleMic;