import { lazy } from '../utilities';

const fileIcons = {
	
	"pdficon": {
		allowedTag: "pdficon",
		Icon: lazy(async () => import("./PDF.svg")),
	},
	"xlsxicon": {
		allowedTag: "xlsxicon",
		Icon: lazy(async () => import("./XLSX.svg")),
	},
};

export default fileIcons;
