
import React from "react";

/* components */
import Avatar from '@mui/material/Avatar';;

/* own components */
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import { upperCase } from "lodash";
import MenuOptions from './MenuOptions';
import { toTitleCase, useController } from './utilities';
import { hasValue } from '../../../general/GeneralUtilities';

const UserSection = () => {

	const {
		anchor,
		open,
		onOpen,
		onClose,
		handleLogOut,
		changeTheme,
		username,
		photo,
		jobPosition,
	} = useController();

	return (
		<div className="header-btn-lg">
			<div className="drop-user">
				<div className="user-wrapper">
					<div className="username-main-header">
						<span>
							{toTitleCase(username)}
						</span>
						{hasValue(jobPosition) && <span className="job-position-name">
							{upperCase(jobPosition)}
						</span>}
					</div>
					<IconButton
						onClick={onOpen}
						ref={anchor}
					>
						<Avatar alt={username} src={photo} />
					</IconButton>
				</div>

				<Menu
					anchorEl={anchor?.current}
					className={"user-menu-options"}
					open={open}
					onClose={onClose}
					PaperProps={{
						elevation: 1,
						sx: {
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							'&::before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				>
					<MenuOptions
						user={username}
						photo={photo}
						onClose={onClose}
						handleLogOut={handleLogOut}
						changeTheme={changeTheme}
					/>
				</Menu>
			</div>
		</div>
	);
};

export default UserSection;