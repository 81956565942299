import React from 'react';
import {
    InputPrefix,
    InputSeparator
} from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";

export const SearchPrefix = () => (
    <>
        <InputPrefix>
            <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontSize: "14px" }} />
        </InputPrefix>
        <InputSeparator />
    </>
);
