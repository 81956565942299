
import React, { useState } from "react";
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next";
/* components */
import Avatar from '@mui/material/Avatar';
/* own components */
import ThemeOption from "./ThemeOption";
import ChangePasswordOption from "./ChangePasswordOption";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ToggleMic from "./ToggleMic";
import IconUse from "../../../uiDesign/IconUse";
import Divider from "../../../general/Divider";
import { store } from "../../../../../../store";
import Modal from "../windows/EnterpriseTab/Modal";
const MenuOptions = ({
    email,
    user,
    photo,
    onClose,
    handleLogOut,
}) => {
    const { t } = useTranslation();
    const rol = store.getState()?.group?.select?.name;
    const company = store.getState()?.tenant?.current?.name;
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true)
    };

    return (
        <div className="rigth-menu-card">
            <div className="superior-banner"></div>
            <div className="menu-user-info-container">
                <div className="avatar-and-personal-info ">
                    <Avatar alt={user} src={photo} sx={{ width: 80, height: 80 }} />
                    {/* <BaseButton
                        label='Cambiar foto'
                        onClick={() => null}
                        className="select-photo-button"
                    /> */}
                    <div className="menu-user-info">
                        <span>
                            {user}
                        </span>
                        <p>{email}</p>
                    </div>
                </div>
                <div className="company-info">


                    <button
                        className="company-info-badge"
                        onClick={handleClick}
                        data-cy="rol-badge"
                    >
                        Rol: <span> {rol} </span>
                    </button>


                    <div className="company-info-badge">
                        Empresa: <span> {company} </span>
                    </div>
                </div>
            </div>

            <div className="menu-accesibility-container">
                <Divider />
                {t('Accesibilidad')}
                <ChangePasswordOption onClose={onClose} />
                <MenuItem onClick={handleClick}>
                    <ListItemIcon className='user-menu-icon'>
                        <IconUse icon={'userviewfinder'} style={{ 'width': '18px', 'height': '18.5px' }} />
                    </ListItemIcon>
                    {t('Cambiar empresa y rol')}

                </MenuItem>
                <Modal open={open} setOpen={setOpen} />
                <ThemeOption />
                <ToggleMic />
            </div>

            <div className="menu-footer-container">
                <div className="logout-button">
                    <MenuItem onClick={handleLogOut} >
                        <ListItemIcon className='user-menu-icon'>
                            <IconUse icon={'logout'} style={{ 'width': '19px', 'height': '19px' }} />
                        </ListItemIcon>
                        {t('logout')}
                    </MenuItem>
                </div>
            </div>

        </div>
    );
};

MenuOptions.propTypes = {
    email: PropTypes.string,
    user: PropTypes.string,
    photo: PropTypes.string,
    onClose:PropTypes.func,
    handleLogOut:PropTypes.func,
}

export default MenuOptions;