
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AbrhilWindowProvider } from '../../contextsAndControllers/useWindow';
import Window from './Window';
import TabsContainer from './TabsContainer';
import { getCurrentPath } from '../../utilities';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Pages from "../../Routes";
import Index from "../../../../../modules/system/principal/Welcome"
// import Index from "./FieldsTester"
import IconsSet from './IconsSet';

const WINDOWS = 6;

const Windows = () => {

	const [fixedElement, setFixedElement] = useState(false);
	const { stamp } = useSelector(state => state.app);

	useEffect(() => {
		let current = getCurrentPath();
		setFixedElement(current.includes("packages"));
		//eslint-disable-next-line
	}, []);

	const classNameback = stamp ? 'stamp-background' : '';

	return (
		<div key='abrl-main-windows-container' className={`windows-container ${classNameback}`}>
			<TabsContainer fixedElement={fixedElement} />
			<div id="windows-container">
				{
					fixedElement ?
						<PerfectScrollbar id={"app-main-container-scroller"} className={"app-main__inner gradientBg"}>
							<Pages />
						</PerfectScrollbar>
						:
						<>
							<IconsSet />
							<Index />
							{Array.from({ length: WINDOWS }).map((_, i) =>
								<AbrhilWindowProvider key={`abrl_window_${i + 1}`} windowNumber={i + 1}>
									<Window id={`window_${i + 1}`} />
								</AbrhilWindowProvider>
							)}
						</>
				}
			</div>
		</div>
	);
};

export default Windows;