import {
	SHOW_ADVANCE_FILTERS,
	HIDE_ADVANCE_FILTERS,
} from '../actions'

const initialState = {
	filters: [],
	show: false
};

export function filter(state = initialState, action) {
	switch (action.type) {
		case SHOW_ADVANCE_FILTERS:
			return {
				...state,
				...action.payload,
				show: true
			}

		case HIDE_ADVANCE_FILTERS:
			return {
				...state,
				show: false
			}

		default:
			return state
	}
}