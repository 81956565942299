import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCog,
} from "@fortawesome/pro-light-svg-icons";
import Modal from "./Modal";
import useController from './useController';

const EnterpriseTab = () => {

	const { tenantValue } = useController();
	const [open, setOpen] = useState(false);

	return (
		<div className={`abrhil-window-tab enterprise-tab`}>
			<button className='abrhil-tabs-reaload-button' onClick={() => setOpen(true)}>
				<FontAwesomeIcon icon={faCog} />
			</button>
			<div className="tab-start" />
			<div className="tab-content">
				<span className='tab-text' onClick={() => setOpen(true)}>
					{tenantValue?.name}
				</span>
			</div>
			<div className="tab-end" />
			<Modal open={open} setOpen={setOpen} />
		</div>
	);
};

export default EnterpriseTab;